export interface BankCode {
  Name: string;
  Code: string;
}

export const SavedBankCodes = {
  data: {
    Banks: [
      {
        Id: 132,
        Code: '560',
        Name: 'Page MFBank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 133,
        Code: '304',
        Name: 'Stanbic Mobile Money',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 134,
        Code: '308',
        Name: 'FortisMobile',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 135,
        Code: '328',
        Name: 'TagPay',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 136,
        Code: '309',
        Name: 'FBNMobile',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 137,
        Code: '011',
        Name: 'First Bank of Nigeria',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 138,
        Code: '326',
        Name: 'Sterling Mobile',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 139,
        Code: '990',
        Name: 'Omoluabi Mortgage Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 140,
        Code: '311',
        Name: 'ReadyCash (Parkway)',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 141,
        Code: '057',
        Name: 'Zenith Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 142,
        Code: '068',
        Name: 'Standard Chartered Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 143,
        Code: '306',
        Name: 'eTranzact',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 144,
        Code: '070',
        Name: 'Fidelity Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 145,
        Code: '023',
        Name: 'CitiBank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 146,
        Code: '215',
        Name: 'Unity Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 147,
        Code: '323',
        Name: 'Access Money',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 148,
        Code: '302',
        Name: 'Eartholeum',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 149,
        Code: '324',
        Name: 'Hedonmark',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 150,
        Code: '325',
        Name: 'MoneyBox',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 151,
        Code: '301',
        Name: 'JAIZ Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 152,
        Code: '050',
        Name: 'Ecobank Plc',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 153,
        Code: '307',
        Name: 'EcoMobile',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 154,
        Code: '318',
        Name: 'Fidelity Mobile',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 155,
        Code: '319',
        Name: 'TeasyMobile',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 156,
        Code: '999',
        Name: 'NIP Virtual Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 157,
        Code: '320',
        Name: 'VTNetworks',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 158,
        Code: '221',
        Name: 'Stanbic IBTC Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 159,
        Code: '501',
        Name: 'Fortis Microfinance Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 160,
        Code: '329',
        Name: 'PayAttitude Online',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 161,
        Code: '322',
        Name: 'ZenithMobile',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 162,
        Code: '303',
        Name: 'ChamsMobile',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 163,
        Code: '403',
        Name: 'SafeTrust Mortgage Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 164,
        Code: '551',
        Name: 'Covenant Microfinance Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 165,
        Code: '415',
        Name: 'Imperial Homes Mortgage Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 166,
        Code: '552',
        Name: 'NPF MicroFinance Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 167,
        Code: '526',
        Name: 'Parralex',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 168,
        Code: '035',
        Name: 'Wema Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 169,
        Code: '084',
        Name: 'Enterprise Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 170,
        Code: '063',
        Name: 'Diamond Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 171,
        Code: '305',
        Name: 'Paycom',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 172,
        Code: '100',
        Name: 'SunTrust Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 173,
        Code: '317',
        Name: 'Cellulant',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 174,
        Code: '401',
        Name: 'ASO Savings and & Loans',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 175,
        Code: '030',
        Name: 'Heritage',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 176,
        Code: '402',
        Name: 'Jubilee Life Mortgage Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 177,
        Code: '058',
        Name: 'GTBank Plc',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 178,
        Code: '032',
        Name: 'Union Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 179,
        Code: '232',
        Name: 'Sterling Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 180,
        Code: '076',
        Name: 'Skye Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 181,
        Code: '082',
        Name: 'Keystone Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 182,
        Code: '327',
        Name: 'Pagatech',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 183,
        Code: '559',
        Name: 'Coronation Merchant Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 184,
        Code: '601',
        Name: 'FSDH',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 185,
        Code: '313',
        Name: 'Mkudi',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 186,
        Code: '214',
        Name: 'First City Monument Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 187,
        Code: '314',
        Name: 'FET',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 188,
        Code: '523',
        Name: 'Trustbond',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 189,
        Code: '315',
        Name: 'GTMobile',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 190,
        Code: '033',
        Name: 'United Bank for Africa',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 191,
        Code: '044',
        Name: 'Access Bank',
        IsMobileVerified: null,
        branches: null,
      },
      {
        Id: 567,
        Code: '90115',
        Name: 'TCF MFB',
        IsMobileVerified: null,
        branches: null,
      },
    ],
    Token: {
      access_token: null,
      refresh_token: null,
      token_type: null,
      expires_in: 0,
    },
    Status: 'Success',
    Message: 'successul',
    Data: null,
    AllTransactions: null,
  },
};
