<div class="website-design">
    <form [formGroup]="websiteForm"
          enctype="multipart/form-data"
          autocomplete="off">
        <div class="website-design__templates">
            <div class="d-flex align-items-center">
                <h4>Select Template</h4>
                <app-info-box class="ml-3"
                              icon="info-icon-grey"
                              title="Website template"
                              content="<p>Change the look and feel of your website by selecting your preferred template</p>">
                </app-info-box>
            </div>
            <div class="row pl-2 pr-2">
                <div *ngFor="let template of templates"
                     class="website-design__template"
                     [class.website-design__active]="template.isSelected">
                    <div class="website-design__overlay-button"
                         (click)="openModal(template)">
                        Click to view full image
                    </div>
                    <div class="website-design__template-image">
                        <img [id]="'template_' + template.name"
                             class="w-100"
                             [src]="template.imageUrl"
                             [alt]="template.name" />
                    </div>
                    <div class="website-design__template-label">
                        <div>
                            <span *ngIf="template.isSelected">Active: &nbsp;</span>
                            {{ template.publicName }}
                        </div>
                        <button [disabled]="template.isSelected"
                                [class.opacity-3-outline]="template.isSelected"
                                mat-stroked-button
                                (click)="onTemplateSelect(template)"
                                color="primary"
                                class="se-button se-button--stroke">
                            SELECT
                        </button>
                        <input [id]="'fileInput_' + template.name.split(' ')[0]"
                               [hidden]="true"
                               type="file"
                               name="bannerImage"
                               accept="image/png, image/jpeg, image/jpg"
                               (change)="getImage($event.target.files[0])" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mt-4 d-flex align-items-center">
                        <h4>Choose a Colour</h4>
                        <app-info-box class="ml-3"
                                      icon="info-icon-grey"
                                      title="Select your brand colour"
                                      content="<p>This colour will be used to highlight the display of your website</p>">
                        </app-info-box>
                    </div>
                    <mat-form-field appearance="outline"
                                    class="se-input-field">
                        <mat-label>click to pick a Colour</mat-label>
                        <input type="color"
                               id="logoTextId"
                               [value]="brandColor.value"
                               formControlName="brandColor"
                               matInput />
                        <mat-hint>You'll have to click the save and publish button below to see
                            colour on your website
                        </mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="mt-4 d-flex align-items-center">
                        <h4>Add a Text Logo</h4>
                        <app-info-box class="ml-3"
                                      icon="info-icon-grey"
                                      title="Website logo"
                                      content="<p>By default, your business name is used as your website logo</p>
                            <p>You can change the website logo by providing a text that will be used instead</p>">
                        </app-info-box>
                    </div>
                    <mat-form-field appearance="outline"
                                    class="se-input-field">
                        <mat-label>Type text logo</mat-label>
                        <input type="text"
                               id="logoTextId"
                               formControlName="logoText"
                               matInput />
                        <mat-error>Your text logo seems to be incorrect</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <h4>Upload Logo Image</h4>
                        <app-info-box class="ml-3"
                                      icon="info-icon-grey"
                                      title="Upload logo image"
                                      content="<p>The uploaded image will replace the text logo on your website</p>
                                      <p>Select <b>CROP IMAGE</b> button after positioning your logo, for changes to take effect on your website</p>">
                        </app-info-box>
                    </div>
                    <app-upload-website-logo #uploadLogo
                                             [logoControl]="logoControl"
                                             [logoUrl]="logoUrl">
                    </app-upload-website-logo>
                </div>
            </div>
            <div class="row website-design__cover-image">
                <div class="col-md-6">
                    <div class="mt-4 d-flex align-items-center">
                        <h4>Homepage Image</h4>
                        <app-info-box class="ml-3"
                                      title="Replace homepage image"
                                      content="<p>We use the default Banner Image for your home page</p>
                            <p>You can change this to an image you prefer by clicking <b>REPLACE IMAGE</b> and choose your preferred image. Also ensure you enter a cover title and a supporting text for the image</p>">
                        </app-info-box>
                    </div>
                    <div class="website-design__image-wrapper">
                        <img [src]="selectedTemplate.coverImageUrl"
                             alt="selleasy image" />
                        <button mat-stroked-button
                                (click)="replaceImage()"
                                color="primary"
                                class="se-button">
                            <span>REPLACE IMAGE</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-6 website-design__form-wrapper">
                    <mat-form-field appearance="outline"
                                    class="se-input-field">
                        <mat-label>Cover title</mat-label>
                        <input type="text"
                               id="bannerTitleId"
                               formControlName="bannerTitle"
                               matInput />
                        <mat-error>Your cover title seems to be incorrect</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                                    class="se-input-field">
                        <mat-label>Add a supporting text</mat-label>
                        <textarea matInput
                                  id="bannerDescriptionId"
                                  formControlName="bannerDescription"
                                  rows="9"></textarea>
                        <mat-error>You seem to have an invalid supporting text</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <app-error-alert *ngIf="uploadLogo?.showCropper"
                         text="Please crop your logo before saving">
        </app-error-alert>

        <div class="d-flex mb-5">
            <button mat-stroked-button
                    (click)="submitForm()"
                    [class.opacity-3-outline]="websiteForm.invalid"
                    color="primary"
                    class="se-button se-button--stroke">
                SAVE
            </button>
            <button mat-flat-button
                    (click)="publishSettings()"
                    color="primary"
                    class="se-button ml-auto">
                SAVE &amp; PUBLISH
            </button>
        </div>
    </form>
    <a [href]="url"
       target="_blank"
       id="__view_site1"></a>
</div>
