import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { FREETEXT_REGEX, PHONE_REGEX } from '../shared/utils/regexes';

/*
  TODO: REFACTOR COMPONENT AND CHILD COMPONENTS TO USE THE SMART/PRESENTATION COMPONENTS DESIGN
*/
@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss'],
})
export class WebsiteComponent implements OnInit {
  websiteForm: FormGroup;
  template: any;
  isLoading: false;
  refreshChildData: Subject<boolean> = new Subject();
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
  ) {}
  selectedTab = {
    value: 0,
  };

  ngOnInit(): void {
    this.buildForm();
    this.activatedRoute.params.subscribe(({ showCustomize }) => {
      if (showCustomize) {
        this.selectedTab.value = 1;
      }
    });
  }

  refresh() {
    this.refreshChildData.next(true);
  }

  selectedTemplate(value: any) {
    this.template = value;
  }

  showLoader(value: any) {
    this.isLoading = value;
  }

  buildForm() {
    this.websiteForm = this.fb.group({
      businessEmail: [, [Validators.email]],
      businessPhoneNumber: [, Validators.pattern(PHONE_REGEX)],
      businessAddress: [, [Validators.pattern(FREETEXT_REGEX)]],
      businessAddress2: [, Validators.pattern(FREETEXT_REGEX)],
      whatsappNum: [, Validators.pattern(PHONE_REGEX)],
      facebookPage: [, Validators.pattern(FREETEXT_REGEX)],
      instagramPage: [, Validators.pattern(FREETEXT_REGEX)],
      twitterPage: [, Validators.pattern(FREETEXT_REGEX)],
      termsOfUse: new FormControl({ value: true, disabled: true }),
      privacyPolicy: new FormControl({ value: true, disabled: true }),
      refundPolicy: new FormControl({ value: true, disabled: true }),
      navItems: [[{ name: null, subNavs: [] }]],
      template: [],
      brandColor: [],
      bannerImage: [],
      bannerTitle: [, Validators.pattern(FREETEXT_REGEX)],
      bannerDescription: [, Validators.pattern(FREETEXT_REGEX)],
      logoText: [, Validators.pattern(FREETEXT_REGEX)],
      logo: '',
      metadata: [, Validators.pattern(FREETEXT_REGEX)],
      websiteTitle: [, Validators.pattern(FREETEXT_REGEX)],
      websiteDescription: [, Validators.pattern(FREETEXT_REGEX)],
    });
  }
}
