import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ContentChildren,
  QueryList,
  AfterContentInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  MatTableDataSource,
  MatHeaderRowDef,
  MatRowDef,
  MatColumnDef,
  MatTable,
} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { defer } from 'lodash';
import { SelectionModel } from '@angular/cdk/collections';
import { StateFacade } from '../services/state.facade';
import { fromEvent } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, AfterContentInit {
  @Input() displayedColumns: string[];
  @Input() showSelectBoxes = false;
  @Input() dataSource: MatTableDataSource<any[]>;
  @Input() showHeaderActions = false;
  @Input() pageSize = null;
  @Input() dataLength = 0;
  @Input() stickyHeader = false;
  @Input() emptyStateTemplate;
  @Input() isLoading = false;
  @Output() onSelectionInit = new EventEmitter();
  @Output() onRowSelected = new EventEmitter();
  @Output() onPaginatorInit = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  isComponentActive = true;
  showSearchInput = false;
  selection = new SelectionModel<any>(true, []);
  isMobile: boolean;

  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<any>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  constructor(private stateFacade: StateFacade) {}

  ngAfterContentInit() {
    this.columnDefs.forEach((columnDef) => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach((rowDef) => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach((headerRowDef) =>
      this.table.addHeaderRowDef(headerRowDef),
    );
    this.exposePaginatorStream();
  }

  exposePaginatorStream() {
    this.onPaginatorInit.emit(this.paginator);
  }

  hideSeachBarOnCLickOut() {
    fromEvent(document, 'click')
      .pipe(
        filter((el: any) => {
          const domEl: any = document.querySelector('#data-table-header');
          if (domEl && typeof domEl === 'object') {
            return !domEl.contains(el.target);
          }
          return false;
        }),
        takeWhile(() => this.isComponentActive),
      )
      .subscribe((_) => {
        if (this.isMobile) {
          this.clearFilter();
        }
      });
  }

  clearFilter() {
    (document.querySelector('#searchInput') as any).value = '';
    this.applyFilter({ target: { value: '' } } as any);
    this.showSearchInput = false;
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
      this.showSearchInput = !v.isMobile;
    });
    this.hideSeachBarOnCLickOut();
    this.onSelectionInit.emit(this.selection as any);
    this.selection.changed.subscribe((val) => {
      this.onRowSelected.emit(val.source.selected);
    });
  }

  showSearchField(): void {
    this.showSearchInput = !this.showSearchInput;
    defer(() => (document.querySelector('#searchInput') as any).focus());
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row = null): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this.isComponentActive = false;
  }
}
