import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateFacade } from '../shared/services/state.facade';
import { TypeAhead } from '../shared/services/typeahead';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
  FormControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FREETEXT_REGEX } from '../shared/utils/regexes';
import { IProduct, Category, variantErrors } from '../shared/models/product';
import { ProductService } from '../shared/services/product-service';
import { AuthService } from '../shared/services/auth-service';
import { Status } from '../shared/models/status';
import { Variation, ProductVariant } from '../shared/models/variation';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import groupBy from 'lodash/groupBy';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { combineLatest, noop } from 'rxjs';
import { AccountService } from '../shared/services/account-service';
import { finalize, tap, delay, takeWhile, filter } from 'rxjs/operators';
import { capitalizeString } from '../shared/utils/format-text';
import { FormErrorService } from '../shared/services/form-error.service';
import { noWhiteSpace } from '../shared/utils/validations';
import { SubscriptionEnum } from '../shared/enums/subscription';
import { ProductVariantComponent } from '../product-variant/product-variant.component';
import { uniqueOptions } from '../shared/utils/variation-utils';
import { defer } from 'lodash/fp';
import { Editor, Toolbar } from 'ngx-editor';
import schema from '../shared/utils/editor/schema';
import plugins from '../shared/utils/editor/plugins';
import { customeReq } from '../shared/utils/editor/custom-validator';
import { adjustModalWidth } from '../shared/utils/helpers';
import { CATEGORIES } from '../shared/models/categories';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit, OnDestroy {
  @ViewChild('autoInputCategory') categoryAutoTrigger: MatAutocompleteTrigger;
  @ViewChild('autoInputNav') navAutoTrigger: MatAutocompleteTrigger;
  @ViewChild('autoInputSubNav') subNavAutoTrigger: MatAutocompleteTrigger;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  isMobile: boolean;
  addProductFormGrp: FormGroup;
  options: string[] = [];
  categoryOptions: Category[] = [];
  categoryTypeAhead: TypeAhead = {} as any;
  navigationTypeAhead: TypeAhead = {} as any;
  subNavigationTypeAhead: TypeAhead = {} as any;
  imagePosition = 0;
  showDiscount = true;
  showProductVariant = { show: true };
  showCategory = true;
  imageUri: string[];
  navItems: any[] = [];
  subNavItems: any[] = [];
  subNavs: any[] = [];
  storeConfig: any;
  businessId: string;
  nav = '';
  html = '';
  businessVariationList: Variation[] = [];
  generateVariant: boolean;
  editMode: boolean = false;
  productId: string;
  productVariation: ProductVariant[];
  savedImages: string[];
  deleteImageList: string[];
  productTitle = 'Add Product';
  productSubtitle = 'Add product details and images';
  variantErrors;
  isInvalidVariant = false;
  isSubmitting = false;
  editNavSetup = [];
  hasAsyncErr = false;
  isComponentActive = true;
  @ViewChild('productVariants') productVariantsRef: ProductVariantComponent;
  emptyVariantOptionFieldRef: HTMLElement | null;
  emptyVariantPriceFieldRef: HTMLElement | null;
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  forwardSlashRegex = /\//g;
  CATEGORIES = CATEGORIES;

  constructor(
    private stateFacade: StateFacade,
    private productService: ProductService,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private profileBusinessService: ProfileBusinessService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private accountService: AccountService,
    private formErr: FormErrorService,
  ) {
    this.productId = '';
    this.deleteImageList = [];
    this.generateVariant = false;
    this.imageUri = [];
    this.savedImages = [];
    this.getNavItems();
  }

  emptyVariantOptionField(e) {
    if (e) {
      this.emptyVariantOptionFieldRef = e.currentTarget;
    }
  }

  emptyVariantPriceField(e) {
    if (e) {
      this.emptyVariantPriceFieldRef = e.currentTarget;
    }
  }

  initVariantErrors(errors) {
    this.variantErrors = errors;
  }

  private _filter(options = [], value: string): string[] {
    value = value || '';
    const filterValue = value.toLowerCase();

    return options.filter(
      (option) =>
        typeof option === 'string' &&
        option.toLowerCase().includes(filterValue),
    );
  }

  private freeText = () => Validators.pattern(FREETEXT_REGEX);

  private showAddButton(options, value = '') {
    value = value || '';
    return !options
      .map((opt) => opt.toLowerCase())
      .includes(value.trim().toLowerCase());
  }

  private initVariantCtrl() {
    return {
      options: [
        {
          option: '',
          values: [],
        },
      ],
      variations: [],
    };
  }

  private initDiscountsCtrl() {
    return [
      { name: 'Xmas Promo', value: 5, status: false },
      { name: 'Salah Promo', value: 5, status: false },
    ];
  }

  private initImagesCtrl() {
    return [
      { file: null, imageSrc: null, isCoverImage: true },
      { file: null, imageSrc: null, isCoverImage: false },
      { file: null, imageSrc: null, isCoverImage: false },
      { file: null, imageSrc: null, isCoverImage: false },
      { file: null, imageSrc: null, isCoverImage: false },
    ];
  }

  private buildForm() {
    this.addProductFormGrp = this.fb.group({
      name: ['', [this.freeText(), Validators.required, noWhiteSpace]],
      price: [
        '',
        [this.freeText(), Validators.required, Validators.min(1), noWhiteSpace],
      ],
      quantity: [
        '',
        [this.freeText(), Validators.required, Validators.min(1), noWhiteSpace],
      ],
      weight: [
        '',
        [
          this.freeText(),
          Validators.required,
          Validators.min(0.001),
          noWhiteSpace,
        ],
      ],
      description: ['', [Validators.required, customeReq]],
      variants: [this.initVariantCtrl()],
      discount: [, [Validators.min(1), Validators.max(100), noWhiteSpace]],
      discounts: [this.initDiscountsCtrl()],
      images: [this.initImagesCtrl()],
      category: [
        '',
        [
          Validators.required,
          (ctrl) =>
            !this.CATEGORIES.includes(ctrl.value) ? { invalid: true } : null,
        ],
      ],
      navigation: ['', this.freeText()],
      subNavigation: ['', this.freeText()],
    });
  }

  get name() {
    return this.addProductFormGrp.get('name');
  }

  get price() {
    return this.addProductFormGrp.get('price');
  }

  get quantity() {
    return this.addProductFormGrp.get('quantity');
  }

  get weight() {
    return this.addProductFormGrp.get('weight');
  }

  get description() {
    return this.addProductFormGrp.get('description');
  }

  get variants() {
    return this.addProductFormGrp.get('variants');
  }

  get discounts() {
    return this.addProductFormGrp.get('discounts');
  }

  get images() {
    return this.addProductFormGrp.get('images');
  }

  get category() {
    return this.addProductFormGrp.get('category');
  }

  get navigation() {
    return this.addProductFormGrp.get('navigation');
  }

  get subNavigation() {
    return this.addProductFormGrp.get('subNavigation');
  }

  get hasVariantErrors() {
    return this.sumVariantQty()?.quantity > Number(this.quantity.value);
  }

  sumVariantQty() {
    return this.variants.value?.variations.reduce(
      (acc, curr) => {
        acc.quantity += Number(curr.quantity);
        return acc;
      },
      { quantity: 0 },
    );
  }

  getVariations() {
    this.productService
      .getVariations()
      .pipe(
        takeWhile(() => this.isComponentActive),
        filter((v: any) => !!v && v?.length),
      )
      .subscribe((variations) => {
        this.businessVariationList = variations as Variation[];
        this.buildVariations();
      });
  }

  ngOnInit(): void {
    this.getVariations();
    this.getProfiles();
    this.buildForm();
    this.listenForViewPortChanges();
    this.getProductCategories();

    this.businessId = this.authService.getBusinessId();
    this.activatedRoute.params
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((routeParams) => {
        this.getParam(routeParams?.id);
      });
    this.imageUri = [];
    this.initEditor();
  }

  initEditor() {
    this.editor = new Editor({
      schema,
      plugins,
    });
    this.editor.onFocus.subscribe((_) => {
      document.querySelector('#editorWrapper').classList.add('outline');
    });
    this.editor.onBlur.subscribe((_) => {
      document.querySelector('#editorWrapper').classList.remove('outline');
    });
  }

  gotoWebsiteCustomize(): void {
    this.router.navigate(['/website', { showCustomize: true }]);
  }

  getProfiles() {
    combineLatest([
      this.profileBusinessService.getBusinessProfile(),
      this.accountService.getAccountDetails(),
    ])
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe(([profile, account]: [any, any]) => {
        if (!profile?.delivery?.length && account?.accountNumber) {
          this.openModal({
            header: 'You should setup your profile first',
            body: `Please setup your profile before you add a product. We'll need some of the settings to add a product.`,
            ctas: {
              back: () => history.back(),
              act: () => this.router.navigate(['/profile']),
              textLeft: 'BACK',
              textRight: this.isMobile ? 'PROFILE' : 'GO TO STORE PROFILE',
            },
          });
        } else if (!account?.accountNumber && profile?.delivery?.length) {
          this.openModal({
            header: 'You should setup your account details first',
            body: `Please setup your account before you add a product. We'll need your account details to add a product.`,
            ctas: {
              back: () => history.back(),
              act: () => this.router.navigate(['/account-details']),
              textLeft: 'BACK',
              textRight: this.isMobile ? 'ACCOUNT' : 'GO TO ACCOUNT DETAILS',
            },
          });
        } else if (!account?.accountNumber && !profile?.delivery?.length) {
          this.openModal({
            header: 'You should setup your account details and profile first',
            body: `Please setup your account details and store profile before adding a product. We'll need your account details and profile to add a product.`,
            ctas: {
              back: () => this.router.navigate(['/profile']),
              act: () => this.router.navigate(['/account-details']),
              textLeft: 'PROFILE',
              textRight: this.isMobile ? 'ACCOUNT' : 'ACCOUNT DETAILS',
            },
          });
        }
      });
  }

  openModal(content, disableClose = true) {
    this.dialog.open(ConfirmationModalComponent, {
      width: this.isMobile ? '100vw' : '40%',
      data: content,
      disableClose,
    });
  }

  setDeleteList(src: any) {
    this.deleteImageList.push(src);
    // remove from saved image
    const idx = this.savedImages.indexOf(src);
    this.savedImages.splice(idx, 1);
  }

  /**
   * @description extract id from path param
   * @param id
   */
  getParam(id: string) {
    if (id !== null && id !== ':id') {
      if (id === 'duplicate') {
        this.productService.sharedProdId.subscribe((p) => {
          if (p !== null) {
            const dupeProd: IProduct = JSON.parse(p);
            // populate form
            delete dupeProd.imageUri;
            this.populateProductForm(dupeProd);
          }
        });
        return;
      }
      this.editMode = true;
      this.getProduct(id);
      this.stateFacade.setPageTitle('Edit Product');
      this.productTitle = 'Edit Product';
      this.productSubtitle = 'Edit product images and product details';
    } else {
      this.buildForm();
      this.getProductCategories();
      this.editMode = false;
      this.stateFacade.setPageTitle('Add Product');
      this.productSubtitle = 'Upload product images and add product details';
    }
  }

  /**
   * @description Get product by id
   * @param id
   */
  getProduct(id) {
    this.productId = id;
    this.productService
      .getProduct(id)
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((result: any[]) => {
        const product: IProduct = result.find((i) => i.id === id);
        this.populateProductForm(product);
      });
  }

  populateProductForm(product: IProduct) {
    this.name.setValue(product.name);
    this.price.setValue(product.price);
    this.quantity.setValue(product.quantity);
    this.weight.setValue(product.weight);
    this.description.setValue(product.description);
    this.category?.setValue(product.category);
    const tag = product.tags[0];

    if (tag) {
      product.tags[0] = tag.includes('__') ? tag.split('__')[1] : tag;
    }

    // Setup nav
    if (this.editNavSetup.length && product.tags.length) {
      // setup navigation and sub-navigation
      this.editNavSetup.forEach((item) => {
        if (typeof item !== 'string') {
          const objKey = Object.keys(item ?? []).toString();
          if (objKey === product.tags[0]) {
            this.navigation?.setValue(objKey);
          } else {
            const index = Object.values(item ?? []).findIndex((i: any) =>
              i.includes(product.tags[0]),
            );
            if (index !== -1) {
              this.navigation?.setValue(objKey);
              this.selectOption(objKey);
              this.subNavigation?.setValue(product.tags[0]);
            }
          }
        } else {
          if (item === product.tags[0]) {
            this.navigation?.setValue(product.tags[0]);
          }
        }
      });
      this.setupNavigationTypeahead();
      this.setupSubNavigationTypeahead();
    }

    const images = [];
    product.imageUri.map((i, index) => {
      images.push({ file: null, imageSrc: product.imageUri[index] });
    });

    if (images.length < 5) {
      const range = 5 - images.length;
      for (let i = 0; i < range; i++) {
        images.push({ file: null, imageSrc: null });
      }
    }
    this.images.setValue(images);
    this.savedImages = product.imageUri;

    this.productVariation = product.variations as ProductVariant[];

    // pre-populate variationIds
    if (this.productVariation) {
      let ids = product.variations.map((p: any) => p?.variationId);
      ids = ids.concat.apply([], ids);
      const variations = this.businessVariationList.filter((i) =>
        ids.includes(i.id),
      );

      this.setupVariations(variations);
    }
  }

  buildVariations(populate = false) {
    // this.productService.getVariations().subscribe((result: any) => {
    // this.businessVariationList = result;
    if (!this.editMode && populate === true) {
      this.setupVariations(this.businessVariationList);
    }
    // });
  }

  setupVariations(list) {
    const groupedList = groupBy(list, (list) =>
      this.capitalizeString(list.attributeName),
    );

    const k = Object.keys(groupedList);
    const v = Object.values(groupedList);
    const optionList = [];
    k.forEach((item, index) => {
      const opt = {
        option: item,
        values: v[index].map((i) => i.attributeValue),
      };
      optionList.push(opt);
    });

    this.variants.setValue({
      options: [...optionList],
    });
    this.generateVariant = true;
  }

  prepSubNavs(nav) {
    if (!nav) return;

    if (!this.subNavigation.value.trim()) {
      this.selectOption(nav, true);
    }
  }

  selectOption(option: string, open = false) {
    this.nav = this.addProductFormGrp.value.navigation;
    // clear current selection
    this.subNavs = [];
    this.subNavigation?.setValue('');
    // populate subNav
    this.subNavItems.forEach((s) => {
      if (Object.keys(s).map((item) => item === option)[0] === true) {
        // add to sub navigation and open drop down
        this.subNavs = Object.values(s).map((i) => i);
        this.subNavs = this.subNavs.concat.apply([], this.subNavs);
      }
    });
    this.setupSubNavigationTypeahead();
    if (open) {
      this.subNavigationTypeAhead.openPanel();
    }
  }

  isFreeOrBasic(subsctiptionType) {
    return (
      subsctiptionType === SubscriptionEnum.FREE ||
      subsctiptionType === SubscriptionEnum.BASIC
    );
  }

  validatePlan() {
    const subsctiptionType = this.storeConfig?.subscription.find(
      (s: { active: any }) => s?.active,
    )?.subscriptionType;
    if (
      // @TODO: quantity limit should probably be fed from the client env file
      this.storeConfig.productCount > 50 &&
      this.isFreeOrBasic(subsctiptionType)
    ) {
      // show modal;
      this.openModal({
        header: 'Upgrade your plan',
        body: `You are currently on the ${SubscriptionEnum[subsctiptionType]} plan which only allows 50 products. Upgrade your plan now to add more products.`,
        ctas: {
          back: () => this.router.navigate(['/manage-product']),
          act: () => this.router.navigate(['/profile', { showPlan: true }]),
          textLeft: 'CLOSE',
          textRight: 'UPGRADE',
        },
      });
    }
  }

  getNavItems() {
    this.profileBusinessService
      .getBusinessProfile()
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((result: any) => {
        this.storeConfig = result;

        // validate plan
        this.validatePlan();

        this.navItems = this.storeConfig?.configuration
          .filter((i) => i.isSelected === true)
          .map((s) => s.navItems);
        this.options = this.options.concat(
          this.navItems.concat.apply([], this.navItems),
        );
        if (this.editMode) {
          this.editNavSetup = [...this.options.filter((opt) => !!opt)];
        }
        this.options.forEach((item, index) => {
          if (typeof item !== 'string') {
            this.subNavItems.push(item);

            // add object key to main nav
            this.options[index] = Object.keys(item ?? []).toString();
          }
        });

        // setControl OR setValidators
        this.addProductFormGrp.setControl(
          'navigation',
          new FormControl('', [
            this.freeText(),
            (ctrl) => {
              if (!ctrl.value) {
                return null;
              }
              return !this.navigationTypeAhead
                .get('options')
                .map((i) => i.toLowerCase())
                .includes(ctrl.value.toLowerCase())
                ? { invalidNav: true }
                : null;
            },
          ]),
        );

        this.addProductFormGrp.setControl(
          'subNavigation',
          new FormControl('', [
            this.freeText(),
            (ctrl) => {
              if (!ctrl.value) {
                return null;
              }
              return !this.subNavigationTypeAhead
                .get('options')
                .map((i) => i.toLowerCase())
                .includes(ctrl.value.toLowerCase())
                ? { invalidSubNav: true }
                : null;
            },
          ]),
        );

        // Reset typeahead
        this.setupNavigationTypeahead();
        this.setupSubNavigationTypeahead();
      });
  }

  getProductCategories() {
    this.productService
      .getProductCategory()
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((result: string[]) => {
        this.CATEGORIES = CATEGORIES;
      });
  }

  getSliderImagePosition(pos) {
    this.imagePosition = pos;
  }

  addDiscount() {
    this.discounts.value.push({
      name: null,
      value: null,
      status: false,
    });
  }

  removeDiscount(discount) {
    const index = this.discounts.value.indexOf(discount);
    this.discounts.value.splice(index, 1);
  }

  setupCategoryTypeahead() {
    this.categoryTypeAhead = new TypeAhead(
      this.categoryAutoTrigger,
      this.category,
      [...this.categoryOptions.map((c) => c.name)],
      this._filter,
      this.productService,
      this.profileBusinessService,
      this.snackbar,
      this.showAddButton,
    );
  }

  setupNavigationTypeahead() {
    this.navigationTypeAhead = new TypeAhead(
      this.navAutoTrigger,
      this.navigation,
      [...this.options.filter((opt) => !!opt)],
      this._filter,
      this.productService,
      this.profileBusinessService,
      this.snackbar,
      this.showAddButton,
    );
  }

  setupSubNavigationTypeahead() {
    this.subNavigationTypeAhead = new TypeAhead(
      this.subNavAutoTrigger,
      this.subNavigation,
      [...this.subNavs],
      this._filter,
      this.productService,
      this.profileBusinessService,
      this.snackbar,
      this.showAddButton,
    );
  }

  listenForViewPortChanges() {
    this.stateFacade
      .getViewPortSize()
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((viewPort) => {
        this.isMobile = viewPort.isMobile;
      });
  }

  /**
   * @description convert base64 to blob
   * @param dataUrl
   */
  convertDataUrlToBlob(dataUrl: string): Blob {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  validateSumOfQtyNotZero() {
    if (!this.addProductFormGrp.value.variants?.variations.length) {
      return;
    }

    const sum = this.sumVariantQty();
    return Number(sum.quantity);
  }

  scrollToVariantList() {
    setTimeout(
      () =>
        document
          .querySelector('#productVariantsList')
          .scrollIntoView({ behavior: 'smooth', inline: 'nearest' }),
      500,
    );
  }

  submit(shouldRouteToManageProducts = false) {
    this.productService.formSubmitSub.next(true);

    if (this.validateSumOfQtyNotZero() === 0) {
      this.openModal(
        {
          header: 'Variant List Has No Quantity',
          body: `This implies that on your store this product will be out of stock. You should have at least 1 variant with more than 0 quantity. Do you want to continue or update variant list?`,
          ctas: {
            back: () => this.scrollToVariantList(),
            act: () => this.submitForm(shouldRouteToManageProducts),
            textLeft: this.isMobile ? 'UPDATE LIST' : 'UPDATE VARIANT LIST',
            textRight: 'SAVE',
          },
        },
        false,
      );
      adjustModalWidth(this.isMobile);
      return;
    }
    this.submitForm(shouldRouteToManageProducts);
  }

  validateEditor() {
    if (this.description.errors) {
      this.formErr.registerErrorFields(
        document.querySelector('#editorWrapper'),
      );
      this.formErr.scrollToErrorCtrl();
    }
  }

  async submitForm(shouldRouteToManageProducts = false) {
    this.setAsyncErrToFalse();

    this.formErr.validate(this.addProductFormGrp);
    this.validateEditor();
    if (this.addProductFormGrp.invalid || this.hasVariantErrors) {
      return;
    }

    this.isSubmitting = true;
    this.isInvalidVariant = false;

    if (!this.showDiscount) {
      this.discounts.setValue(null);
    }

    if (!this.showProductVariant.show) {
      this.variants.setValue(null);
    }

    if (!this.showCategory) {
      this.category.setValue(null);
      this.navigation.setValue(null);
      this.subNavigation.setValue(null);
    }

    const name = this.addProductFormGrp.value.name;
    const description =
      this.addProductFormGrp.value.description.replace(/href=/, '') ?? '';
    const category = this.addProductFormGrp.value.category ?? '';
    const discount = Number(this.addProductFormGrp.value.discount) ?? 0;
    const price = Number(this.addProductFormGrp.value.price);
    const quantity = Number(this.addProductFormGrp.value.quantity);
    const weight = Number(this.addProductFormGrp.value.weight) ?? 0;
    const images = this.addProductFormGrp.value.images;
    const variants = uniqueOptions(this.addProductFormGrp.value.variants);
    this.nav = this.addProductFormGrp.value.navigation;
    const subNavigation = this.addProductFormGrp.value.subNavigation;

    // create product model
    let product = {
      sku: 'SKU-' + Date.now(),
      name,
      description,
      category,
      discount,
      price,
      quantity,
      weight,
    } as IProduct;

    await this.uploaadImages(
      images.filter((p: any) => p.file && p.imageSrc) ?? [],
    );

    // check for cover image
    const addImagesList: any[] = images.filter((p) => p?.imageSrc !== null);
    product.defaultImageIndex =
      addImagesList.findIndex((i) => i?.isCoverImage) === -1
        ? 0
        : addImagesList.findIndex((i) => i?.isCoverImage);

    if (this.editMode) {
      const savedImages = this.savedImages.filter(
        (item) => !this.deleteImageList.includes(item),
      );

      this.imageUri = this.imageUri
        .filter((item) => !this.deleteImageList.includes(item))
        .concat(savedImages);

      // create comma seperated ids
      const deletedIds = this.deleteImageList
        .map((item) => item.split('/').pop())
        .join(',');

      if (deletedIds.length !== 0) {
        this.productService
          .deleteImages(deletedIds)
          .pipe(takeWhile(() => this.isComponentActive))
          .subscribe(
            (_) => {
              this.deleteImageList = [];
            },
            () => {
              this.isSubmitting = false;
              this.setAsyncErrToTrue();
              this.showErrorMsg(
                'We are sorry, we could not save this product please try again later',
              );
            },
          );
      }
    }

    product = this.populateProductInfo(product, price, subNavigation);

    if (variants) {
      product.variations = await this.handleVariants(variants, product);
    }

    if (this.isInvalidVariant) {
      this.snackbar.open('Variant price cannot be zero', 'OK', {
        panelClass: 'error',
      });
      this.productService.variantErrorSub.next(variantErrors.ZERO_PRICE);
      this.isSubmitting = false;
      return;
    }

    if (!this.editMode) {
      this.createProduct(product, shouldRouteToManageProducts);
    } else {
      this.patchProduct(product, this.productId, shouldRouteToManageProducts);
    }
  }

  showErrorMsg(msg) {
    this.snackbar.open(msg, 'OK', { panelClass: 'error' });
  }

  async handleVariants(variants, product) {
    // get variation options
    let variationOptions = [];
    variants.options.forEach((variant) => {
      const attributeName = variant.option;
      const attributeValue = variant.values;
      variationOptions.push(
        attributeValue.map(
          (v) =>
            this.capitalizeString(attributeName) +
            ':' +
            this.capitalizeString(v),
        ),
      );
    });

    // flatten
    variationOptions = variationOptions.concat.apply([], variationOptions);
    // recursive
    variationOptions.concat.apply([], variationOptions);

    for (let v of variationOptions) {
      const attributeName = this.capitalizeString(v.split(':')[0]);
      const attributeValue = this.capitalizeString(v.split(':')[1]);
      const variation: Variation = {
        attributeName,
        attributeValue,
        businessId: this.authService.getBusinessId(),
      };

      if (
        !this.businessVariationList.find(
          (v) =>
            v.attributeName ===
              this.capitalizeString(variation.attributeName) &&
            v.attributeValue ===
              this.capitalizeString(variation.attributeValue) &&
            v.businessId === variation.businessId,
        )
      ) {
        const vr: any = await this.saveVariation(variation);
        this.businessVariationList.push(vr);
      }
    }

    const productVariants = [] as ProductVariant[];

    // get variants
    variants.variations.forEach((variation) => {
      const price = Number(variation.price);
      const quantity = Number(variation.quantity);
      const variations = variation.variations;
      const variationIds = this.businessVariationList
        .filter((v) =>
          variations.some(
            (i, index) =>
              v.attributeValue === this.capitalizeString(i) &&
              v.attributeName ===
                this.capitalizeString(variation.option[index].split('$$$')[0]),
          ),
        )
        .map((i) => i.id);

      const variants: ProductVariant = {
        variationId: variationIds,
        price,
        quantity,
      };

      if (
        variants.quantity >= 0 &&
        variants.price > 0 &&
        variationIds.length !== 0
      ) {
        productVariants.push(variants);
      } else {
        this.isInvalidVariant = true;
      }
    });

    product.variations = productVariants;
    return product.variations;
  }

  buildTag(tags) {
    const nav = this.navigation.value?.trim();
    const subNav = this.subNavigation.value?.trim();
    tags[0] = nav && subNav ? `${nav}__${subNav}` : tags[0];
  }

  populateProductInfo(product, price, subNavigation) {
    // Remove duplicates
    product.imageUri = [...new Set(this.imageUri)];

    product.businessId = this.authService.getBusinessId();
    product.shortDetails = 'na';
    product.brand = 'na';
    product.sale = this.editMode ? product.sale : false; // TODO: this should come from the UI
    product.salePrice = price;
    product.views = this.editMode ? product.views : 0;
    product.weightType = 'kg';
    product.showInNotification = this.editMode
      ? product.showInNotification
      : true;
    product.status = Status.ACTIVE.toString();
    product.purchased = this.editMode ? product.purchased : 0;
    product.openOrder = this.editMode ? product.openOrder : 0;
    product.tags = [];
    let selectedNav = null;
    subNavigation !== ''
      ? (selectedNav = subNavigation)
      : (selectedNav = this.nav);
    if (selectedNav !== '') {
      product.tags.push(selectedNav ?? '');
    }

    this.buildTag(product.tags);
    !this.navigation.value?.trim() ? (product.tags = []) : noop();

    return product;
  }

  async uploaadImages(images: []) {
    for (const image of images) {
      const img: any = await this.saveImages(image);
      this.imageUri.push(img.uri);
    }
  }

  private resetForm() {
    this.addProductFormGrp.reset();
    this.images.setValue(this.initImagesCtrl());
    this.variants.setValue(this.initVariantCtrl());
    this.discounts.setValue(this.initDiscountsCtrl());
    this.imageUri = [];
    this.deleteImageList = [];
    this.editor.setContent('');
  }

  scrollTop() {
    defer(() => {
      document.querySelector('mat-sidenav-content').scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }

  /**
   * @description create product
   * @param product
   */
  createProduct(product: IProduct, shouldRouteToManageProducts = false) {
    this.productService
      .addProduct(product)
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        takeWhile(() => this.isComponentActive),
        tap(() => {
          // show notification
          this.snackbar.open(`Product created`, 'Close', {
            panelClass: 'success',
          });
          this.resetForm();
        }),
        delay(500),
      )
      .subscribe(
        (result) => {
          this.scrollTop();
          this.productService.formSubmitSub.next(false);

          if (shouldRouteToManageProducts && !this.hasAsyncErr) {
            this.router.navigate(['manage-product']);
          }
        },
        (err) => {
          this.setAsyncErrToTrue();
          this.snackbar.open('Error, could not create product', 'Close', {
            panelClass: 'error',
          });
        },
      );
  }

  capitalizeString(s: string) {
    return capitalizeString(s);
  }

  /**
   * @description Patch product
   * @param product
   * @param productId
   */
  patchProduct(
    product: IProduct,
    productId: string,
    shouldRouteToManageProducts = false,
  ) {
    this.productService
      .patchProduct(product, productId)
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        takeWhile(() => this.isComponentActive),
        tap(() => {
          // show notification
          this.snackbar.open(`Product updated`, 'Close', {
            panelClass: 'success',
          });
          this.imageUri = [];
          this.deleteImageList = [];
        }),
        delay(500),
      )
      .subscribe(
        (_) => {
          this.scrollTop();
          this.productService.formSubmitSub.next(false);

          if (shouldRouteToManageProducts && !this.hasAsyncErr) {
            this.router.navigate(['manage-product']);
          }
        },
        (err) => {
          this.setAsyncErrToTrue();
          this.snackbar.open('Error, could not update product', 'Close', {
            panelClass: 'error',
          });
        },
      );
  }

  setAsyncErrToTrue() {
    this.hasAsyncErr = true;
  }

  setAsyncErrToFalse() {
    this.hasAsyncErr = false;
  }

  /**
   * @description save product variants
   * @param variation
   */
  async saveVariation(variation: Variation) {
    return new Promise((resolve) => {
      this.productService
        .addVariation(variation)
        .pipe(takeWhile(() => this.isComponentActive))
        .subscribe(
          (result: Variation) => {
            resolve(result);
          },
          (_) => {
            this.isSubmitting = false;
            this.setAsyncErrToTrue();
            this.snackbar.open(
              'One or more of your variation options are empty. Variation options are required',
              'OK',
              { panelClass: 'error', duration: 10000 },
            );
            this.productService.variantErrorSub.next(
              variantErrors.EMPTY_OPTION,
            );
          },
        );
    });
  }

  //TODO: move to shared service
  /**
   * @description send images to storage
   * @param image
   */
  async saveImages(image: any) {
    return new Promise((resolve, reject) => {
      if (image.imageSrc) {
        const imageBlob = new Uint8Array(image.buffer);
        const imageType = image.file.type;
        let typeName = imageType.split('/')[1];

        // convert blob to base64
        const base64String =
          `data:${imageType};base64,` +
          btoa(
            new Uint8Array(imageBlob).reduce(function (data, byte) {
              return data + String.fromCharCode(byte);
            }, ''),
          );

        // change webp to jpeg
        if (typeName === 'webp') {
          typeName = 'jpeg';
        }
        const fileName = `store-image-${Date.now()}.${typeName}`;
        const file = new File(
          [this.convertDataUrlToBlob(base64String)],
          fileName,
        );

        // update file
        const formData = new FormData();
        formData.append('image', file);
        const options = { content: formData };
        this.productService
          .populateImage(formData, options, fileName)
          .pipe(takeWhile(() => this.isComponentActive))
          .subscribe(
            (result: any) => {
              resolve(result);
            },
            () => {
              this.setAsyncErrToTrue();
              this.showErrorMsg(
                'We are sorry we could not add product at this time. Please try again later',
              );
              this.isSubmitting = false;
            },
          );
      }
    });
  }

  ngOnDestroy() {
    this.isComponentActive = false;
    this.editor.destroy();
  }
}
