import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { fromEvent, Observable } from 'rxjs';
import {
  map,
  shareReplay,
  filter,
  takeWhile,
  take,
  withLatestFrom,
  startWith,
  distinctUntilChanged,
} from 'rxjs/operators';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { get as _get, defer, noop } from 'lodash';
import {
  navConfig,
  INavConfig,
  navItems,
  INavItem,
  buildWebsiteRoutes,
  TABLETPLUS,
} from './nav.config';
import * as appActions from '../../state/app.actions';
import { StateFacade } from '../services/state.facade';
import { AuthService } from '../services/auth-service';
import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { scrollToView } from '../utils/helpers';
import { environment } from 'src/environments/environment';
import { ProfileBusinessService } from '../services/profile-business-service';
import { isAndroidOrIOS } from '../factories/user-agent-factory';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private store: Store,
    private stateFacade: StateFacade,
    private authService: AuthService,
    private metrica: NgxMetrikaService,
    private profileService: ProfileBusinessService,
  ) {
    this.initMetrica();
    this.websiteRoutes = buildWebsiteRoutes(this.router.config);
  }

  get isLoggedIn() {
    return !!localStorage.getItem('AUTH_TOKEN');
  }

  @ViewChild('sidenav') sidenav: MatSidenav;
  isMainNav = false;
  navConfig: INavConfig = navConfig;
  navItems: INavItem[] = navItems;
  isMobile: boolean;
  isTabletPlus: boolean;
  title: string;
  componentActive = true;
  hideProfileMenu = true;
  websiteRoutes = null;
  websiteUrl = '';
  isHomePage = false;

  isNotMobileApp: Observable<boolean>;
  shouldTurnCoffee$;

  @isAndroidOrIOS
  isMobileApp: boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  isTabletPlus$: Observable<boolean> = this.breakpointObserver
    .observe(TABLETPLUS)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  initMetrica() {
    this.metrica.hit.emit();
  }

  scrollTo(id, url = '/') {
    if (this.isMobile) {
      this.sidenav.close();
    }
    scrollToView(this.router)(id, url);
  }

  logOut() {
    this.authService.logout();
    this.closeSideNav();
    this.router.navigate(['/signin']);
  }

  toggleConfig(isMainNav) {
    this.isMainNav = isMainNav;
    this.configureNav();
    this.store.dispatch(new appActions.ToggleNav(isMainNav));
  }

  ngOnInit() {
    this.store.dispatch(new appActions.InitNav());
    this.listenToRouterEvent();
    this.listenToViewPort();
    this.getTitle();
    this.stateFacade.getWebsiteUrl().subscribe((url) => {
      this.websiteUrl = url;
    });
    this.getWebsiteUrl();
    this.initChatWoot();
    this.shouldTurnCoffee$ = this.listenToScrollEvt();

    this.isTabletPlus$.subscribe((val) => {
      this.isTabletPlus = val;
    });
  }

  listenToScrollEvt() {
    const router = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.url),
    );

    const scroll = fromEvent(
      document.querySelector('mat-sidenav-content'),
      'scroll',
    ).pipe(map((el: any) => el.target.scrollTop));

    return scroll.pipe(
      withLatestFrom(router),
      startWith([0, '/home']),
      takeWhile(() => this.componentActive),
      map(([num, url]) => !(num >= 990 && (url === '/home' || url === ''))),
      distinctUntilChanged(),
    );
  }

  initChatWoot() {
    this.stateFacade
      .getViewPortSize()
      .pipe(take(1))
      .subscribe(({ isMobile }) => {
        (window as any).selleasy__chatwoot__config.initChatBox(
          document,
          'script',
          isMobile ? ' ' : 'Chat with us',
        );

        this.updateChatWootStyles(isMobile);
      });
  }

  updateChatWootStyles(isMobile) {
    setTimeout(() => {
      (document.querySelectorAll(
        '.woot-widget-bubble',
      )[0] as any).style.setProperty('z-index', '1000', 'important');

      isMobile
        ? ((document.querySelector(
            '#woot-widget--expanded__text',
          ) as any).style.paddingRight = '10px')
        : noop();
    }, 5000);
  }

  getTitle() {
    this.stateFacade
      .getPageTitle()
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((title) => {
        this.title = title.nav;
      });
  }

  listenToViewPort() {
    this.isHandset$
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((val: boolean) => {
        this.isMobile = val;

        this.navConfig.opened = this.isMainNav && !val;
        this.navConfig.mode = val ? 'over' : 'side';
        this.store.dispatch(new appActions.IsMobile(val));
      });
  }

  navHome() {
    this.isMobileApp && !this.isLoggedIn
      ? this.router.navigate(['/signin'])
      : this.isMobileApp && this.isLoggedIn
      ? this.router.navigate['/dashboard']
      : this.router.navigate(['/home']);
    this.sidenav.close();
  }

  getWebsiteUrl() {
    if (!this.websiteUrl && this.isLoggedIn) {
      this.profileService
        .getBusinessProfile()
        .subscribe(({ customDomainName, websiteName }) => {
          if (customDomainName) {
            this.websiteUrl = `https://${customDomainName}`;
            return;
          }
          this.websiteUrl = environment.path.replace(
            '$websiteName',
            websiteName,
          );
        });
    }
  }

  setActiveNav(navItems, url) {
    for (const nav in navItems) {
      navItems[nav].active =
        url === navItems[nav].url.split('/')[1] ||
        (navItems[nav].childUrls && navItems[nav].childUrls.includes(url));

      if (navItems[nav].children?.length) {
        this.setActiveNav(navItems[nav].children, url);
      }
    }
  }

  scrollTop() {
    defer(() => {
      document.querySelector('mat-sidenav-content').scrollTo(0, 0);
    });
  }

  listenToRouterEvent() {
    this.router.events
      .pipe(filter((e) => e instanceof RoutesRecognized))
      .subscribe((e: RoutesRecognized) => {
        const url = e.url.split('/')[1];

        this.isHomePage = url.includes('home') || url === '';

        if (e.url !== e.urlAfterRedirects) {
          this.toggleConfig(false);
          return;
        }

        !this.websiteRoutes[url] && this.isLoggedIn
          ? this.toggleConfig(true)
          : this.toggleConfig(false);

        this.setActiveNav(this.navItems, url);
        this.scrollTop();
      });
  }

  configureNav() {
    if (this.isMainNav) {
      this.navConfig = {
        ...navConfig,
        position: 'start',
        opened: !this.isMobile,
        mode: this.isMobile ? 'over' : 'side',
      };
      return;
    }

    this.navConfig = {
      ...navConfig,
      position: 'start',
      opened: false,
      mode: 'over',
    };
  }

  closeSideNav() {
    if (this.isMobile) {
      this.sidenav.close();
    }

    if (!this.isMainNav) {
      this.sidenav.close();
    }
  }

  initNavItems() {
    const dashboard = this.navItems[0];
    defer(() => this.goTo(dashboard.url, dashboard));
  }

  goTo(url?: string, item?: INavItem) {
    if (_get(item, 'children.length')) {
      item.showChildren = !item.showChildren;
    }

    if (this.isTabletPlus && !_get(item, 'children.length')) {
      this.closeSideNav();
    }

    if (url && !_get(item, 'children.length')) {
      this.router.navigate([url]);
    }
  }

  childNavTo(url: string, item: INavItem) {
    if (this.isTabletPlus) {
      this.closeSideNav();
    }

    this.router.navigate([url]);
  }

  ngOnDestroy() {
    this.componentActive = false;
  }
}
