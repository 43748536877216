import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  SetPageTitle,
  SelectOrder,
  DeliveryType,
  IsLoggedIn,
  SetWebsiteUrl,
} from 'src/app/state/app.actions';
import {
  getPageTitle,
  getNavState,
  getSelectedOrder,
  getDeliveryType,
  getLoginStatus,
  getWebsiteUrl,
} from '../../state/selectors';

@Injectable({ providedIn: 'root' })
export class StateFacade {
  constructor(private store: Store) {}

  setPageTitle(title: string) {
    this.store.dispatch(new SetPageTitle(title));
  }

  setWebsiteUrl(url: string) {
    this.store.dispatch(new SetWebsiteUrl(url));
  }

  getWebsiteUrl() {
    return this.store.pipe(select(getWebsiteUrl));
  }

  setLoginStatus(isLoggedIn) {
    this.store.dispatch(new IsLoggedIn(isLoggedIn));
  }

  getLoginStatus() {
    return this.store.pipe(select(getLoginStatus));
  }

  getPageTitle() {
    return this.store.pipe(select(getPageTitle));
  }

  getViewPortSize() {
    return this.store.pipe(select(getNavState));
  }

  setSelectedOrder(order) {
    this.store.dispatch(new SelectOrder(order));
  }

  getSelectedOrder() {
    return this.store.pipe(select(getSelectedOrder));
  }

  setDeliveryType(mode: string) {
    this.store.dispatch(new DeliveryType(mode));
  }

  getDeliveryType() {
    return this.store.pipe(select(getDeliveryType));
  }
}
