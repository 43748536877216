import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { list } from '../shared/models/user-profile';
import { StateFacade } from '../shared/services/state.facade';
import { PlanModalComponent } from '../plan-modal/plan-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import { PaymentService } from '../shared/services/payment-service';
import { Subscription } from '../shared/models/subscription';
import { SubscriptionEnum } from '../shared/enums/subscription';
import { noop } from 'lodash';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit {
  showAnnualFee = false;
  isMobile = false;
  monthlyFee = 0;
  monthlyFeeDiscounted = 0;
  paymentPlans: any;
  paymentOptions = {};
  freePlan = SubscriptionEnum.FREE;
  basicPlan = SubscriptionEnum.BASIC;
  monthlyPlan = SubscriptionEnum.MONTHLY;
  yearlyPlan = SubscriptionEnum.YEARLY;
  selectedPlan: SubscriptionEnum;
  subscribedPlan: SubscriptionEnum;
  isSubscribed: boolean;
  isSubscriptionVerified: boolean;
  nextDue: string;
  modalBody: string;
  businessWebsite = '';
  subscriptionExpired = false;
  basicPlanFee: number;
  subscriptions = [];
  @ViewChild('payBtn', { static: false }) payBtn: any;

  plan = {
    free: [...list],
    paid: [...list],
  };

  paidOnly = {
    'Product Listings - Unlimited': true,
  };

  constructor(
    private stateFacade: StateFacade,
    private dialog: MatDialog,
    public paymentService: PaymentService,
    private profileBusinessService: ProfileBusinessService,
    private snackbar: MatSnackBar,
    private zone: NgZone,
  ) {}

  ngOnInit(): void {
    this.stateFacade.getViewPortSize().subscribe((size) => {
      this.isMobile = size.isMobile;
    });
    this.getPlans();
    this.getActivePlan();
    this.modalBody = '';
    this.isSubscribed = false;
    this.isSubscriptionVerified = false;
    this.profileBusinessService.businessConfigSub$.subscribe((config) => {
      this.subscriptionExpired = config.subscriptionExpired;
    });
  }

  onPlanSelected(e) {
    if (e.value === 'annually') {
      const el = document.querySelector('#selectFreePlanButton');
      if (this.isMobile) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      this.showAnnualFee = true;
      if (!this.isSubscribed) {
        this.isSubscribed = this.subscribedPlan === SubscriptionEnum.YEARLY;
      }
    } else {
      this.showAnnualFee = false;
      if (!this.isSubscribed) {
        this.isSubscribed = this.subscribedPlan === SubscriptionEnum.MONTHLY;
      }
    }
  }

  showSelectFreeBtn() {
    const activeSubs = this.subscriptions.find((v: any) => v.active);

    return (
      this.subscriptionExpired ||
      activeSubs?.subscriptionType === SubscriptionEnum.MONTHLY ||
      activeSubs?.subscriptionType === SubscriptionEnum.YEARLY
    );
  }

  showSelectPremiumBtn() {
    const activeSubs = this.subscriptions.find((v: any) => v.active);

    return (
      this.subscriptionExpired ||
      activeSubs?.subscriptionType === SubscriptionEnum.FREE ||
      activeSubs?.subscriptionType === SubscriptionEnum.BASIC
    );
  }

  selectPlan(plan: SubscriptionEnum, isAnnual = false) {
    let modalBody = '';
    let ispremiumMnthlyToYearly = false;

    if (plan !== this.subscribedPlan && isAnnual) {
      const currentPlan = SubscriptionEnum[this.subscribedPlan];
      const date = new Date(this?.nextDue).toDateString();
      ispremiumMnthlyToYearly = true;
      modalBody = `You are currently subscribed to our ${currentPlan} PLAN. If you are trying to upgrade your current plan, click the continue button and your plan will be upgraded after your current plan expires.`;
    } else if (plan !== this.subscribedPlan && plan === this.monthlyPlan) {
      const currentPlan = SubscriptionEnum[this.subscribedPlan];
      const date = new Date(this?.nextDue).toDateString();
      modalBody = `You are currently subscribed to our ${currentPlan} PLAN and this plan expires on ${date}. If you are trying to downgrade your current plan, click the continue button and your plan will be downgraded after your current plan expires.`;
    } else if (this.isSubscribed && plan === this.basicPlan) {
      const currentPlan = SubscriptionEnum[this.subscribedPlan];
      const date = new Date(this?.nextDue).toDateString();
      modalBody = `You are currently subscribed to our ${currentPlan} PLAN and this plan expires on ${date}. If you are trying to downgrade your current plan, click the continue button and your plan will be downgraded after your current plan expires.`;
    } else {
      modalBody = `You are about to subscribe to our BASIC PLAN, click continue to complete this process.`;
    }

    this.dialog
      .open(PlanModalComponent, {
        data: {
          modalBody,
          plan,
        },
        maxWidth: this.isMobile ? '100vw' : '50%',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.plan !== null) {
          this.pay(true, ispremiumMnthlyToYearly);
          ispremiumMnthlyToYearly = false;
        }
      });
  }

  getPlans() {
    this.profileBusinessService.getPaymentPlans().subscribe((result: any[]) => {
      this.paymentPlans = result.map((v) => ({
        ...v,
        name: v.name.toLowerCase(),
      }));

      this.monthlyFee = this.paymentPlans.filter(
        (p) => p.name === 'premium-monthly',
      )[0]?.amount;
      this.monthlyFeeDiscounted = this.paymentPlans.filter(
        (p) => p.name === 'premium-yearly',
      )[0]?.amount;
      this.monthlyFeeDiscounted /= 12;

      this.basicPlanFee = this.paymentPlans.find(
        (v) => v.name === 'basic-monthly',
      )?.amount;
    });
  }

  getActivePlan() {
    this.profileBusinessService
      .getBusinessProfile()
      .subscribe((result: any) => {
        this.businessWebsite = result?.websiteName;
        this.subscriptions = result.subscription;

        let plans = result.subscription ?? [];
        plans = plans
          .filter((plan: { active: boolean }) => plan.active === true)
          .reverse();

        if (plans.length > 0) {
          this.isSubscribed = plans[0]?.active;
          this.subscribedPlan = plans[0]?.subscriptionType;
          this.verifyPlanSubscription(
            plans[0]?.transactionRef,
            plans[0]?.active,
          );
        }
      });
  }

  verifyPlanSubscription(ref: string, isActive = false) {
    this.profileBusinessService
      .verifySubscription(ref)
      .subscribe((result: any) => {
        if (
          result.status === 'success' &&
          result?.data?.plansubscriptions.length > 0
        ) {
          this.isSubscriptionVerified =
            result?.data?.plansubscriptions[0].status;
          this.nextDue = result?.data?.plansubscriptions[0].next_due;
        }
      });
  }

  pay(fromModal = false, ispremiumMnthlyToYearly?) {
    if (
      this.showAnnualFee &&
      this.subscribedPlan === this.monthlyPlan &&
      !fromModal
    ) {
      this.selectPlan(this.yearlyPlan, true);
      return;
    } else if (
      !this.showAnnualFee &&
      this.subscribedPlan === this.yearlyPlan &&
      !fromModal
    ) {
      this.selectPlan(this.monthlyPlan, true);
      return;
    }

    this.paymentService.paymentOptions = {
      txref: 'sub-' + new Date().toJSON(),
      currency: 'NGN',
      customer_email: `${
        this.businessWebsite ?? 'customer_subscriptions'
      }_subscriptions@selleasy.co`,
      customer_phone: 12345679,
    };

    if (this.showAnnualFee) {
      this.paymentService.paymentOptions['amount'] = fromModal
        ? this.basicPlanFee * 12
        : this.monthlyFeeDiscounted * 12;

      // If user is subscribed to premium monthly and wants to upgrade to premium yearly
      if (ispremiumMnthlyToYearly) {
        this.paymentService.paymentOptions['amount'] =
          this.monthlyFeeDiscounted * 12;
      }

      this.paymentService.paymentOptions[
        'payment_plan'
      ] = this.paymentPlans.find((p) => p.interval === 'yearly')?.id;
      this.paymentService.paymentOptions['charge_type'] = 'recurring-anually';
      this.selectedPlan = fromModal
        ? SubscriptionEnum.BASIC
        : SubscriptionEnum.YEARLY;
    } else {
      this.paymentService.paymentOptions['amount'] = fromModal
        ? this.basicPlanFee
        : this.monthlyFee;

      this.paymentService.paymentOptions[
        'payment_plan'
      ] = this.paymentPlans.find((p) => p.interval === 'monthly')?.id;
      this.paymentService.paymentOptions['charge_type'] = 'recurring-monthly';
      this.selectedPlan = fromModal
        ? SubscriptionEnum.BASIC
        : SubscriptionEnum.MONTHLY;
    }

    setTimeout(() => {
      this.paymentService.pay(this.payBtn);
    }, 5);
  }

  /**
   *
   * @param oldSubscription
   * @param newSubcription
   * @param subscriptionDetails
   * @param startDate
   */
  downgradeSubscription(
    oldSubscription: any,
    newSubcription: any,
    subscriptionDetails: Object,
    startDate: any,
  ) {
    const subscription: Subscription = {
      subscriptionType: newSubcription,
      active: false,
      isVerified: true,
      subscriptionDetails,
      timestampCreated: new Date().toJSON(),
      timestampModified: new Date().toJSON(),
      isDownGraded: true,
      oldSubscription,
      newSubcription,
      startDate,
      cancelledDate: new Date().toJSON(),
    };

    // TODO: complete this part
  }

  verifyPayment(event: any) {
    const subscription: Subscription = {
      subscriptionType: this.selectedPlan,
      active: true,
      isVerified: false,
      transactionRef: event?.tx?.id,
      subscriptionDetails: event,
      timestampCreated: new Date().toJSON(),
      timestampModified: new Date().toJSON(),
    };
    this.paymentService
      .verifyPayment(
        event,
        subscription,
        this.paymentService?.paymentOptions?.txref,
      )
      .subscribe(
        () => {
          this.displaySnacky(`You have subscribed successfully`, 'success');
          this.getActivePlan();
        },
        (err) => {
          this.displaySnacky(err.message, 'error');
        },
      );
  }

  displaySnacky(message: string, cls: string) {
    this.zone.run(() => {
      const snackBar = this.snackbar.open(message, 'Close', {
        panelClass: cls,
      });
      snackBar.onAction().subscribe(() => {
        snackBar.dismiss();
      });
    });
  }
}
