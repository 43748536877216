<div class="reset-password">
    <app-spinner *ngIf="isSubmitting"></app-spinner>
    <form class="reset-password__form"
          [formGroup]="resetPasswordForm"
          autocomplete="off"
          (submit)="onSubmitForm()"
          novalidate>
        <h4>Reset Password</h4>
        <p>
            <mat-error *ngIf="serverError else welcomeMsg">
                {{ serverError }}
            </mat-error>
            <ng-template #welcomeMsg>
                <span>Please reset your password</span>
            </ng-template>
        </p>
        <mat-form-field appearance="outline"
                        class="se-input-field sign-up__hinted-input mb-3">
            <mat-label>Password</mat-label>
            <input [type]="hidePassword ? 'password' : 'text'"
                   formControlName="password"
                   matInput>
            <mat-error *ngIf="password.hasError('minlength')">You need a minimum of 8 characters</mat-error>
            <mat-hint class="reset-password__error"
                      *ngIf="resetPasswordForm.errors?.notEqual else hint1">Passwords should match</mat-hint>
            <ng-template #hint1>
                <mat-hint>Minimum of 8 characters</mat-hint>
            </ng-template>
            <button mat-icon-button
                    type="button"
                    matSuffix
                    (click)="hidePassword = !hidePassword">
                <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        class="se-input-field sign-up__hinted-input">
            <mat-label>Re-type password</mat-label>
            <input type="password"
                   (paste)="$event.preventDefault()"
                   formControlName="confirmPassword"
                   matInput>
            <mat-error *ngIf="confirmPassword.hasError('minlength')">You need a minimum of 8 characters</mat-error>
            <mat-hint class="reset-password__error"
                      *ngIf="resetPasswordForm.errors?.notEqual else hint2">Passwords should match</mat-hint>
            <ng-template #hint2>
                <mat-hint>Minimum of 8 characters</mat-hint>
            </ng-template>

        </mat-form-field>
        <div class="reset-password__login-wrapper">
            <p>Need an account? <span routerLink="/signup">Sign up</span></p>
            <button mat-flat-button
                    color="primary"
                    class="se-button"
                    [disabled]="resetPasswordForm.invalid || serverError">
                RESET PASSWORD
            </button>
        </div>
    </form>
</div>
