import { Component, Input } from '@angular/core';

@Component({
  selector: 'se-icon',
  templateUrl: './se-icon.component.html',
  styleUrls: ['./se-icon.component.scss'],
})
export class SeIconComponent {
  @Input() iconName: string;
  @Input() size: string;
  @Input() xs = false;
  @Input() sm = false;
  @Input() md = false;
  @Input() lg = false;
  @Input() xl = false;
}
