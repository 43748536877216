import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { WebsiteReadyComponent } from './website-ready/website-ready.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PlatformComponent } from './platform/platform.component';
import { TermsAndConditionsComponent } from './policies/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './policies/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './policies/refund-policy/refund-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { UserAgentGuard } from '../shared/services/user-agent.guard';

export const landingPageRoutes: Routes = [
  {
    path: 'home',
    component: LandingPageComponent,
    canActivate: [UserAgentGuard],
  },
  {
    path: 'signin',
    component: SignInComponent,
  },
  {
    path: 'signup',
    component: SignUpComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'website-ready/:websiteName',
    component: WebsiteReadyComponent,
  },
  {
    path: 'platform',
    component: PlatformComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'refund-policy',
    component: RefundPolicyComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'splash-screen',
    component: SplashScreenComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(landingPageRoutes)],
  exports: [RouterModule],
  providers: [UserAgentGuard],
})
export class LandingPageRoutingModule {}
