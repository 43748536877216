<section class="container-fluid header-section p-0"
         style="overflow-x: hidden;">
    <div class="container hero-image">
        <!-- <img src="assets/platform.jpg"
             class="img-fluid lg p-0"> -->
        <img src="assets/platform-v2.jpeg"
             class="img-fluid sm p-0">
        <div class="container hero-image-text">
            <h4>SELLEASY PLATFORM</h4>
            <h2 class="h2-bold">Secure Ecommerce Platform</h2>
            <p class="title-support-text">Selling online can be tedious, expensive and time consuming (paying a
                developer to
                build your ecommerce website, integrating a payment gateway, handling delivery, managing your inventory,
                increasing sales and traction). This is why we have built SellEasy; SellEasy provides increased sales
                via product visibility, delivery, payment, inventory management, product insights etc.
            </p>
        </div>
    </div>
</section>

<!-- Website Creation -->
<section class="sec-color website-creation">
    <div class="container">
        <div class="row p2">
            <div class="col-12">
                <h2 class="h2-bold">Ecommerce Website Creation & Management</h2>
                <p class="title-support-text">Create an e-commerce website, list your products and start selling on
                    Google, Instagram, WhatsApp, EasyDeals etc
                </p>
            </div>
        </div>
        <img src="assets/website-creation.png"
             class="img-fluid platform-website">
    </div>
</section>

<!---Integrated Payment -->
<section class="integrated-payment"
         id="platformPayment">
    <div class="container">
        <div>
            <h2 class="h2-bold">Integrated Payment</h2>
            <p class="title-support-text">SellEasy’s platform integrates SECURE payment solutions out-of-the box</p>
        </div>
        <div class="row content">
            <div class="col-md-6">
                <img src="assets/integrated-payment.jpg"
                     class="img-fluid">
            </div>
            <div class="col-md-6 text-content">
                <p class="text-normal">We partner with industry leading payment gateways to process and deposit Sellers’
                    payments directly to
                    Sellers’ account from multiple payment channels, such as credit / debit cards, bank transfer,
                    USSD,
                    etc.</p>
                <p class="text-normal">Sellers can sell and accept payments as soon as a website is created and products
                    are
                    uploaded</p>
                <h5 class="mt-5">Partners</h5>
                <p class="text-normal">Our payment Partners are PCI DSS Compliant - Highest level of security audit
                    satisfied</p>
            </div>
        </div>
        <div>
            <p class="text-muted text-center">Please review terms & conditions for more information</p>
        </div>
    </div>
</section>

<!-- Integrated delivery-->
<section class="sec-color"
         id="platformDelivery">
    <div class="container">
        <div class="row platform-delivery">
            <div class="col-md-5 inward-section">
                <h2 class="h2-bold">Integrated Delivery</h2>
                <p class="title-support-text">One of SellEasy’s core feature is Sellers’ order delivery management. No
                    more
                    delivery hassle! Sellers choose between pickup and dropoff delivery options and our system takes
                    care of
                    the rest.</p>
                <img src="assets/integrated-delivery.jpg"
                     class="img-fluid"
                     style="border-radius: 10px;">
            </div>
            <div class="col-md-7 platform-payment-text-area">
                <h5 class="del-txt-header">Customers can track their orders from when an order is placed to when a
                    delivery
                    is done.</h5>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <img src="assets/pickup-icon.svg"
                             class="img-fluid"
                             style="width: 20%;">
                        <h6>Pickup delivery option</h6>
                        <p class="text-normal">Orders are picked up from the Seller’s preferred location and delivered
                            to
                            the Customer’s location.</p>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <img src="assets/drop-off-icon.svg"
                             class="img-fluid"
                             style="width: 30%;">
                        <h6>Drop-off delivery option</h6>
                        <p class="text-normal">Customers orders are aggregated daily and delivered to our delivery
                            Partner for onward delivery to Customer’s location.</p>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <img src="assets/delivery-coverage-icon.svg"
                             class="img-fluid"
                             style="width: 30%;">
                        <h6>Delivery Coverage</h6>
                        <p class="text-normal">Nationwide delivery from one location to another within Nigeria - Powered
                            by our delivery partners.</p>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <img src="assets/delivery-time-icon.svg"
                             class="img-fluid"
                             style="width: 30%;">
                        <h6>Delivery Timelines</h6>
                        <p class="text-normal">We offer same day - 12 days delivery depending on the locations and time
                            of transactions.</p>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <img src="assets/delivery-partners-icon.svg"
                             class="img-fluid"
                             style="width: 20%;">
                        <h6>Delivery Partners</h6>
                        <img src="assets/delivery-partnersv2.png"
                             class="img-fluid opacity-1">
                    </div>
                </div>
            </div>
        </div>
        <div class="section-footer-del text-center">
            <p class="text-muted">Please review terms & conditions for more information</p>
        </div>
    </div>
</section>

<!-- Other platform detials -->
<section class="management-sections">
    <div class="container">
        <div class="row order-management">
            <div class="col-md-6">
                <h2 class="h2-bold">Order Management</h2>
                <p class="title-support-text">Simplified order fulfilment system.</p>
                <p class="title-support-text">SellEasy provides a unified platform to view, manage and keep tabs on
                    Sellers’
                    orders
                    throughout the order fulfilment stages.</p>
            </div>
            <div class="col-md-6 platform-column-area">
                <img src="assets/order-managment.svg"
                     class="img-fluid">
            </div>
        </div>
        <div class="row inventory-management">
            <div class="col-md-6 platform-column-area">
                <h2 class="h2-bold">Inventory Management</h2>
                <p class="title-support-text">SellEasy’s built-in Inventory Management System (IMS) manages all your
                    product
                    data
                    and stock inventory
                    through the sales cycle.</p>
                <p class="title-support-text">The IMS simplifies re-stocking, generates product statistics and notifies
                    you
                    about certain events that
                    could affect sales.</p>
            </div>
            <div class="col-md-6 platform-column-area">
                <img src="assets/inventory-management.svg"
                     class="img-fluid">
            </div>
        </div>
        <div class="row sales-and-insights">
            <div class="col-md-6 platform-column-area">
                <h2 class="h2-bold">Product and Sales Insights</h2>
                <p class="title-support-text">The importance of product and sales metrics cannot be over-emphasized in
                    ecommerce.</p>
                <p class="title-support-text">SellEasy provides full insights to your store’s performance by analysing
                    your
                    sales data
                    and product level insights, giving you insights such as; Number of views, Number of purchases, Total
                    sales,
                    Out-of-stock products etc.</p>
            </div>
            <div class="col-md-6 platform-column-area">
                <img src="assets/Product-insight.svg"
                     class="img-fluid">
            </div>
        </div>
    </div>
</section>
