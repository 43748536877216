import * as hexToHsl from 'hex-to-hsl';

/**
 * Determines foreground color
 * @param bgColor -> hexcode
 * @param lightColor
 * @param darkColor
 */

export function pickTextColorBasedOnBgColor(
  bgColor,
  lightColor = '#FFFFFF',
  darkColor = '#000000',
) {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];

  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });

  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}

// Accepts RGB
export function setForeground(
  colorInRGBFormat: string,
  lightColor = '#FFFFFF',
  darkColor = '#000000',
) {
  const rgb = colorInRGBFormat.replace(/[rgb()]/gi, '').split(',');

  //http://www.w3.org/TR/AERT#color-contrast

  const o = Math.round(
    (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) /
      1000,
  );

  if (o > 125) {
    return darkColor;
  }
  return lightColor;
}

export function getHue(from: string, to: string) {
  const diff = hexToHsl(to)[0] - hexToHsl(from)[0];

  return diff;
}
