import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { StateFacade } from '../services/state.facade';
import { takeWhile } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthService } from '../services/auth-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() hideName = false;
  @ViewChild('profileMenuTrigger') profileMenuTrigger: MatMenuTrigger;
  tenantName: string;
  firstLetter: string;
  componentIsActive = true;
  hideMenu = false;

  constructor(
    private stateFacade: StateFacade,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.tenantName = this.getTenantName();
    this.firstLetter = this.tenantName.substr(0, 1).toUpperCase();

    this.stateFacade
      .getViewPortSize()
      .pipe(takeWhile(() => this.componentIsActive))
      .subscribe(({ isMobile }) => {
        this.hideMenu = isMobile;
      });
  }

  /**
   * get tenant's name - Nav
   */
  getTenantName() {
    return this.authService.getTenantName();
  }

  logOut() {
    this.authService.logout();
    this.router.navigate(['/signin']);
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }

  triggerMenu() {
    if (this.hideMenu) {
      this.profileMenuTrigger.closeMenu();
      return;
    }

    this.profileMenuTrigger.openMenu();
  }
}
