<div class="product-slider">
    <div *ngIf="!isMobile"
         (click)="scrollImagesLeft()"
         class="product-slider__left">
        <se-icon iconName="left"></se-icon>
    </div>
    <div *ngIf="!isMobile"
         (click)="scrollImagesRight()"
         class="product-slider__right">
        <se-icon iconName="right"></se-icon>
    </div>
    <div class="product-slider__images"
         #productSliderImages>
        <div class="product-slider__image"
             *ngFor="let img of sliderImages; index as i"
             [id]="'productSliderImage' + i"
             (swipeLeft)="onSwipeLeft(img)"
             (swipeRight)="onSwipeRight(img)">
            <div class="product-slider__toolbar"
                 #toolBar
                 (mouseenter)="displayFlex(toolBar)"
                 (mouseleave)="displayNone(toolBar)">
                <button mat-button
                        *ngIf="!isMobile"
                        (click)="photoEdit(img)"
                        [disabled]="true">
                    <div class="product-slider__button-content">
                        <se-icon iconName="edit-green">
                        </se-icon>
                        <span>Photo Edit</span>
                    </div>
                </button>
                <div class="file-button file-button--icon">
                    <input type="file"
                           name="productImage1"
                           accept="image/*"
                           (change)="getImage($event, img, true)"
                           multiple>
                    <button mat-button>
                        <div class="product-slider__button-content">
                            <se-icon [iconName]="isMobile ? 'replace' : 'replace-white'">
                            </se-icon>
                            <span>Replace</span>
                        </div>
                    </button>
                </div>
                <button mat-button
                        [disabled]="img.isCoverImage || !img.imageSrc"
                        (click)="coverImage(img)">
                    <div class="product-slider__button-content">
                        <se-icon [iconName]="isMobile ? 'image' : 'image-white'">
                        </se-icon>
                        <span>Default Image</span>
                    </div>
                </button>
                <button mat-button
                        [disabled]="!img.imageSrc"
                        (click)="deleteImage(img)">
                    <div class="product-slider__button-content">
                        <se-icon [iconName]="isMobile ? 'delete' : 'delete-white'">
                        </se-icon>
                        <span>Delete</span>
                    </div>
                </button>
            </div>
            <div *ngIf="img.imageSrc else uploadImg"
                 class="product-slider__upload-image border-0"
                 [ngClass]="{'on-dragover': isDraggedOver}">
                <img class="animated fadeIn w-100"
                     (drop)="dropHandler($event, img)"
                     (dragover)="dragOverHandler($event)"
                     (dragleave)="dragLeave($event)"
                     [src]="img.imageSrc"
                     (mouseenter)="displayFlex(toolBar)"
                     (mouseleave)="displayNone(toolBar)"
                     (load)="freeMemory($event)"
                     alt="uploaded image">
            </div>
            <ng-template #uploadImg>
                <div class="product-slider__upload-image"
                     [ngClass]="{'on-dragover': isDraggedOver}"
                     id="productSliderDropZone"
                     (drop)="dropHandler($event, img)"
                     (dragover)="dragOverHandler($event)"
                     (dragleave)="dragLeave($event)">
                    <div class="product-slider__upload-icon"></div>
                    <div class="product-slider__icon-text">
                        <span class="d-none body-text-1">.jpg, .png, .gif, .tiff</span>
                        <span class="heading-text-1">Drag and drop files to upload, or</span>
                        <div class="file-button mb-3">
                            <input type="file"
                                   name="productImage1"
                                   accept="image/png, image/jpeg, image/jpg"
                                   (change)="getImage($event, img)"
                                   multiple>
                            <button mat-stroked-button
                                    type="button"
                                    color="primary"
                                    class="se-button se-button--stroke">
                                BROWSE
                            </button>
                        </div>
                        <span class="body-text-2 d-none">Maximum upload image size is 10MB</span>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="product-slider__dots">
        <div class="d-flex">
            <span [class.product-slider__active]="position === 0"
                  (click)="slideImage(0)"></span>
            <span [class.product-slider__active]="position === 1"
                  (click)="slideImage(1)"></span>
            <span [class.product-slider__active]="position === 2"
                  (click)="slideImage(2)"></span>
            <span [class.product-slider__active]="position === 3"
                  (click)="slideImage(3)"></span>
            <span [class.product-slider__active]="position === 4"
                  (click)="slideImage(4)"></span>
        </div>
    </div>
</div>
