import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateFacade } from '../shared/services/state.facade';
import { upperFirst } from 'lodash';
import { Status } from '../shared/models/status';
import { OrderService } from '../shared/services/order-service';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss'],
})
export class ViewOrderComponent implements OnInit {
  constructor(
    private route: Router,
    private stateFacade: StateFacade,
    private orderService: OrderService,
  ) {}
  orderDetails;
  isMobile = false;
  isSelfDelivery = false;
  status = Status;
  deliveryType: string;
  formatStr = (str: string) => upperFirst(str.toLowerCase());
  trackingDetails;
  deliveryTypes = {
    dropoff: true,
    pickup: true,
  };

  ngOnInit() {
    this.getViewport();
    this.getSelectedOrder();
    this.getDeliveryType();
    // this.getTrackingDetails(); To be revisted
  }

  getViewport() {
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
    });
  }

  getDeliveryType() {
    this.stateFacade.getDeliveryType().subscribe((type) => {
      this.deliveryType = type;
    });
  }

  getSelectedOrder() {
    this.stateFacade.getSelectedOrder().subscribe((details: any) => {
      if (!details) {
        this.navBack();
        return;
      }

      const { products } = details;
      this.orderDetails = {
        products,
        trackingNo: products[0]?.trackingNo,
      };
    });
  }

  getTrackingDetails() {
    this.orderService
      .getOrderTrackingDetails(this.deliveryType, this.orderDetails?.trackingNo)
      .subscribe((data) => {
        this.trackingDetails = data;
      });
  }

  confirmDelivery() {}

  navBack() {
    history.back();
  }
}
