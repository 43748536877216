<div class="splash-screen">
    <div class="splash-screen__content">
        <h1>Welcome on board!</h1>

        <p>Before you get started, there are <b>3 important</b> things we would like to share with you.</p>
        <ol>
            <li>Click the question icon
                <div class="info-icon animated heartBeat infinite">
                    <app-info-box class="d-inline"></app-info-box>
                </div>
                to learn more about any topic.
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCFE_BUP7y78JtMXhhyYhm5Q"
                   target="_blank">Click
                    here</a> to view a list of short videos that shows you how to setup your store.
            </li>
            <li>If you have any questions, chat with us using the blue chat button at the bottom
                right of
                your screen.
            </li>
        </ol>

        <button mat-stroked-button
                (click)="gotoWebsiteDesign()"
                class="se-button se-button--stroke">
            Add products to your store
        </button>
    </div>
</div>
