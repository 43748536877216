<form [formGroup]="bankForm"
      novalidate>
    <div>
        <mat-form-field appearance="outline"
                        class="se-input-field"
                        [ngClass]="{'mb-4' : !banks.length}">
            <mat-label>Bank name</mat-label>
            <mat-select class="mat-elevation-z0"
                        formControlName="bankCode"
                        (selectionChange)="selectedBankName($event)"
                        [(ngModel)]="selectedBank">
                <mat-option class="se-option"
                            *ngFor="let bank of banks"
                            [value]="bank.Code">{{bank.Name}}
                </mat-option>
            </mat-select>
            <mat-error>Your bank name seems to be incorrect</mat-error>
            <mat-hint class="account-details-form__isLoading"
                      *ngIf="!banks.length">Loading banks, please wait...</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline"
                        class="se-input-field">
            <mat-label>Account number</mat-label>
            <input type="text"
                   formControlName="accountNumber"
                   matInput>
            <mat-error>Your account number seems to be incorrect</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline"
                        class="se-input-field">
            <mat-label>Account name</mat-label>
            <input type="text"
                   formControlName="accountName"
                   matInput>
            <mat-error>Your account name seems to be incorrect</mat-error>
        </mat-form-field>
    </div>
    <div class="mt-3">
        <button mat-flat-button
                (click)="submitAccountDetails()"
                [disabled]="bankForm.invalid || !formHasChanges || !banks.length"
                color="primary"
                class="se-button">
            Submit
        </button>
    </div>
</form>
