import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { OrderService } from '../shared/services/order-service';
import { Status } from '../shared/models/status';
import { Router } from '@angular/router';
import { StateFacade } from '../shared/services/state.facade';
import { upperFirst } from 'lodash';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import { finalize } from 'rxjs/operators';
import { CalculatedateRange } from '../shared/utils/dates';
import { TruncateText } from '../shared/utils/format-text';

@Component({
  selector: 'app-orders-shipping',
  templateUrl: './orders-shipping.component.html',
  styleUrls: ['./orders-shipping.component.scss'],
})
export class OrdersShippingComponent implements OnInit {
  title = 'Order & Shipping';
  openOrders = 0;
  deliveredOrders = 0;
  cancelledOrders = 0;
  isLoading = false;
  isMobile = false;
  duration = ['Week', 'Month', 'Today'];
  openOrderSelected = this.duration[0];
  cancelledOrderSelected = this.duration[0];
  deliveredOrderSelected = this.duration[0];
  formatStr = (str: string) => upperFirst(str.toLowerCase());
  deliveryType: string;
  ordersCount;
  paginatorStream: MatPaginator;

  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    'select',
    'orderDate',
    'total',
    'orderNumber',
    'customer',
    'product',
    'status',
  ];

  columnsSelect = [
    { label: 'Order Number', value: 'orderNumber' },
    { label: 'Customer', value: 'customer' },
    { label: 'Product', value: 'product' },
    { label: 'Total', value: 'total' },
    { label: 'Status', value: 'status' },
  ];
  columnsToDisplay = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    public orderService: OrderService,
    public route: Router,
    public stateFacade: StateFacade,
    public snackbar: MatSnackBar,
    public businessProfile: ProfileBusinessService,
  ) {}

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.getOpenOrders();
    this.getDeliveredOrders(this.deliveredOrderSelected);
    this.getCancelledOrders(this.cancelledOrderSelected);
    this.getOrders();
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
    });
    this.getDeliveryMode();
    this.columnsToDisplay = this.displayedColumns.slice(
      0,
      this.isMobile ? 3 : 7,
    );
  }

  onSelectChange({ value }) {
    this.columnsToDisplay.pop();
    this.columnsToDisplay.push(value);
  }

  getDeliveryMode() {
    this.businessProfile.getBusinessProfile().subscribe((data: any) => {
      if (!data) return;
      const config = data.delivery.find((d) => d.isActive);
      if (config) {
        const isPickup = config.configuration.isPickup;
        this.stateFacade.setDeliveryType(isPickup ? 'pickup' : 'dropoff');
      }
    });
  }

  truncateText(text: string, length?) {
    return TruncateText(text, length);
  }

  viewOrder(details) {
    this.stateFacade.setSelectedOrder({
      products: this.buildQueryParam(details),
    });
    this.route.navigate(['view-order']);
  }

  buildQueryParam(details) {
    const {
      orderLines,
      products,
      status,
      trackingNo,
      orderId,
      serviceCharge,
      deliveryCharge,
    } = details;

    return products.map((product) => {
      const { productId, quantityBought, price } = orderLines.find(
        (order) => order.productId === product.id,
      );

      return {
        orderId,
        productId,
        status,
        quantityBought,
        price,
        trackingNo,
        imageUri: product.imageUri[0],
        name: product.name,
        serviceCharge,
        deliveryCharge,
      };
    });
  }

  getOpenOrders() {
    this.orderService
      .getOpenOrders()
      .subscribe((result: any) => (this.openOrders = result.orders));
  }

  gotoAddProduct() {
    this.route.navigateByUrl('/add-product/:id');
  }

  getDeliveredOrders(duration: string) {
    switch (duration) {
      // Week
      case this.duration[0]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          7,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];

        // call backend service
        this.orderService
          .getDeliveredOrders(from, to)
          .subscribe((result: any) => (this.deliveredOrders = result.orders));
        break;
      }
      // Month
      case this.duration[1]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          true,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];

        // call backend service
        this.orderService
          .getDeliveredOrders(from, to)
          .subscribe((result: any) => (this.deliveredOrders = result.orders));
        break;
      }
      // Today
      case this.duration[2]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];

        // call backend service
        this.orderService
          .getDeliveredOrders(from, to)
          .subscribe((result: any) => (this.deliveredOrders = result.orders));
        break;
      }
      default: {
        break;
      }
    }
  }

  getCancelledOrders(duration: string) {
    switch (duration) {
      // Week
      case this.duration[0]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          7,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];

        // call backend service
        this.orderService
          .getCancelledOrders(from, to)
          .subscribe((result: any) => (this.cancelledOrders = result.orders));
        break;
      }
      // Month
      case this.duration[1]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          true,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];

        // call backend service
        this.orderService
          .getCancelledOrders(from, to)
          .subscribe((result: any) => (this.cancelledOrders = result.orders));
        break;
      }
      // Today
      case this.duration[2]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];

        // call backend service
        this.orderService
          .getCancelledOrders(from, to)
          .subscribe((result: any) => (this.cancelledOrders = result.orders));
        break;
      }
      default: {
        break;
      }
    }
  }

  getAllOrders() {
    this.isLoading = true;
    this.orderService
      .getAllOrdersAndCount()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(([result, count]) => {
        this.dataSource.data = this.prepOrdersForTable(result);
        this.ordersCount = count;
      });
  }

  concatAll(arr) {
    return arr.reduce((acc, curr) => [...acc, ...curr], []);
  }

  getOrders(limit = 15, skip = 0) {
    this.isLoading = true;
    this.orderService
      .getAllOrders(limit, skip)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((result: any) => {
        if (!result) {
          return;
        }

        this.ordersCount = this.orderService.getOrdersCount();
        this.dataSource.data = this.prepOrdersForTable(result);
      });
  }

  getPaginator(stream) {
    this.paginatorStream = stream;

    this.paginatorStream.page.subscribe((_) => {
      const index = this.paginatorStream.pageIndex;
      const size = this.paginatorStream.pageSize;

      const limit = index === 0 ? 15 : 10;
      const skip = size * index;

      this.getOrders(limit, skip);
    });
  }

  prepOrdersForTable(orders) {
    return orders.map((res) => ({
      ...res,
      customerName: res.customerDetails.name,
      date: new Date(
        res.timestampCreated ?? res.timestampModified,
      ).toDateString(),
      productDesc: res.products
        .map((p) => p.name + ' ' + p.description)
        .join(', '),
      _status: this.formatStr(Status[res.status]),
    }));
  }

  getStatus(status: number) {
    return this.formatStr(Status[status]);
  }
}
