import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../shared/services/auth-service';
import { concatMap, finalize, takeWhile } from 'rxjs/operators';
import { ProfileBusinessService } from 'src/app/shared/services/profile-business-service';
import { environment } from 'src/environments/environment';
import { StateFacade } from 'src/app/shared/services/state.facade';
import { of } from 'rxjs';
import { noop } from 'lodash';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackbar: MatSnackBar,
    private profileService: ProfileBusinessService,
    private stateFacade: StateFacade,
  ) {}

  serverError = null;
  isSubmitting = false;
  private validations = [Validators.required];
  isComponentActive = true;

  ngOnInit() {
    if (localStorage.getItem('AUTH_TOKEN')) {
      this.router.navigateByUrl('/dashboard');
    }
  }

  storeEmail(email) {
    localStorage.setItem('I__I', btoa(email));
  }

  getStoredEmail() {
    const email = localStorage.getItem('I__I');
    return email ? atob(email) : null;
  }

  signinForm = new FormGroup({
    email: new FormControl(this.getStoredEmail(), [
      ...this.validations,
      Validators.email,
    ]),
    password: new FormControl(null, [
      ...this.validations,
      Validators.minLength(8),
    ]),
  });

  displayErrorMsg(msg) {
    this.isSubmitting = false;
    this.snackbar.open(msg, 'Close', { panelClass: 'error' });
    this.serverError = { msg };
    this.signinForm.valueChanges.subscribe((_) => (this.serverError = null));
  }

  handleSuccessLogin(delivery, email) {
    environment.isMobileApp ? this.storeEmail(email) : noop();

    const hasSeenSplashScreen = !!localStorage.getItem('splashScreen');
    if (delivery.length || hasSeenSplashScreen) {
      this.router.navigateByUrl('/dashboard');
      return;
    }
    this.router.navigateByUrl('/splash-screen');
  }

  onSubmitForm() {
    this.isSubmitting = true;
    this.authService
      .login(this.signinForm.value)
      .pipe(
        concatMap(({ msg }) => {
          if (msg) {
            this.displayErrorMsg(msg);
            return of({});
          }
          return this.profileService.getBusinessProfile();
        }),
        finalize(() => (this.isSubmitting = false)),
        takeWhile(() => this.isComponentActive),
      )
      .subscribe(({ customDomainName, websiteName, delivery }) => {
        if (!customDomainName && !websiteName && !delivery) return;

        this.handleSuccessLogin(delivery, this.signinForm.value.email);
        this.getWebsiteUrl(customDomainName, websiteName);
        this.isComponentActive = false;
      });
  }

  getWebsiteUrl(customDomainName, websiteName) {
    if (customDomainName) {
      this.stateFacade.setWebsiteUrl(`https://${customDomainName}`);
      return;
    }
    this.stateFacade.setWebsiteUrl(
      environment.path.replace('$websiteName', websiteName),
    );
  }
}
