import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth-service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private authService: AuthService,
  ) {}

  serverError = null;
  isSubmitting = false;
  private validations = [Validators.required];
  errMsg = `Ooops! It appears your email is incorrect or unregistered with selleasy`;

  ngOnInit() {}

  forgotPasswordForm = new FormGroup({
    email: new FormControl(null, [...this.validations, Validators.email]),
  });

  displayErrorMsg(errorMsg) {
    this.isSubmitting = false;
    this.snackbar.open(this.errMsg, 'Close', { panelClass: 'error' });
    this.serverError = this.errMsg;
    this.forgotPasswordForm.valueChanges.subscribe(
      (_) => (this.serverError = null),
    );
  }

  handleSuccessLogin() {
    this.snackbar.open(
      'A password reset link has been sent to your email.',
      'Close',
      { panelClass: 'success' },
    );
  }

  onSubmitForm() {
    this.isSubmitting = true;
    this.authService
      .sendResetLink(this.forgotPasswordForm.value.email)
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe((response) => {
        response.updated
          ? this.handleSuccessLogin()
          : this.displayErrorMsg(response);
      });
  }
}
