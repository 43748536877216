import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '.product-variant__quantity-field',
})
export class FormFieldDirective {
  constructor(public ref: ElementRef) {}
}

@Directive({
  selector: '.product-variant__options-mat-field',
})
export class VariantOptionsDirective {
  constructor(public ref: ElementRef) {}
}

@Directive({
  selector: '.product-variant__price-mat-field',
})
export class VariantPriceDirective {
  constructor(public ref: ElementRef) {}
}
