<div class="account-tax d-flex">
    <div class="col-lg-5">
        <div class="mb-5">By default, your customers are not going to be charged value added tax (VAT).</div>
        <div class="mb-4">
            <mat-radio-group color="primary"
                             [disabled]="true"
                             class="d-flex flex-column"
                             [(ngModel)]="payVatRate">
                <mat-radio-button class="account-tax__options"
                                  *ngFor="let option of options"
                                  [checked]="option.checked"
                                  (change)="selectedValue(option.value)"
                                  [value]="option.value">{{option.description}}</mat-radio-button>
                <br />
            </mat-radio-group>
            <mat-slider type="range"
                        [disabled]="true"
                        (change)="selectedSliderValue(sliderValue)"
                        [(ngModel)]="sliderValue"
                        class="account-tax__slider"
                        min="0"
                        max="100"></mat-slider>
            <span class="account-tax__slider-value">7.5%</span>
        </div>
    </div>
    <div class="col-lg-4 p-0 account-tax__img-tax">
    </div>
</div>
