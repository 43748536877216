import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import {
  Subject,
  BehaviorSubject,
  timer,
  of,
  merge,
  interval,
  Observable,
} from 'rxjs';
import { switchMap, takeUntil, catchError, take } from 'rxjs/operators';

@AutoUnsubscribe()
@Injectable({ providedIn: 'root' })
export class PollerService implements OnDestroy {
  constructor(private http: HttpClient) {}
  private apiCall$ = null;
  private BASEURL = (window as any).API_BASE_URL;
  private readonly POLLING_INTERVAL = 5000;
  private readonly POLLING_TIMEOUT = 120000;

  private stopSub = new Subject();
  private startSub = new BehaviorSubject('START');
  private stopPoller$ = merge(
    this.stopSub,
    interval(this.POLLING_TIMEOUT).pipe(take(1)),
  );

  private poller = timer(0, this.POLLING_INTERVAL).pipe(
    switchMap(() => this.apiCall$),
    takeUntil(this.stopPoller$),
    catchError((_) => {
      this.stopSub.next('STOP');
      localStorage.removeItem('pollingStatus');
      return of(_ || null);
    }),
  );

  start(url?: string, endpoint$?: Observable<any>) {
    this.apiCall$ = endpoint$ || this.http.get(`${this.BASEURL}${url}`);
    return this.poller;
  }

  stop() {
    this.stopSub.next('STOP');
  }

  restart() {
    this.stop();
    return this.poller;
  }

  destroy() {
    this.startSub.unsubscribe();
    this.stopSub.unsubscribe();
    this.poller = null;
  }

  ngOnDestroy() {}
}
