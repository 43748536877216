<div *ngIf="title"
     class="header-title">
    <div *ngIf="showBackButton && !isMobile">
        <button mat-flat-button
                (click)="goBack()">
            <se-icon iconName="back-icon"></se-icon>
        </button>
    </div>
    <div>
        <h1 [innerHTML]="title"></h1>
        <div class="d-flex align-items-center">
            <h6 *ngIf="subTitle"
                [innerHTML]="subTitle"></h6>
            <app-info-box *ngIf="showInfoBox"
                          class="ml-3 mt-3"
                          [title]="infoBoxContent.title"
                          [content]="infoBoxContent.content"></app-info-box>
        </div>
    </div>
</div>
