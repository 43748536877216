import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { defer, noop, omit } from 'lodash';
import { merge, Subject } from 'rxjs';
import {
  mergeMap,
  takeWhile,
  filter,
  debounceTime,
  tap,
  delay,
} from 'rxjs/operators';
import { SelectProductModalComponent } from '../select-product-modal/select-product-modal.component';
import { IDiscount } from '../shared/models/product';
import { DiscountsService } from '../shared/services/discounts.service';
import { FormErrorService } from '../shared/services/form-error.service';
import { ProductService } from '../shared/services/product-service';
import { StateFacade } from '../shared/services/state.facade';
import { isBeforeToday, startOfToday, today } from '../shared/utils/dates';
import {
  percentValidators,
  amountValidators,
  noWhiteSpace,
  invalidEndDate,
  selectedProductsValidator,
  noAmountType,
  truthyNavs,
} from '../shared/utils/validations';

const mapDiscountFormValues = (d: IDiscount) => ({
  selectedProducts: d.products,
  startDate: new Date(d.start),
  endDate: new Date(d.end),
  discountCode: d.code,
  type: d.type,
  value: d.value,
  scope: d.scope,
  name: d.name,
});

@Component({
  selector: 'app-create-discount',
  templateUrl: './create-discount.component.html',
  styleUrls: ['./create-discount.component.scss'],
})
export class CreateDiscountComponent implements OnInit, OnDestroy {
  @ViewChild('productsList') productsList;
  discountsForm: FormGroup;
  discountTypes = [
    { label: 'Percentage', value: 'percentage' },
    { label: 'Amount', value: 'amount' },
  ];

  discountScopes = [
    { label: 'All products in my store', value: 'store' },
    { label: 'Loading products...', value: null },
    { label: 'An Order', value: 'order' },
  ];

  pageTitle = 'Add Discount';
  isMobile = false;
  isSubmitting = false;
  minDate: Date;
  maxDate: Date;
  expMinDate: Date;
  products = null;
  isComponentActive = true;
  discount = {
    discount: null,
    editMode: false,
  };
  deselectAllProducts = new Subject();
  discountId: string;
  isSaveClicked = false;
  discountStatus = '';

  constructor(
    private fb: FormBuilder,
    private stateFacade: StateFacade,
    private dialog: MatDialog,
    private productService: ProductService,
    private discountService: DiscountsService,
    private snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private formErr: FormErrorService,
    private router: Router,
  ) {
    this.initMinMaxDates();
  }

  get nextYear() {
    return new Date().getFullYear() + 1;
  }

  ngOnInit(): void {
    this.getViewPort();
    this.buildForm();
    this.onStartDateChanges();
    this.getProducts();
    this.getDiscount();
    this.type.disable();
  }

  initMinMaxDates() {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date();
    this.maxDate = new Date(currentYear + 1, 11, 31);
    this.expMinDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
    );
  }

  getDiscount() {
    this.activatedRoute.params
      .pipe(
        takeWhile(() => this.isComponentActive),
        filter(({ id }) => {
          this.reInitState(id);
          return !!id;
        }),
        mergeMap(({ id }) => {
          this.discountId = id;
          return this.discountService.getDiscount(id);
        }),
      )
      .subscribe((discount) => {
        this.discountStatus = discount.status;
        this.discount.discount = discount;
        this.discount.editMode = true;
        this.updatePageTitle('Edit Discount');
        this.prefillForm(mapDiscountFormValues(discount));
      });
  }

  updatePageTitle(title) {
    this.stateFacade.setPageTitle(title);
  }

  resetDiscountState() {
    this.initMinMaxDates();
    this.discount = {
      discount: null,
      editMode: false,
    };
  }

  reInitState(id) {
    if (id) return;

    this.buildForm();
    this.resetDiscountState();
    this.updatePageTitle('Add Product');
    if (this.products?.length) {
      this.deselectAllProducts.next(true);
    }
  }

  prefillForm(formValues) {
    this.discountsForm.setValue(formValues);
    this.minDate = isBeforeToday(formValues.startDate)
      ? formValues.startDate
      : today();
    this.type.setValue(formValues.type.toLowerCase());
    this.validateEndDate();
    this.validateSelectedProducts();
    this.onTypeChange({ value: formValues.type });
  }

  buildForm() {
    this.discountsForm = this.fb.group({
      name: [
        ,
        [
          Validators.required,
          Validators.pattern(/^[0-9A-Za-z% @\-]+$/),
          noWhiteSpace,
        ],
      ],
      type: ['percentage', Validators.required],
      value: [, percentValidators()],
      scope: ['store', Validators.required],
      selectedProducts: [[]],
      startDate: [, Validators.required],
      endDate: [],
      discountCode: [''],
    });
    this.validateEndDate();
    this.setTypeValidator();
  }

  setTypeValidator() {
    this.type.setValidators([noAmountType(this.scopeCtrl)]);
    this.type.updateValueAndValidity();
  }

  getProducts() {
    this.productService
      .getProductsAndVariants()
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe(
        (res) => {
          this.products = res;
          this.reinitDiscountScopes(res);
        },
        () => {
          this.products = [];
          this.reinitDiscountScopes();
          this.snackbar.open(
            'We could not fetch products. Please reload this page to try again later',
            'OK',
            {
              panelClass: 'error',
            },
          );
        },
      );
  }

  reinitDiscountScopes(res = []) {
    this.discountScopes = [
      this.discountScopes[0],
      {
        label: !res.length ? '(No products to select)' : 'Selected products',
        value: 'product',
      },
      this.discountScopes[2],
    ];
  }

  onStartDateChanges() {
    this.startDate.valueChanges
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((_) => {
        this.endDate.updateValueAndValidity();
      });
  }

  get appliedDiscount() {
    return this.type.value === 'percentage'
      ? this.value.value
        ? this.value.value + '%'
        : null
      : this.value.value
      ? '₦' + this.value.value
      : null;
  }

  onScopeChange({ value }) {
    value === 'order'
      ? this.discountCode.setValidators([Validators.required, noWhiteSpace])
      : this.discountCode.clearValidators();
    this.discountCode.updateValueAndValidity();

    this.validateSelectedProducts();
    this.setTypeValidator();

    if (value === 'product') {
      defer(() => {
        document.querySelector('#productTree').scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      });
    }
  }

  // Useless at the moment
  openSelectProductModal() {
    this.dialog.open(SelectProductModalComponent, {
      data: {
        data: [],
        cta: () => this.scopeCtrl.setValue('store'),
      },
      width: this.isMobile ? '100vw' : '50vw',
    });

    this.isMobile
      ? ((document.querySelector('.cdk-overlay-pane') as any).style.maxWidth =
          '90vw')
      : noop();
  }

  get isOrderSelected() {
    return this.scopeCtrl.value === 'order';
  }

  get discountCode() {
    return this.discountsForm.get('discountCode');
  }

  get name() {
    return this.discountsForm.get('name');
  }

  get type() {
    return this.discountsForm.get('type');
  }

  get value() {
    return this.discountsForm.get('value');
  }

  get scopeCtrl() {
    return this.discountsForm.get('scope');
  }

  get startDate() {
    return this.discountsForm.get('startDate');
  }

  get endDate() {
    return this.discountsForm.get('endDate');
  }

  validateEndDate() {
    merge(this.startDate.valueChanges, this.endDate.valueChanges)
      .pipe(
        debounceTime(500),
        takeWhile(() => this.isComponentActive),
      )
      .subscribe((_) => {
        this.endDate.setValidators([
          Validators.required,
          invalidEndDate(this.discountsForm),
        ]);
        this.endDate.updateValueAndValidity();
      });
  }

  validateSelectedProducts() {
    Promise.resolve().then(() => {
      this.selectedProductsCtrl.setValidators([
        selectedProductsValidator(this.discountsForm),
      ]);
      this.selectedProductsCtrl.updateValueAndValidity();
    });
  }

  getViewPort() {
    this.stateFacade
      .getViewPortSize()
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe(({ isMobile }) => {
        this.isMobile = isMobile;
      });
  }

  get selectedProductsCtrl() {
    return this.discountsForm.get('selectedProducts');
  }

  onTypeChange({ value }) {
    value === 'percentage'
      ? (this.value.clearValidators(),
        this.value.setValidators(percentValidators()))
      : (this.value.clearValidators(),
        this.value.setValidators(amountValidators()));
    this.value.updateValueAndValidity();
  }

  generateCode() {
    this.discountService
      .generateCode(this.name.value || 'DS')
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((code) => {
        this.discountCode.setValue(code);
      });
  }

  mapFormValues() {
    const vals = this.discountsForm.value;
    const { startDate, endDate, selectedProducts, discountCode, value } = vals;

    return {
      ...omit(vals, [
        'startDate',
        'endDate',
        'selectedProducts',
        'discountCode',
      ]),
      value: Number(value),
      start: startDate,
      end: endDate,
      products: this.scopeCtrl.value === 'product' ? selectedProducts : [],
      code: this.scopeCtrl.value === 'order' ? discountCode : '',
      status:
        this.discountStatus === 'EXPIRED' && endDate.getTime() >= startOfToday()
          ? 'ACTIVE'
          : this.discountStatus,
    };
  }

  save(shouldSaveAndView = false) {
    this.formErr.validate(this.discountsForm);
    this.isSaveClicked = true;

    if (this.discountsForm.invalid) {
      return;
    }

    if (this.discount.editMode) {
      this.updateDiscount(shouldSaveAndView);
      return;
    }
    this.saveDiscount(shouldSaveAndView);
  }

  updateDiscount(shouldUpdateAndView) {
    this.discountService
      .patchDiscount({
        ...this.mapFormValues(),
        id: this.discountId,
      })
      .pipe(
        takeWhile(() => this.isComponentActive),
        tap(() =>
          this.snackbar.open('Discount updated', 'Ok', {
            panelClass: 'success',
          }),
        ),
        delay(500),
      )
      .subscribe(
        (_) => {
          this.scrollTop();
          if (shouldUpdateAndView) {
            this.router.navigate(['discounts-list']);
          }
        },
        () => {
          this.handleError('update');
        },
      );
  }

  scrollTop() {
    defer(() => {
      document.querySelector('mat-sidenav-content').scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }

  saveDiscount(shouldSaveAndView) {
    this.discountService
      .saveDiscount({ ...this.mapFormValues(), type: 'percentage' })
      .pipe(
        takeWhile(() => this.isComponentActive),
        tap(() => {
          this.snackbar.open('Discount saved', 'Ok', {
            panelClass: 'success',
          }),
            this.buildForm();
          this.deselectAllProducts.next(true);
        }),
        delay(500),
      )
      .subscribe(
        (_) => {
          this.scrollTop();
          if (shouldSaveAndView) {
            this.router.navigate(['discounts-list']);
          }
        },
        (_) => {
          this.handleError();
        },
      );
  }

  handleError(msg = 'add') {
    this.snackbar.open(
      `We are sorry we can't ${msg} discount at this time. Please try again later`,
      'OK',
      {
        panelClass: 'error',
      },
    );
  }

  ngOnDestroy() {
    this.isComponentActive = false;
  }
}
