import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { get as _get } from 'lodash';
import { Status } from '../models/status';
import { Notification } from '../models/notification';
import _ from 'lodash';
import { TruncateText } from '../utils/format-text';
import { AuthService } from './auth-service';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  private readonly BASEURL = (window as any).API_BASE_URL;
  sharedNotification: INotifications[] = [];
  sharedNotificationAlerts: INotifications[] = [];
  sharedNotificationInfo: INotifications[] = [];
  sharedNotificationWarnings: INotifications[] = [];

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  // TODO: Update all of this to a pubsub
  getSharedNotifications() {
    return this.sharedNotification;
  }

  getSharedInfo() {
    return this.sharedNotificationInfo;
  }

  getSharedAlerts() {
    return this.sharedNotificationAlerts;
  }

  getSharedWarnings() {
    return this.sharedNotificationWarnings;
  }

  /**
   * @description get notifications from endpoint
   * @param businessId business id
   */
  getNotifications(businessId: string) {
    const token = this.authService.getAuthtoken();
    const path = `/businesses/${businessId}/notifications?filter={\"where\":{\"status\": ${Status.UNREAD}}}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .get(`${this.BASEURL}${path}`, httpOptions)
      .pipe(
        switchMap((result) => this.getOutOfStockProducts(businessId, result)),
      );
  }

  /**
   * @description get out of stock products
   * @param businessId businessId
   * @param data data
   */
  getOutOfStockProducts(businessId: string, data?: any) {
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    const path = `/businesses/${businessId}/products/nostock`;
    return this.httpClient.get(`${this.BASEURL}${path}`, httpOptions).pipe(
      tap((result) =>
        this.setNotifications(this.sortNotification(data.concat(result))),
      ),
      map((result) => data.concat(result)),
    );
  }

  /**
   * @description set shared values after update
   * @param data data: INotifications[]
   */
  setNotifications(data: INotifications[]) {
    this.sharedNotification = data;
    this.sharedNotificationAlerts = data.filter(
      (item) => item.type.toString() === Notification.Alert.toString(),
    );
    this.sharedNotificationInfo = data.filter(
      (item) => item.type.toString() === Notification.Activity.toString(),
    );
    this.sharedNotificationWarnings = data.filter(
      (item) => item.type.toString() === Notification.OutOfStock.toString(),
    );
  }

  /**
   * @description update and persist notification status
   * @param data data
   * @param list list
   */
  deleteNotifications(data: object, list: INotifications[]) {
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    const path = `/businesses/notifications/status`;
    return this.httpClient
      .patch(`${this.BASEURL}${path}`, data, httpOptions)
      .pipe(
        map((result) =>
          this.removeNotificationFromList(this.sharedNotification, list),
        ),
      );
  }

  /**
   * @description remove deleted notifications and update list
   * @param list list
   * @param deleteList deleteList
   */
  removeNotificationFromList(list, deleteList): INotifications[] {
    const status = this.getGroupedStatus(deleteList);
    let dataDifference = this.sortNotification(
      _.differenceBy(list, deleteList, 'id'),
    );
    const dataDifferenceAll = this.sortNotification(
      _.differenceBy(this.sharedNotification, deleteList, 'id'),
    );
    this.sharedNotification = dataDifference;
    if (status !== Notification.All.toString()) {
      dataDifference = dataDifference.filter(
        (item) => item.type.toString() === status,
      );
    }
    this.setNotifications(dataDifferenceAll);
    return dataDifference;
  }

  /**
   * @description check if selection is All, Activities, Alerts or Warnings
   * @param list list of INotifications
   */
  getGroupedStatus(list: INotifications[]) {
    if (
      list.every(
        (item) => item.type.toString() === Notification.Activity.toString(),
      )
    ) {
      return Notification.Activity.toString();
    } else if (
      list.every(
        (item) => item.type.toString() === Notification.Alert.toString(),
      )
    ) {
      return Notification.Alert.toString();
    } else if (
      list.every(
        (item) => item.type.toString() === Notification.OutOfStock.toString(),
      )
    ) {
      return Notification.OutOfStock.toString();
    } else {
      return Notification.All.toString();
    }
  }

  /**
   * @description sort notification
   * @param data data: INotifications[]
   */
  sortNotification(data): INotifications[] {
    const list: INotifications[] = [];
    data.forEach(
      (item: { id: string; details: any; description: any; type: any }) => {
        const details = {} as INotifications;
        details.id = item.id;
        details.name = item.details ?? item.description;
        details.name = TruncateText(details.name ?? item['name'], 200);
        details.type =
          typeof item.type === 'undefined'
            ? Notification.OutOfStock
            : Notification[Notification[item.type]];
        if (
          details.type.toString() === Notification.OutOfStock.toString() &&
          !details.name.includes('out of stock')
        ) {
          details.name += ' is out of stock';
        }
        list.push(details);
      },
    );
    return list;
  }

  /**
   * @description get tenant's gross sale amount
   * @param businessId business id
   * @param from start date
   * @param to end date
   */
  getGrossAmount(businessId: string, from = '', to = '') {
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    let path = `/businesses/${businessId}/stats/order/total`;
    if (from && to) {
      // tslint:disable-next-line: max-line-length
      path += `?filter={\"where\": {\"timestampCreated\": {\"between\": ["${from}","${to}"]}, \"or\": [{\"status\": ${Status.PROCESSING}},{\"status\": ${Status.PAID}}, {\"status\": ${Status.DISPATCHED}},{\"status\": ${Status.DELIVERED}}]}}`;
    }
    return this.httpClient
      .get(`${this.BASEURL}${path}`, httpOptions)
      .pipe(map((result) => result));
  }

  /**
   * @description get orders placed
   * @param businessId business id
   * @param from start date
   * @param to end date
   */
  getOrdersPlaced(businessId: string, from = '', to = '') {
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    let path = `/businesses/${businessId}/stats/order/count`;
    if (from && to) {
      // tslint:disable-next-line: max-line-length
      path += `?filter={\"where\": {\"timestampCreated\": {\"between\": ["${from}","${to}"]}, \"or\": [{\"status\": ${Status.PAID}},{\"status\": ${Status.PROCESSING}},{\"status\": ${Status.DISPATCHED}},{\"status\": ${Status.DELIVERED}}]}}`;
    }
    return this.httpClient
      .get(`${this.BASEURL}${path}`, httpOptions)
      .pipe(map((result) => result));
  }

  /**
   *
   * @description get website visits
   * @param businessId business id
   * @param from start date
   * @param to end date
   */
  getVisits(businessId: string, from = '', to = '') {
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    const path = `/businesses/${businessId}/user/stat/traffic?date1=${from}&date2=${to}`;
    return this.httpClient
      .get(`${this.BASEURL}${path}`, httpOptions)
      .pipe(map((result) => result));
  }
}

export interface INotifications {
  id: string;
  name: string;
  type: string;
  status: string;
}
