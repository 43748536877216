<app-modal class="download-report-modal">
    <app-modal-header>
        <h1>{{content.header}}</h1>
    </app-modal-header>
    <app-modal-content>
        <p [innerHTML]="content.body"></p>
    </app-modal-content>
    <app-modal-footer>
        <button mat-stroked-button
                (click)="content.ctas.back(); closeModal()"
                color="primary"
                class="se-button se-button--stroke">
            {{content.ctas.textLeft}}
        </button>
        <button mat-flat-button
                (click)="content.ctas.act(); closeModal()"
                color="primary"
                class="se-button">
            {{content.ctas.textRight}}
        </button>
    </app-modal-footer>
</app-modal>
