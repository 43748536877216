<div class="policies">
    <div class="policies__banner-img">
        <div class=" policy-banner-text text-center">
            <h2 class="h2-bold">SellEasy Refund Policy</h2>
            <div class="policy-text">
                <div class="text-muted">Last updated: August 1, 2020</div>
                <div class="title-support-text">
                    <div>Thank you for your purchase at SellEasy operated by SellEasy Online Limited.</div>
                    <div>If, for any reason, You are not completely satisfied with your Plan We invite You to review our
                        policy on refunds and returns.</div>
                    <div>The following terms are applicable for any Plans that You purchased with Us.</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container policies__text-wrapper text-normal">
        <section id="Interpretation-and-Definitions">
            <h3 class="policy-area-h">1. Interpretation and Definitions</h3>
            <h6 style="padding-top: 1rem;">Interpretation</h6>
            <p>The words of which the initial letter is capitalized have meanings defined under the following
                conditions.
                The following definitions shall have the same meaning regardless of whether they appear in singular or
                in
                plural.</p>
            <h5>Definitions</h5>
            <p>For the purposes of this Return and Refund Policy:</p>
            <ul>
                <li>You means the individual accessing or using the Service, or the company, or other legal entity on
                    behalf
                    of which such individual is accessing or using the Service, as applicable.</li>
                <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
                    SellEasy
                    Online Limited, Lagos, Nigeria.</li>
                <li>Service refers to the Website.</li>
                <li>Website refers to SellEasy, accessible from selleasy.ng</li>
                <li>Plans refer to the items offered for sale on the Service.</li>
                <li>Orders mean a request by You to purchase Plans from Us.</li>
            </ul>
        </section>
        <section id="Your-Cancellation-Rights">
            <h3 class="policy-area-h">2. Your Order Cancellation Rights</h3>
            <p>You are entitled to cancel Your Order within 7 days without giving any reason for doing so. The deadline
                for
                cancelling your Plan is 7 days from the date on which You purchased the Plan or on which a third party
                you
                have appointed, who is not the carrier, starts using the Plan. In order to exercise Your right of
                cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of
                your
                decision by:</p>
            <h6>Definitions</h6>
            <ul>
                <li>By email: info@selleasy.co</li>
            </ul>
            <p>We will prorate the services already offered and reimburse You the balance no later than 14 days from the
                day
                the return is approved. We will use the same means of payment as You used for the Order, and You will
                not
                incur any fees for such reimbursement.</p>
        </section>
        <section id="Conditions-for-Returns">
            <h3 class="policy-area-h">3. Conditions for Returns</h3>
            <p>In order for the Plans to be eligible for a return, please make sure that:</p>
            <ul>
                <li>The Plans were purchased in the last 7 days</li>
            </ul>
            <p>We reserve the right to refuse returns of any Plan that does not meet the above return conditions in our
                sole
                discretion.</p>
        </section>
        <section id="Contact-Us">
            <h3 class="policy-area-h">4. Contact us</h3>
            <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
            <ul>
                <li>By email: info@selleasy.co</li>
                <li>By phone: +234 913 455 5555</li>
            </ul>
        </section>
    </div>
</div>
