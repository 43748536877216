import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/auth-service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {}

  serverError = null;
  isSubmitting = false;
  hidePassword = true;
  private validations = [Validators.required];
  resetPassword: Function;

  ngOnInit() {
    this.activatedRoute.params.subscribe((param) => {
      if (param?.token && param?.token !== ':token') {
        this.resetPassword = this.authService.resetPassword(param.token);
      }
    });
  }

  get password() {
    return this.resetPasswordForm.get('password');
  }

  get confirmPassword() {
    return this.resetPasswordForm.get('confirmPassword');
  }

  resetPasswordForm = new FormGroup(
    {
      password: new FormControl(null, [
        ...this.validations,
        Validators.minLength(8),
      ]),
      confirmPassword: new FormControl(null, [
        ...this.validations,
        Validators.minLength(8),
      ]),
    },
    {
      validators: (formGrp: FormGroup) => {
        const password = formGrp.get('password');
        const confirm = formGrp.get('confirmPassword');
        const err = { notEqual: true };

        if (
          password.value &&
          confirm.value &&
          password.value !== confirm.value
        ) {
          return err;
        }
        return null;
      },
    },
  );

  displayErrorMsg(
    msg = 'Unfortunately we could not reset your email at this time. You can contact selleasy or try again later.',
  ) {
    this.isSubmitting = false;
    this.snackbar.open(msg, 'Close', { panelClass: 'error' });
    this.serverError = msg;
    this.resetPasswordForm.valueChanges.subscribe(
      (_) => (this.serverError = null),
    );
  }

  handleSuccessLogin() {
    this.snackbar.open('Your password has been successfully resset', 'Close', {
      panelClass: 'success',
    });
    this.router.navigateByUrl('/signin');
  }

  onSubmitForm() {
    if (!this.resetPassword) {
      return;
    }

    this.isSubmitting = true;
    this.resetPassword({
      password: this.resetPasswordForm.value.confirmPassword,
    })
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe((response) => {
        response?.updated
          ? this.handleSuccessLogin()
          : this.displayErrorMsg(response);
      });
  }
}
