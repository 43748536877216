import { Component, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  @Input() title: string = `Information / Learn More`;
  @Input()
  content: string = `<p>Hi there, I am the information or learn more icon. My job is to help you learn more about anything. Sometimes I display images and links to short videos.</p>`;
  @Input() icon = '';
  @ViewChild('infoBoxMenuTrigger') infoBoxMenuTrigger: MatMenuTrigger;
}
