import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularRaveModule } from '@selleasy/angular-rave';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { LandingPageModule } from './landing-page/landing-page.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { environment } from '../environments/environment';
import {
  userReducer,
  navReducer,
  selectedOrderReducer,
} from './state/app.reducers';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxEditorModule } from 'ngx-editor';

import { AuthGuard } from './shared/services/auth.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileEditFormComponent } from './user-profile-edit-form/user-profile-edit-form.component';
import { StoreProfileEditFormComponent } from './store-profile-edit-form/store-profile-edit-form.component';
import { EditFieldComponent } from './edit-field/edit-field.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AccountServiceChargeComponent } from './account-service-charge/account-service-charge.component';
import { AccountTaxComponent } from './account-tax/account-tax.component';
import { AccountCalculateChargeComponent } from './account-calculate-charge/account-calculate-charge.component';
import { PlanComponent } from './plan/plan.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountDetailsFormComponent } from './account-details-form/account-details-form.component';
import { PlanModalComponent } from './plan-modal/plan-modal.component';
import { ModalComponent } from './shared/modal/modal.component';
import { ModalHeaderComponent } from './shared/modal-header/modal-header.component';
import { ModalContentComponent } from './shared/modal-content/modal-content.component';
import { ModalFooterComponent } from './shared/modal-footer/modal-footer.component';
import { PaymentService } from './shared/services/payment-service';
import { ProductLibraryComponent } from './product-library/product-library.component';
import { AddProductComponent } from './add-product/add-product.component';
import { ManageProductComponent } from './manage-product/manage-product.component';
import { OrdersShippingComponent } from './orders-shipping/orders-shipping.component';
import { WebsiteComponent } from './website/website.component';
import { AccountComponent } from './account/account.component';
import { DownloadReportModalComponent } from './download-report-modal/download-report-modal.component';
import { ProductSliderComponent } from './product-slider/product-slider.component';

import * as Hammer from 'hammerjs';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ProductVariantComponent } from './product-variant/product-variant.component';
import { WebsiteCustomizeComponent } from './website-customize/website-customize.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { WebsiteDesignComponent } from './website-design/website-design.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { SwipeLeftInfoComponent } from './swipe-left-info/swipe-left-info.component';
import { AuthInterceptor } from './shared/services/auth-interceptor.service';
import { AutoLogOutInterceptor } from './shared/services/autologout-interceptor.service';
import { ShowImageModalComponent } from './show-image-modal/show-image-modal.component';
import { NgxMetrikaModule } from '@kolkov/ngx-metrika';
import { UploadWebsiteLogoComponent } from './upload-website-logo/upload-website-logo.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SelleasyDeliveryModalComponent } from './selleasy-delivery-modal/selleasy-delivery-modal.component';
import { CreateDiscountComponent } from './create-discount/create-discount.component';
import { DiscountsListComponent } from './discounts-list/discounts-list.component';
import { SelectProductsComponent } from './select-products/select-products.component';
import { SelectProductModalComponent } from './select-product-modal/select-product-modal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UnsupportedStatesModalComponent } from './unsupported-states-modal/unsupported-states-modal.component';
import {
  isMobileAppFactory,
  IS_MOBILE_APP,
} from './shared/factories/user-agent-factory';
import { LearnComponent } from './learn/learn.component';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'pan-y',
    });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    UserProfileComponent,
    UserProfileEditFormComponent,
    StoreProfileEditFormComponent,
    EditFieldComponent,
    AccountSettingsComponent,
    AccountServiceChargeComponent,
    AccountTaxComponent,
    AccountCalculateChargeComponent,
    PlanComponent,
    AccountDetailsComponent,
    AccountDetailsFormComponent,
    AccountComponent,
    ModalComponent,
    ModalHeaderComponent,
    ModalContentComponent,
    ModalFooterComponent,
    PlanModalComponent,
    ProductLibraryComponent,
    AddProductComponent,
    ManageProductComponent,
    ProductLibraryComponent,
    OrdersShippingComponent,
    WebsiteComponent,
    DownloadReportModalComponent,
    ProductSliderComponent,
    ConfirmDialogComponent,
    ProductVariantComponent,
    WebsiteCustomizeComponent,
    ConfirmationModalComponent,
    WebsiteDesignComponent,
    ViewOrderComponent,
    SwipeLeftInfoComponent,
    ShowImageModalComponent,
    UploadWebsiteLogoComponent,
    SelleasyDeliveryModalComponent,
    CreateDiscountComponent,
    DiscountsListComponent,
    SelectProductsComponent,
    SelectProductModalComponent,
    UnsupportedStatesModalComponent,
    LearnComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LandingPageModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HammerModule,
    ImageCropperModule,
    NgxEditorModule,
    NgxMetrikaModule.forRoot({
      id: environment.yaCounterId,
      defer: true,
      trackPageViews: true,
      webvisor: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    }),
    StoreModule.forRoot({
      user: userReducer,
      nav: navReducer,
      selectedOrder: selectedOrderReducer,
    }),
    StoreDevtoolsModule.instrument({
      name: 'SellEasy DevTools',
      maxAge: 25,
      logOnly: environment.production,
    }),
    AngularRaveModule.forRoot({
      key: (environment as any).FLW_PUBLIC_KEY,
      isTest: environment.isTest,
      protocol: environment.isMobileApp ? 'https:' : location.protocol,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  entryComponents: [
    ModalComponent,
    ModalHeaderComponent,
    ModalContentComponent,
    ModalFooterComponent,
    PlanModalComponent,
    DownloadReportModalComponent,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AutoLogOutInterceptor,
      multi: true,
    },
    {
      provide: IS_MOBILE_APP,
      useFactory: isMobileAppFactory,
    },
    AuthGuard,
    PaymentService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
