<div class="container dashboard main-page">
    <div class="row">
        <app-header-title [title]="title"></app-header-title>
    </div>
    <div class="row dashboard__box-wrapper">
        <!-- website visit -->
        <div class="col-lg dashboard__visits">
            <h6> {{visitSelected}}
                <div class="dashboard__select">
                    <mat-form-field class="opacity-0">
                        <mat-select class="mat-elevation-z0"
                                    [(value)]="visitSelected">
                            <mat-option *ngFor="let item of duration"
                                        (click)="getVisits(item)"
                                        [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <se-icon iconName="arrow-down-small"
                         sm="true">
                </se-icon>
            </h6>
            <span>{{siteVisits}}</span>
            <h4>Website Visits</h4>
            <h5 class="centered body-text-2">{{siteVisitsComparisonText}}</h5>
        </div>
        <!-- orders placed -->
        <div class="col-lg dashboard__orders">
            <h6>{{orderSelected}}
                <div class="dashboard__select">
                    <mat-form-field class="opacity-0">
                        <mat-select [(value)]="orderSelected">
                            <mat-option *ngFor="let item of duration"
                                        (click)="getOrdersPlaced(item)"
                                        [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <se-icon iconName="arrow-down-small"
                         sm="true">
                </se-icon>
            </h6>
            <span>{{ordersPlaced}}</span>
            <h4>Orders Placed</h4>
            <h5 class="centered body-text-2">{{orderPlacedComparisonText}}
            </h5>
        </div>
        <!-- gross amount -->
        <div class="col-lg dashboard__amount">
            <h6>{{amountSelected}}
                <div class="dashboard__select">
                    <mat-form-field class="opacity-0">
                        <mat-select [(value)]="amountSelected">
                            <mat-option *ngFor="let item of duration"
                                        (click)="getGrossAmount(item)"
                                        [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <se-icon iconName="arrow-down-small"
                         sm="true">
                </se-icon>
            </h6>
            <span>{{grossAmount ? (grossAmount | currency:'₦':'symbol':'4.2-2') : '₦0'}}</span>
            <h4>Gross Amount</h4>
            <h5 class="centered body-text-2">{{grossAmountComparisonText}}</h5>
        </div>
    </div>
    <div class="row"
         id="notifications">
        <div class="col-lg dashboard__list">
            <paginated-list [data]="getall()">
                <span>All</span>
                <div class="dashboard__icon">
                    <se-icon iconName="info-icon-grey"
                             matTooltip="All your notifications"
                             matTooltipPosition="right"></se-icon>
                </div>
            </paginated-list>
        </div>
        <div class="col-lg dashboard__list dashboard__list--even">
            <paginated-list [data]="getinfo()"
                            [showIcons]="false">
                <div class="dashboard__titles">
                    <span>Activities</span>
                    <div class="dashboard__icon">
                        <se-icon iconName="info-icon-circle"
                                 matTooltip="Events happening on your store"
                                 matTooltipPosition="right"></se-icon>
                    </div>
                </div>
            </paginated-list>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg dashboard__list">
            <paginated-list [data]="getalerts()"
                            [showIcons]="false">
                <div class="dashboard__titles">
                    <span>Alerts</span>
                    <div class="dashboard__icon">
                        <se-icon iconName="alert-icon"
                                 matTooltip="Notifications requiring immediate attention"
                                 matTooltipPosition="right"></se-icon>
                    </div>
                </div>
            </paginated-list>
        </div>
        <div class="col-lg dashboard__list dashboard__list--even">
            <paginated-list [data]="getwarnings()"
                            [showIcons]="false">
                <div class="dashboard__titles">
                    <span>Out of Stock</span>
                    <div class="dashboard__icon">
                        <se-icon iconName="warning-icon-circle"
                                 matTooltip="Products no longer available"
                                 matTooltipPosition="right"></se-icon>
                    </div>
                </div>
            </paginated-list>
        </div>
    </div>
</div>
