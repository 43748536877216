import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductService } from './product-service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private productService: ProductService) {}

  /**
   * @description convert base64 to blob
   * @param dataUrl
   */
  convertDataUrlToBlob(dataUrl: string): Blob {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  saveImage(image: any) {
    return new Observable((obs) => {
      const type = image.type.split('/')[1];

      const fileName = `store-image-${Date.now()}.${type}`;

      // update file
      const formData = new FormData();
      formData.append('image', image);
      const options = { content: formData };
      this.productService
        .populateImage(formData, options, fileName)
        .subscribe((result: any) => {
          obs.next(result.uri);
        });
    });
  }
}
