export const COLORS = [
  '#000000',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#ffeb3b',
  '#ffffff',
  '#ffebee',
  '#fce4ec',
  '#f3e5f5',
  '#ede7f6',
  '#e3f2fd',
  '#e1f5fe',
  '#e0f7fa',
  '#e0f2f1',
  '#fffde7',
  '#f2f2f2',
  '#ffcdd2',
  '#f8bbd0',
  '#e1bee7',
  '#d1c4e9',
  '#bbdefb',
  '#b3e5fc',
  '#b2ebf2',
  '#b2dfdb',
  '#fff9c4',
  '#e6e6e6',
  '#ef9a9a',
  '#f48fb1',
  '#ce93d8',
  '#b39ddb',
  '#90caf9',
  '#81d4fa',
  '#80deea',
  '#80cbc4',
  '#fff59d',
  '#cccccc',
  '#e57373',
  '#f06292',
  '#ba68c8',
  '#9575cd',
  '#64b5f6',
  '#4fc3f7',
  '#4dd0e1',
  '#4db6ac',
  '#fff176',
  '#b3b3b3',
  '#ef5350',
  '#ec407a',
  '#ab47bc',
  '#7e57c2',
  '#42a5f5',
  '#29b6f6',
  '#26c6da',
  '#26a69a',
  '#ffee58',
  '#999999',
  '#e53935',
  '#d81b60',
  '#8e24aa',
  '#5e35b1',
  '#1e88e5',
  '#039be5',
  '#00acc1',
  '#00897b',
  '#fdd835',
  '#808080',
  '#d32f2f',
  '#c2185b',
  '#7b1fa2',
  '#512da8',
  '#1976d2',
  '#0288d1',
  '#0097a7',
  '#00796b',
  '#fbc02d',
  '#666666',
  '#c62828',
  '#ad1457',
  '#6a1b9a',
  '#4527a0',
  '#1565c0',
  '#0277bd',
  '#00838f',
  '#00695c',
  '#f9a825',
  '#4d4d4d',
  '#b71c1c',
  '#880e4f',
  '#4a148c',
  '#311b92',
  '#0d47a1',
  '#01579b',
  '#006064',
  '#004d40',
  '#f57f17',
];

export const COLORS_MOD = [
  '#f2f2f2',
  '#ffcdd2',
  '#f8bbd0',
  '#e1bee7',
  '#d1c4e9',
  '#bbdefb',
  '#b3e5fc',
  '#b2ebf2',
  '#b2dfdb',
  '#fff9c4',
  '#e6e6e6',
  '#ef9a9a',
  '#f48fb1',
  '#ce93d8',
  '#b39ddb',
  '#90caf9',
  '#81d4fa',
  '#80deea',
  '#80cbc4',
  '#fff59d',
  '#cccccc',
  '#e57373',
  '#f06292',
  '#ba68c8',
  '#9575cd',
  '#64b5f6',
  '#4fc3f7',
  '#4dd0e1',
  '#4db6ac',
  '#fff176',
  '#b3b3b3',
  '#ef5350',
  '#ec407a',
  '#ab47bc',
  '#7e57c2',
  '#42a5f5',
  '#29b6f6',
  '#26c6da',
  '#26a69a',
  '#ffee58',
  '#999999',
  '#e53935',
  '#d81b60',
  '#8e24aa',
  '#5e35b1',
  '#1e88e5',
  '#039be5',
  '#00acc1',
  '#00897b',
  '#fdd835',
  '#808080',
  '#d32f2f',
  '#c2185b',
  '#7b1fa2',
  '#512da8',
  '#1976d2',
  '#0288d1',
  '#0097a7',
  '#00796b',
  '#fbc02d',
  '#666666',
  '#c62828',
  '#ad1457',
  '#6a1b9a',
  '#4527a0',
  '#1565c0',
  '#0277bd',
  '#00838f',
  '#00695c',
  '#f9a825',
  '#4d4d4d',
  '#b71c1c',
  '#880e4f',
  '#4a148c',
  '#311b92',
  '#0d47a1',
  '#01579b',
  '#006064',
  '#004d40',
  '#f57f17',
];

const removeWhiteNBlack = (arr) => {
  return arr.filter((v, i) => i % 10 !== 0);
};

export const orderColors = (_arr) => {
  const arr = removeWhiteNBlack(_arr);
  const newArr = [];

  for (let j = 0; j < 9; j++) {
    for (let i = j; i < arr.length; i += 9) {
      newArr.push(arr[i]);
    }
  }

  return newArr;
};
