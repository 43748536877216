import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { StateFacade } from '../services/state.facade';

@Component({
  selector: 'app-full-info-box',
  templateUrl: './full-info-box.component.html',
  styleUrls: ['./full-info-box.component.scss'],
})
export class FullInfoBoxComponent implements OnInit, OnDestroy {
  @Input() template: TemplateRef<any>;
  @Input() title: string;
  @Input() icon = '';
  showInfoBox = false;
  isMobile = false;
  navRef = null;

  constructor(private stateFacade: StateFacade) {}

  ngOnInit() {
    this.stateFacade.getViewPortSize().subscribe(({ isMobile }) => {
      this.isMobile = isMobile;
    });
    Promise.resolve().then(() => {
      this.navRef = document.querySelector('#se-nav__top-bar');
    });
  }

  toggle() {
    this.showInfoBox = !this.showInfoBox;
    this.toggleNavDisplay();
  }

  // TODO: Remove hacky fix, use CSS instead
  toggleNavDisplay() {
    this.showInfoBox
      ? this.navRef.classList.add('d-none')
      : this.navRef.classList.remove('d-none');
  }

  ngOnDestroy(): void {
    this.navRef.classList.remove('d-none');
  }
}
