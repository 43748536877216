import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { noop } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FormErrorService {
  private errorFields = [];
  private form: FormGroup;

  get hasErrors() {
    return !!this.errorFields.length;
  }

  clearErrorFields() {
    this.errorFields = [];
  }

  registerForm(form: FormGroup) {
    this.form = form;
  }

  registerErrorFields(errField) {
    this.errorFields.push(errField);
  }

  registerErrCtrls() {
    const ctrls = this.form.controls;
    for (const ctrl in ctrls) {
      if (ctrls[ctrl].errors) {
        const field = document.querySelector(`#${ctrl}Id`);
        field ? this.registerErrorFields(field) : noop;
      }
    }
  }

  scrollToErrorCtrl() {
    const field = this.errorFields[0];
    field
      ? (field.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        }),
        setTimeout(() => field.focus(), 1000))
      : noop();
  }

  validate(form: FormGroup) {
    this.registerForm(form);
    this.clearErrorFields();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.registerErrCtrls();

    if (!this.hasErrors) {
      return;
    }
    this.scrollToErrorCtrl();
  }
}
