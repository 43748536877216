import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { LandingPageComponent } from './landing-page.component';
import { SliderComponent } from './slider/slider.component';
import { LandingPageRoutingModule } from './landing-page.routing.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SettingupWebsiteComponent } from './settingup-website/settingup-website.component';
import { WebsiteReadyComponent } from './website-ready/website-ready.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PlatformComponent } from './platform/platform.component';
import { PrivacyPolicyComponent } from './policies/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './policies/refund-policy/refund-policy.component';
import { TermsAndConditionsComponent } from './policies/terms-and-conditions/terms-and-conditions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    SliderComponent,
    SignInComponent,
    SignUpComponent,
    SettingupWebsiteComponent,
    WebsiteReadyComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PlatformComponent,
    PrivacyPolicyComponent,
    RefundPolicyComponent,
    TermsAndConditionsComponent,
    AboutUsComponent,
    SplashScreenComponent,
  ],
  imports: [
    CommonModule,
    LandingPageRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class LandingPageModule {}
