import { Component, OnInit } from '@angular/core';
import { StateFacade } from '../shared/services/state.facade';
import { STORE_PLANS } from '../shared/models/user-profile';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
  title = 'Account Settings';
  tabIndex = 0;
  constructor() {}

  ngOnInit(): void {}

  getSelectedTab(e) {
    this.tabIndex = e.index;
  }
}
