import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from '../services/auth-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @Input()
  total = 0;

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.dashboardService
      .getNotifications(this.authService.getBusinessId())
      .subscribe((result) =>
        this.dashboardService.setNotifications(
          this.dashboardService.sortNotification(result),
        ),
      );
  }

  get notifications() {
    return this.dashboardService.getSharedNotifications().length;
  }

  gotoAlerts() {
    this.router.navigate(['/dashboard', { fromNotifications: true }]);
  }
}
