export enum Status {
  'ORDER RECEIVED',
  PROCESSING,
  DISPATCHED,
  COMPLETED,
  'IN TRANSIT',
  CANCELLED,
  ACCEPTED,
  REJECTED,
  UPDATED,
  'MISSED DELIVERY',
  DISABLED,
  DELETED,
  ACTIVE,
  'OUT OF STOCK',
  PAID,
  'PAYMENT FAILED',
  'PAYMENT REJECTED',
  UNREAD,
  READ,
  DELIVERED,
  UNPAID,
}
