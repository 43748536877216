import { IEnv } from './model';

export const environment: IEnv = {
  production: true,
  API_BASE_URL: 'https://api.selleasy.co',
  FLW_PUBLIC_KEY: 'FLWPUBK-930cefa471a978287ce3faae80ef2df1-X',
  hmr: false,
  isTest: false,
  path: 'https://$websiteName.selleasy.ng',
  yaCounterId: 67741126,
  mixpanelToken: '9ed445be53ae55ae1252d4dc5f52561e',
};
