import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  OnInit,
  SimpleChanges,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as fastCartesian from 'fast-cartesian';
import { Variation, ProductVariant } from '../shared/models/variation';
import { StateFacade } from '../shared/services/state.facade';
import {
  FormFieldDirective,
  VariantOptionsDirective,
  VariantPriceDirective,
} from '../shared/directives/form-field.directive';
import {
  addErrorClass,
  errorClass,
  removeErrorClass,
  toLowerOpts,
  truthyOpts,
} from '../shared/utils/validations';
import { compose, filter, uniq } from 'lodash/fp';
import { uniqueOptions } from '../shared/utils/variation-utils';
import { ProductService } from '../shared/services/product-service';
import { variantErrors } from '../shared/models/product';
import { takeWhile } from 'rxjs/operators';
import { defer } from 'lodash';

@Component({
  selector: 'app-product-variant',
  templateUrl: './product-variant.component.html',
  styleUrls: ['./product-variant.component.scss'],
})
export class ProductVariantComponent implements OnChanges, OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() showProductVariant;
  @Input() variants;
  @Input() generateVariant;
  @Input() editMode = false;
  @Input() productVariation: ProductVariant[];
  @Input() businessVariationList: Variation[];
  @Input() totalQuantity;
  @Input() price = 0;
  @Output() variantErrors = new EventEmitter();
  @Output() emptyOptFieldRef = new EventEmitter();
  @Output() emptyPriceFieldRef = new EventEmitter();

  errors = {};
  isMobile: boolean;
  optionRegex = /[^0-9A-Za-z_ @\-]/g;
  valueRegex = /[^0-9A-Za-z_,. @\-]/g;
  numRegex = /[^0-9]/g;
  hasViewedVariantsLearnMore = false;
  optErrors = {};
  isComponentActive = true;
  @ViewChildren(FormFieldDirective) matFormField: QueryList<FormFieldDirective>;
  @ViewChildren(VariantOptionsDirective) optionMatFields: QueryList<
    VariantOptionsDirective
  >;
  @ViewChildren(VariantPriceDirective) priceMatFields: QueryList<
    VariantPriceDirective
  >;
  submitClicked = false;

  constructor(
    private stateFacade: StateFacade,
    private productService: ProductService,
  ) {
    this.stateFacade
      .getViewPortSize()
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((v) => {
        this.isMobile = v.isMobile;
      });
  }

  checkEmptyField(el, field) {
    !el.currentTarget.value.trim()
      ? addErrorClass(field)
      : removeErrorClass(field);
  }

  priceCannotBeZero(el, field) {
    !el.currentTarget.value.trim() || el.currentTarget.value.trim() === '0'
      ? addErrorClass(field)
      : removeErrorClass(field);
  }

  get hasOptError() {
    return !!filter((v) => !!v)(Object.values(this.optErrors)).length;
  }

  hasDuplicates = (index, ref) => {
    const clean = compose(uniq, toLowerOpts, truthyOpts)(this.variants.options);
    clean.length && clean.length !== truthyOpts(this.variants.options).length
      ? ((this.optErrors[index] = true), addErrorClass(ref))
      : ((this.optErrors[index] = false), removeErrorClass(ref));
    return this.optErrors[index];
  };

  ngOnInit() {
    this.variantErrors.emit(this.errors);
    this.hasViewedVariantsLearnMore = !!localStorage.getItem(
      'hasViewedVariantsLearnMore',
    );
    this.onVariantErrors();
    this.productService.formSubmitSub.subscribe((v: boolean) => {
      this.buildVariations(v);
      this.submitClicked = v;
    });
  }

  onVariantErrors() {
    this.productService.variantErrorSub
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((e: string) => {
        switch (e) {
          case variantErrors.EMPTY_OPTION:
            this.handleEmptyOptionErr();
            break;
          case variantErrors.ZERO_PRICE:
            defer(() => this.handleZeroPriceErr());
            break;
          case variantErrors.ZERO_TOTAL_QUANTITY:
            break;
        }
      });
  }

  handleEmptyOptionErr() {
    document
      .querySelector('#productVariantsContainer')
      .scrollIntoView({ behavior: 'smooth' });
    const optFields = document.querySelectorAll(
      '.product-variant__options-input',
    );
    if (!optFields) {
      return;
    }

    const emptyField: any = Array.from(optFields).find(
      (el: HTMLInputElement) => !el.value.trim(),
    );
    if (emptyField) {
      const index = Number(emptyField.dataset.index);
      (this.optionMatFields as any)._results[
        index
      ].ref.nativeElement.classList.add(...errorClass);
      setTimeout(() => emptyField.focus(), 1000);
    }
  }

  handleZeroPriceErr() {
    document
      .querySelector('#productVariantsList')
      .scrollIntoView({ behavior: 'smooth' });
    const priceFields = document.querySelectorAll(
      '.product-variant__price-field',
    );
    if (!priceFields) {
      return;
    }
    const emptyField: any = Array.from(priceFields).find(
      (el: HTMLInputElement) => !el.value.trim() || el.value.trim() === '0',
    );
    if (emptyField) {
      const index = Number(emptyField.dataset.index);
      (this.priceMatFields as any)._results[
        index
      ].ref.nativeElement.classList.add(...errorClass);
      setTimeout(() => {
        emptyField.focus();
      }, 1000);
    }
  }

  ngOnChanges({ totalQuantity }: SimpleChanges) {
    if (this.generateVariant) {
      this.buildVariations();
    }

    if (totalQuantity && totalQuantity.currentValue) {
      const index = Number(Object.keys(this.errors)[0]);
      if (!isNaN(index)) {
        const field = this.matFormField.find((v, i) => index === i);
        this.showErrorMessage(index, field.ref.nativeElement);
      }
    }
  }

  clearErrors() {
    this.errors = {};
    if (this.matFormField) {
      this.matFormField.forEach((el) =>
        this.toggleErrorClass(el.ref.nativeElement, false),
      );
    }
  }

  toggleErrorClass(el, err) {
    const cls = ['mat-form-field-invalid', 'mb-5'];
    err ? el.classList.add(...cls) : el.classList.remove(...cls);
  }

  showErrorMessage(i, field?) {
    this.clearErrors();

    this.errors[i] = this.validateVariantQty();
    this.toggleErrorClass(
      field?._elementRef?.nativeElement || field,
      this.errors[i],
    );
  }

  validateVariantQty() {
    const sum = this.variants.variations.reduce(
      (acc, curr) => {
        acc.quantity += Number(curr.quantity);
        return acc;
      },
      { quantity: 0 },
    );

    return sum.quantity > this.totalQuantity;
  }

  get hasVariantError() {
    return this.validateVariantQty();
  }

  get qtySumIsZero() {
    if (!this.variants?.variations.length) {
      return false;
    }
    const sum = this.variants.variations.reduce(
      (acc, curr) => {
        acc.quantity += Number(curr.quantity);
        return acc;
      },
      { quantity: 0 },
    );
    return this.submitClicked && sum.quantity === 0;
  }

  get errorObjectList() {
    return Object.values(this.errors).length;
  }

  removeAnimation(ref) {
    ref.template.elementRef.nativeElement.nextSibling.classList.remove(
      'animated',
      'heartBeat',
      'infinite',
    );
    localStorage.setItem('hasViewedVariantsLearnMore', 'true');
  }

  buildVariations(shouldCalc = false) {
    const variants = uniqueOptions(this.variants);
    const vals = variants?.options
      .map((v: any) => {
        return v.values.map((i) => `${v.option}$$$${i}`);
      })
      .filter((i) => !!i);

    if (variants) {
      const newVals = fastCartesian(vals ?? []);
      this.variants.variations = newVals.map((v, index) => ({
        variations: v.map((i) => i.split('$$$')[1]),
        quantity: shouldCalc
          ? variants.variations[index].quantity
          : this.editMode === false
          ? 0
          : this.getVariantQty(
              v.map((i) => i.split('$$$')[1]),
              v.map((i) => i.split('$$$')[0]),
            ),
        price: shouldCalc
          ? variants.variations[index].price
          : this.editMode === false
          ? this.price
          : this.getVariantPrice(
              v.map((i) => i.split('$$$')[1]),
              v.map((i) => i.split('$$$')[0]),
            ),
        option: v,
      }));
    }
    this.clearErrors();
  }

  getVariantQty(value: any, option: any) {
    let ids = [];
    value?.forEach((v, index) => {
      ids.push(
        this.businessVariationList.find(
          (b) => b.attributeName === option[index] && b.attributeValue === v,
        )?.id,
      );
    });
    const qty = this.productVariation
      .filter((p) => p.variationId?.sort().join('') === ids.sort().join(''))
      .map((pv) => pv.quantity);
    return qty[0] ?? 0;
  }

  getVariantPrice(value: any, option: any) {
    let ids = [];
    value?.forEach((v, index) => {
      ids.push(
        this.businessVariationList.find(
          (b) => b.attributeName === option[index] && b.attributeValue === v,
        )?.id,
      );
    });
    const price = this.productVariation
      .filter((p) => p.variationId?.sort().join('') === ids.sort().join(''))
      .map((pv) => pv.price);
    return price[0] ?? this.price;
  }

  addVariant() {
    this.variants.options.push({
      option: '',
      values: [],
    });
  }

  deleteVariant(opt) {
    const index = this.variants.options.indexOf(opt);
    this.variants.options.splice(index, 1);
    this.buildVariations();
  }

  removeValue(value, opt) {
    const index = this.variants.options.indexOf(opt);
    this.variants.options[index].values = this.variants.options[
      index
    ].values.filter((v) => v !== value);
    this.buildVariations();
  }

  addValue(e, opt?) {
    const input = e.input;
    const value = (e.value && e.value.trim()) || '';

    if (value) {
      opt.values.push(value);
    }

    if (input) {
      input.value = '';
    }
    this.buildVariations();
  }
}
