export const STORE_PLANS = {
  storeFront: [
    {
      feature: {
        heading: 'Personalized Online Store',
        body: 'Fully customisable secured e-commerce website',
      },
      free: 'available',
      premium: 'available',
    },
    {
      feature: {
        heading: 'Free SellEasy subdomain',
        body:
          'All created stores have SellEasy subdomains that are accessible immediately E.g; www.mystore.selleasy.ng',
      },
      free: 'available',
      premium: 'available',
    },
    {
      feature: {
        heading: 'Free Custom Domain',
        body:
          'Get a free ".com.ng" domain or connect a domain you already own to your SellEasy hosted e-commerce website. E.g www.mystore.com.ng',
      },
      free: null,
      premium: 'available',
    },
    {
      feature: {
        heading: 'Mobile Ready Responsive Website',
        body: 'Deliver user-friendly shopping experience across devices',
      },
      free: 'available',
      premium: 'available',
    },
    {
      feature: {
        heading: '1-Page Checkout',
        body:
          'Simple single-page checkout process. Avoid abandoned checkouts when your customers checkout with a few clicks.',
      },
      free: 'available',
      premium: 'available',
    },
    {
      feature: {
        heading: 'Secured Website, Free SSL',
        body:
          'Your security is a top priority, SellEasy ensure your website is secured with industry standard SSL certificate out-of-the-box.',
      },
      free: 'available',
      premium: 'available',
    },
    {
      feature: {
        heading: 'Customer Account management',
        body:
          'Your Customers can create accounts to manage orders, shopping cart, wishlist, compare items across devices and experience faster checkout experience',
      },
      free: 'available',
      premium: 'available',
    },
    {
      feature: {
        heading: 'Customer Order Tracking',
        body: ' Your Customers can track orders right from dropoff to delivery',
      },
      free: 'available',
      premium: 'available',
    },
  ],
  storeManagement: [],
};

export const list = [
  'Free Custom Domain',
  'Personalized Online Store',
  'Free SellEasy subdomain',
  'Mobile Ready Responsive Website',
  '1-Page Checkout',
  'Secured Website, Free SSL',
  'Customer Account management',
  'Inventory Management - Product metrics and reporting',
  'Order Management - Order metrics and reporting',
  'Customer Order Tracking',
  'Product Listings - Unlimited',
  'Website & Sales Metrics',
  'Integrated Payment',
  'Integrated Delivery',
  'Automatic Scaling',
  'Integrated messaging (Email and Chat)',
  'Social Commerce - Share products directly on WhatsApp',
  'Transaction Charge',
  'Customer Support',
];
