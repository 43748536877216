export const CATEGORIES = [
  'Computer & Accessories',
  'Home & Living',
  'Phones & Tablets',
  'Electronics',
  'Fashion',
  'Arts & Crafts',
  'Auto & Hardware',
  'Baby',
  'Bags & Luggage',
  'Beauty',
  'Books, Media & Entertainment',
  'Camera & Photo',
  'DVD & Videos',
  'Food & Beverage',
  'Health & Personal care',
  'Home Improvement',
  'Motorcycles & Powersports',
  'Musical Instruments',
  'Office Supplies',
  'Outdoor & Garden',
  'Pet Supplies',
  'Software',
  'Sporting Goods',
  'Toys Games & Hobbies',
  'Video Game Consoles',
  'Warranty & Service',
  'Watches',
  'Others',
];
