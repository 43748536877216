import { InjectionToken } from '@angular/core';
import { of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export const IS_MOBILE_APP = new InjectionToken('IS_MOBILE_APP');

export const isMobileAppFactory = () => {
  const sub = of(!!environment.isMobileApp).pipe(shareReplay());
  return sub;
};

export const isAndroidOrIOS = (target, propkey) => {
  target[propkey] = !!environment.isMobileApp;
};
