import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { states, lgas, locations } from '../shared/models/states';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import { MatDialog } from '@angular/material/dialog';
import { MatOption } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectChange } from '@angular/material/select';
import { StateFacade } from '../shared/services/state.facade';
import { FREETEXT_REGEX, PHONE_REGEX } from '../shared/utils/regexes';
import { timeout } from 'rxjs/operators';
import { filter } from 'lodash/fp';
import { SelleasyDeliveryModalComponent } from '../selleasy-delivery-modal/selleasy-delivery-modal.component';
import {
  addErrorClass,
  noWhiteSpace,
  removeErrorClass,
  validateValues,
} from '../shared/utils/validations';
import { FormErrorService } from '../shared/services/form-error.service';
import { adjustModalWidth } from '../shared/utils/helpers';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { of, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { capitalize, isEqual, noop, get as _get } from 'lodash';
import {
  StoreProfile,
  DeliveryConfiguration,
} from '../shared/models/store-profile';
import { UnsupportedStatesModalComponent } from '../unsupported-states-modal/unsupported-states-modal.component';

@Component({
  selector: 'app-store-profile-edit-form',
  templateUrl: './store-profile-edit-form.component.html',
  styleUrls: ['./store-profile-edit-form.component.scss'],
})
export class StoreProfileEditFormComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() userEmail;
  timeout = 5000;
  profileEditForm: FormGroup;
  formHasChanges = false;
  states = [];
  lgas = [];
  locations = [];
  dropOffLocations = [];
  filteredDropOffs = [];
  selectedState = '';
  selectedLgaId = 0;
  selectedStateId: number;
  isMobile: boolean;
  readonly lagosId = 1061493610;
  countries: string[] = ['nigeria'];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  valueRegex = /[^0-9A-Za-z_,. @\-]/g;
  removable = true;
  selectable = true;
  isComponentActive = true;
  deliveryProvider: string;
  private sub$ = new Subject();
  useSelleasyForOtherStates: boolean;

  lootedLocationsId = {
    981: 'KONGA CHERUB MALL STORE',
    980: 'KONGA ADMIRALTY STORE',
    976: 'KONGA IKOYI STORE',
    1121: 'Kxpress Surulere',
    1026: 'Kxpress Lekki Proprietary',
  };

  private excludeLootedLocations = filter(
    (v: any) => !this.lootedLocationsId[v.id],
  );

  constructor(
    private fb: FormBuilder,
    private profileBusinessService: ProfileBusinessService,
    private snackbar: MatSnackBar,
    private stateFacade: StateFacade,
    private dialog: MatDialog,
    private formErr: FormErrorService,
  ) {}

  get name() {
    return this.profileEditForm.get('name');
  }

  get streetAddress() {
    return this.profileEditForm.get('streetAddress');
  }

  get email() {
    return this.profileEditForm.get('email');
  }

  get phone() {
    return this.profileEditForm.get('phone');
  }

  get state() {
    return this.profileEditForm.get('state');
  }

  get localGovt() {
    return this.profileEditForm.get('localGovt');
  }

  get pickUpLocation() {
    return this.profileEditForm.get('pickUpLocation');
  }

  get deliveryType() {
    return this.profileEditForm.get('deliveryType');
  }

  get deliveryMode() {
    return this.profileEditForm.get('deliveryMode');
  }

  get dropOffLocation() {
    return this.profileEditForm.get('dropOffLocation');
  }

  ngOnChanges() {
    if (this.userEmail) {
      this.email.setValue(this.email.value || this.userEmail);
    }
  }

  openModal(fn?) {
    this.dialog.open(SelleasyDeliveryModalComponent, {
      width: this.isMobile ? '100vw' : '40%',
      data: { action: () => (fn ? fn() : null) },
    });
    adjustModalWidth(this.isMobile);
  }

  openUnsupportedStatesModal(content, disableClose = true) {
    this.dialog.open(UnsupportedStatesModalComponent, {
      width: this.isMobile ? '100vw' : '40%',
      data: content,
      disableClose,
    });
  }

  validateSupportedStates(model) {
    const delivery = model.delivery[0];
    const allStates = delivery.selfDeliveryZones
      .map((v) => v.config.map((c) => c.states))
      .reduce((acc, v) => [...acc, ...v], [])
      .reduce((acc, v) => [...acc, ...v], []);
    const states = this.states.map((v) => v.name);

    if (!isEqual(allStates, states)) {
      this.openUnsupportedStatesModal(
        {
          model,
          formValues: {
            ...this.profileEditForm.value,
            dropOffLocations: this.filteredDropOffs,
            lagosId: this.lagosId,
          },
          sub: this.sub$,
        },
        false,
      );
      adjustModalWidth(this.isMobile);
      return false;
    }
    return true;
  }

  removeValue(state: string, states: string[]) {
    const index = states.indexOf(state);
    states.splice(index, 1);
  }

  filterDropOffLocation(e) {
    const locations = this.dropOffLocations.filter(
      (location) => location.state.id === e.value,
    );
    this.filteredDropOffs = locations;
  }

  getSavedStates() {
    this.states = JSON.parse(states.locations).data;
  }

  getSavedLgas(stateId: any) {
    this.lgas = lgas?.locations?.data
      .filter((l) => l.id === stateId)
      .map((o) => o.lgas?.locations?.data)[0];
  }

  getSavedLocations() {
    this.dropOffLocations = this.excludeLootedLocations(
      JSON.parse(locations.locations).data,
    );
    this.filterDropOffLocation({ value: this.selectedStateId });
  }

  ngOnInit(): void {
    this.initForm();
    this.getStates();
    this.getDropOffLocations();
    this.getBusinessProfile();
    this.onViewPortChange();
    this.onModalClosed();
    this.sub$.subscribe((_) => this.getBusinessProfile());
  }

  onViewPortChange() {
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
    });
    this.onModalClosed();
  }

  onModalClosed() {
    this.dialog.afterAllClosed
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((_) => {
        if (this.deliveryType.value === 'selleasydelivery') {
          this.selfDeliveryLocations.setValue(this.initSelfDeliveryConfig());
        }
      });
  }

  initForm() {
    this.profileEditForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern(FREETEXT_REGEX),
          noWhiteSpace,
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(PHONE_REGEX)]],
      streetAddress: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern(FREETEXT_REGEX),
        ],
      ],
      state: ['', [Validators.required, Validators.minLength(3), noWhiteSpace]],
      localGovt: [
        '',
        [Validators.required, Validators.minLength(3), noWhiteSpace],
      ],
      deliveryType: [, Validators.required],
      deliveryMode: ['pickup', Validators.required],
      pickUpLocation: [
        '',
        [
          Validators.minLength(3),
          Validators.pattern(FREETEXT_REGEX),
          noWhiteSpace,
        ],
      ],
      dropOffLocation: ['', noWhiteSpace],
      selfDeliveryLocations: [this.initSelfDeliveryConfig()],
      selfDeliveryDropOffLocation: [],
      selfDeliveryPickUpLocation: [],
    });
  }

  get selfDeliveryPickUpLocation() {
    return this.profileEditForm.get('selfDeliveryPickUpLocation');
  }

  get selfDeliveryDropOffLocation() {
    return this.profileEditForm.get('selfDeliveryDropOffLocation');
  }

  get isSelfDelivery() {
    return this.deliveryType?.value === 'selfdelivery';
  }

  get isSellEasyDelivery() {
    return this.deliveryType?.value === 'selleasydelivery';
  }

  get isLagosResident() {
    return this.state.value === this.lagosId;
  }

  initSelfDeliveryConfig() {
    return [
      {
        country: 'Nigeria',
        config: [
          {
            zone: '',
            states: [],
            price: '',
            etaMin: '',
            etaMax: '',
          },
        ],
      },
    ];
  }

  addGroup(arr: any[]) {
    arr.push({
      zone: '',
      states: [],
      price: '',
      etaMin: '',
      etaMax: '',
    });
  }

  formatSelfDeliveryLocations(locations) {
    return locations.map((v) => ({
      country: v.country,
      config: v.config.map((c, index) => ({
        ...c,
        zone: !c.zone.trim() ? `Group ${index + 1}` : c.zone,
        etaMin: !c.etaMin ? 1 : Number(c.etaMin),
        etaMax: Number(c.etaMax),
        price: Number(c.price),
      })),
    }));
  }

  removeGroup(item, arr: any[]) {
    const index = arr.indexOf(item);
    arr.splice(index, 1);
  }

  addValue(e, states?: any[]) {
    const input = e.input;
    const value = (e.value && e.value.trim()) || '';
    const val = value.toLowerCase();
    const isValid =
      !!this.states.filter((v) => v.name.toLowerCase() === val).length &&
      !states.includes(capitalize(val));

    if (value && isValid) {
      states.push(capitalize(value));
    }
  }

  addOption({ option }, states?: any[]) {
    const _states = states.map((v) => v.toLowerCase());
    !_states.includes(option.value.toLowerCase())
      ? states.push(option.value)
      : noop();
  }

  filterStates(value) {
    if (!value) return of(this.states.slice());
    const _value = value.toLowerCase();
    return of(
      this.states.filter((v) => v.name.toLowerCase().indexOf(_value) === 0),
    );
  }

  get selfDeliveryLocations() {
    return this.profileEditForm.get('selfDeliveryLocations');
  }

  /**
   * @description patch business profile form
   * @param data data
   */
  patchForm(data) {
    const stateLga = data?.businessAddressStateLga;
    const state = stateLga ? stateLga.state[0] : null;
    const lga = stateLga ? stateLga.lga[0] : null;

    const deliveryConfig = data?.delivery?.filter(
      (d: { isActive: boolean }) => d.isActive === true,
    )[0];
    const deliveryType = !deliveryConfig
      ? null
      : (() =>
          deliveryConfig?.provider === 'Express'
            ? 'selleasydelivery'
            : 'selfdelivery')();

    this.deliveryProvider = deliveryConfig?.provider;
    this.useSelleasyForOtherStates = _get(
      deliveryConfig,
      'selfDeliveryZones[0].useSelleasyForOtherStates',
    );

    let deliveryMode = '';
    let isPickup = null;
    let dropoffLocation = null;
    let pickupLocation = null;

    isPickup = deliveryConfig?.configuration?.isPickup;

    if (isPickup) {
      deliveryMode = 'pickup';
      pickupLocation = deliveryConfig?.configuration?.pickupLocation;
    } else {
      deliveryMode = 'dropoff';
      dropoffLocation = deliveryConfig?.configuration?.dropoffLocation;
      dropoffLocation = dropoffLocation?.length ? dropoffLocation[0].id : null;
    }

    if (data.businessAddressStateLga) {
      this.getLgas(state?.id);
      this.selectedState = state.name;
    }

    const delivery = data.delivery[0];

    this.profileEditForm.patchValue({
      name: data.name ?? '',
      email: data.businessEmail ?? '',
      phone: data.businessPhoneNumber ?? '',
      streetAddress: data.businessAddress ?? '',
      state: state?.id,
      localGovt: lga?.id,
      deliveryType,
      deliveryMode,
      pickUpLocation: pickupLocation || data.businessAddress,
      dropOffLocation: dropoffLocation,
      selfDeliveryLocations:
        delivery?.provider === 'Self'
          ? delivery?.selfDeliveryZones
          : this.initSelfDeliveryConfig(),
      selfDeliveryDropOffLocation: _get(
        delivery,
        'configuration.selfDeliveryDropoffLocation[0].id',
      ),
      selfDeliveryPickUpLocation:
        delivery?.configuration?.selfDeliveryPickUpLocation,
    });

    this.selectedLgaId = lga?.id;
    this.selectedStateId = state?.id;
  }

  triggerModal() {
    if (
      this.deliveryType.value === 'selfdelivery' &&
      this.state.value === this.lagosId
    ) {
      this.openModal(() => {
        this.deliveryType.setValue('selleasydelivery');
        this.deliveryMode.setValue('pickup');
        this.updateProfile();
        setTimeout(() => {
          document
            .querySelector('#updateProfileCta')
            .scrollIntoView({ block: 'end' });
        });
      });
    }
  }

  /**
   * @description get business profile
   */
  getBusinessProfile() {
    this.profileBusinessService.getBusinessProfile().subscribe((result) => {
      this.patchForm(result);
    });
  }

  /**
   * @description select state
   * @param event on select change event
   */
  selectState(event: MatSelectChange) {
    const selectedOption = event.source.selected as MatOption;
    const value = selectedOption.value;
    this.selectedState = selectedOption.viewValue;
    this.getLgas(value);
    this.filterDropOffLocation({ value: event.value });

    if (
      (this.selectedState === '' || this.selectedState !== 'Lagos') &&
      this.deliveryType.value !== 'selfdelivery'
    ) {
      this.forceFieldValidation('dropOffLocation');
    } else {
      this.forceFieldValidation('dropOffLocation', false);
      this.profileEditForm.patchValue({
        deliveryMode: 'pickup',
      });
    }
  }

  /**
   * @description get states
   */
  getStates() {
    this.profileBusinessService
      .getStates()
      .pipe(timeout(this.timeout))
      .subscribe(
        (result) => {
          result = JSON.parse(JSON.stringify(result));
          result = JSON.parse(result['locations']);
          if (result) {
            this.states = result['data'];
          } else {
            this.getSavedStates();
          }
        },
        () => this.getSavedStates(),
      );
  }

  /**
   * @description get lgas
   * @param stateId state id
   */
  getLgas(stateId: string) {
    this.profileBusinessService
      .getLgas(stateId)
      .pipe(timeout(this.timeout))
      .subscribe(
        (result) => {
          result = JSON.parse(JSON.stringify(result));
          result = JSON.parse(result['locations']);
          if (result) {
            this.lgas = result['data'];
          } else {
            this.getSavedLgas(stateId);
          }
        },
        () => this.getSavedLgas(stateId),
      );
  }

  /**
   * @description get drop off locations
   */
  getDropOffLocations() {
    this.profileBusinessService
      .getDropOffLocations()
      .pipe(timeout(this.timeout))
      .subscribe(
        (result) => {
          result = JSON.parse(JSON.stringify(result));
          result = JSON.parse(result['locations']);
          if (result) {
            this.dropOffLocations = this.excludeLootedLocations(result['data']);
            this.filterDropOffLocation({ value: this.selectedStateId });
          } else {
            this.getSavedLocations();
          }
        },
        () => {
          this.getSavedLocations();
        },
      );
  }

  hasSelfDeliveryConfigErr() {
    if (this.deliveryType.value === 'selleasydelivery') {
      return false;
    }
    return validateValues(
      this.formatSelfDeliveryLocations(this.selfDeliveryLocations.value),
    );
  }

  showErrMsg(msg) {
    this.snackbar.open(msg, 'OK', {
      panelClass: 'error',
      duration: 20000,
    });
  }

  scrollToDeilverySettings() {
    document.querySelector('#deliverySettings').scrollIntoView({
      behavior: 'smooth',
    });
  }

  checkEmptyField(el, matField) {
    const val = el.value.trim();
    !val || val === '0'
      ? (addErrorClass(matField), (el.__hasReqErr = true))
      : (removeErrorClass(matField), (el.__hasReqErr = false));
  }

  checkMinMaxErr(min, max, label, matField) {
    const _min = Number(min.value?.trim());
    const _max = Number(max.value?.trim());

    if (!_min || !_max) {
      return;
    }

    _max < _min
      ? (addErrorClass(matField),
        label === 'min' ? (min.__hasReqErr = true) : (max.__hasReqErr = true))
      : (removeErrorClass(matField),
        ((max.__hasReqErr = false), (min.__hasReqErr = false)));
  }

  markErrorFields() {
    const fields: NodeList = document.querySelectorAll(
      '.self-delivery-settings input',
    );
    if (!fields.length) {
      return;
    }
    fields.forEach((el: any) => {
      el.focus();
      el.blur();
    });
  }

  /**
   * @description update profile
   */
  updateProfile() {
    this.formErr.validate(this.profileEditForm);
    if (this.profileEditForm.invalid) {
      return;
    } else if (this.hasSelfDeliveryConfigErr() === 'falsyValue') {
      this.showErrMsg(
        'All fields in your self delivery settings are required. Please make sure none of them is zero or empty',
      );
      this.markErrorFields();
      this.scrollToDeilverySettings();
      return;
    } else if (this.hasSelfDeliveryConfigErr() === 'duplicateZones') {
      this.showErrMsg(
        'No two or more group names should be the same. Group names should be unique',
      );
      this.scrollToDeilverySettings();
      return;
    } else if (this.hasSelfDeliveryConfigErr() === 'etaMaxGreaterThanMin') {
      this.showErrMsg(
        'Minimum number of delivery days should not be more than maximum number of delivery days',
      );
      this.scrollToDeilverySettings();
      return;
    }

    // TODO: Check for form validity(enable/ disable save button) when changes occur
    const formValue = this.profileEditForm.value;
    const model: StoreProfile = {
      name: formValue.name,
      businessEmail: formValue.email,
      businessPhoneNumber: formValue.phone,
      businessAddress: formValue.streetAddress,
      businessAddressStateLga: {
        state: this.states.filter((state) => state.id === formValue.state),
        lga: this.lgas.filter((lga) => lga.id === formValue.localGovt),
      },
    };
    if (formValue.deliveryType === 'selleasydelivery') {
      const delivery: DeliveryConfiguration = {
        provider: 'Express',
        configuration: {
          fromState: this.states.find((state) => state.id === formValue.state),
          fromLga: this.lgas.find((lga) => lga.id === formValue.localGovt),
          pickupLocation: formValue.pickUpLocation ?? null,
          dropoffLocation: this.dropOffLocations.filter(
            (location) => location.id === formValue.dropOffLocation,
          ),
          isPickup: this.state.value === this.lagosId,
        },
        isActive: true,
        notes: '',
      };
      model.delivery = [delivery];
    } else if (formValue.deliveryType === 'selfdelivery') {
      const delivery: DeliveryConfiguration = {
        provider: 'Self',
        configuration: {
          fromState: this.states.find((state) => state.id === formValue.state),
          fromLga: this.lgas.find((lga) => lga.id === formValue.localGovt),
          isPickup: this.state.value === this.lagosId,
        },
        isActive: true,
        notes: '',
        selfDeliveryZones: this.formatSelfDeliveryLocations(
          formValue.selfDeliveryLocations,
        ),
      };
      model.delivery = [delivery];

      if (this.hasHandleSupportedStates(model)) {
        this.patchProfile(model);
        return;
      } else if (!this.validateSupportedStates(model)) {
        return;
      }
    }

    this.patchProfile(model);
  }

  hasHandleSupportedStates(model) {
    return (
      model.delivery[0].provider === 'Self' &&
      (model.delivery[0].configuration.pickupLocation ||
        model.delivery[0].configuration.dropoffLocation?.length)
    );
  }

  noStates(statesArr: [], inputRef, matField) {
    !statesArr.length
      ? (addErrorClass(matField), (inputRef.__hasReqErr = true))
      : (removeErrorClass(matField), (inputRef.__hasReqErr = false));
  }

  /**
   * patch business user profile
   * @param data patch data
   */
  patchProfile(data: StoreProfile) {
    this.profileBusinessService.patchBusinessProfile(data).subscribe(
      () => {
        this.snackbar.open(
          `Your profile has been successfully updated`,
          'Close',
          {
            panelClass: 'success',
          },
        );
        if (this.deliveryType.value === 'selleasydelivery') {
          this.selfDeliveryLocations.setValue(this.initSelfDeliveryConfig());
        }
        this.deliveryProvider = data.delivery[0].provider;
      },
      (err) => {
        this.snackbar.open(err.message, 'Close', {
          panelClass: 'error',
        });
      },
    );
  }

  /**
   * on change event update form value
   * @param event event
   * @param type type of radio button (dt - deliveryType: selleasydelivery/ selfdelivery | dm - deliveryMode: pickup/dropoff)
   */
  changeRadioOption(event: any, type: string) {
    const value = event.value;
    if (type === 'dt') {
      this.profileEditForm.patchValue({
        deliveryType: value,
      });

      this.injectValidation(type, value, this.state?.value);
    } else if (type === 'dm') {
      this.profileEditForm.patchValue({
        deliveryMode: value,
      });
    }
  }

  injectValidation(type: any, value: any, state: any) {
    if (
      type === 'dt' &&
      value === 'selleasydelivery' &&
      state === this.lagosId
    ) {
      // check state and enforce validation
      this.profileEditForm.patchValue({
        deliveryMode: 'pickup',
      });
    } else if (
      type === 'dt' &&
      value === 'selleasydelivery' &&
      state !== this.lagosId
    ) {
      this.forceFieldValidation('dropOffLocation', true);
    } else {
      this.forceFieldValidation('dropOffLocation', false);
    }
  }

  forceFieldValidation(fieldName: string, enfore = true) {
    if (enfore) {
      this.profileEditForm.controls[fieldName].setValue(null);
      this.profileEditForm.controls[fieldName].setValidators([
        Validators.required,
      ]);
      this.profileEditForm.controls[fieldName].updateValueAndValidity();
    } else {
      this.profileEditForm.controls[fieldName].setValidators(null);
      this.profileEditForm.controls[fieldName].updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.isComponentActive = false;
  }
}
