import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { iconsMap } from './utils';
import {
  INotifications,
  DashboardService,
} from '../services/dashboard.service';
import { Status } from '../enums/status';

@Component({
  selector: 'paginated-list',
  templateUrl: './paginated-list.component.html',
  styleUrls: ['./paginated-list.component.scss'],
})
export class PaginatedListComponent implements OnInit, OnChanges {
  @Input() data: any[];
  @Input() showIcons = true;
  deleteList: INotifications[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns = ['select', 'type', 'name'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  iconsMap = iconsMap;

  constructor(private dashboardService: DashboardService) {}

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
  }

  get isSelected() {
    return this.selection.hasValue();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.deleteList = [];
  }

  deleteRow(row) {
    if (this.selection.isSelected(row) === false) {
      // add delete list
      this.deleteList.push(row);
    } else {
      // remove from delete list
      this.deleteList = this.deleteList.filter((item) => item.id !== row.id);
    }
  }

  removeNotification() {
    const model = {} as StatusUpdate;
    model.items = [];

    this.deleteList.forEach((item) => {
      item.status = Status.READ.toString();
      model.items.push(item);
    });

    this.dashboardService
      .deleteNotifications(model, this.deleteList)
      .subscribe((result) => {
        this.data = result;
        this.dataSource.data = this.data;
        this.selection = new SelectionModel<Element>(true, []);
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row = null): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }
}

interface StatusUpdate {
  items: INotifications[];
}
