<div class="data-table__heading"
     id="data-table-header">
    <button mat-icon-button
            class="data-table__search"
            [hidden]="showSearchInput"
            (click)="showSearchField()">
        <se-icon iconName="search">
        </se-icon>
    </button>
    <mat-form-field appearance="outline"
                    [hidden]="!showSearchInput"
                    class="data-table__search-input">
        <mat-label>Search</mat-label>
        <input id="searchInput"
               type="text"
               autocomplete="off"
               (keyup)="applyFilter($event)"
               matInput>
        <div matSuffix>
            <se-icon iconName="search"
                     [hidden]="showSearchInput && isMobile">
            </se-icon>
            <se-icon iconName="cancel"
                     [sm]="true"
                     [hidden]="!showSearchInput || !isMobile"
                     (click)="clearFilter()">
            </se-icon>
        </div>
    </mat-form-field>
    <mat-paginator color="primary"
                   [hidden]="showSearchInput && isMobile"
                   hidePageSize
                   [length]="dataLength"
                   [pageSize]="pageSize || 10">
    </mat-paginator>
    <div class="data-table__actions">
        <ng-content select="app-data-table-header-actions"></ng-content>
    </div>
</div>

<div class="data-table mb-5">
    <table mat-table
           [dataSource]="dataSource"
           matSort
           class="mat-elevation-z0 w-100">
        <ng-container matColumnDef="select">
            <th mat-header-cell
                *matHeaderCellDef
                class="data-table__select-all">
                <div *ngIf="showSelectBoxes">
                    <mat-checkbox color="primary"
                                  (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                                  [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                class="data-table__select">
                <div *ngIf="showSelectBoxes">
                    <mat-checkbox color="primary"
                                  (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)"
                                  [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </div>
            </td>
        </ng-container>

        <ng-content *ngIf="dataSource.data?.length"></ng-content>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="data-table__th"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)"></tr>
    </table>

    <div *ngIf="!dataSource.data.length && !isLoading">
        <ng-container *ngTemplateOutlet="emptyStateTemplate"></ng-container>
    </div>
</div>
