<app-modal class="selleasy-delivery-dialog">
    <app-modal-header>
        <h1>Are You Sure You Want to Handle Delivery Yourself?</h1>
    </app-modal-header>
    <app-modal-content>
        <p class="mt-5">
            <span>SellEasy Delivery</span> costs only <span class="price f-800">₦824</span><b> anywhere within
                Lagos</b> and we have <b>very affordable delivery prices both nationwide and International</b>
        </p>
        <p class="mb-5">
            Also your customers can <b>track the delivery</b> on your website.
        </p>
    </app-modal-content>
    <app-modal-footer>
        <button mat-stroked-button
                (click)="closeModal()"
                color="primary"
                class="se-button se-button--stroke mr-3">
            Self Delivery
        </button>
        <button mat-flat-button
                (click)="action(); closeModal()"
                color="primary"
                class="se-button">
            <div [ngStyle]="{'margin-left.px': isMobile ? -10 : 0}">SellEasy Delivery</div>
        </button>
    </app-modal-footer>
</app-modal>
