import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss'],
})
export class LearnComponent {
  constructor(private sanitizer: DomSanitizer) {}

  configList = [
    {
      title: 'How to use SellEasy',
      subTitle:
        'Add product, setup profile, customize website, setup store profile etc',
      embeddedLink: this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/5xKVnFgXgJ4',
      ),
      link:
        'https://www.youtube.com/watch?v=5xKVnFgXgJ4&list=PLKJd9-Ui_G2JFqSuIHBGr7ejr7AtLUR83&index=8',
    },
    {
      title: 'Website Navigation',
      subTitle: 'Group products on your website with menus and menu-items',
      embeddedLink: this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/1XcgjoBGVsw',
      ),
      link:
        'https://www.youtube.com/watch?v=1XcgjoBGVsw&list=PLKJd9-Ui_G2JFqSuIHBGr7ejr7AtLUR83&index=9',
    },
    {
      title: 'How to Add Product',
      subTitle:
        'Add product, product variants, images, quantity, description etc',
      embeddedLink: this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/QsW6vkUhZN0',
      ),
      link:
        'https://www.youtube.com/watch?v=QsW6vkUhZN0&list=PLKJd9-Ui_G2JFqSuIHBGr7ejr7AtLUR83&index=5',
    },
    {
      title: 'Setting up your profile',
      subTitle:
        'Setup your store profile. Pick-up and drop off location, contact number etc',
      embeddedLink: this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/abzmIRHkVMo',
      ),
      link:
        'https://www.youtube.com/watch?v=abzmIRHkVMo&list=PLKJd9-Ui_G2JFqSuIHBGr7ejr7AtLUR83&index=3',
    },
    {
      title: 'Customize your Website',
      subTitle:
        'Choose a template, color, upload your website logo, contact details etc',
      embeddedLink: this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/pxHWiPlm1CU',
      ),
      link:
        'https://www.youtube.com/watch?v=pxHWiPlm1CU&list=PLKJd9-Ui_G2JFqSuIHBGr7ejr7AtLUR83&index=2',
    },
    {
      title: 'Setup Account Details',
      subTitle:
        'Setup account where your money gets paid into when you receive orders',
      embeddedLink: this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/pxHWiPlm1CU',
      ),
      link:
        'https://www.youtube.com/watch?v=zAOWS8wJEJU&list=PLKJd9-Ui_G2JFqSuIHBGr7ejr7AtLUR83&index=4',
    },
  ];
}
