<div class="row m-0">
    <div #wrapper
         class="col-md-6 flex-column pl-0 mb-4">
        <div class="d-flex mt-2 mb-4">
            <!-- TODO: Square logo / favicon vs rectagular logo -->
            <mat-radio-group color="primary"
                             [hidden]="true"
                             class="radios"
                             [(ngModel)]="logoShape">
                <mat-radio-button value="rectangle">Rectangular shaped logo</mat-radio-button>
                <mat-radio-button value="square">Square shaped logo</mat-radio-button>
            </mat-radio-group>

            <button mat-stroked-button
                    (click)="openFileInput()"
                    color="outline"
                    class="se-stroke se-button">
                {{ (showCroppedImage || showCropper) ? 'CHANGE IMAGE' : 'UPLOAD IMAGE'}}
            </button>
            <button *ngIf="showCroppedImage"
                    (click)="clearImage()"
                    mat-flat-button
                    class="se-button"
                    color="primary">
                DELETE IMAGE
            </button>

        </div>
        <input #fileInput
               type="file"
               accept="image/jpg, image/jpeg, image/png, image/svg"
               (change)="onUpload($event)">
        <ng-container *ngIf="showCropper">
            <div class="d-flex justify-content-center">
                <button mat-button
                        matTooltip="Zoom in"
                        (click)="zoomIn()"><span>+</span></button>
                <button mat-button
                        matTooltip="Zoom out"
                        (click)="zoomOut()"><span>-</span></button>
            </div>
            <image-cropper [imageChangedEvent]="imageChangedEvent"
                           [maintainAspectRatio]="false"
                           [containWithinAspectRatio]="true"
                           [aspectRatio]="8 / 3"
                           [resizeToWidth]="200"
                           [cropperMinWidth]="200"
                           [onlyScaleDown]="true"
                           [roundCropper]="false"
                           [transform]="transform"
                           [alignImage]="'left'"
                           [style.display]="showCropper ? null : 'none'"
                           (imageCropped)="imageCropped($event)">
            </image-cropper>
        </ng-container>
        <div class="cropped-image"
             *ngIf="showCroppedImage">
            <img [src]="logoUrl">
        </div>
        <div class="d-flex show-cropper">
            <button *ngIf="showCropper"
                    (click)="cropImage()"
                    mat-flat-button
                    class="se-button mt-4"
                    color="primary">
                CROP IMAGE
            </button>
        </div>
    </div>
</div>
