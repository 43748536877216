<section class="header-section pt-0"
         style="overflow-x: hidden">
    <div class="container hero-image">
        <img src="assets/about-us-v2.jpeg"
             class="img-fluid sm p-0 w-100">
        <div class="container hero-image-text">
            <h4>ABOUT US</h4>
            <h2 class="h2-bold">All the tools you need to sell successfully</h2>
            <p class="title-support-text">Success does not come by working hard, it comes by working smart.
                Sell
                smart with SellEasy. Streamline your order fulfilment process, generate organic sales and expand your
                customer base by building a self-sustaining online store and increase your product visibility.</p>
        </div>
    </div>
</section>

<!--- Setup & Manage -->
<section>
    <div class="container manage-store">
        <div class="row about-us-setup align-items-center">
            <div class="col-lg-6">
                <img src="assets/setupv2.png"
                     class="img-fluid">
            </div>
            <div class="col-lg-6">
                <h2 class="h2-bold">Setup & manage your store</h2><br>
                <p class="title-support-text">SellEasy provides a secured platform with all the tools required for you
                    to
                    setup and manage your store
                    with no IT knowledge required.</p>
                <div class="row list">
                    <div class="col-lg-6">
                        <ul class="fa-ul">
                            <li><i class="fa-li fa fa-check"></i>Create and manage your ecommerce store</li>
                            <li><i class="fa-li fa fa-check"></i>Receive orders from Google, Instagram, Whatsapp,
                                etc.</li>
                            <li><i class="fa-li fa fa-check"></i>Integrated payment solution</li>
                            <li><i class="fa-li fa fa-check"></i>Integrated delivery solution</li>
                            <li><i class="fa-li fa fa-check"></i>Order metrics</li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <ul class="fa-ul">
                            <li><i class="fa-li fa fa-check"></i>Inventory management</li>
                            <li><i class="fa-li fa fa-check"></i>Product insights / metrics</li>
                            <li><i class="fa-li fa fa-check"></i>Order management</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mission and Values-->
<section class="mission-value-area">
    <div class="container">
        <div class="row mission-value-card align-items-center">
            <div class="col-lg-6 card-outline">
                <div class="card h-100 card-mr">
                    <img class="card-img-top img-fluid"
                         src="assets/mission.svg">
                    <div class="card-body text-center">
                        <h3>Mission</h3>
                        <p class="title-support-text">SellEasy’s mission is to become an ecommerce powerhouse that
                            empowers Micro
                            Enterprises to succeed.</p><br>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 card-outline">
                <div class="card h-100 card-ml">
                    <img class="card-img-top img-fluid"
                         src="assets/core-value.svg">
                    <div class="card-body text-center">
                        <h3>Core Value</h3>
                        <p class="title-support-text">At SellEasy, we are building a culture that highlights kindness
                            and
                            fairness;
                            to the Sellers, Customers, Partners and Employees.</p><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--- The Team -->
<section>
    <div class="container">
        <div class="row about-us-setup align-items-center">
            <div class="col-md-6 about-us-setup-text-area">
                <h2 class="h2-bold">The Team</h2>
                <p class="title-support-text">SellEasy is a team of passionate, forward thinking, technology driven
                    entrepreneurs, dedicated to
                    delivering cutting edge solutions, whilst ensuring world class customer experience.</p>
            </div>
            <div class="col-md-6">
                <img src="assets/the-team.jpg"
                     class="img-fluid"
                     style="border-radius: 12px; width: 100%;">
            </div>
        </div>
    </div>
</section>

<!--- Contact us -->
<section id="contactUs">
    <div class="container contact-us">
        <div class="row about-us-setup align-items-center">
            <div class="col-md-6 text">
                <h2 class="h2-bold">Contact Us</h2>
                <p class="title-support-text">
                    <b>Location:</b> 9 Majekodunmi Street, Off Allen Avenue Ikeja, Lagos<br>
                    <b>Phone:</b> +234 913 455 5555<br>
                    <b>Email:</b> info@selleasy.co
                </p>
            </div>
            <div class="col-md-6">
                <img src="assets/contact-us-alt.jpg"
                     class="img-fluid w-100">
            </div>
        </div>
    </div>
</section>
