import { Component, OnInit, ApplicationRef, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, merge, Observable } from 'rxjs';
import { filter, map, distinctUntilChanged, first, tap } from 'rxjs/operators';
import { IS_MOBILE_APP } from './shared/factories/user-agent-factory';
import { AnalyticService } from './shared/services/analytics-service';
import { AuthService } from './shared/services/auth-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private analyticService: AnalyticService,
    private updates: SwUpdate,
    private appRef: ApplicationRef,
    @Inject(IS_MOBILE_APP) private userAgent: Observable<boolean>,
  ) {
    this.checkForAppUpdatesEvey24hrs();
    this.startMixPanel();
    this.detectUserAgent();
  }

  ngOnInit() {
    this.updates.available.subscribe((_) => {
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }

  detectUserAgent() {
    this.userAgent.pipe(filter((v) => !!v)).subscribe((_) => {
      this.router.navigate(['/signin']);
    });
  }

  checkForAppUpdatesEvey24hrs() {
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true),
    );
    const every24Hours$ = interval(24 * 60 * 60 * 1000);
    const every24HoursOnceAppIsStable$ = concat(
      appIsStable$,
      merge(every24Hours$, this.startMixPanel()),
    );

    every24HoursOnceAppIsStable$
      .pipe(filter((v) => typeof v === 'number'))
      .subscribe(() => this.updates.checkForUpdate());
  }

  startMixPanel() {
    return this.router.events.pipe(
      filter((e: NavigationEnd) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.url.split('/')[1]),
      distinctUntilChanged(),
      tap((pageName) => {
        Promise.resolve().then(() => {
          if (localStorage.getItem('AUTH_TOKEN')) {
            const id = this.authService.getBusinessId();
            this.analyticService.initMixpanel(id);
          } else {
            this.analyticService.initMixpanel(null);
          }
          this.analyticService.track(pageName, {});
        });
      }),
    );
  }
}
