import { PaymentInstance } from '@selleasy/angular-rave';
import { Subscription } from '../models/subscription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth-service';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  private paymentInstance: PaymentInstance;
  public paymentOptions;
  private readonly BASEURL = (window as any).API_BASE_URL;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  paymentStart(paymentInstance) {
    this.paymentInstance = paymentInstance;
  }

  pay(payBtn) {
    payBtn.nativeElement.click();
  }

  close() {
    this.paymentInstance.close();
  }

  verifyPayment(event: any, data: Subscription, txRef: string) {
    // try to verify
    const path = `${this.BASEURL}/businesses/verifypayment/${txRef}?isSubscription=true&provider=fw`;
    const id = this.authService.getUserId();
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .get(path, httpOptions)
      .pipe(switchMap((result) => this.patchProfile(result, data)));
  }

  patchProfile(result: any, data: Subscription) {
    const id = this.authService.getUserId();
    const businessId = this.authService.getBusinessId();
    const path = `${this.BASEURL}/users/${id}/business/${businessId}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    if (result?.status === 'success') {
      data.isVerified = true;
      data.subscriptionDetails = result;
      data.timestampModified = new Date().toJSON();
    } else {
      data.isVerified = false;
      data.subscriptionDetails = result;
      data.timestampModified = new Date().toJSON();
    }
    const model = {
      subscription: [data],
    };
    return this.httpClient
      .patch(path, model, httpOptions)
      .pipe(map((result) => result));
  }
}
