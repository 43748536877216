import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEqual } from 'lodash';
import { PHONE_REGEX } from '../shared/utils/regexes';
import { noWhiteSpace } from '../shared/utils/validations';
import { FormErrorService } from '../shared/services/form-error.service';

@Component({
  selector: 'app-user-profile-edit-form',
  templateUrl: './user-profile-edit-form.component.html',
  styleUrls: ['./user-profile-edit-form.component.scss'],
})
export class UserProfileEditFormComponent implements OnInit {
  userEditForm: FormGroup;
  hidePassword = true;
  formHasChanges = false;
  formInitialValues = null;
  @Output() userProfile = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private profileBusinessService: ProfileBusinessService,
    private snackbar: MatSnackBar,
    private formErr: FormErrorService,
  ) {}

  get firstName() {
    return this.userEditForm.get('firstName');
  }

  get lastName() {
    return this.userEditForm.get('lastName');
  }

  get email() {
    return this.userEditForm.get('email');
  }

  get phone() {
    return this.userEditForm.get('phone');
  }

  ngOnInit(): void {
    // Setup user profile form
    this.userEditForm = this.fb.group({
      firstName: [
        null,
        [Validators.required, Validators.minLength(3), noWhiteSpace],
      ],
      lastName: [null, [Validators.minLength(3), noWhiteSpace]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, Validators.pattern(PHONE_REGEX)]],
    });

    // Get profile data
    this.profileBusinessService.getProfile().subscribe((result) => {
      this.patchFormValue(result);
      this.userProfile.emit(result);
      this.watchFormChanges();
    });
  }

  watchFormChanges() {
    this.formInitialValues = this.userEditForm.value;
    this.userEditForm.valueChanges.subscribe((changes) => {
      this.formHasChanges = !isEqual(changes, this.formInitialValues);
    });
  }

  /**
   * @description update user profile form
   * @param data patch data
   */
  patchFormValue(data: any) {
    this.userEditForm.patchValue({
      firstName: data?.firstName ?? '',
      lastName: data?.lastName?.trim() ?? '',
      email: data?.email ?? '',
      phone: data?.telephone?.toString() ?? '',
    });
  }

  updateProfile() {
    this.formErr.validate(this.userEditForm);
    if (this.userEditForm.invalid) {
      return;
    }

    const model = { ...this.userEditForm.value };
    model.telephone = model.phone;
    delete model.phone;

    this.profileBusinessService.patchProfile(model).subscribe(
      (_) => {
        this.formInitialValues = this.userEditForm.value;
        this.formHasChanges = false;

        this.snackbar.open(
          `Your profile has been successfully updated`,
          'Close',
          {
            panelClass: 'success',
          },
        );
      },
      (err) => {
        this.snackbar.open(err.message, 'Close', {
          panelClass: 'error',
        });
      },
    );
  }
}
