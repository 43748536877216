<div class="sign-in">
    <app-spinner *ngIf="isSubmitting"></app-spinner>
    <form class="sign-in__form"
          [formGroup]="signinForm"
          (submit)="onSubmitForm()"
          novalidate>
        <h4>Log in</h4>
        <p>
            <mat-error *ngIf="serverError; else welcomeMsg">
                {{ serverError.msg }}
            </mat-error>
            <ng-template #welcomeMsg>
                <span>Hello, welcome back!</span>
            </ng-template>
        </p>
        <mat-form-field appearance="outline"
                        class="se-input-field">
            <mat-label>Email</mat-label>
            <input type="email"
                   formControlName="email"
                   matInput>
            <mat-error>There's an error in your email</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        class="se-input-field">
            <mat-label>Password</mat-label>
            <input type="password"
                   formControlName="password"
                   matInput>
            <mat-error>Your password is incomplete</mat-error>
        </mat-form-field>
        <div class="sign-in__login-wrapper">
            <!-- RememberMe will come in later -->
            <mat-checkbox *ngIf="false"
                          formControlName="rememberMe"
                          color="primary">Remember me</mat-checkbox>
            <p>Need an account? <span routerLink="/signup">Sign up</span></p>
            <button mat-flat-button
                    color="primary"
                    class="se-button"
                    [disabled]="signinForm.invalid || serverError">
                LOG IN
            </button>
        </div>
        <div class="sign-in__need-account-wrapper">
            <p><span routerLink="/forgot-password">Forgot password</span></p>
        </div>
    </form>
</div>
