import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateFacade } from '../shared/services/state.facade';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  constructor(private router: Router, private stateFacade: StateFacade) {}
  discountedPrice = '2,999.99';
  isMobile: boolean;

  ngOnInit() {
    this.stateFacade.getViewPortSize().subscribe((m) => {
      this.isMobile = m.isMobile;
    });
  }

  togglePrice(e) {
    this.discountedPrice = e.target.checked ? '2,849.99' : '2,999.99';
  }

  goto(url) {
    this.router.navigate([url]);
  }

  getSelectedTab(e) {}

  scrollToHowItWorks() {
    document.querySelector('#howItWorksSection').scrollIntoView({
      behavior: 'smooth',
    });
  }
}
