<div class="account-service-charge d-flex">
    <div class="col-lg-5">
        <div class="mb-3">By default, Sellers pay the service charges.<br /> You can change this settings below</div>
        <div class="mb-4 spaced-top">
            <mat-radio-group color="primary"
                             class="account-service-charge__option-grp"
                             [(ngModel)]="payServiceCharge">
                <mat-radio-button class="account-service-charge__options"
                                  *ngFor="let option of options"
                                  [checked]="option.checked"
                                  (change)="selectedValue(option.value)"
                                  [value]="option.value">{{option.description}}</mat-radio-button>
                <br />
            </mat-radio-group>
        </div>
    </div>
    <div class="col-lg-4 p-0 account-service-charge__img-bill account-service-charge__props">
    </div>
</div>
