<app-modal class="download-report-modal">
    <app-modal-header>
        <h1>Download Report</h1>
    </app-modal-header>
    <app-modal-content>
        <form autocomplete="off"
              class="d-flex flex-column mt-5">
            <mat-form-field appearance="outline"
                            class="se-input-field">
                <mat-label>From:</mat-label>
                <input matInput
                       id="from"
                       [matDatepicker]="picker1"
                       [formControl]="from">
                <mat-datepicker-toggle matSuffix
                                       [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline"
                            class="se-input-field">
                <mat-label>To:</mat-label>
                <input matInput
                       [matDatepicker]="picker2"
                       [formControl]="to">
                <mat-datepicker-toggle matSuffix
                                       [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </form>
    </app-modal-content>
    <app-modal-footer>
        <button mat-stroked-button
                (click)="closeModal()"
                color="primary"
                class="se-button se-button--stroke">
            CLOSE
        </button>
        <button mat-flat-button
                (click)="download()"
                color="primary"
                class="se-button">
            DOWNLOAD
        </button>
    </app-modal-footer>
</app-modal>
