<div class="container main-page">
    <div class="row">
        <app-header-title title="View Discounts"
                          subTitle="View list of created discounts">
        </app-header-title>
    </div>

    <ng-template #emptyState>
        <div class="p-5 text-center flex-column">
            <div>You have not added any discounts</div>
            <button (click)="goto('create-discount')"
                    mat-flat-button
                    color="primary"
                    class="se-button mt-3">
                Add Discount
            </button>
        </div>
    </ng-template>

    <app-data-table class="w-100"
                    [isLoading]="isLoading"
                    [dataSource]="dataSource"
                    [displayedColumns]="columnsToDisplay"
                    [showHeaderActions]="true"
                    [showSelectBoxes]="true"
                    [emptyStateTemplate]="emptyState"
                    [stickyHeader]="true"
                    (onSelectionInit)="onSelectionModelInit($event)"
                    (onRowSelected)="onRowSelected($event)"
                    matSort
                    #sort="matSort">

        <!-- For extra header actions if needed -->
        <app-data-table-header-actions class="d-flex">
            <div *ngIf="isMobile">
                <button mat-icon-button
                        class="discounts-list__more"
                        [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <div class="discounts-list__menu">
                        <button class="d-flex"
                                (click)="goto('create-discount')"
                                mat-menu-item>
                            <se-icon iconName="add-orange"
                                     [lg]="true">
                            </se-icon>
                            <span>Add Discount</span>
                        </button>
                        <button mat-menu-item
                                (click)="editSelectedDiscount()"
                                [disabled]="!selectedRows.length || selectedRows.length > 1">
                            <se-icon iconName="edit-green">
                            </se-icon>
                            <span>Edit Discount</span>
                        </button>
                        <button mat-menu-item
                                (click)="startDiscount()"
                                [disabled]="shouldDisableStart">
                            <se-icon iconName="start">
                            </se-icon>
                            <span>Start Discount</span>
                        </button>
                        <button mat-menu-item
                                (click)="stopDiscount()"
                                [disabled]="shouldDisableStop">
                            <se-icon iconName="stop">
                            </se-icon>
                            <span>Stop Discount</span>
                        </button>
                        <button class="d-flex"
                                (click)="deleteSelectedDiscount()"
                                [disabled]="!selectedRows.length"
                                mat-menu-item>
                            <se-icon iconName="delete">
                            </se-icon>
                            <span>Delete Discount</span>
                        </button>
                        <button mat-menu-item
                                *ngIf="false"
                                class="whatsapp"
                                (click)="shareOnSocial('whatsapp')"
                                [disabled]="!selectedRows.length || selectedRows.length > 1">
                            <div class="d-flex">
                                <se-icon iconName="whatsapp"
                                         [xl]="true"></se-icon>
                                <span>Share on WhatsApp</span>
                            </div>
                        </button>
                    </div>
                </mat-menu>
            </div>

            <div class="d-flex"
                 *ngIf="!isMobile">
                <button class="d-flex pl-2 pr-2"
                        matTooltip="Add discount"
                        matTooltipPosition="above"
                        [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                        (click)="goto('create-discount')"
                        mat-menu-item>
                    <se-icon iconName="add-orange"
                             [lg]="true">
                    </se-icon>
                </button>
                <button class="d-flex pl-2 pr-2"
                        matTooltip="Edit discount"
                        matTooltipPosition="above"
                        (click)="editSelectedDiscount()"
                        [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                        [disabled]="!selectedRows.length || selectedRows.length > 1"
                        mat-menu-item>
                    <se-icon iconName="edit-green"
                             [lg]="true">
                    </se-icon>
                </button>
                <button class="d-flex pl-2 pr-2"
                        matTooltip="Start discount"
                        matTooltipPosition="above"
                        (click)="startDiscount()"
                        [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                        [disabled]="shouldDisableStart"
                        mat-menu-item>
                    <se-icon iconName="start"
                             [lg]="true">
                    </se-icon>
                </button>
                <button class="d-flex pl-2 pr-2"
                        matTooltip="Stop discount"
                        matTooltipPosition="above"
                        (click)="stopDiscount()"
                        [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                        [disabled]="shouldDisableStop"
                        mat-menu-item>
                    <se-icon iconName="stop"
                             [lg]="true">
                    </se-icon>
                </button>
                <button class="d-flex pl-2 pr-2"
                        matTooltip="Delete discount"
                        matTooltipPosition="above"
                        [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                        (click)="deleteSelectedDiscount()"
                        [disabled]="!selectedRows.length"
                        mat-menu-item>
                    <se-icon iconName="delete"
                             [lg]="true">
                    </se-icon>
                </button>
                <button *ngIf="false"
                        class="d-flex pl-3 pr-2"
                        matTooltip="Share on WhatsApp"
                        matTooltipPosition="above"
                        [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                        (click)="shareOnSocial('whatsapp')"
                        [disabled]="!selectedRows.length || selectedRows.length > 1"
                        mat-menu-item>
                    <se-icon iconName="whatsapp-lg"
                             [lg]="true">
                    </se-icon>
                </button>
            </div>

        </app-data-table-header-actions>

        <!-- name Column -->
        <ng-container matColumnDef="name"
                      sticky>
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <div class="ml-3">
                    Name
                </div>
            </th>
            <td mat-cell
                *matCellDef="let el">
                <div class="ml-3">
                    <span [matTooltip]="el.name">{{truncateText(el.name, {length : 20})}}</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="code">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <span *ngIf="!isMobile else inStockRef">
                    Code
                </span>
                <ng-template #inStockRef>
                    <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                         class="discounts-list__last-col ml-3">
                        <button mat-stroked-button
                                color="primary"
                                class="se-button se-button--stroke">
                            Code
                        </button>
                        <mat-form-field appearance="outline">
                            <mat-label></mat-label>
                            <mat-select class="mat-elevation-z0"
                                        (selectionChange)="onSelectChange($event)">
                                <mat-option *ngFor="let col of columnsSelect"
                                            [value]="col.value">
                                    {{col.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-template>
            </th>
            <td mat-cell
                *matCellDef="let el">
                <span [matTooltip]="el.code">
                    {{truncateText(el.code, {length : 15}) || '-'}}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="value">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <span *ngIf="!isMobile else inStockRef">
                    Discount
                </span>
                <ng-template #inStockRef>
                    <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                         class="discounts-list__last-col ml-3">
                        <button mat-stroked-button
                                color="primary"
                                class="se-button se-button--stroke">
                            Discount
                        </button>
                        <mat-form-field appearance="outline">
                            <mat-label></mat-label>
                            <mat-select class="mat-elevation-z0"
                                        (selectionChange)="onSelectChange($event)">
                                <mat-option *ngFor="let col of columnsSelect"
                                            [value]="col.value">
                                    {{col.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-template>
            </th>
            <td mat-cell
                *matCellDef="let el"> {{el.value}} </td>
        </ng-container>

        <ng-container matColumnDef="scope">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <span *ngIf="!isMobile else inStockRef">
                    Applies To
                </span>
                <ng-template #inStockRef>
                    <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                         class="discounts-list__last-col ml-3">
                        <button mat-stroked-button
                                color="primary"
                                class="se-button se-button--stroke">
                            Applies To
                        </button>
                        <mat-form-field appearance="outline">
                            <mat-label></mat-label>
                            <mat-select class="mat-elevation-z0"
                                        (selectionChange)="onSelectChange($event)">
                                <mat-option *ngFor="let col of columnsSelect"
                                            [value]="col.value">
                                    {{col.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-template>
            </th>
            <td mat-cell
                *matCellDef="let el"> {{scopes[el.scope]}} </td>
        </ng-container>

        <ng-container matColumnDef="start">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <span *ngIf="!isMobile else inStockRef">
                    Start
                </span>
                <ng-template #inStockRef>
                    <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                         class="discounts-list__last-col ml-3">
                        <button mat-stroked-button
                                color="primary"
                                class="se-button se-button--stroke">
                            Start
                        </button>
                        <mat-form-field appearance="outline">
                            <mat-label></mat-label>
                            <mat-select class="mat-elevation-z0"
                                        (selectionChange)="onSelectChange($event)">
                                <mat-option *ngFor="let col of columnsSelect"
                                            [value]="col.value">
                                    {{col.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-template>
            </th>
            <td mat-cell
                *matCellDef="let el"> {{el.start | date: 'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="end">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <span *ngIf="!isMobile else inStockRef">
                    End
                </span>
                <ng-template #inStockRef>
                    <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                         class="discounts-list__last-col ml-3">
                        <button mat-stroked-button
                                color="primary"
                                class="se-button se-button--stroke">
                            End
                        </button>
                        <mat-form-field appearance="outline">
                            <mat-label></mat-label>
                            <mat-select class="mat-elevation-z0"
                                        (selectionChange)="onSelectChange($event)">
                                <mat-option *ngFor="let col of columnsSelect"
                                            [value]="col.value">
                                    {{col.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-template>
            </th>
            <td mat-cell
                *matCellDef="let el"> {{el.end | date: 'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                <span *ngIf="!isMobile else inStockRef">
                    Status
                </span>
                <ng-template #inStockRef>
                    <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                         class="discounts-list__last-col ml-3">
                        <button mat-stroked-button
                                color="primary"
                                class="se-button se-button--stroke">
                            Status
                        </button>
                        <mat-form-field appearance="outline">
                            <mat-label></mat-label>
                            <mat-select class="mat-elevation-z0"
                                        (selectionChange)="onSelectChange($event)">
                                <mat-option *ngFor="let col of columnsSelect"
                                            [value]="col.value">
                                    {{col.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-template>
            </th>
            <td mat-cell
                *matCellDef="let el"
                [ngClass]="statusClasses[el.status]"> {{el.status}} </td>
        </ng-container>
    </app-data-table>
</div>
