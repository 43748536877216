<app-spinner *ngIf="isLoading"></app-spinner>
<div class="container website main-page">
    <div class="row">
        <app-header-title title="Customise your Website"
                          subTitle="Choose a template, build your website navigation and other website details">
        </app-header-title>
    </div>
    <div class="row">
        <div class="col-lg website__content p-0"
             (click)="refresh()">
            <mat-tab-group [selectedIndex]="selectedTab.value"
                           (selectedIndexChange)="selectedTab.value = $event">
                <mat-tab label="DESIGN">
                    <div class="website__tab-content">
                        <app-website-design [websiteForm]="websiteForm"
                                            (selected)="selectedTemplate($event)"
                                            (loader)="showLoader($event)"
                                            [refresh]="refreshChildData">
                        </app-website-design>
                    </div>
                </mat-tab>
                <mat-tab label="CUSTOMISE">
                    <div class="website__tab-content">
                        <app-website-customize [websiteForm]="websiteForm"
                                               [selectedTab]="selectedTab">
                        </app-website-customize>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
