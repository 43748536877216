<app-settingup-website *ngIf="isPolling"
                       [percent]="progressValue">
</app-settingup-website>
<div class="sign-up"
     *ngIf="!isPolling">
    <form class="sign-up__form"
          autocomplete="off"
          [formGroup]="signupForm"
          (submit)="onSubmitForm()"
          novalidate>
        <h4>Start a 30-day Free Trial</h4>
        <p>
            <mat-error *ngIf="serverError; else titleSupport">
                {{ serverError.msg }}
            </mat-error>
            <ng-template #titleSupport>
                <span>Create your unique e-commerce website and sell with ease</span>
            </ng-template>
        </p>
        <!-- Hide temporarily -->
        <div class="sign-up__social-media-buttons"
             *ngIf="false">
            <button type="button"
                    mat-stroked-button
                    class="se-stroke se-button">
                <div class="sign-up__facebook-icon"></div>
                Continue with Facebook
            </button>
            <button type="button"
                    mat-stroked-button
                    class="se-stroke se-button">
                <div class="sign-up__instagram-icon"></div>
                Continue with Instagram
            </button>
            <div class="sign-up__separator">
                <hr />
                OR
                <hr />
            </div>
        </div>
        <mat-form-field appearance="outline"
                        [ngClass]="{'mb-3': firstNameCtrl.touched && firstNameCtrl.errors}"
                        class="se-input-field">
            <mat-label>Name</mat-label>
            <input type="text"
                   id="firstNameId"
                   formControlName="firstName"
                   matInput>
            <mat-error>
                <div *ngIf="firstNameCtrl.hasError('required')">Please enter your name</div>
                <div *ngIf="firstNameCtrl.hasError('minlength')">Your name should not be less than 2 characters</div>
                <div *ngIf="firstNameCtrl.hasError('maxlength')">Your name should not be more than 40 characters</div>
                <div *ngIf="firstNameCtrl.hasError('pattern')">Your name should not contain special charaters</div>
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        [ngClass]="{'mb-3': emailCtrl.touched && emailCtrl.errors}"
                        class="se-input-field">
            <mat-label>Email</mat-label>
            <input type="text"
                   id="emailId"
                   formControlName="email"
                   matInput>
            <mat-error *ngIf="!isAsyncInvalidEmail">Please enter a valid email</mat-error>
            <mat-error *ngIf="isAsyncInvalidEmail">Email already exists</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        class="se-input-field mb-4">
            <mat-label>Phone number</mat-label>
            <input type="text"
                   id="phoneId"
                   formControlName="phone"
                   matInput>
            <mat-error>Your phone number should begin with +234. Example +2348012345678</mat-error>
            <mat-hint>Ex. +2348012345678</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        class="se-input-field sign-up__hinted-input">
            <mat-label>Password</mat-label>
            <input [type]="hidePassword ? 'password' : 'text'"
                   formControlName="password"
                   id="passwordId"
                   matInput>
            <mat-error>You need a minimum of 8 characters</mat-error>
            <mat-hint>Minimum of 8 characters</mat-hint>

            <button mat-icon-button
                    type="button"
                    matSuffix
                    (click)="hidePassword = !hidePassword">
                <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        [ngClass]="{'mb-5': websiteName.hasError('pattern')}"
                        class="se-input-field sign-up__hinted-input">
            <mat-label>Website name</mat-label>
            <input type="text"
                   id="websiteNameId"
                   formControlName="websiteName"
                   matInput>
            <mat-error *ngIf="websiteName.hasError('pattern')">
                Your website name cannot contain space and special charaters. An example of a valid website name is
                <b>thechildrensplace</b>
            </mat-error>
            <mat-error *ngIf="websiteName.hasError('required')">
                Please enter your website name
            </mat-error>
            <mat-error *ngIf="websiteNameExists">Website name already exists</mat-error>
            <mat-hint>Ex. chineducars</mat-hint>
            <div class="sign-up__input-suffix"
                 matSuffix>.selleasy.ng</div>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        class="se-input-field">
            <mat-label>Business name</mat-label>
            <input type="text"
                   id="businessNameId"
                   formControlName="businessName"
                   matInput>
            <mat-error>
                <div *ngIf="businessNameCtrl?.hasError('maxlength')">Your business name should not be more than 40
                    characters.</div>
                <div *ngIf="businessNameCtrl?.hasError('minlength')">Your businessname should not be less than 2
                    characters.</div>
                <div *ngIf="businessNameCtrl?.hasError('required')">Please enter your business name</div>
                <div *ngIf="businessNameCtrl?.hasError('pattern')">You business name can only contain letters, numbers,
                    space and the @ character.</div>
            </mat-error>
        </mat-form-field>
        <div class="sign-up__get-started-wrapper">
            <p class="sign-up__have-account">Already have an account? <span routerLink="/signin">Log in</span></p>
            <button mat-flat-button
                    color="primary"
                    class="se-button">
                GET STARTED
            </button>
        </div>
        <div class="sign-up__terms-of-use">
            <p>
                By signing up, you agree to our <span routerLink="/terms-and-conditions">Terms of Use</span> and
                acknowledge that you
                read our Privacy Policy.
            </p>
        </div>
    </form>
</div>
