import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import imageCompression from 'browser-image-compression';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import {
  BusinessConfiguration,
  Business,
} from '../shared/models/template-config';
import { ProductService } from '../shared/services/product-service';
import { SharedDataService } from '../shared/services/shared-data-service';
import { orderColors, COLORS_MOD } from '../shared/models/colors';
import { ShowImageModalComponent } from '../show-image-modal/show-image-modal.component';
import { StateFacade } from '../shared/services/state.facade';
import { environment } from 'src/environments/environment';
import { getHue } from '../shared/utils/foreground-color';
import { UploadWebsiteLogoComponent } from '../upload-website-logo/upload-website-logo.component';
import { defer } from 'lodash';
import { FormErrorService } from '../shared/services/form-error.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-website-design',
  templateUrl: './website-design.component.html',
  styleUrls: ['./website-design.component.scss'],
})
export class WebsiteDesignComponent implements OnInit, OnDestroy {
  @Input() websiteForm: FormGroup;
  @Input() refresh: Subject<boolean>;
  @Output() selected = new EventEmitter();
  @Output() loader = new EventEmitter();
  @ViewChild('uploadLogo') uploadLogo: UploadWebsiteLogoComponent;

  isLoading = false;
  selectedTemplate: any;
  selectedTemplateIndex = 3;
  storeConfig: Business;
  storeTemplates: BusinessConfiguration[];
  eventSubscription: any;
  url;
  templates = [
    {
      name: 't4',
      publicName: 'Full Width Homepage Image',
      isSelected: true,
      imageUrl: '../../assets/house-of-beauty.png',
      coverImageUrl: '../../assets/house-of-beauty.png',
      coverImage: null,
      color: '#f9a825',
    },
    {
      name: 't1',
      publicName: 'Full Width Homepage Image & Coloured Header',
      isSelected: false,
      imageUrl: '../../assets/the-appliance-place.png',
      coverImageUrl: '../../assets/the-appliance-place.png',
      coverImage: null,
      color: '#90caf9',
    },
    {
      name: 't2',
      publicName: 'Coloured Navigation Bar',
      isSelected: false,
      imageUrl: '../../assets/kitchen-unlimited.png',
      coverImageUrl: '../../assets/kitchen-unlimited.png',
      coverImage: null,
      color: '#ef9a9a',
    },
    {
      name: 't3',
      publicName: 'Coloured Header',
      isSelected: false,
      imageUrl: '../../assets/fashion-house.png',
      coverImageUrl: '../../assets/fashion-house.png',
      coverImage: null,
      color: '#80cbc4',
    },
  ];

  colors = orderColors(COLORS_MOD).map((code) => ({ name: '', code }));
  isMobile: boolean;
  logoUrl;

  constructor(
    private cdRef: ChangeDetectorRef,
    private sanitize: DomSanitizer,
    private snackbar: MatSnackBar,
    private productService: ProductService,
    private businessProfileService: ProfileBusinessService,
    private sharedDataService: SharedDataService,
    private dialog: MatDialog,
    private stateFacade: StateFacade,
    private formErr: FormErrorService,
  ) {}

  ngOnInit(): void {
    this.selectedTemplate = this.templates.find((t) => !!t.isSelected);
    this.template.setValue(this.selectedTemplate);
    this.selected.emit(this.selectedTemplate);
    this.getTemplateConfig();
    this.stateFacade.getViewPortSize().subscribe((viewPort) => {
      this.isMobile = viewPort.isMobile;
    });
    this.refresh.subscribe((data) => {
      if (data) {
        this.getTemplateConfig(this.selectedTemplate, false);
      }
    });
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  get brandColor() {
    return this.websiteForm.get('brandColor');
  }

  get bannerImage() {
    return this.websiteForm.get('bannerImage');
  }

  get logoControl() {
    return this.websiteForm.get('logo');
  }

  get bannerTitle() {
    return this.websiteForm.get('bannerTitle');
  }

  get bannerDescription() {
    return this.websiteForm.get('bannerDescription');
  }

  get template() {
    return this.websiteForm.get('template');
  }

  get logoText() {
    return this.websiteForm.get('logoText');
  }

  openModal(template) {
    this.dialog.open(ShowImageModalComponent, {
      width: this.isMobile ? '100vw' : '55%',
      data: { imageUrl: template.imageUrl },
    });
  }

  getTemplateConfig(tm?: string, isSubmiting?) {
    this.businessProfileService
      .getBusinessProfile()
      .subscribe((result: any) => {
        this.storeConfig = result;
        this.storeTemplates = result?.configuration;
        const tmp = this.storeTemplates.find((t) => t.isSelected);
        const selectedTemplate = this.templates.find(
          (t) => t.name === (tm ?? tmp.template),
        );

        if (selectedTemplate) {
          selectedTemplate.coverImageUrl =
            tmp.bannerImage ?? selectedTemplate.coverImageUrl;
          this.onTemplateSelect(selectedTemplate, isSubmiting);
        }
      });
  }

  getSelectedTemplate(tm?: string) {
    let templateConfig = {} as BusinessConfiguration;
    if (!tm) {
      templateConfig = this.storeTemplates.find((t) => t.isSelected);
    } else {
      templateConfig = this.storeTemplates.find(
        (t) => t.template.toLowerCase() === tm.toLowerCase(),
      );
    }

    const tpl = this.templates.find((t) => t.name === tm);
    const brandColour: string =
      (templateConfig?.brandColour as any)?.code ||
      templateConfig?.brandColour ||
      tpl.color;

    this.brandColor.setValue(
      brandColour.startsWith('#') ? brandColour : `#${brandColour}`,
    );

    this.logoText.setValue(
      templateConfig?.logoText ||
        this.storeConfig?.configuration[0]?.logoText ||
        this.storeConfig.websiteName,
    );
    this.bannerTitle.setValue(
      templateConfig?.bannerTitle ??
        this.storeTemplates.find((t) => t.bannerTitle)?.bannerTitle,
    );
    this.bannerDescription.setValue(
      templateConfig?.bannerDescription ??
        this.storeTemplates.find((t) => t.bannerDescription)?.bannerDescription,
    );
    this.logoControl.setValue(
      templateConfig?.logo ?? this.storeTemplates.find((t) => t.logo)?.logo,
    );
    this.logoUrl =
      templateConfig?.logo ?? this.storeTemplates.find((t) => t.logo)?.logo;
  }

  onTemplateSelect(template, isSubmiting?) {
    this.getSelectedTemplate(template.name);
    this.templates.forEach((t) => (t.isSelected = false));
    template.isSelected = true;
    this.selectedTemplate = template;
    this.selectedTemplateIndex = this.templates.indexOf(template);
    this.template.setValue(template);
    this.eventSubscription = this.sharedDataService.template.next(template);
    const storedTemps = this.storeTemplates.find(
      (t) => t.template === template.name,
    );

    let imageUri = storedTemps?.bannerImage ?? template.coverImageUrl;
    if (imageUri === '') {
      imageUri = template.imageUrl;
    }
    this.bannerImage.setValue(imageUri);
    const index = this.templates
      .filter((t) => t.name === template.name)
      .map((i) => (i.coverImageUrl = imageUri));
  }

  setTemplateImageHue(color) {
    (document.querySelector(
      `#template_${this.selectedTemplate.name}`,
    ) as HTMLElement).style.filter = `hue-rotate(${getHue(
      this.selectedTemplate.color,
      color,
    )}deg)`;
  }

  onColorSelect(e) {
    Promise.resolve().then(() => {
      const el = document.querySelector(
        '.mat-form-field-outline',
      ) as HTMLElement;
      el.style.backgroundColor = e.value.code;
      const matEl = e.source._elementRef.nativeElement;
      if (matEl) {
        matEl.blur();
      }
      this.cdRef.detectChanges();
    });
    // this.setTemplateImageHue(e.value.code);
  }

  replaceImage() {
    (document.querySelector(
      `#fileInput_${this.selectedTemplate.name.split(' ')[0]}`,
    ) as HTMLElement).click();
  }

  sanitizeUrl(url) {
    return this.sanitize.bypassSecurityTrustUrl(url);
  }

  getImage(file?) {
    if (!file) {
      return;
    }

    if (!file || !file.type.startsWith('image/')) {
      this.snackbar.open('You should upload only images', 'Close', {
        panelClass: 'error',
      });
      return;
    }
    this.loader.emit(true);
    imageCompression(file, {
      maxSizeMB: 1,
      useWebWorker: true,
    })
      .then(async (image) => {
        (this.templates[this.selectedTemplateIndex]
          .coverImageUrl as any) = this.sanitizeUrl(URL.createObjectURL(image));
        this.templates[this.selectedTemplateIndex].coverImage = image;
        this.cdRef.detectChanges();
        const imageSrc: any = await this.saveImages(image);
        this.bannerImage.setValue(imageSrc.uri);
      })
      .finally(() => this.loader.emit(false));
  }

  // activate config
  publishSettings() {
    // set config to isSelected = true
    this.submitForm(true);
  }

  //TODO: move to shared service
  /**
   * @description convert base64 to blob
   * @param dataUrl
   */
  convertDataUrlToBlob(dataUrl: string): Blob {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  //TODO: move to shared service
  /**
   * @description send images to storage
   * @param image
   */
  async saveImages(image: any) {
    return new Promise((resolve, reject) => {
      if (image) {
        const imageBlob = new Uint8Array(image);
        const imageType = image.type;
        const suffix = imageType.split('/')[1];
        const typeName = suffix === 'webp' ? 'jpeg' : suffix;

        const fileName = `store-image-${Date.now()}.${typeName}`;
        const file = new File([image], fileName);

        // update file
        const formData = new FormData();
        formData.append('image', file);
        const options = { content: formData };
        this.productService
          .populateImage(formData, options, fileName)
          .subscribe(
            (result: any) => {
              resolve(result);
            },
            (err) => {
              // TODO: log failed uploads
            },
          );
      }
    });
  }

  checkImageUrl(uri: string) {
    const imagePath = uri?.split('/').splice(-1)[0];
    if (
      imagePath === 'house-of-beauty.png' ||
      imagePath === 'fashion-house.png' ||
      imagePath === 'kitchen-unlimited.png' ||
      imagePath === 'the-appliance-place.png'
    ) {
      return '';
    } else {
      return uri;
    }
  }

  openSiteInNewTab(selected) {
    if (!selected) return;

    this.url = this.storeConfig.customDomainName
      ? `https://${this.storeConfig.customDomainName}`
      : environment.path.replace('$websiteName', this.storeConfig.websiteName);

    defer(() => {
      document.getElementById('__view_site1').click();
    });
  }

  submitForm(selected?: boolean) {
    this.formErr.validate(this.websiteForm);

    if (this.websiteForm.invalid || this.uploadLogo?.showCropper) {
      return;
    }

    const formValue = this.websiteForm.value;
    const templateConfig = {} as BusinessConfiguration;
    templateConfig.bannerTitle = formValue.bannerTitle;
    templateConfig.bannerDescription = formValue.bannerDescription;
    templateConfig.bannerImage =
      formValue.bannerImage ??
      this.storeTemplates.find((t) => t.bannerImage)?.bannerImage;
    templateConfig.brandColour = formValue.brandColor;
    templateConfig.logoText = formValue.logoText;
    templateConfig.logo = formValue.logo;
    templateConfig.template = this.selectedTemplate.name;
    templateConfig.bannerImage = formValue.bannerImage;
    templateConfig.template = formValue.template.name;
    templateConfig.businessId = this.storeConfig.id;
    templateConfig.facebookPage =
      templateConfig.facebookPage ??
      this.storeTemplates?.find(
        (t) => t.facebookPage !== null || t.facebookPage !== '',
      ).facebookPage;
    templateConfig.instagramPage =
      templateConfig.instagramPage ??
      this.storeTemplates?.find(
        (t) => t.instagramPage !== null || t.instagramPage !== '',
      ).instagramPage;
    templateConfig.whatsappNum =
      templateConfig.whatsappNum ??
      this.storeTemplates?.find(
        (t) => t.whatsappNum !== null || t.whatsappNum !== '',
      ).whatsappNum;
    templateConfig.twitterPage =
      templateConfig.twitterPage ??
      this.storeTemplates?.find(
        (t) => t.twitterPage !== null || t.twitterPage !== '',
      ).twitterPage;
    templateConfig.navItems = this.storeTemplates.find(
      (t) => t?.navItems?.length > 0,
    )?.navItems;

    templateConfig.bannerImage = this.checkImageUrl(templateConfig.bannerImage);
    this.getTemplateConfig(templateConfig.template, true);
    const tmp = this.storeTemplates.find((t) => t.isSelected);
    templateConfig.isSelected =
      selected ?? tmp.template === this.selectedTemplate.name;

    const index = this.storeTemplates.findIndex(
      (i) => i.template === templateConfig.template,
    );

    if (templateConfig.isSelected) {
      this.storeConfig.configuration.map((item) => (item.isSelected = false));
    }

    if (index !== -1) {
      // update configuration

      // --- update all templates -----
      this.storeConfig.configuration.map(
        (t) => (t.bannerTitle = templateConfig?.bannerTitle),
      );
      this.storeConfig.configuration.map(
        (t) => (t.bannerDescription = templateConfig?.bannerDescription),
      );
      this.storeConfig.configuration.map(
        (t) => (t.logo = templateConfig?.logo),
      );
      this.storeConfig.configuration.map(
        (t) => (t.logoText = templateConfig?.logoText),
      );
      this.storeConfig.configuration.map(
        (t) =>
          (t.bannerImage =
            templateConfig?.bannerImage ??
            this.storeTemplates.find((t) => t.bannerImage)?.bannerImage),
      );
      // ---- end  ----------------

      this.storeConfig.configuration[index].brandColour =
        templateConfig?.brandColour;
      this.storeConfig.configuration[index].template = templateConfig?.template;
      this.storeConfig.configuration[index].businessId =
        templateConfig.businessId;
      this.storeConfig.configuration[index].isSelected =
        templateConfig.isSelected;
      this.storeConfig.configuration[index].navItems = templateConfig?.navItems;
    } else {
      // add to configuration
      this.storeConfig.configuration.push(templateConfig);
    }

    delete this.storeConfig.id;
    delete this.storeConfig.bankDetails;

    // patch
    this.businessProfileService
      .patchBusinessProfile(this.storeConfig)
      .subscribe(
        (_) => {
          this.snackbar.open(
            `Your update has been saved successfully`,
            'Close',
            {
              panelClass: 'success',
            },
          );
          const t = this.templates.find(
            (t) => t.name === templateConfig.template,
          );
          this.getTemplateConfig(t.name, true);
          this.openSiteInNewTab(selected);
        },
        (err) => {
          this.snackbar.open(err.message, 'Close', {
            panelClass: 'error',
          });
        },
      );
  }
}
