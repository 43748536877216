<div class="forgot-password">
    <app-spinner *ngIf="isSubmitting"></app-spinner>
    <form class="forgot-password__form"
          [formGroup]="forgotPasswordForm"
          (submit)="onSubmitForm()"
          novalidate>
        <h4>Forgot Password?</h4>
        <p>
            <mat-error *ngIf="serverError; else welcomeMsg">
                {{ serverError }}
            </mat-error>
            <ng-template #welcomeMsg>
                <span>Enter your email and click SEND to get a password reset link</span>
            </ng-template>
        </p>
        <mat-form-field appearance="outline"
                        class="se-input-field">
            <mat-label>Email</mat-label>
            <input type="email"
                   formControlName="email"
                   matInput>
            <mat-error>There's an error in your email</mat-error>
        </mat-form-field>
        <div class="forgot-password__login-wrapper">
            <p>Need an account? <span routerLink="/signup">Sign up</span></p>
            <button mat-flat-button
                    color="primary"
                    class="se-button"
                    [disabled]="forgotPasswordForm.invalid || serverError">
                SEND
            </button>
        </div>
    </form>
</div>
