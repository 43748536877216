<div class="profile"
     [matMenuTriggerFor]="profileMenu"
     #profileMenuTrigger="matMenuTrigger"
     (click)="triggerMenu()">
    <div class="d-flex align-items-center">
        <div class="profile__icon">{{firstLetter}}</div>
        <div class="profile__text"
             *ngIf="!hideName">{{tenantName}}</div>
        <se-icon [iconName]="hideMenu ? 'arrow-down' : 'arrow-down-white'"
                 [sm]="true">
        </se-icon>
    </div>

    <mat-menu #profileMenu="matMenu"
              xPosition="before"
              class="mt-2">
        <div class="profile__menu">
            <div class="profile__arrow-up"></div>
            <div mat-menu-item
                 (click)="goTo('/profile')"
                 class="profile__menu-item">
                <se-icon iconName="blue-book"></se-icon>
                Profile details
            </div>
            <div mat-menu-item
                 class="profile__menu-item"
                 (click)="logOut()">
                <se-icon iconName="sign-out"></se-icon>
                Log out
            </div>
        </div>
    </mat-menu>
</div>
