import { AbstractControl, FormArray, Validators } from '@angular/forms';
import { uniq } from 'lodash';
import { filter, map } from 'lodash/fp';

export const errorClass = ['mat-form-field-invalid', 'mb-5'];

export const addErrorClass = (ref) => {
  ref._elementRef?.nativeElement.classList.add(...errorClass);
};

export const removeErrorClass = (ref) => {
  ref._elementRef?.nativeElement.classList.remove(...errorClass);
};

export const toLower = map((v: any) => v.name?.toLowerCase());
export const truthyNavs = filter((v: any) => !!v.name);

export const toLowerOpts = map((v: any) => v.option?.toLowerCase());
export const truthyOpts = filter((v: any) => !!v.option);

export const isValidNumber = (ctrl) => {
  if (ctrl.value && !ctrl.value?.toString().trim()) return null;
  return isNaN(Number(ctrl?.value)) ? { invalidNumber: true } : null;
};

export const noWhiteSpace = (ctrl) => {
  if (!ctrl.value) {
    return null;
  }
  return !ctrl.value.toString().trim() ? { required: true } : null;
};

export const invalidEndDate = (formModel) => (ctrl) =>
  ctrl.value?.getTime() < formModel.get('startDate').value?.getTime()
    ? { invalidEndDate: true }
    : null;

export const selectedProductsValidator = (formModel) => (
  productsCtrl: AbstractControl,
) => {
  if (
    formModel.get('scope').value === 'product' &&
    !productsCtrl.value.length
  ) {
    return { noSelectedProducts: true };
  }
  return null;
};

export const noAmountType = (scopeCtrl) => {
  return (ctrl) => {
    if (ctrl.value === 'amount' && scopeCtrl.value !== 'product') {
      return { noAmountType: true };
    }
    return null;
  };
};

export const requiredN = (ctrl) => {
  if (!ctrl) return;

  return !ctrl.get('states').value.length ? { required: true } : null;
};

export const hasInvalidCtrl = (ctrl) => {
  const val = !!(ctrl.value as [{ cntrl: FormArray }]).find(
    (v) => v.cntrl.status === 'INVALID',
  );
  return val ? { hasInvalidCtrl: true } : null;
};

export const truthyArrLength = (ctrl) =>
  !ctrl.value?.length ? { required: true } : null;

export const percentValidators = () => [
  Validators.required,
  noWhiteSpace,
  isValidNumber,
  Validators.min(1),
  Validators.max(90),
];

export const amountValidators = () => [
  Validators.required,
  noWhiteSpace,
  isValidNumber,
];

const hasFalsyValue = (obj) => {
  for (const c in obj) {
    const v = obj[c].toString();
    if (!v.trim() || v === '[]' || v === '0') {
      return true;
    }
  }
  return false;
};

const hasMaxEtaGreaterThanMin = (obj) => {
  return obj.etaMax < obj.etaMin;
};

const hasDuplicateVals = (arr) => {
  const vals = arr.map((v) => v.zone);
  return uniq(vals).length !== vals.length;
};

export function validateValues(vals) {
  const res = vals.map((v) => v.config).reduce((acc, v) => [...acc, ...v], []);

  for (const v of res) {
    if (hasFalsyValue(v)) {
      return 'falsyValue';
    } else if (hasDuplicateVals(res)) {
      return 'duplicateZones';
    } else if (hasMaxEtaGreaterThanMin(v)) {
      return 'etaMaxGreaterThanMin';
    }
  }
  return false;
}
