<div class="website-customize">
    <form [formGroup]="websiteForm"
          autocomplete="off">
        <div class="row">
            <div class="col-12">
                <section id="navItemsId"
                         tabindex="0">
                    <div class="website-customize__nav-heading">
                        <h4 class="mb-0">Navigation
                            <app-full-info-box class="d-inline-block ml-3"
                                               [template]="navigationInfo">
                            </app-full-info-box>
                        </h4>
                        <p class="mb-5">
                            To learn more about navigations and sub-navigations you can click on the question icon or
                            watch <a class="a-tag"
                               href="https://www.youtube.com/watch?v=pxHWiPlm1CU&list=UUFE_BUP7y78JtMXhhyYhm5Q&index=5"
                               target="_blank">this
                                video</a>
                        </p>
                    </div>

                    <ng-template #navigationInfo>
                        <div class="full-info-box-content">
                            <h4 class="heading-text-3--lg">Navigation and Sub-navigation</h4>
                            <div class="no-overflow">
                                <img class="w-100"
                                     src="../../../assets/navigation-info.png"
                                     alt="selleasy - navigation description">
                            </div>
                            <div class="content-text">
                                <div class="no-overflow mt-4">
                                    <img class="w-100"
                                         src="../../../assets/nav-desc.png"
                                         alt="selleasy - navigation description">
                                </div>
                                <h5 class="mt-4">NAVIGATION (group)</h5>
                                <p>
                                    Assuming you sell clothes you could have a group of products called <i>MEN</i>.
                                    It appears as a navigation button on your website. When your customer clicks, it
                                    displays all men's clothes.
                                </p>

                                <h5 class="mt-4">SUB-NAVIGATION (sub-group)</h5>
                                <p>
                                    Within a group you can have groups. For example within the <i>MEN</i> group, you
                                    could have <i>SHIRTS, TROUSERS</i> etc.
                                </p>
                                <h5 class="mt-4">WATCH A VIDEO</h5>
                                <p>
                                    To learn more about navigations and sub-navigations you can watch <a
                                       href="https://www.youtube.com/watch?v=pxHWiPlm1CU&list=UUFE_BUP7y78JtMXhhyYhm5Q&index=5"
                                       target="_blank">this
                                        video</a>
                                </p>
                            </div>
                        </div>
                    </ng-template>

                    <div *ngFor="let nav of navs; index as i"
                         class="row mt-3">
                        <div class="col-4 pr-0">
                            <mat-form-field #matField
                                            appearance="outline"
                                            class="se-input-field">
                                <mat-label>Navigation item</mat-label>
                                <input type="text"
                                       #input
                                       [value]="nav.name"
                                       (input)="(nav.name = input.value); hasDuplicates(i, matField)"
                                       matInput>
                                <mat-hint *ngIf="navErrors[i]"
                                          class="error-text">You cannot have 2 items with the same
                                    name
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-7">
                            <mat-form-field appearance="outline"
                                            class="se-input-field"
                                            [class.mb-5]="i === 0">
                                <mat-label>Sub-navigation item (optional)</mat-label>
                                <mat-chip-list #chipList>
                                    <mat-chip *ngFor="let subNav of nav?.subNavs"
                                              [selectable]="selectable"
                                              [removable]="removable"
                                              (removed)="removeValue(subNav, nav)">
                                        {{subNav}}
                                        <mat-icon matChipRemove
                                                  *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>
                                    <input [matChipInputFor]="chipList"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           [matChipInputAddOnBlur]="addOnBlur"
                                           (matChipInputTokenEnd)="addValue($event, nav)">
                                </mat-chip-list>
                                <mat-hint *ngIf="i === 0">
                                    {{ isMobile ? 'Separate sub-groups by tapping outside the box or typing a comma' :
                                    'Separate sub-groups by a comma or by clicking out of this box' }}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-1 pl-0">
                            <button mat-icon-button
                                    type="button"
                                    (click)="deleteNav(nav); hasDuplicates(i, matField)">
                                <div>
                                    <se-icon iconName="delete"
                                             size="md">
                                    </se-icon>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="website-customize__add-icon">
                        <div class="d-flex"
                             *ngIf="navs.length === 5">
                            <se-icon iconName="info-icon-circle"
                                     size="md"></se-icon>
                            <span class="body-text-1 d-block mb-4 ml-2">You cannot have more than 5 items</span>
                        </div>
                        <button mat-mini-fab
                                class="mat-elevation-z0 se-fab-icon"
                                type="button"
                                color="primary"
                                [disabled]="navs.length === 5 || hasNavError"
                                (click)="addNav()">
                            <mat-icon>add</mat-icon>
                        </button>

                        <div class="mt-2 mb-3">Add Navigation</div>
                    </div>
                </section>
            </div>
            <div class="col-12">
                <section class="pb-2">
                    <h4>Search Engine Optimization</h4>
                    <div class="d-flex">
                        <se-icon iconName="info-icon-circle"
                                 size="md"></se-icon>
                        <span class="body-text-1 d-block mb-4 ml-2">
                            Increase the visibility of your website when searched online
                        </span>
                    </div>

                    <div class="row">
                        <mat-form-field appearance="outline"
                                        class="se-input-field col-md-6">
                            <mat-label>Website title (not more than 60 xters)</mat-label>
                            <textarea matInput
                                      formControlName="websiteTitle"
                                      rows="7"></textarea>
                            <mat-error>You seem to have an invalid title</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline"
                                        class="se-input-field col-md-6">
                            <mat-label>Website description</mat-label>
                            <textarea matInput
                                      formControlName="websiteDescription"
                                      rows="7"></textarea>
                            <mat-error>You seem to have an invalid description</mat-error>
                        </mat-form-field>
                    </div>
                </section>
            </div>
            <div class="col-12">
                <section class="pb-2">
                    <h4>Contact details</h4>
                    <div class="d-flex">
                        <se-icon iconName="info-icon-circle"
                                 size="md"></se-icon>
                        <span class="body-text-1 d-block mb-4 ml-2">Display your store address on your website
                            (Optional)</span>
                    </div>

                    <div class="row">
                        <mat-form-field appearance="outline"
                                        class="se-input-field col-md-6">
                            <mat-label>Phone</mat-label>
                            <input type="text"
                                   id="businessPhoneNumberId"
                                   formControlName="businessPhoneNumber"
                                   matInput>
                            <mat-error>Your phone number should begin with +234. Example +2348012345678</mat-error>
                            <mat-hint>Ex. +2348012345678</mat-hint>
                        </mat-form-field>
                        <mat-form-field [hidden]="true"
                                        appearance="outline"
                                        class="se-input-field col-md-6">
                            <mat-label>Email</mat-label>
                            <input type="text"
                                   id="businessEmailId"
                                   formControlName="businessEmail"
                                   matInput>
                            <mat-error>Your email seems to be incorrect</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline"
                                        class="se-input-field col-md-6">
                            <mat-label>Address line 1</mat-label>
                            <input type="text"
                                   id="businessAddressId"
                                   formControlName="businessAddress"
                                   matInput>
                            <mat-error>Your address seems to be incorrect</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline"
                                    class="se-input-field">
                        <mat-label>Address line 2</mat-label>
                        <input type="text"
                               id="businessAddress2Id"
                               formControlName="businessAddress2"
                               matInput>
                        <mat-error>Your address seems to be incorrect</mat-error>
                    </mat-form-field>
                </section>
            </div>
            <div class="col-12">
                <section class="pb-0">
                    <div>
                        <!-- <mat-checkbox color="primary"
                                      (change)="showSocialMedia = !showSocialMedia"
                                      [checked]="showSocialMedia">
                        </mat-checkbox> -->
                        <h4 class="d-inline-block">Add social media handles</h4>
                        <div class="d-flex">
                            <se-icon iconName="info-icon-circle"
                                     size="md"></se-icon>
                            <span class="body-text-1 d-block mb-4 ml-2">Display your store social media handles on your
                                website (Optional)</span>
                        </div>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline"
                                        class="se-input-field col-md-6 mb-3">
                            <mat-label>Facebook</mat-label>
                            <input type="text"
                                   id="facebookPageId"
                                   formControlName="facebookPage"
                                   matInput>
                            <mat-error>This appears to be an invalid facebook handle</mat-error>
                            <mat-hint>E.g myfacebookpage</mat-hint>
                            <div matSuffix>
                                <se-icon iconName="facebook"
                                         size="lg"></se-icon>
                            </div>
                        </mat-form-field>
                        <mat-form-field appearance="outline"
                                        class="se-input-field col-md-6 mb-3">
                            <mat-label>Instagram</mat-label>
                            <input type="text"
                                   id="instagramPageId"
                                   formControlName="instagramPage"
                                   matInput>
                            <mat-error>This appears to be an invalid instagram handle</mat-error>
                            <mat-hint>E.g myinstagramhandle</mat-hint>
                            <div matSuffix>
                                <se-icon iconName="instagram-colored"
                                         size="lg"></se-icon>
                            </div>
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline"
                                    class="se-input-field">
                        <mat-label>Twitter</mat-label>
                        <input type="text"
                               id="twitterPageId"
                               formControlName="twitterPage"
                               matInput>
                        <div matSuffix>
                            <se-icon iconName="twitter"
                                     size="lg"></se-icon>
                        </div>
                        <mat-error>This appears to be an invalid twitter handle</mat-error>
                    </mat-form-field>
                </section>
            </div>
            <div class="col-md-6">
                <section class="pb-4">
                    <div>
                        <!-- <mat-checkbox color="primary"
                                      (change)="showWhatsappLiveChat = !showWhatsappLiveChat"
                                      [checked]="showWhatsappLiveChat">
                        </mat-checkbox> -->
                        <h4 class="d-inline-block">Add whatsApp livechat</h4>
                        <div class="d-flex">
                            <se-icon iconName="info-icon-circle"
                                     size="md"></se-icon>
                            <span class="body-text-1 d-block mb-4 ml-2">Chat with your website visitors and customers
                                (Optional)</span>
                        </div>
                    </div>
                    <mat-form-field appearance="outline"
                                    class="se-input-field">
                        <mat-label>WhatsApp phone number</mat-label>
                        <input type="text"
                               id="whatsappNumId"
                               formControlName="whatsappNum"
                               matInput>
                        <div matSuffix>
                            <se-icon iconName="whatsapp"
                                     size="lg"></se-icon>
                        </div>
                        <mat-hint>Ex. +2348012345678</mat-hint>
                        <mat-error>Your phone number should begin with +234. Example +2348012345678</mat-error>
                    </mat-form-field>
                </section>
            </div>
            <div class="col-md-6">
                <section class="pb-0">
                    <div>
                        <h4 class="d-inline-block">Custom domain</h4>
                    </div>

                    <div class="d-flex">
                        <se-icon iconName="info-icon-circle"
                                 size="md"></se-icon>
                        <span class="body-text-1 d-block mb-4 ml-2">
                            <p>To use a custom domain eg; <b>mystore.com</b>, if you already have one or you want to
                                create a new one, please
                                contact us.</p>
                            <p>
                                <b>Email:</b> info@selleasy.co
                            </p>
                            <mat-hint>A custom domain means you get <b>mystore.com</b> instead of
                                <b>mystore.selleasy.ng</b>
                            </mat-hint>
                        </span>
                    </div>
                </section>
            </div>


            <mat-accordion displayMode="flat"
                           class="mb-5 w-100">
                <mat-expansion-panel togglePosition="before">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Website policies <small class="mt-1">&nbsp;(Optional)</small>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row mr-2 ml-2">
                        <div class="col-md-6 pb-0">
                            <section class="pb-0">
                                <div>
                                    <!-- <mat-checkbox color="primary"
                                          (change)="updateShowPrivacyPolicy($event)"
                                          [checked]="showPrivacyPolicy">
                            </mat-checkbox> -->
                                    <h4 class="d-inline-block">Privacy Policy</h4>
                                </div>

                                <mat-form-field appearance="outline"
                                                class="se-input-field">
                                    <mat-label>Privacy Policy</mat-label>
                                    <textarea matInput
                                              formControlName="privacyPolicy"
                                              rows="8"></textarea>
                                    <mat-error>Your have an error in your description</mat-error>
                                </mat-form-field>
                            </section>
                        </div>
                        <div class="col-md-6">
                            <section class="pb-0">
                                <div>
                                    <!-- <mat-checkbox color="primary"
                                          (change)="updateShowRefundPolicy($event)"
                                          [checked]="showRefundPolicy">
                            </mat-checkbox> -->
                                    <h4 class="d-inline-block">Refund Policy</h4>
                                </div>

                                <mat-form-field appearance="outline"
                                                class="se-input-field">
                                    <mat-label>Refund Ploicy</mat-label>
                                    <textarea matInput
                                              formControlName="refundPolicy"
                                              rows="8"></textarea>
                                    <mat-error>Your have an error in your description</mat-error>
                                </mat-form-field>
                            </section>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <section class="pb-0">
                            <div>
                                <!-- <mat-checkbox color="primary"
                                      (change)="updateShowTermsOfUse($event)"
                                      [checked]="showTermsOfUse">
                        </mat-checkbox> -->
                                <h4 class="d-inline-block">Terms of use</h4>
                            </div>

                            <mat-form-field appearance="outline"
                                            class="se-input-field">
                                <mat-label>Terms of use</mat-label>
                                <textarea matInput
                                          formControlName="termsOfUse"
                                          rows="8"></textarea>
                                <mat-error>Your have an error in your description</mat-error>
                            </mat-form-field>
                        </section>
                    </div>


                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <app-error-alert *ngIf="hasNavError"
                         text="You cannot have 2 navigation items with the same name. Please update or delete one.">
        </app-error-alert>

        <div class="d-flex mt-3 mb-5">
            <button mat-stroked-button
                    (click)="submitForm()"
                    color="primary"
                    class="se-button se-button--stroke">
                SAVE
            </button>
            <button mat-flat-button
                    (click)="publishSettings()"
                    color="primary"
                    class="se-button ml-auto">
                SAVE &amp; PUBLISH
            </button>
        </div>
    </form>
    <a [href]="url"
       target="_blank"
       id="__view_site2"></a>
</div>
