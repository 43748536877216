import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModule } from './mat-module/mat-module';
import { SpinnerComponent } from './spinner/spinner.component';
import { FooterComponent } from './footer/footer.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { SeIconComponent } from './se-icon/se-icon.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileComponent } from './profile/profile.component';
import { PaginatedListComponent } from './paginated-list/paginated-list.component';
import { HeaderTitleComponent } from './header-title/header-title.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableRowsColsComponent } from './data-table-rows-cols/data-table-rows-cols.component';
import { DataTableHeaderActionsComponent } from './data-table-header-actions/data-table-header-actions.component';
import { ImageGridComponent } from './image-grid/image-grid.component';
import { ImageGridHeaderActionsComponent } from './image-grid-header-actions/image-grid-header-actions.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { ForeGroundDirective } from './foreground-color/foreground-color.directive';
import {
  FormFieldDirective,
  VariantOptionsDirective,
  VariantPriceDirective,
} from './directives/form-field.directive';
import { InputFilterDirective } from './directives/input-filter.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ChipsMobileHackDirective } from './directives/chips-mobile-hack.directive';
import { FullInfoBoxComponent } from './full-info-box/full-info-box.component';
import { InputTrimDirective } from './directives/input-trim.directive';
import { ErrorAlertComponent } from './error-alert/error-alert.component';
import { InfoBannerComponent } from './info-banner/info-banner.component';
import { ErrorBannerComponent } from './error-banner/error-banner.component';
import { BannerDirective } from './directives/banner.directive';

@NgModule({
  declarations: [
    SpinnerComponent,
    FooterComponent,
    ProgressBarComponent,
    NavComponent,
    SeIconComponent,
    NotificationsComponent,
    ProfileComponent,
    PaginatedListComponent,
    HeaderTitleComponent,
    DataTableComponent,
    DataTableRowsColsComponent,
    DataTableHeaderActionsComponent,
    ImageGridComponent,
    ImageGridHeaderActionsComponent,
    InfoBoxComponent,
    ForeGroundDirective,
    FormFieldDirective,
    InputFilterDirective,
    NumbersOnlyDirective,
    ChipsMobileHackDirective,
    FullInfoBoxComponent,
    InputTrimDirective,
    ErrorAlertComponent,
    VariantOptionsDirective,
    VariantPriceDirective,
    InfoBannerComponent,
    ErrorBannerComponent,
    BannerDirective,
  ],
  imports: [
    CommonModule,
    MatModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
  ],
  exports: [
    SpinnerComponent,
    FooterComponent,
    ProgressBarComponent,
    NavComponent,
    MatModule,
    SeIconComponent,
    NotificationsComponent,
    ProfileComponent,
    PaginatedListComponent,
    HeaderTitleComponent,
    DataTableComponent,
    DataTableRowsColsComponent,
    DataTableHeaderActionsComponent,
    ImageGridComponent,
    ImageGridHeaderActionsComponent,
    InfoBoxComponent,
    ForeGroundDirective,
    FormFieldDirective,
    InputFilterDirective,
    NumbersOnlyDirective,
    ChipsMobileHackDirective,
    FullInfoBoxComponent,
    InputTrimDirective,
    ErrorAlertComponent,
    VariantOptionsDirective,
    VariantPriceDirective,
    InfoBannerComponent,
    ErrorBannerComponent,
    BannerDirective,
  ],
})
export class SharedModule {}
