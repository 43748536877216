import { Injectable, OnDestroy } from '@angular/core';
import { Status } from '../models/status';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth-service';
import { map, delay, filter } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { Router, RoutesRecognized } from '@angular/router';
import { noop } from 'lodash';

@Injectable({ providedIn: 'root' })
export class OrderService implements OnDestroy {
  private readonly BASEURL = (window as any).API_BASE_URL;
  businessId;
  cache: any = {};
  argsList = [];
  interval;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private router: Router,
  ) {
    this.businessId = this.authService.getBusinessId();
    this.setClearCache();
    this.router.events
      .pipe(filter((e) => e instanceof RoutesRecognized))
      .subscribe((e: any) => (e.url === '/home' ? this.clearCache() : noop()));
  }

  setClearCache() {
    this.interval = setInterval(() => {
      this.cache = {};
    }, 3600000); // Clear cache after 1hr
  }

  clearCache() {
    this.cache = {};
  }

  getOpenOrders() {
    const businessId = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const url = `${this.BASEURL}/businesses/${businessId}/stats/order/count?filter={\"where\": {"\or\": [{\"status\": ${Status.PAID}}, {\"status\": ${Status.PROCESSING}}]}}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(url, httpOptions).pipe(map((result) => result));
  }

  getDeliveredOrders(from = '', to = '') {
    const businessId = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const url = `${this.BASEURL}/businesses/${businessId}/stats/order/count?filter={\"where\": {\"timestampCreated\": {\"between\": ["${from}","${to}"]}, "\status\": ${Status.DELIVERED}}}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(url, httpOptions).pipe(map((result) => result));
  }

  getCancelledOrders(from = '', to = '') {
    const businessId = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const url = `${this.BASEURL}/businesses/${businessId}/stats/order/count?filter={\"where\": {\"timestampCreated\": {\"between\": ["${from}","${to}"]}, "\status\": ${Status.CANCELLED}}}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(url, httpOptions).pipe(map((result) => result));
  }

  concatAll(arr) {
    return arr.reduce((acc, curr) => [...acc, ...curr], []);
  }

  concatCache() {
    const arr = [];
    for (const c in this.cache) {
      if (c !== 'all' && c !== 'count') {
        arr.push(this.cache[c]);
      }
    }
    this.cache['all'] = this.concatAll(arr);
  }

  getAllOrders(limit = 15, skip = 0) {
    const args = [...arguments].toString();

    if (this.cache[args]) {
      return of(this.cache['all']);
    }

    const businessId = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const url = `${this.BASEURL}/businesses/${businessId}/orders?filter[offset]=0&filter[limit]=${limit}&filter[skip]=${skip}&filter[order]=timestampCreated%20DESC&filter[where][status][inq]=${Status.PAID}&filter[where][status][inq]=${Status.PROCESSING}&filter[where][status][inq]=${Status.DISPATCHED}&filter[where][status][inq]=${Status.DELIVERED}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(url, httpOptions).pipe(
      map((result: any[]) => {
        const res = result.map((v) => ({
          ...v,
          timestampCreated: new Date(v.timestampCreated).getTime(),
        }));

        this.cache[args] = res;
        this.concatCache();
        return this.cache['all'];
      }),
    );
  }

  getAllOrdersAndCount() {
    return combineLatest([this.getAllOrders(), this.getOrdersCount()]);
  }

  getOrdersCount() {
    if (this.cache['count']) {
      return of(this.cache['count']).pipe(delay(5));
    }

    const businessId = this.authService.getBusinessId();

    // TODO: include the appropriate status
    return this.httpClient
      .get(
        `${this.BASEURL}/businesses/${businessId}/stats/order/count?filter[where][status][inq]=${Status.PAID}&filter[where][status][inq]=${Status.PROCESSING}&filter[where][status][inq]=${Status.DISPATCHED}&filter[where][status][inq]=${Status.DELIVERED}`,
      )
      .pipe(
        map((res: any) => {
          this.cache['count'] = res.orders;
          return res.orders;
        }),
      );
  }

  getOrderTrackingDetails(deliveryType, trackingNo) {
    return deliveryType === 'dropoff'
      ? this.httpClient.post(
          `${this.BASEURL}/businesses/shipping/track`,
          trackingNo,
        )
      : this.httpClient.post(
          `${this.BASEURL}/businesses/shipping/ecn`,
          trackingNo,
        );
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
