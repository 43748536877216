import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface IUser {
  isLoggedIn: boolean;
  email: string;
  websiteUrl: string;
}

export interface INav {
  isMainNav: boolean;
  isMobile: boolean;
  pageTitle: string;
}

export interface State {
  user: IUser;
  nav: INav;
  selectedOrder: any;
}

export interface selectedOrder {
  order: any;
  deliveryType: string;
}

const getNavFeatureState = createFeatureSelector<INav>('nav');
const getOrderFeatureState = createFeatureSelector('selectedOrder');
const getUserFeatureState = createFeatureSelector<IUser>('user');

export const getNavState = createSelector(getNavFeatureState, (nav) => nav);

export const getSelectedOrder = createSelector(
  getOrderFeatureState,
  (state: selectedOrder) => state?.order,
);

export const getDeliveryType = createSelector(
  getOrderFeatureState,
  (order: selectedOrder) => order?.deliveryType,
);

export const getLoginStatus = createSelector(getUserFeatureState, (user) => {
  return user?.email;
});

export const getWebsiteUrl = createSelector(getUserFeatureState, (user) => {
  return user?.websiteUrl;
});

export const getPageTitle = createSelector(
  getNavState,
  ({ isMobile, pageTitle }) =>
    isMobile ? { nav: pageTitle, page: null } : { nav: null, page: pageTitle },
);
