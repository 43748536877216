import { AbstractControl } from '@angular/forms';
import { Observable, Subject, merge } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';

import { startWith, map } from 'rxjs/operators';
import { defer } from 'lodash';
import { Category } from '../models/product';
import { ProductService } from './product-service';
import { ProfileBusinessService } from './profile-business-service';

export class TypeAhead {
  showAddButton = false;
  filteredOptions: Observable<string[]>;
  resetFilter: Subject<string> = new Subject();

  /**
   * @param inputTriggerRef A reference to the inputTrigger
   * @param cntrl A reference to the formCntrl
   * @param options The options that would be displayed in the autocomplete panel
   * @param filterFn A filter function to determine how options should be filtered
   * @param showAddButtonFn An optional function to determine when the add button is displayed
   */

  constructor(
    private inputTriggerRef: MatAutocompleteTrigger,
    private cntrl: AbstractControl,
    private options: any[],
    private filterFn: (options, value) => string[],
    private productService: ProductService,
    private profileBusinessService: ProfileBusinessService,
    private snackbar: MatSnackBar,
    private showAddButtonFn?: (options, value) => boolean,
  ) {
    this.shouldShowAddButton();
    this.setupAutocomplete();
  }

  get(prop) {
    return this[prop];
  }

  private shouldShowAddButton() {
    if (!this.cntrl) {
      return;
    }
    this.cntrl.valueChanges.subscribe((v) => {
      v = v || '';
      this.showAddButton = !this.showAddButtonFn
        ? !!v.trim()
        : this.showAddButtonFn(this.options, v) && !!v.trim;
    });
  }

  private setupAutocomplete() {
    if (!this.cntrl) {
      return;
    }

    this.filteredOptions = merge(
      this.cntrl.valueChanges,
      this.resetFilter,
    ).pipe(
      startWith(''),
      map((value) => this.filterFn(this.options, value)),
    );
  }

  addOption(
    optionType?: string,
    storeConfig?: any,
    businessId?: string,
    nav?: string,
  ) {
    this.cntrl.setValue(this.cntrl.value.trim());
    if (!this.cntrl.value) {
      return;
    }

    if (optionType === 'category') {
      const category: Category = {
        name: this.cntrl.value,
        businessId,
      };

      // send to backend
      this.productService.addCategory(category).subscribe(
        (_) => {
          this.confirmOption();
        },
        (err) => {
          //TODO: Log error
        },
      );
    } else if (optionType === 'nav') {
      if (storeConfig?.configuration.length > 0) {
        const index = storeConfig?.configuration.findIndex(
          (i) => i.isSelected === true,
        );

        // get navItems
        const navItems: any[] =
          storeConfig?.configuration.find((i) => i?.isSelected === true)
            ?.navItems || [];

        // is unique?
        const inNav =
          navItems.filter((i) => i === this.cntrl.value).length > 0
            ? true
            : false;

        if (!inNav && navItems.length < 5) {
          // persist data

          // Hack for BE not sending an empty array when navItems is undefined
          if (!storeConfig?.configuration[index]?.navItems) {
            storeConfig.configuration[index].navItems = [];
          }

          storeConfig?.configuration[index]?.navItems.push(this.cntrl.value);
          if (typeof storeConfig?.vat !== 'boolean') {
            storeConfig['vat'] = false;
          }
          // patch
          this.profileBusinessService
            .patchBusinessProfile(storeConfig)
            .subscribe((_) => {
              this.confirmOption();
            });
        } else {
          // show snackbar
          if (navItems.length + 1 > 5) {
            this.snackbar.open(
              `You can't add more than 5 Navigation items`,
              'Close',
              {
                panelClass: 'error',
              },
            );
          }
        }
      }
    } else if (optionType === 'subNav') {
      if (storeConfig?.configuration.length > 0 && nav !== '') {
        // get configuration index
        const index = storeConfig?.configuration.findIndex(
          (i) => i.isSelected === true,
        );

        // update sub navs
        storeConfig?.configuration[index]?.navItems.forEach((s) => {
          // if subnav'd, add new entry to list
          if (
            Object.keys(s).map((item) => item === nav)[0] === true &&
            s[nav]?.includes(this.cntrl.value) === false
          ) {
            // only add unique entries
            s[nav].push(this.cntrl.value);
          } else if (s === nav) {
            // convert index to subnav'd object and add to list
            const sn = {
              [nav]: [this.cntrl.value],
            };

            // get index
            const propIndex = storeConfig?.configuration[
              index
            ]?.navItems.findIndex((p) => p === nav);

            // remove main nav
            storeConfig?.configuration[index]?.navItems.splice(propIndex, 1);

            // add new sub nav'd item
            storeConfig?.configuration[index]?.navItems.push(sn);
          }
        });

        if (typeof storeConfig?.vat !== 'boolean') {
          storeConfig['vat'] = false;
        }

        // patch
        this.profileBusinessService.patchBusinessProfile(storeConfig).subscribe(
          (_) => {
            this.confirmOption();
          },
          (err) => {
            //TODO: log error
          },
        );
      }
    }
  }

  confirmOption() {
    this.options = [...new Set([this.cntrl.value, ...this.options])];
    this.cntrl.updateValueAndValidity();
    this.openPanel();
  }

  removeOption(option) {
    this.options = this.options.filter((i) => i !== option);
    defer(() => {
      this.openPanel();
    });
  }

  openPanel() {
    this.resetFilter.next('');
    if (this.inputTriggerRef) {
      this.inputTriggerRef.openPanel();
    }
  }
}
