import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { StateFacade } from '../shared/services/state.facade';
import { DownloadReportService } from '../shared/services/download-report.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { defer } from 'lodash';

@Component({
  selector: 'app-download-report-modal',
  templateUrl: './download-report-modal.component.html',
  styleUrls: ['./download-report-modal.component.scss'],
})
export class DownloadReportModalComponent implements OnInit, AfterViewInit {
  from = new FormControl(new Date());
  to = new FormControl(new Date());
  isMobile: boolean;

  constructor(
    private stateFacade: StateFacade,
    private downloadService: DownloadReportService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
    });
  }

  ngAfterViewInit(): void {
    defer(() =>
      this.isMobile
        ? ((document.querySelector('#from') as HTMLInputElement).tabIndex = -1)
        : null,
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  download() {
    this.downloadService.download(this.data.products);
  }
}
