<ng-container [ngSwitch]="size">
    <div class="spinner spinner--lg"
         *ngSwitchCase="'lg'">
        <div class="spinner--lg__backdrop"></div>
        <div class="spinner--lg__wrapper">
            <mat-spinner strokeWidth="8"></mat-spinner>
        </div>
    </div>
    <div class="spinner spinner--sm"
         *ngSwitchCase="'sm'">
        <mat-spinner strokeWidth="4"
                     diameter="50"></mat-spinner>
    </div>
    <div class="spinner spinner--xs"
         *ngSwitchCase="'xs'">
        <mat-spinner strokeWidth="2"
                     diameter="25"></mat-spinner>
    </div>
</ng-container>
