<div class="container account-details main-page">
    <div class="row">
        <app-header-title [title]="title"
                          [subTitle]="subTitle"
                          [showInfoBox]="showInfoBox"
                          [infoBoxContent]="{title: 'Account details updates', content: 'For security reasons, we require users to contact us for changes to account details'}">
        </app-header-title>
        <p *ngIf="isMobile && !showInfoBox"
           class="p-3">
            {{subTitle}}
        </p>
    </div>
    <div class="row">
        <div class="col-lg account-details__props p-0">
            <div class="account-details__form-content">
                <app-account-details-form (updateSubtitle)="onUpdateSubtitle($event)"></app-account-details-form>
            </div>
        </div>
        <div class="col-lg p-0 d-sm-none d-md-block account-details__img-account-details">
        </div>
    </div>
    <div class="row account-details__light-line">
        <hr class="account-details__light-line" />
        <p><b>Note:</b> Customer payments are deposited into your account within the timeframes below: <br>
            1 - 3 business days for bank transfers and card payments (Nigeria) <br>
            1 - 3 business days after delivery for pay on delivery orders <br>
            3 - 10 business days for card payments (International) <br>
            <span *ngIf="showInfoBox">
                For security reasons, we require users to contact us for subsequent changes to account details
            </span>
        </p>
    </div>
</div>
