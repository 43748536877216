import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { noop } from 'lodash';

@Component({
  selector: 'app-website-ready',
  templateUrl: './website-ready.component.html',
  styleUrls: ['./website-ready.component.scss'],
})
export class WebsiteReadyComponent implements OnInit {
  websiteName: string;
  constructor(private route: ActivatedRoute, private navRoute: Router) {}

  ngOnInit() {
    const name: string = this.route.snapshot.params.websiteName;
    this.websiteName = `${name ? name.toLowerCase() : noop()}.selleasy.ng`;
  }

  openWebsite() {
    open('https://' + this.websiteName, '_blank');
  }

  goTo(url) {
    this.navRoute.navigateByUrl(url);
  }
}
