import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionEnum } from '../shared/enums/subscription';

@Component({
  selector: 'app-plan-modal',
  templateUrl: './plan-modal.component.html',
  styleUrls: ['./plan-modal.component.scss'],
})
export class PlanModalComponent implements OnInit {
  modalBody = '';
  plan: any;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.modalBody = data.modalBody;
    this.plan = data.plan;
  }

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close({ plan: null });
  }

  continue() {
    this.dialogRef.close({ plan: this.plan });
  }
}
