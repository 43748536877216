<mat-sidenav-container class="nav"
                       (backdropClick)="closeSideNav()">
    <mat-sidenav class="nav__side-nav"
                 [ngStyle]="{'z-index': isTabletPlus ? 3 : 1}"
                 #sidenav
                 (keydown.escape)="closeSideNav()"
                 disableclose
                 [attr.role]="isMobile ? 'dialog' : 'navigation'"
                 fixedInViewport
                 [position]="navConfig?.position"
                 [mode]="navConfig?.mode"
                 [opened]="navConfig?.opened"
                 [style.width]="!isMainNav ? '20rem' : '25rem'">

        <ng-container *ngIf="!isMainNav">
            <mat-toolbar color="primary"
                         class="nav__drawer-tool-bar">
                <button mat-icon-button
                        (click)="sidenav.close()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar>
            <mat-nav-list>
                <a mat-list-item
                   (click)="goTo('/signup')">{{ !isLoggedIn ? 'Start Free' : 'Dashboard'}}</a>
                <a mat-list-item
                   *ngIf="isLoggedIn"
                   (click)="logOut()">Logout</a>
                <a mat-list-item
                   *ngIf="!isLoggedIn"
                   (click)="goTo('/signin')">Login
                </a>

                <hr>

                <ng-container *ngIf="!isMobileApp">
                    <a mat-list-item
                       *ngIf="!isLoggedIn"
                       class="nav__login"
                       (click)="scrollTo('#howItWorksSection')">How SellEasy Works</a>
                    <a mat-list-item
                       *ngIf="!isLoggedIn"
                       class="nav__login"
                       (click)="scrollTo('#featuresSection')">Features</a>
                    <!-- <button mat-button
                             *ngIf="!isLoggedIn"
                             class="nav__login"
                             (click)="scrollTo('#blogsSection')">Blogs</button> -->
                    <a mat-list-item
                       *ngIf="!isLoggedIn"
                       class="nav__login"
                       (click)="scrollTo('#pricingSection')">Pricing</a>
                    <a mat-list-item
                       *ngIf="!isLoggedIn"
                       class="nav__login"
                       (click)="scrollTo('#technicalSupportSection')">Support</a>
                    <a mat-list-item
                       class="nav__menu-item"
                       (click)="goTo('/platform')">SellEasy Platform</a>
                    <a mat-list-item
                       class="nav__menu-item"
                       (click)="scrollTo('#platformPayment', '/platform')">Payment</a>
                    <a mat-list-item
                       class="nav__menu-item"
                       (click)="scrollTo('#platformDelivery', '/platform')">Delivery</a>
                </ng-container>

            </mat-nav-list>
        </ng-container>

        <ng-container *ngIf="isMainNav">
            <mat-toolbar class="main-nav__logo">
                <div (click)="navHome()"></div>

                <button mat-icon-button
                        (click)="sidenav.close()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar>
            <mat-nav-list #mainNavItems
                          class="main-nav__nav-list">
                <div class="main-nav__profile">
                    <a mat-list-item
                       *ngIf="isMobile">
                        <app-profile (click)="hideProfileMenu = !hideProfileMenu">
                        </app-profile>
                    </a>
                    <div class="main-nav__profile-menu"
                         *ngIf="isMobile"
                         [ngClass]="hideProfileMenu ? 'se-slide-up' : 'se-slide-down'">
                        <a mat-list-item
                           (click)="goTo('/profile')">
                            <div class="px-1"></div>
                            <se-icon iconName="blue-book"
                                     md="true"></se-icon>
                            Profile details
                        </a>
                        <a mat-list-item
                           class="pb-3"
                           (click)="logOut()">
                            <div class="px-1"></div>
                            <se-icon iconName="sign-out"
                                     md="true"></se-icon>
                            Log out
                        </a>
                    </div>
                </div>
                <div *ngFor="let item of navItems">
                    <a mat-list-item
                       class="main-nav__nav-item"
                       [class.main-nav__nav-item_first]="item.first"
                       [class.main-nav__active]="item.active"
                       (click)="goTo(item.url, item)">
                        <se-icon *ngIf="item.icon"
                                 [iconName]="item.icon">
                        </se-icon>
                        <div class="main-nav__nav-name">{{ item.name }}</div>
                        <div class="main-nav__arrow-down-icon"
                             *ngIf="item?.children?.length">
                            <se-icon iconName="arrow-down"
                                     [sm]="true">
                            </se-icon>
                        </div>
                    </a>
                    <div class="main-nav__nav-sub-items"
                         [ngClass]="item.showChildren ? 'se-slide-down' : 'se-slide-up'">
                        <a *ngFor="let child of item.children"
                           class="main-nav__nav-item-child"
                           [class.main-nav__child-active]="child.active"
                           (click)="childNavTo(child.url, child)">
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <div>{{ child.name }}</div>
                        </a>
                    </div>
                </div>
                <hr>
                <a mat-list-item
                   class="pb-3"
                   [href]="websiteUrl"
                   (click)="closeSideNav()"
                   target="_blank">
                    <div class="px-1"></div>
                    <se-icon class="nav__view-store"
                             iconName="visit-store"
                             xl="true"></se-icon>
                    View Website
                </a>
            </mat-nav-list>
        </ng-container>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar *ngIf="!isMainNav"
                     color="primary"
                     class="nav__toolbar"
                     [ngClass]="{'bg-darkgrey': shouldTurnCoffee$ | async}">
            <div class="container p-0 nav__toolbar-content">
                <button mat-flat-button
                        class="se-button nav__brand-logo"
                        color="accent"
                        (click)="navHome()"></button>
                <ng-template #login>
                    <div class="nav__sub-group">
                        <!-- <button mat-button
                                *ngIf="!isLoggedIn"
                                class="nav__login"
                                [matMenuTriggerFor]="menu">
                            <div class="d-flex">
                                <div>Company</div>
                                <se-icon iconName="arrow-down-white"
                                         size="xs"></se-icon>
                            </div>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item
                                    class="nav__menu-item"><a href="/about-us">About us</a></button>
                            <button mat-menu-item
                                    class="nav__menu-item"><a href="/blogs">Blog</a></button>
                            <button mat-menu-item
                                    class="nav__menu-item"><a href="/careers">Careers</a></button>
                        </mat-menu> -->

                        <button mat-button
                                *ngIf="!isLoggedIn"
                                class="nav__login"
                                (click)="scrollTo('#howItWorksSection')">How it Works</button>
                        <button mat-button
                                *ngIf="!isLoggedIn"
                                class="nav__login"
                                (click)="scrollTo('#featuresSection')">Features</button>
                        <!-- <button mat-button
                                *ngIf="!isLoggedIn"
                                class="nav__login"
                                (click)="scrollTo('#blogsSection')">Blogs</button> -->
                        <button mat-button
                                *ngIf="!isLoggedIn"
                                class="nav__login"
                                (click)="scrollTo('#pricingSection')">Pricing</button>
                        <button mat-button
                                *ngIf="!isLoggedIn"
                                class="nav__login"
                                (click)="scrollTo('#technicalSupportSection')">Support</button>
                        <button mat-button
                                *ngIf="!isLoggedIn"
                                class="nav__login"
                                [matMenuTriggerFor]="menu2">
                            <div class="d-flex">
                                <div>Platform</div>
                                <se-icon iconName="arrow-down-white"
                                         size="xs"></se-icon>
                            </div>
                        </button>
                        <mat-menu #menu2="matMenu">
                            <button mat-menu-item
                                    class="nav__menu-item"
                                    (click)="goTo('/platform')">SellEasy Platform</button>
                            <button mat-menu-item
                                    class="nav__menu-item"
                                    (click)="scrollTo('#platformPayment', '/platform')">Payment</button>
                            <button mat-menu-item
                                    class="nav__menu-item"
                                    (click)="scrollTo('#platformDelivery', '/platform')">Delivery</button>
                        </mat-menu>
                    </div>
                    <div class="nav__sub-group2">
                        <button mat-button
                                [hidden]="true"
                                *ngIf="!isLoggedIn"
                                class="nav__login"
                                [matMenuTriggerFor]="menu3">
                            <div class="d-flex">
                                <div>Support</div>
                                <se-icon iconName="arrow-down-white"
                                         size="xs"></se-icon>
                            </div>
                        </button>
                        <mat-menu #menu3="matMenu">
                            <button mat-menu-item
                                    class="nav__menu-item"
                                    (click)="scrollTo('#technicalSupportSection')">Technical Support
                            </button>
                            <button mat-menu-item
                                    class="nav__menu-item"
                                    (click)="goTo('/help-center')">Help Center</button>
                        </mat-menu>
                        <button mat-button
                                *ngIf="!isLoggedIn"
                                class="nav__login"
                                (click)="goTo('/signin')">Login</button>
                    </div>
                </ng-template>
                <button mat-button
                        *ngIf="isLoggedIn else login"
                        class="nav__login"
                        (click)="logOut()">LOGOUT</button>
                <button *ngIf="!isLoggedIn else dashboard"
                        mat-flat-button
                        class="se-button nav__get-started"
                        color="accent"
                        (click)="goTo('/signup')">
                    START FREE
                </button>
                <ng-template #dashboard>
                    <button mat-flat-button
                            class="se-button nav__get-started"
                            color="accent"
                            (click)="goTo('/dashboard')">
                        DASHBOARD
                    </button>
                </ng-template>

                <button mat-icon-button
                        class="nav__open-side-nav"
                        (click)="sidenav.open()">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </mat-toolbar>

        <mat-toolbar *ngIf="isMainNav"
                     id="se-nav__top-bar"
                     color="primary"
                     class="nav__toolbar">
            <button type="button"
                    class="nav__menu-icon-mobile"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="sidenav.toggle()"
                    *ngIf="isMobile">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <h1>{{title}}</h1>
            <app-notifications [total]="30"></app-notifications>
            <app-profile *ngIf="!isMobile"></app-profile>
        </mat-toolbar>

        <div class="nav__body"
             [class.main-nav__bg-white]="isMainNav"
             [ngClass]="{'bg-darkgrey': isHomePage}">
            <ng-content></ng-content>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
