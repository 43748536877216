import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from './auth-service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AutoLogOutInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      catchError((err) => {
        if (
          err.status === 401 &&
          err.error.error.message.includes('jwt expired')
        ) {
          this.authService.logout(true);
          return;
        }
        return throwError(err);
      }),
    );
  }
}
