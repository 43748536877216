import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[inputFilter]',
})
export class InputFilterDirective {
  @Input() regex;
  @Input() formCtrl: AbstractControl;

  @HostListener('input', ['$event.target'])
  updateValue(el) {
    el.value = el.value.replace(this.regex, '');
    if (this.formCtrl instanceof AbstractControl) {
      this.formCtrl.setValue(el.value);
    }
  }
}
