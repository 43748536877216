<form class="user-edit-form"
      [formGroup]="userEditForm"
      novalidate>
    <mat-form-field appearance="outline"
                    class="se-input-field">
        <mat-label>First name</mat-label>
        <input type="text"
               id="firstNameId"
               formControlName="firstName"
               matInput>
        <mat-error>Your name seems to be incorrect</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline"
                    class="se-input-field">
        <mat-label>Last Name</mat-label>
        <input type="text"
               id="lastNameId"
               formControlName="lastName"
               matInput>
        <mat-error>Your name seems to be incorrect</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline"
                    class="se-input-field">
        <mat-label>Email</mat-label>
        <input type="text"
               id="emailId"
               formControlName="email"
               matInput>
        <mat-error>
            <span *ngIf="email.hasError('required')">Please enter store email</span>
            <span *ngIf="email.hasError('email')">
                Your email seems to be incorrect. You might be missing an <b>@</b>
            </span>
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline"
                    class="se-input-field">
        <mat-label>Phone number</mat-label>
        <input type="phone"
               id="phoneId"
               formControlName="phone"
               matInput>
        <mat-error>Your phone number should begin with +234. Example +2348012345678</mat-error>
        <mat-hint>Ex. +2348012345678</mat-hint>
    </mat-form-field>
    <div class="mt-5">
        <button mat-flat-button
                (click)="updateProfile()"
                color="primary"
                class="se-button">
            UPDATE PROFILE
        </button>
    </div>
</form>
