import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { IDiscount } from '../shared/models/product';
import { DiscountsService } from '../shared/services/discounts.service';
import { StateFacade } from '../shared/services/state.facade';
import { map, noop, find } from 'lodash/fp';
import { Router } from '@angular/router';
import { finalize, takeWhile } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { SelectionModel } from '@angular/cdk/collections';
import { truncate } from 'lodash';
import { forkJoin } from 'rxjs';
import { endOfToday } from '../shared/utils/dates';

const isInTheFuture = (date: string) => {
  return new Date(date).getTime() > endOfToday();
};

const statuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  EXPIRED: 'Expired',
};

const mapDiscounts = map((d: any) => ({
  ...d,
  value: d.type?.toLowerCase() === 'percentage' ? `${d.value}%` : `₦${d.value}`,
  type: `${d.type[0].toUpperCase()}${d.type.slice(1)}`,
  status: isInTheFuture(d.start) ? 'Inactive' : statuses[d.status],
  rules: [],
}));

const getValue = (val: string) =>
  val.includes('%') ? val.split('%')[0] : val.split('₦')[1];

const hasActiveStoreLevel = find(
  (v: any) => v.scope === 'store' && v.status === 'Active',
);

@Component({
  selector: 'app-discounts-list',
  templateUrl: './discounts-list.component.html',
  styleUrls: ['./discounts-list.component.scss'],
})
export class DiscountsListComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<any>([]);
  selectionModel: SelectionModel<any>;
  selectedRows: IDiscount[] = [];
  isAllSelected = false;
  displayedColumns: string[] = [
    'select',
    'name',
    'code',
    'value',
    'scope',
    'start',
    'end',
    'status',
  ];
  columnsSelect = [
    { label: 'Status', value: 'status' },
    { label: 'Code', value: 'code' },
    { label: 'Discount', value: 'value' },
    { label: 'Applies To', value: 'scope' },
    { label: 'Start Date', value: 'start' },
    { label: 'End Date', value: 'end' },
  ];
  columnsToDisplay = [];
  isComponentActive = true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  statusClasses = {
    Active: 'success-text',
    Inactive: 'stopped-text',
    Expired: 'f-500',
  };
  truncateText = truncate;
  isInTheFuture = isInTheFuture;

  constructor(
    private discountService: DiscountsService,
    private snackBar: MatSnackBar,
    private stateFacade: StateFacade,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  discounts;
  isMobile = false;
  isLoading = false;
  scopes = {
    product: 'Selected products',
    order: 'An order',
    store: 'All products',
  };

  ngOnInit(): void {
    this.getDiscounts();
    this.getViewPort();
  }

  // Should clean up for cyclomatic complexity
  get shouldDisableStop() {
    return (
      !this.selectedRows.length ||
      this.selectedRows.length > 1 ||
      this.selectedRows[0].status === 'Inactive' ||
      this.selectedRows[0].status === 'Expired'
    );
  }

  // Should clean up for cyclomatic complexity
  get shouldDisableStart() {
    return (
      !this.selectedRows.length ||
      this.selectedRows.length > 1 ||
      this.selectedRows[0].status === 'Active' ||
      this.selectedRows[0].status === 'Expired'
    );
  }

  getDiscounts() {
    this.isLoading = true;
    this.discountService
      .getDiscounts()
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeWhile(() => this.isComponentActive),
      )
      .subscribe((discounts: any[]) => {
        this.discounts = discounts;
        this.dataSource.data = mapDiscounts(discounts);
      });
  }

  getViewPort() {
    this.stateFacade
      .getViewPortSize()
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe(({ isMobile }) => {
        this.isMobile = isMobile;
        if (isMobile) {
          this.displayedColumns = [
            'select',
            'name',
            'status',
            'code',
            'value',
            'start',
            'end',
          ];
        }

        this.columnsToDisplay = this.displayedColumns.slice(
          0,
          this.isMobile ? 3 : 8,
        );
      });
  }

  goto(url) {
    this.router.navigate([url]);
  }

  editSelectedDiscount() {
    this.router.navigate(['create-discount', { id: this.selectedRows[0].id }]);
  }

  updateDiscount(status: string) {
    const _status = status === 'Active' ? 'started' : 'stopped';
    const selection: any = this.selectedRows[0];

    this.discountService
      .updateDiscount({
        ...selection,
        status: status.toUpperCase(),
        value: Number(getValue(selection.value)),
      })
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((discounts: any) => {
        this.snackBar.open(`Discount has been ${_status}`, 'OK', {
          panelClass: 'success',
        });

        this.selectionModel.toggle(this.selectedRows[0]);
        this.dataSource.data = mapDiscounts(discounts);
      });
  }

  startDiscount() {
    const shouldNotStart =
      !!hasActiveStoreLevel(this.dataSource.data) &&
      this.selectedRows[0].scope === 'store';

    if (shouldNotStart) {
      this.displayInfo(
        'Sorry, you cannot have more than 1 active ALL PRODUCTS discount at a time',
      );
      return;
    }

    if (this.isInTheFuture(this.selectedRows[0].start)) {
      this.displayInfo(
        'Discount cannot be started now because start date is in the future',
      );
      return;
    }
    this.updateDiscount('Active');
  }

  displayInfo(msg) {
    this.snackBar.open(msg, 'OK', {
      panelClass: 'info',
      duration: 10000000000,
    });
  }

  stopDiscount() {
    this.updateDiscount('Inactive');
  }

  shareOnSocial(social) {}

  onSelectionModelInit(model) {
    this.selectionModel = model;
    this.selectionModel.changed
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((e) => {
        this.isAllSelected =
          e.source.selected.length === this.dataSource.data.length;
        this.selectedRows = this.isAllSelected ? e.source.selected : [];
      });
  }

  onRowSelected(rows) {
    this.selectedRows = rows;
  }

  deleteDiscount() {
    const ids: string[] = this.selectedRows.map((v) => v.id);
    forkJoin(
      ids.map((id) => this.discountService.deleteDiscount(id)),
    ).subscribe((_) => {
      // Remove from product result set
      this.selectionModel.deselect(...this.selectedRows);
      this.dataSource.data = this.dataSource.data.filter(
        (p) => !ids.includes(p.id),
      );
      this.selectedRows = [];
      this.snackBar.open('Discount(s) deleted', 'OK', {
        panelClass: 'success',
      });
    });
  }

  deleteSelectedDiscount() {
    this.openModal({
      header: 'Delete Discount',
      body: `Are you sure you want to delete selected discount(s)?`,
      ctas: {
        back: noop,
        act: this.deleteDiscount.bind(this),
        textLeft: 'NO',
        textRight: 'YES',
      },
    });
  }

  onSelectChange({ value }) {
    this.columnsToDisplay.pop();
    this.columnsToDisplay.push(value);
  }

  openModal(content) {
    this.dialog.open(ConfirmationModalComponent, {
      width: this.isMobile ? '100vw' : '40%',
      data: content,
    });
  }

  ngOnDestroy() {
    this.isComponentActive = false;
    this.snackBar.dismiss();
  }
}
