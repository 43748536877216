import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss'],
})
export class ErrorBannerComponent {
  constructor(private router: Router) {}

  navTo() {
    this.router.navigate(['/profile', { showPlan: true }]);
  }
}
