import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { random } from 'lodash';
import { AuthService } from './auth-service';
import { HttpClient } from '@angular/common/http';
import { concatMap, map } from 'rxjs/operators';
import { IDiscount } from '../models/product';

@Injectable({
  providedIn: 'root',
})
export class DiscountsService {
  private readonly BASEURL = (window as any).API_BASE_URL;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
  ) {}

  get bizId(): string {
    return this.authService.getBusinessId();
  }

  getDiscounts() {
    return this.httpClient
      .get<IDiscount[]>(`${this.BASEURL}/businesses/${this.bizId}/discounts`)
      .pipe(
        map((discounts) =>
          discounts.sort(
            (a, b) =>
              new Date(b.timestampCreated.toString()).getTime() -
              new Date(a.timestampCreated.toString()).getTime(),
          ),
        ),
      );
  }

  getDiscount(id) {
    return this.httpClient.get<IDiscount>(`${this.BASEURL}/discounts/${id}`);
  }

  deleteDiscount(id) {
    return this.httpClient.delete(`${this.BASEURL}/discounts/${id}`);
  }

  patchDiscount(payload) {
    return this.httpClient.patch(
      `${this.BASEURL}/discounts/${payload.id}`,
      payload,
    );
  }

  updateDiscount(payload) {
    return this.patchDiscount(payload).pipe(
      concatMap(() => this.getDiscounts()),
    );
  }

  saveDiscount(payload) {
    return this.httpClient.post(
      `${this.BASEURL}/businesses/${this.bizId}/discounts`,
      payload,
    );
  }

  generateCode(discountName: string) {
    return of(
      `${discountName
        .toUpperCase()
        .replace(/ /g, '')
        .substring(0, 4)}${Date.now()
        .toString()
        .split('')
        .reverse()
        .join('')
        .substring(0, 6)}${random(0, 100)}`,
    );
  }
}
