import { Component, OnDestroy, Self, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { get as _get } from 'lodash';

import { AuthService } from '../../shared/services/auth-service';
import { PollerService } from '../../shared/services/poller-service';
import { interval, Subject, merge } from 'rxjs';
import { mapTo, scan, take, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { noWhiteSpace } from '../../shared/utils/validations';
import { FormErrorService } from '../../shared/services/form-error.service';
import { PHONE_REGEX } from 'src/app/shared/utils/regexes';

@AutoUnsubscribe()
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [PollerService],
})
export class SignUpComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackbar: MatSnackBar,
    @Self() private poller: PollerService,
    private formErr: FormErrorService,
  ) {}

  private minLength = [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(40),
  ];
  private stopProgressBarSub = new Subject();
  private readonly PROGRESS_INTERVAL = 15000;

  hidePassword = true;
  isPolling = localStorage.getItem('isPolling') || false;
  serverError = null;
  progressValue = '0%';

  progress$ = merge(
    interval(this.PROGRESS_INTERVAL),
    interval(1000).pipe(take(1)),
  ).pipe(
    mapTo(25),
    scan((acc, curr) => acc + curr, 0),
    take(3),
    takeUntil(this.stopProgressBarSub),
  );

  signupForm = new FormGroup({
    firstName: new FormControl(null, [
      ...this.minLength,
      Validators.pattern(/^[0-9A-Za-z @\-]+$/),
      noWhiteSpace,
    ]),
    lastName: new FormControl(' '), // To be removed, when backend updates
    email: new FormControl(
      null,
      [Validators.required, Validators.email],
      this.authService.checkEmailAvailability,
    ),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern(PHONE_REGEX),
    ]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
    ]),
    websiteName: new FormControl(
      null,
      [Validators.required, Validators.pattern(/^[0-9A-Za-z\-]+$/)],
      this.authService.checkSiteAvailability,
    ),
    businessName: new FormControl(null, [
      ...this.minLength,
      Validators.pattern(/^[0-9A-Za-z @]+$/),
      noWhiteSpace,
    ]),
  });

  get businessNameCtrl() {
    return this.signupForm.get('businessName');
  }

  get firstNameCtrl() {
    return this.signupForm.get('firstName');
  }

  get websiteName() {
    return this.signupForm.get('websiteName');
  }

  get emailCtrl() {
    return this.signupForm.get('email');
  }

  get websiteNameExists() {
    return this.signupForm.get('websiteName').hasError('nameExists');
  }

  get isAsyncInvalidEmail() {
    return this.signupForm.get('email').hasError('emailExists');
  }

  get formInvalid() {
    return (
      this.signupForm.invalid || this.signupForm.pending || this.serverError
    );
  }

  ngOnInit() {
    if (localStorage.getItem('AUTH_TOKEN')) {
      this.router.navigateByUrl('/dashboard');
    }

    const websiteName = localStorage.getItem('pollingStatus');
    if (websiteName) {
      this.startPoller(websiteName);
      this.progressValue = '75%';
    }
  }

  displayErrorMsg({ msg }) {
    this.snackbar.open(msg, 'Close', { panelClass: 'error' });
    this.serverError = { msg };
    this.signupForm.valueChanges.subscribe((_) => (this.serverError = null));
    this.stopProgressCount();
  }

  signUp(formData) {
    formData['telephone'] = formData?.phone ?? '';
    delete formData.phone;
    this.authService.signUp(formData).subscribe((response) => {
      if (!response.isSignup) {
        this.displayErrorMsg(response);
      }
    });
  }

  stopProgressCount() {
    this.stopProgressBarSub.next('STOP_PROGRESS_BAR');
  }

  enableIsPolling(websiteName) {
    this.isPolling = true;
    localStorage.setItem('pollingStatus', websiteName);
  }

  disableIsPolling() {
    this.isPolling = false;
    localStorage.removeItem('pollingStatus');
  }

  startPoller(websiteName) {
    this.enableIsPolling(websiteName);
    this.poller
      .start(`/businessess/${websiteName}/status`)
      .subscribe((isCreated: boolean | object) => {
        if (_get(isCreated, 'error') || typeof isCreated === 'object') {
          this.stopProgressCount();
          this.disableIsPolling();
          return;
        }

        if (isCreated) {
          this.poller.stop();
          this.progressValue = '100%';

          setTimeout(() => {
            this.disableIsPolling();
            this.router.navigate([`/website-ready/${websiteName}`]);
          }, 500);
        }
      });
  }

  onSubmitForm() {
    this.formErr.validate(this.signupForm);

    if (this.formInvalid) {
      return;
    }

    const formData = {
      ...this.signupForm.value,
      timestampCreated: new Date(Date.now()),
    };

    this.signUp(formData);
    // Needs rework --> Ideally UI should get a flag from the backend
    this.startPoller(formData.websiteName);
    this.progress$.subscribe((val) => (this.progressValue = `${val}%`));
  }

  ngOnDestroy() {
    this.poller.destroy();
  }
}
