<div class="container main-page account">
    <div class="row">
        <app-header-title title="Account">
        </app-header-title>
    </div>
    <div class="row account__box-wrapper">
        <!-- amountDeposited -->
        <div class="col-lg account__amount-deposited">
            <h6> {{amountDepositedSelected}}
                <div class="account__select">
                    <mat-form-field class="opacity-0">
                        <mat-select class="mat-elevation-z0"
                                    [(value)]="amountDepositedSelected">
                            <mat-option *ngFor="let item of duration"
                                        (click)="getAmountDeposited(item)"
                                        [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <se-icon iconName="arrow-down-small"
                         sm="true">
                </se-icon>
            </h6>
            <div class="centered spaced-bottom-mini"
                 *ngIf="isLoadingDeposit">
                <mat-spinner strokeWidth="4"
                             diameter="50"></mat-spinner>
            </div>
            <span *ngIf="!isLoadingDeposit">
                {{amountDeposited ? (amountDeposited | currency:'₦':'symbol':'4.2-2') : '₦0'}}
            </span>
            <h4>Total Deposit</h4>
        </div>
        <!-- end -->
        <!-- pending amount -->
        <div class="col-lg account__pending-amount">
            <h6> {{pendingAmountSelected}}
                <div class="account__select">
                    <mat-form-field class="opacity-0">
                        <mat-select class="mat-elevation-z0"
                                    [(value)]="pendingAmountSelected">
                            <mat-option *ngFor="let item of duration"
                                        (click)="getPendingAmount(item)"
                                        [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <se-icon iconName="arrow-down-small"
                         sm="true">
                </se-icon>
            </h6>
            <div class="centered spaced-bottom-mini"
                 *ngIf="isLoadingPending">
                <mat-spinner strokeWidth="4"
                             diameter="50"></mat-spinner>
            </div>

            <span *ngIf="!isLoadingPending">
                {{pendingAmount ? (pendingAmount | currency:'₦':'symbol':'4.2-2') : '₦0'}}
            </span>
            <h4>Pending Deposit</h4>

        </div>
        <!-- end -->
    </div>
    <!-- datatable -->

    <ng-template #emptyState>
        <div class="p-5 text-center">
            <span>You have no orders yet</span>
        </div>
    </ng-template>

    <div class="row">
        <app-data-table class="w-100"
                        [isLoading]="isLoading"
                        [emptyStateTemplate]="emptyState"
                        [dataSource]="dataSource"
                        [displayedColumns]="columnsToDisplay"
                        [showHeaderActions]="true"
                        [pageSize]="10"
                        [dataLength]="ordersCount | async"
                        [stickyHeader]="!isMobile"
                        (onPaginatorInit)="getPaginator($event)"
                        matSort
                        #sort="matSort">

            <!-- extra header action -->
            <app-data-table-header-actions class="d-flex">

            </app-data-table-header-actions>
            <!-- end -->

            <!-- order date -->
            <ng-container matColumnDef="orderDate"
                          sticky>
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>Order Date</th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el.date | date}}</td>
            </ng-container>
            <!-- end -->

            <!-- total -->
            <ng-container matColumnDef="total">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        Total
                    </span>
                    <ng-template #inStockRef>
                        <div class="account__last-col">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Total
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el.total | currency:'₦':'symbol':'4.2-2'}}</td>
            </ng-container>
            <!-- end -->

            <!-- order number -->
            <ng-container matColumnDef="orderNumber">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        Order Number
                    </span>
                    <ng-template #inStockRef>
                        <div class="account__last-col">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Order No.
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el.orderId}}</td>
            </ng-container>
            <!-- end -->

            <!-- Customer -->
            <ng-container matColumnDef="customer">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        Customer
                    </span>
                    <ng-template #inStockRef>
                        <div class="account__last-col">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Customer
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el.customerName}}</td>
            </ng-container>
            <!-- end -->

            <!-- status -->
            <ng-container matColumnDef="status">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        Status
                    </span>
                    <ng-template #inStockRef>
                        <div class="account__last-col">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Status
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el._status}}</td>
            </ng-container>
            <!-- end -->
        </app-data-table>
    </div>
    <app-spinner *ngIf="isLoading"></app-spinner>
</div>
