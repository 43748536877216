<div (click)="toggle()">
    <se-icon [iconName]="icon || 'question'"
             size="md"></se-icon>
</div>

<div *ngIf="showInfoBox"
     class="full-page animated fadeIn"
     style="animation-duration: 0.2s">
    <div class="wrapper">
        <header>
            <img *ngIf="isMobile else title"
                 src="../../../assets/selleasy-logo-black.svg"
                 alt="selleasy logo">
            <ng-template #title>
                <h3>SellEasy</h3>
            </ng-template>
            <span class="material-icons close"
                  (click)="$event.stopPropagation(); toggle()">close</span>
        </header>

        <ng-template #defaultTpl>
            <div>Pass a template reference here</div>
        </ng-template>

        <ng-container *ngTemplateOutlet="template || defaultTpl"></ng-container>
        <div class="mt-5">
            <button mat-stroked-button
                    color="primary"
                    class="se-button se-button--stroke"
                    (click)="toggle()">GOT IT</button>
        </div>
    </div>
</div>
