import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { StoreProfile } from '../shared/models/store-profile';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import { StateFacade } from '../shared/services/state.facade';
import {
  addErrorClass,
  noWhiteSpace,
  removeErrorClass,
} from '../shared/utils/validations';

@Component({
  selector: 'app-unsupported-states-modal',
  templateUrl: './unsupported-states-modal.component.html',
  styleUrls: ['./unsupported-states-modal.component.scss'],
})
export class UnsupportedStatesModalComponent implements OnInit {
  isMobile = false;
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      model: StoreProfile;
      formValues: any;
      sub: Subject<string>;
    },
    private dialogRef: MatDialogRef<any>,
    private statefacade: StateFacade,
    private snackbar: MatSnackBar,
    private profileBusinessService: ProfileBusinessService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.statefacade.getViewPortSize().subscribe(({ isMobile }) => {
      this.isMobile = isMobile;
    });
    this.form = this.fb.group({
      pickUpLocation: [this.data.formValues.pickUpLocation],
      dropOffLocation: [this.data.formValues.dropOffLocation],
    });
  }

  get isLagosResident() {
    const { lagosId, state } = this.data.formValues;
    return lagosId === state;
  }

  get filteredDropOffs() {
    return this.data.formValues.dropOffLocations;
  }

  get streetAddress() {
    return this.data.formValues.streetAddress;
  }

  get dropOffLocation() {
    return this.form.get('dropOffLocation');
  }

  get pickUpLocation() {
    return this.form.get('pickUpLocation');
  }

  doNotHandle() {
    this.data.model.delivery[0].selfDeliveryZones[0].useSelleasyForOtherStates = false;
    this.patchProfile(this.data.model);
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close({ continue: false });
  }

  markFormAsTouched() {
    document
      .querySelectorAll('.form-fields input')
      .forEach((el: HTMLInputElement) => {
        el.focus();
        el.blur();
        el.focus();
      });
  }

  updateValidators() {
    this.isLagosResident
      ? this.pickUpLocation.setValidators([
          Validators.minLength(3),
          Validators.required,
          noWhiteSpace,
        ])
      : this.dropOffLocation.setValidators([Validators.required]);
    this.pickUpLocation.updateValueAndValidity();
    this.dropOffLocation.updateValueAndValidity();
  }

  handle() {
    this.updateValidators();
    this.markFormAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.data.model.delivery[0].selfDeliveryZones[0].useSelleasyForOtherStates = true;
    this.data.model.delivery[0].configuration.dropoffLocation = this.filteredDropOffs.filter(
      (v) => v.id === this.dropOffLocation.value,
    );
    this.data.model.delivery[0].configuration.pickupLocation =
      this.pickUpLocation.value || null;
    this.patchProfile(this.data.model);
    this.closeModal();
  }

  checkEmptyField(el, matField) {
    const val = el.value.trim();
    !val || val === '0'
      ? (addErrorClass(matField), (el.__hasReqErr = true))
      : (removeErrorClass(matField), (el.__hasReqErr = false));
  }

  patchProfile(data: StoreProfile, shouldHandle = false) {
    this.profileBusinessService.patchBusinessProfile(data).subscribe(
      () => {
        this.snackbar.open(
          `Your profile has been successfully updated`,
          'Close',
          {
            panelClass: 'success',
          },
        );
        this.data.sub.next('update');
      },
      (err) => {
        this.snackbar.open(err.message, 'Close', {
          panelClass: 'error',
        });
      },
    );
  }
}
