<div class="info-box"
     [matMenuTriggerFor]="infoBoxMenu"
     #infoBoxMenuTrigger="matMenuTrigger">
    <se-icon [iconName]="icon || 'question'"
             size="md"></se-icon>

    <mat-menu class="info-box__panel mat-elevation-z2"
              #infoBoxMenu="matMenu">
        <div class="info-box__panel-wrapper">
            <div class="d-flex justify-content-between">
                <h4>{{title}}</h4>
                <se-icon iconName="info-icon-circle"></se-icon>
            </div>
            <div class="info-box__panel-content">
                <div [innerHTML]="content"></div>
            </div>
        </div>
    </mat-menu>
</div>
