import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth-service';
import { map } from 'rxjs/operators';
import { IProduct, Category } from '../models/product';
import { Variation } from '../models/variation';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { compose, map as _map } from 'lodash/fp';

const getProd = _map(({ id, name, variations, imageUri, price }) => ({
  id,
  name,
  variations,
  imageUri,
  price,
}));

const findVariant = (variationsData) => (id) =>
  variationsData.find((v) => id === v.id);

const mapVariants = (variationsData) =>
  _map((v: any) => {
    return {
      ...v,
      image: v.imageUri[0],
      children: _map(({ variationId, price, quantity }) => ({
        id: v.id,
        price,
        quantity,
        variationId,
        name: !variationId
          ? ''
          : variationId
              .map(findVariant(variationsData))
              .map((vr) => ` ${vr.attributeName} ${vr.attributeValue}`),
      }))(v.variations || []),
    };
  });

const defaultChildren = _map((v: any) => ({
  ...v,
  children: v.children.length ? v.children : [{}],
}));

@Injectable({ providedIn: 'root' })
export class ProductService implements Resolve<Object> {
  private readonly BASEURL = (window as any).API_BASE_URL;
  private readonly variationErrsSub = new Subject();
  private readonly addProdFormSubmitSub = new Subject();

  private sharedProductId$ = new BehaviorSubject(null);

  addSharedProdId(id: string) {
    this.sharedProductId$.next(id);
  }

  get sharedProdId() {
    return this.sharedProductId$.asObservable();
  }

  get variantErrorSub() {
    return this.variationErrsSub;
  }

  get formSubmitSub() {
    return this.addProdFormSubmitSub;
  }

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Object> {
    return this.getVariations();
  }

  addProduct(product: IProduct) {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/${id}/products`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient
      .post(path, product, httpOptions)
      .pipe(map((result) => result));
  }

  patchProduct(product: IProduct, productId: string) {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/${productId}/products`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient
      .patch(path, product, httpOptions)
      .pipe(map((result) => result));
  }

  deleteProduct(productId: string) {
    const businessId = this.authService.getBusinessId();
    const path = `${this.BASEURL}/businesses/${businessId}/products/${productId}`;
    return this.httpClient.delete(path).pipe(map((result) => result));
  }

  getProducts() {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/${id}/products/stats?filter[order]=timestampCreated%20DESC`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient.get(path, httpOptions).pipe(map((result) => result));
  }

  concatProducts() {
    return this.getProducts().pipe(
      map((prods: any[]) =>
        prods.reduce((acc, curr, i) => {
          return acc + `${curr.name}${i === prods.length - 1 ? '' : ', '}`;
        }, ''),
      ),
    );
  }

  getProduct(productId: string) {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/${id}/products/stats?filter={\"where\": {\"id\": "${productId}"}}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient.get(path, httpOptions).pipe(map((result) => result));
  }

  addCategory(category: Category) {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/${id}/categories`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient
      .post(path, category, httpOptions)
      .pipe(map((result) => result));
  }

  getProductCategory() {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/${id}/categories`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient.get(path, httpOptions).pipe(map((result) => result));
  }

  getVariations() {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/${id}/variations`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient.get(path, httpOptions);
  }

  addVariation(variation: Variation) {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/${id}/variations`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient
      .post(path, variation, httpOptions)
      .pipe(map((result) => result));
  }

  getProductsAndVariants() {
    return forkJoin([this.getProducts(), this.getVariations()]).pipe(
      map(([products, variants]) =>
        compose(
          defaultChildren,
          mapVariants(variants),
          getProd,
        )(products as any),
      ),
    );
  }

  populateImage(formData: any, options: any, fileId?: any) {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    let path = '';
    if (fileId) {
      path = `${this.BASEURL}/businesses/image?id=${fileId}`;
    } else {
      path = `${this.BASEURL}/businesses/image`;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient
      .post(path, formData, options)
      .pipe(map((result) => result));
  }

  deleteImages(imagesIds: string) {
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/${id}/images`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html',
        Authorization: `Bearer ${token}`,
      }),
      body: imagesIds,
    };

    return this.httpClient
      .delete(path, httpOptions)
      .pipe(map((result) => result));
  }
}
