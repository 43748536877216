import { Directive, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[numbersOnly]',
})
export class NumbersOnlyDirective {
  @Input() ctrl = new FormControl();

  @HostListener('input', ['$event.target'])
  updateValue(el) {
    el.value = el.value.replace(/[^0-9.]/g, '');
    if (el.value.includes('..')) {
      el.value = el.value.replace('..', '.');
    }
    this.ctrl.setValue(el.value);
    this.ctrl.updateValueAndValidity();
  }
}
