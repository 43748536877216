import { Component, Inject, OnInit } from '@angular/core';
import { getNavState } from '../../state/selectors';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { scrollToView } from '../utils/helpers';
import { IS_MOBILE_APP } from '../factories/user-agent-factory';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isMainNav = false;
  scrollTo = scrollToView(this.router);
  get currentYear() {
    return new Date().getFullYear();
  }
  isNotMobileOS$: Observable<boolean>;

  constructor(
    private store: Store,
    private router: Router,
    @Inject(IS_MOBILE_APP) public isMobileOS: Observable<boolean>,
  ) {
    this.isNotMobileOS$ = this.isMobileOS.pipe(map((v) => !v));
  }

  ngOnInit() {
    this.store.pipe(select(getNavState)).subscribe((nav) => {
      this.isMainNav = nav.isMainNav;
    });
  }

  goTo(url) {
    this.router.navigate([url]);
  }
}
