import { Component, OnInit, Input } from '@angular/core';
import { StateFacade } from '../services/state.facade';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
})
export class HeaderTitleComponent implements OnInit {
  @Input() title;
  @Input() subTitle;
  @Input() showBackButton;
  @Input() showInfoBox = false;
  @Input() infoBoxContent: any = {};
  isMobile = false;
  constructor(private stateFacade: StateFacade) {}

  ngOnInit(): void {
    this.stateFacade.setPageTitle(this.title);
    this.stateFacade.getPageTitle().subscribe((title) => {
      this.title = title.page;
    });
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
    });
  }

  goBack() {
    history.back();
  }
}
