import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-settingup-website',
  templateUrl: './settingup-website.component.html',
  styleUrls: ['./settingup-website.component.scss'],
})
export class SettingupWebsiteComponent {
  @Input() percent = '0%';

  headings = {
    '0%': '<h2 class="animated fadeIn">We are setting up your website</h2>',
    '25%': '<h2 class="animated fadeIn">We are setting up your website</h2>',
    '50%':
      '<h2 class="animated fadeIn">Adding some content to your website</h2>',
    '75%': '<h2 class="animated fadeIn">Securing your website settings</h2>',
    '100%': '<h2 class="animated fadeIn">Securing your website settings</h2>',
  };
}
