<app-modal class="plan-modal">
    <app-modal-header>
        <h1>Subscribe to a Plan</h1>
    </app-modal-header>
    <app-modal-content>
        <p>
            {{modalBody}}
        </p>
    </app-modal-content>
    <app-modal-footer>
        <button mat-stroked-button
                (click)="closeModal()"
                color="primary"
                class="se-button se-button--stroke">
            CLOSE
        </button>
        <button mat-flat-button
                (click)="continue()"
                color="primary"
                class="se-button">
            CONTINUE
        </button>
    </app-modal-footer>
</app-modal>
