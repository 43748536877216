<div class="container orderandshipping website main-page">
    <div class="row">
        <app-header-title title="Orders &amp; Shipping">
        </app-header-title>
    </div>
    <div class="row orderandshipping__box-wrapper">
        <app-spinner *ngIf="isLoading"></app-spinner>
        <!-- open orders -->
        <div class="col-lg orderandshipping__open-orders"><br />
            <span>{{openOrders}}</span>
            <h4>Open Orders</h4>
        </div>
        <!-- end -->
        <!-- delivered orders -->
        <div class="col-lg orderandshipping__delivered-orders">
            <h6> {{deliveredOrderSelected}}
                <div class="orderandshipping__select">
                    <mat-form-field class="opacity-0">
                        <mat-select class="mat-elevation-z0"
                                    [(value)]="deliveredOrderSelected">
                            <mat-option *ngFor="let item of duration"
                                        (click)="getDeliveredOrders(item)"
                                        [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <se-icon iconName="arrow-down-small"
                         sm="true">
                </se-icon>
            </h6>
            <span>{{deliveredOrders}}</span>
            <h4>Delivered Orders</h4>
        </div>
        <!-- end -->
        <!-- cancelled orders -->
        <div class="col-lg orderandshipping__cancelled-orders">
            <h6> {{cancelledOrderSelected}}
                <div class="orderandshipping__select">
                    <mat-form-field class="opacity-0">
                        <mat-select class="mat-elevation-z0"
                                    [(value)]="cancelledOrderSelected">
                            <mat-option *ngFor="let item of duration"
                                        (click)="getCancelledOrders(item)"
                                        [value]="item">{{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <se-icon iconName="arrow-down-small"
                         sm="true">
                </se-icon>
            </h6>
            <span>{{cancelledOrders}}</span>
            <h4>Cancelled Orders</h4>
        </div>
        <!-- end -->
    </div>
    <ng-template #emptyState>
        <div class="p-5 text-center">
            <span>You have no orders yet</span>
        </div>
    </ng-template>
    <div class="row">
        <app-data-table class="w-100"
                        [isLoading]="isLoading"
                        [dataSource]="dataSource"
                        [emptyStateTemplate]="emptyState"
                        [displayedColumns]="columnsToDisplay"
                        [showHeaderActions]="true"
                        [pageSize]="10"
                        [dataLength]="ordersCount | async"
                        [stickyHeader]="!isMobile"
                        (onPaginatorInit)="getPaginator($event)"
                        matSort
                        #sort="matSort">

            <!-- extra header action -->
            <app-data-table-header-actions class="d-flex">

            </app-data-table-header-actions>
            <!-- end -->

            <!-- order date -->
            <ng-container matColumnDef="orderDate"
                          sticky>
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>Order Date</th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el.date | date}}</td>
            </ng-container>
            <!-- end -->

            <!-- total -->
            <ng-container matColumnDef="total">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        Total
                    </span>
                    <ng-template #inStockRef>
                        <div class="orderandshipping__last-col">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Total
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el.total | currency:'₦':'symbol':'4.2-2'}}</td>
            </ng-container>
            <!-- end -->

            <!-- order number -->
            <ng-container matColumnDef="orderNumber">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        Order Number
                    </span>
                    <ng-template #inStockRef>
                        <div class="orderandshipping__last-col">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Order No.
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el.orderId}}
                </td>
            </ng-container>
            <!-- end -->

            <!-- Customer -->
            <ng-container matColumnDef="customer">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        Customer
                    </span>
                    <ng-template #inStockRef>
                        <div class="orderandshipping__last-col">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Customer
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el.customerName}}</td>
            </ng-container>
            <!-- end -->

            <!-- products -->
            <ng-container matColumnDef="product">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        Product
                    </span>
                    <ng-template #inStockRef>
                        <div class="orderandshipping__last-col">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Product
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">
                    <div class="text-left">
                        <span #productDescEl
                              class="d-none"
                              [innerHTML]="el.productDesc"></span>
                        <span [innerHTML]="truncateText(productDescEl.textContent)"
                              [matTooltip]="productDescEl.textContent"></span>
                    </div>
                </td>
            </ng-container>
            <!-- end -->

            <!-- status -->
            <ng-container matColumnDef="status">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        Status
                    </span>
                    <ng-template #inStockRef>
                        <div class="orderandshipping__last-col">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Status
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    (click)="viewOrder(el)"
                    *matCellDef="let el">{{el._status}}</td>
            </ng-container>
            <!-- end -->
        </app-data-table>
    </div>
</div>
