export enum AppActionTypes {
  InitNav = '[NAV] INIT NAV',
  ToggleNav = '[NAV] TOGGLE NAV',
  IsMobile = '[NAV] IS VIEWPORT MOBILE',
  SetPageTitle = 'SET_PAGE_TITLE',
  SelectOrder = 'SELECT_ORDER',
  DeliveryType = 'DELIVERY_TYPE',
  IsLoggedIn = 'IS_LOGGED_IN',
  SetWebsiteUrl = '[SIGN IN] SET WEBSITE URL',
}

export class ToggleNav {
  readonly type = AppActionTypes.ToggleNav;

  constructor(public payload: boolean) {}
}

export class SetWebsiteUrl {
  readonly type = AppActionTypes.SetWebsiteUrl;

  constructor(public payload: string) {}
}

export class IsMobile {
  readonly type = AppActionTypes.IsMobile;

  constructor(public payload: boolean) {}
}

export class IsLoggedIn {
  readonly type = AppActionTypes.IsLoggedIn;

  constructor(public payload: boolean) {}
}

export class InitNav {
  readonly type = AppActionTypes.InitNav;
}

export class SelectOrder {
  readonly type = AppActionTypes.SelectOrder;
  constructor(public payload: any) {}
}

export class DeliveryType {
  readonly type = AppActionTypes.DeliveryType;
  constructor(public payload: string) {}
}

export class SetPageTitle {
  readonly type = AppActionTypes.SetPageTitle;

  constructor(public payload: string) {}
}

export type AppActionType = ToggleNav | InitNav | SetPageTitle;
