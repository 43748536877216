import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth-service';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private readonly BASEURL = (window as any).API_BASE_URL;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private snackbar: MatSnackBar,
  ) {}

  /**
   * @description get banks from api
   * @returns Array<Object>
   */
  getBanks() {
    const path = `${this.BASEURL}/businesses/bankcodes`;
    return this.httpClient.get(`${path}`).pipe(map((result) => result));
  }

  getAccountMetrics(
    from = '',
    to = '',
    ref: string,
    provider: string,
    single = true,
  ) {
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/businesses/settlements/${provider}/${ref}?fromDate=${from}&toDate=${to}&single=${single}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(path, httpOptions).pipe(map((result) => result));
  }

  // TODO: merge service http calls
  /**
   * @description get tenant's account details
   */
  getAccountDetails() {
    const id = this.authService.getUserId();
    const businessId = this.authService.getBusinessId();
    const path = `${this.BASEURL}/businesses/${businessId}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .get(path, httpOptions)
      .pipe(map((result) => result['bankDetails'] ?? null));
  }

  // TODO: merge service http calls
  /**
   * @description get tenant's service charge settings
   */
  getServiceChargeSettings() {
    const id = this.authService.getUserId();
    const businessId = this.authService.getBusinessId();
    const path = `${this.BASEURL}/businesses/${businessId}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .get(path, httpOptions)
      .pipe(map((result) => result['payServiceCharge'] ?? null));
  }

  // TODO: merge service http calls
  /**
   * @description get tenant's tax settings
   */
  getTaxSettings() {
    const id = this.authService.getUserId();
    const businessId = this.authService.getBusinessId();
    const path = `${this.BASEURL}/businesses/${businessId}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .get(path, httpOptions)
      .pipe(
        map((result) => [
          { payVatRate: result['payVatRate'] ?? null },
          { vatRate: result['vatRate'] ?? null },
        ]),
      );
  }

  /**
   * @description patch account details
   * @param data details to be updated
   */
  patchDetails(data: Object) {
    const id = this.authService.getUserId();
    const businessId = this.authService.getBusinessId();
    const path = `${this.BASEURL}/users/${id}/business/${businessId}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .patch(path, data, httpOptions)
      .pipe(map((result) => result));
  }
}
