<form [formGroup]="profileEditForm"
      novalidate
      class="store-profile-edit-form"
      autocomplete="off">
    <div class="d-flex">
        <se-icon iconName="info-icon-circle"
                 size="md"></se-icon>
        <span class="body-text-1 d-block mb-5 ml-3">Please note that all fields on this form are required</span>
    </div>
    <div class="row">
        <mat-form-field appearance="outline"
                        class="se-input-field col-md-6">
            <mat-label>Store name</mat-label>
            <input type="text"
                   id="nameId"
                   formControlName="name"
                   matInput>
            <mat-error>Store name seems to be incorrect</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        class="se-input-field col-md-6 mb-3">
            <mat-label>Store email</mat-label>
            <input type="text"
                   id="emailId"
                   formControlName="email"
                   matInput>
            <mat-error>
                <span *ngIf="email.hasError('required')">Please enter store email</span>
                <span *ngIf="email.hasError('email')">
                    Your email seems to be incorrect. You might be missing an <b>@</b>
                </span>
            </mat-error>
            <mat-hint>This will be used for business correspondence</mat-hint>
        </mat-form-field>
    </div>
    <div class="row mt-2">
        <mat-form-field appearance="outline"
                        class="se-input-field col-md-6 mb-4">
            <mat-label>Store phone</mat-label>
            <input type="text"
                   id="phoneId"
                   formControlName="phone"
                   matInput>
            <mat-error>Your phone number should begin with +234. Example +2348012345678</mat-error>
            <mat-hint>Ex. +2348012345678</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        class="se-input-field col-md-6 mb-4">
            <mat-label>Store address</mat-label>
            <input type="text"
                   id="streetAddressId"
                   formControlName="streetAddress"
                   matInput>
            <mat-error>Store address seems to be incorrect</mat-error>
            <mat-hint>Ex. 201, Elegushi Rd, Lagos</mat-hint>
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field appearance="outline"
                        class="se-input-field col-md-6">
            <mat-label>State</mat-label>
            <mat-select id="stateId"
                        class="mat-elevation-z0"
                        (selectionChange)="selectState($event)"
                        [formControl]="state">
                <mat-option *ngFor="let state of states"
                            [value]="state.id">
                    {{state.name}}
                </mat-option>
            </mat-select>
            <mat-error>Please select a state</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        class="se-input-field col-md-6">
            <mat-label>Local goverment area</mat-label>
            <mat-select id="localGovtId"
                        class="mat-elevation-z0"
                        [formControl]="localGovt">
                <mat-option *ngFor="let lga of lgas"
                            [value]="lga.id">
                    {{lga.name}}
                </mat-option>
            </mat-select>
            <mat-error>
                <div *ngIf="!state?.value else selectLocalGovt">
                    Please select a state to see all the local goverment areas in the state
                </div>
                <ng-template #selectLocalGovt>
                    Please select a local government area
                </ng-template>
            </mat-error>
        </mat-form-field>
    </div>

    <h3 class="mt-3 mb-2"
        id="deliverySettings">Delivery Settings</h3>
    <p class="mb-5">How do you want to get your products delivered?</p>

    <div class="mb-4 d-flex align-items-center">Choose a delivery method</div>
    <div class="mb-4">
        <mat-radio-group [formControl]="deliveryType"
                         color="primary"
                         (change)="changeRadioOption($event, 'dt')"
                         class="d-flex">
            <div class="d-flex">
                <mat-radio-button value="selleasydelivery">SellEasy delivery</mat-radio-button>
                <app-info-box class="ml-3"
                              title="Selleasy Delivery"
                              content="<p>We handle your delivery for you.</p>
                              <p><b>Pick Up:</b> Type the location you want us to pick up your items for delivery. If you do not type any location, we'll use your store address 
                              <p><b>Drop Off:</b> Choose a location closest to you where you can drop off your goods for our delivery services to pick them up</p></p>">
                </app-info-box>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <div class="d-flex">
                <mat-radio-button value="selfdelivery">Self delivery</mat-radio-button>
                <ng-template #selfDelivery>
                    <div class="full-info-box-content">
                        <h4 class="heading-text-3--lg">Self Delivery</h4>
                        <div class="no-overflow">
                            <img class="w-100"
                                 src="../../../assets/selfdelivery.png"
                                 alt="selleasy - variation description">
                        </div>
                    </div>
                </ng-template>
                <app-full-info-box class="ml-3"
                                   class="d-inline-block ml-3"
                                   [template]="selfDelivery">
                </app-full-info-box>
            </div>
        </mat-radio-group>
    </div>
    <ng-container *ngIf="deliveryType.value !== 'selfdelivery'">

        <div class="mb-3"
             *ngIf="selectedState === 'Lagos' || state.value === lagosId">
            <mat-radio-group *ngIf="false"
                             [formControl]="deliveryMode"
                             color="primary"
                             (change)="changeRadioOption($event, 'dm')">
                <mat-radio-button value="pickup">Pick up</mat-radio-button>
                &nbsp;&nbsp;&nbsp;&nbsp;
            </mat-radio-group>
        </div>

        <mat-form-field *ngIf="isSellEasyDelivery && isLagosResident"
                        appearance="outline"
                        class="se-input-field mb-5">
            <mat-label>Pick up location</mat-label>
            <input type="text"
                   id="pickUpLocationId"
                   #pickUp
                   formControlName="pickUpLocation"
                   [value]="streetAddress.value"
                   (blur)="pickUp.value = pickUp.value.trim() || streetAddress.value"
                   matInput>
            <mat-error>Pickup location seems to be incorrect</mat-error>
            <mat-hint>Our delivery service will pick up your goods at this location</mat-hint>
        </mat-form-field>

        <div class="mt-4">
            <mat-form-field *ngIf="isSellEasyDelivery && !isLagosResident"
                            appearance="outline"
                            class="mb-5">
                <mat-label>Drop off location</mat-label>
                <mat-select id="dropOffLocationId"
                            class="mat-elevation-z0"
                            [formControl]="dropOffLocation">
                    <mat-option class="dropoff-option"
                                *ngFor="let location of filteredDropOffs"
                                [value]="location.id">
                        {{location?.address}}, {{location?.name}}, {{location?.state?.name}}
                    </mat-option>
                </mat-select>
                <mat-hint>You can drop off your goods for delivery at our drop off location closest to you
                </mat-hint>
                <mat-error>
                    <div *ngIf="!state?.value else selectDroff">
                        Please select a state to see all the drop off locations available to you
                    </div>
                    <ng-template #selectDroff>
                        Please a select drop off location
                    </ng-template>
                </mat-error>
            </mat-form-field>
        </div>
    </ng-container>

    <div *ngIf="deliveryType.value === 'selfdelivery'"
         class="self-delivery-settings">
        <div class="mt-2 mb-2 w-90">Currently you can do self-delivery only within Nigeria. Please enter the
            <b>states</b>
            you want to support. Click the question mark icon to see an example.
        </div>

        <div class="mb-5 pb-5"
             *ngFor="let country of selfDeliveryLocations.value; index as i">
            <div class="list-heading"></div>
            <div *ngFor="let region of country.config; index as j"
                 class="row mt-3"
                 [ngClass]="{'border-top mt-5 pt-5': j !== 0}">
                <div class="col-3 pr-0 d-none">
                    <mat-form-field #matFieldZone
                                    appearance="outline"
                                    class="se-input-field">
                        <mat-label>Group {{j + 1}}</mat-label>
                        <input #zoneInput
                               type="text"
                               value="{{region.zone || 'Group ' + (j + 1)}}"
                               (input)="region.zone = zoneInput.value; checkEmptyField(zoneInput, matFieldZone)"
                               (blur)="checkEmptyField(zoneInput, matFieldZone)"
                               matInput>
                        <mat-hint class="error-text"
                                  *ngIf="zoneInput.__hasReqErr">Please enter group name</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-9">
                    <mat-form-field #matFieldStates
                                    appearance="outline"
                                    class="se-input-field">
                        <mat-label>Enter or select state(s)</mat-label>
                        <mat-chip-list #chipList>
                            <mat-chip *ngFor="let state of region.states"
                                      [selectable]="selectable"
                                      [removable]="removable"
                                      (removed)="removeValue(state, region.states)">
                                {{state}}
                                <mat-icon matChipRemove
                                          *ngIf="removable">cancel
                                </mat-icon>
                            </mat-chip>
                            <input #stateInput
                                   autocomplete="chrome-off"
                                   chipsMobileHack
                                   [matAutocomplete]="auto"
                                   [matChipInputFor]="chipList"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="true"
                                   (blur)="noStates(region.states, stateInput, matFieldStates)"
                                   (matChipInputTokenEnd)="addValue($event, region.states)">
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete"
                                          (optionSelected)="addOption($event, region.states); noStates(region.states, stateInput, matFieldStates)">
                            <mat-option *ngFor="let _state of filterStates(stateInput.value) | async"
                                        [value]="_state.name">
                                {{_state.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-hint class="error-text"
                                  *ngIf="stateInput.__hasReqErr">Please select a state</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-3 pl-0">
                    <mat-form-field #matFieldPrice
                                    appearance="outline"
                                    class="se-input-field">
                        <mat-label>Price</mat-label>
                        <input #priceInput
                               type="number"
                               [value]="region.price"
                               (input)="region.price = priceInput.value; checkEmptyField(priceInput, matFieldPrice)"
                               (blur)="checkEmptyField(priceInput, matFieldPrice)"
                               matInput>
                        <mat-hint class="error-text"
                                  *ngIf="priceInput.__hasReqErr">Price cannot be zero or empty</mat-hint>
                        <div class="body-text-2 se-suffix"
                             matSuffix>₦</div>
                    </mat-form-field>
                </div>
                <div class="row pl-5 pr-1 w-100">
                    <div class="col-12 mt-3 mb-3 pl-0">Product(s) will be delivered within <b>{{etaMinInput.value ||
                            'n'}} to
                            {{etaMaxInput.value
                            ||
                            'n'}} </b> day(s)
                    </div>
                    <div class="col-5 pl-0">
                        <mat-form-field #matFieldMin
                                        appearance="outline"
                                        class="se-input-field">
                            <mat-label>{{ isMobile ? 'Minimum no of days' : 'Minimum no of days to deliver products' }}
                            </mat-label>
                            <input #etaMinInput
                                   type="number"
                                   [value]="region.etaMin || 1"
                                   (input)="region.etaMin = etaMinInput.value; checkEmptyField(etaMinInput, matFieldMin); checkMinMaxErr(etaMinInput, etaMaxInput, 'min', matFieldMin)"
                                   (blur)="checkEmptyField(etaMinInput, matFieldMin); checkMinMaxErr(etaMinInput, etaMaxInput, 'min', matFieldMin)"
                                   matInput>
                            <div class="body-text-2 se-suffix"
                                 matSuffix>day(s)</div>
                            <mat-hint class="error-text"
                                      *ngIf="etaMinInput.__hasReqErr else templMin">Minimum no of days cannot be zero or
                                less than maximum number of days
                            </mat-hint>
                            <ng-template #templMin>
                                <mat-hint>Minimum no of days to deliver products</mat-hint>
                            </ng-template>
                        </mat-form-field>
                    </div>
                    <div class="col-6 pl-0 pr-0">
                        <mat-form-field #matFieldMax
                                        appearance="outline"
                                        class="se-input-field">
                            <mat-label>{{ isMobile ? 'Maximum no of...' : 'Maximum no of days to deliver products' }}
                            </mat-label>
                            <input #etaMaxInput
                                   type="number"
                                   [value]="region.etaMax"
                                   (input)="region.etaMax = etaMaxInput.value; checkEmptyField(etaMaxInput, matFieldMax); checkMinMaxErr(etaMinInput, etaMaxInput, 'max', matFieldMax)"
                                   (blur)="checkEmptyField(etaMaxInput, matFieldMax); checkMinMaxErr(etaMinInput, etaMaxInput, 'max', matFieldMax)"
                                   matInput>
                            <div class="body-text-2 se-suffix"
                                 matSuffix>day(s)</div>
                            <mat-hint class="error-text"
                                      *ngIf="etaMaxInput.__hasReqErr else templMaxHint">Maximum number of
                                days cannot be zero or less than minimum number of days</mat-hint>
                            <ng-template #templMaxHint>
                                <mat-hint>Maximum no of days to deliver products</mat-hint>
                            </ng-template>
                        </mat-form-field>
                    </div>
                    <div class="col-1 pr-0 delete-button">
                        <button mat-icon-button
                                [ngClass]="{'opacity-4': j === 0}"
                                [disabled]="j === 0"
                                matTooltip="Delete group of states"
                                (click)="removeGroup(region, country.config)">
                            <div>
                                <se-icon iconName="delete"
                                         size="md">
                                </se-icon>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="add-group mt-2"
                 [ngClass]="isMobile ? 'mt-5 pt-3' : 'mt-5'">
                <button mat-mini-fab
                        class="mat-elevation-z0 se-fab-icon"
                        type="button"
                        color="primary"
                        [disabled]="false"
                        (click)="addGroup(country.config)">
                    <mat-icon>add</mat-icon>
                </button>
                <div class="mt-2 mb-3">Add Another Group of States</div>
            </div>
            <mat-form-field *ngIf="isSelfDelivery && deliveryProvider === 'Self' && isLagosResident && useSelleasyForOtherStates"
                            appearance="outline"
                            class="se-input-field mb-5 mt-5">
                <mat-label>Pick up location</mat-label>
                <input type="text"
                       id="pickUpLocationId"
                       formControlName="pickUpLocation"
                       matInput>
                <mat-error>Pickup location seems to be incorrect</mat-error>
                <mat-hint>Our delivery service will pick up your goods at this location</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="isSelfDelivery && deliveryProvider === 'Self' && !isLagosResident && useSelleasyForOtherStates"
                            appearance="outline"
                            class="mb-5 mt-5">
                <mat-label>Drop off location</mat-label>
                <mat-select id="dropOffLocationId"
                            class="mat-elevation-z0"
                            [formControl]="dropOffLocation">
                    <mat-option class="dropoff-option"
                                *ngFor="let location of filteredDropOffs"
                                [value]="location.id">
                        {{location?.address}}, {{location?.name}}, {{location?.state?.name}}
                    </mat-option>
                </mat-select>
                <mat-hint>You can drop off your goods for delivery at our drop off location closest to you
                </mat-hint>
            </mat-form-field>
        </div>
    </div>

    <div class="mt-4">
        <button id="updateProfileCta"
                mat-flat-button
                (click)="updateProfile()"
                color="primary"
                class="se-button">
            UPDATE PROFILE
        </button>
    </div>
</form>
