<app-slider></app-slider>

<div class="container-fluid grey-bg arrow-down">
    <se-icon (click)="scrollToHowItWorks()"
             iconName="arrow-down"
             size="lg">
    </se-icon>
</div>

<!-- How it Works -->
<div sec
     how-it-works
     id="howItWorksSection"
     class="container-fluid"
     style="overflow-x: hidden;">
    <div class="row how-it-works text-center">
        <div class="container row m-auto">
            <div class="col-12">
                <h2>How it works</h2>
            </div>
            <div class="col-12">
                <p class="title-support-text text-width">SellEasy provides a secure platform with all the
                    tools
                    required for
                    you to
                    setup your online store, manage your store, sell on multiple platforms, deliver your product and
                    accept payments with no IT
                    knowledge
                    required</p>
            </div>
            <!--- Three Column Section -->
            <div class="col-xs-12 col-sm-6 col-md-4">
                <img class="step-img"
                     src="./assets/step1.svg">
                <p class="text-secondary">STEP 1</p>
                <h3>Register</h3>
                <p class="step-text">Start a 30-day free trial by creating your ecommerce website and start selling in
                    minutes. Your store is pre-built with integrated payment and delivery systems</p>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4">
                <img class="step-img"
                     src="assets/step2.svg">
                <p class="text-secondary">STEP 2</p>
                <h3>List your product</h3>
                <p class="step-text">Upload your product details such as name, amount, variations, images, etc. Listed
                    products immediately become visible on your website, Google shopping, EasyDeals and
                    your social media pages or stores
                </p>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4">
                <img class="step-img"
                     src="assets/step3.svg">
                <p class="text-secondary">STEP 3</p>
                <h3>Sell your product</h3>
                <p class="step-text">Receive orders from your ecommerce website, Google shopping, EasyDeals, 
                    your social media stores.
                    We take care of your payment, delivery & inventory
                </p>
            </div>
        </div>
        <!-- <div class="container video">
            <iframe src="https://www.youtube.com/embed/watch?v=WQpDtP4cp8I&feature=youtu.be&list=PLKJd9-Ui_G2JFqSuIHBGr7ejr7AtLUR83"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
        </div> -->
    </div>
</div>

<!-- Features and Benefits -->
<div sec
     class="container-fluid"
     id="featuresSection">
    <div class="row jumbotron feats">
        <div class="container"
             style="overflow: hidden;">
            <div class="col-lg-6 feats-col">
                <h2>Features and Benefits</h2><br>
                <p class="title-support-text d-none">Streamline your order fulfilment process, generate
                    organic
                    sales and expand your
                    customer base by building a self-sustaining online store and increase your product visibility</p>
                <ul class="fa-ul mobileIpadOnly-hidden mt-4">
                    <li><i class="fa-li fa fa-check"></i>Create a fully customisable and secure e-commerce website
                        without
                        IT or coding knowledge in minutes</li>
                    <li><i class="fa-li fa fa-check"></i>Get sales from Google, Instagram, WhatsApp
                        EasyDeals, your ecommerce store and
                        manage all from your SellEasy app
                    </li>
                    <li><i class="fa-li fa fa-check"></i>Securely accept payments from multiple payment channels, such
                        as
                        bank transfer, USSD, credit / debit cards, pay on delivery etc</li>
                    <li><i class="fa-li fa fa-check"></i>Local & International delivery - No more delivery hassle! We
                        ship and
                        deliver
                        your product across Nigeria and abroad</li>
                    <li><i class="fa-li fa fa-check"></i>Product and Sales Insights into your Store and Product
                        performance
                        such as; Number of views, Number of purchases, Total sales, Out-of-stock products etc.</li>
                    <li><i class="fa-li fa fa-check"></i>Built-in Inventory Management System to manage all your product
                        data and stock inventory through the sales cycle</li>
                    <li><i class="fa-li fa fa-check"></i>Discounts & Coupons - Offer store wide, product or order
                        specific discounts to your Customers</li>
                    <li><i class="fa-li fa fa-check"></i>Social commerce - Share products directly on WhatsApp</li>
                    <li><i class="fa-li fa fa-check"></i>Your business in your pocket anywhere, anytime</li>
                </ul>
                <ul class="fa-ul mobileIpadOnly-view">
                    <li><i class="fa-li fa fa-check"></i>Fully customisable e-commerce website</li>
                    <li class="lh-28"><i class="fa-li fa fa-check "></i>Get sales from Google, Instagram, WhatsApp,
                        EasyDeals, your ecommerce store and
                        manage all from your SellEasy app
                    </li>
                    <li><i class="fa-li fa fa-check"></i>Securely accept payments</li>
                    <li><i class="fa-li fa fa-check"></i>Local & International delivery</li>
                    <li><i class="fa-li fa fa-check"></i>Product and Sales Insights</li>
                    <li><i class="fa-li fa fa-check"></i>Inventory Management System</li>
                    <li><i class="fa-li fa fa-check"></i>Discounts & Coupons</li>
                    <li><i class="fa-li fa fa-check"></i>Share products directly on WhatsApp</li>
                    <li><i class="fa-li fa fa-check"></i>Your store with you anywhere, anytime</li>
                </ul>
            </div>
            <div class="col-lg-6">
                <img src="assets/features.png"
                     class="img-fluid padFtImage">
            </div>
        </div>
    </div>
</div>


<!---Pricing-->
<div *ngIf="!isMobile"
     sec
     pricing
     id="pricingSection"
     class="container-fluid mobileOnly-hidden pricing-table"
     style="overflow-x: hidden;"><a name="pricing"></a>
    <div class="row jumbotron text-center">
        <div class="container">
            <div class="col-12">
                <h2>Pricing</h2>
            </div>
            <div class="container-fluid">
                <div>
                    <p class="title-support-text text-width">Transparent and straightforward pricing. You can
                        choose a
                        basic
                        plan or a
                        premium plan, You get the best value for the plan you choose.</p>
                </div>
            </div>
            <!-- Pricing Top -->
            <div class="row pricing container-fluid">
                <div class="col-md-6 text-left">
                    <h3 class="ipadOnly-hidden">Pay Monthly <label class="switch mr-2"><input type="checkbox"
                                   (change)="togglePrice($event)"><span class="slider round"></span></label> Pay Yearly
                        <span style="color: #6DA531">save
                            <span class="percent">5%</span></span>
                    </h3>
                    <h3 class="ipadOnly-view">Pay Monthly <label class="switch"><input type="checkbox"
                                   (change)="togglePrice($event)"><span class="slider round"></span></label> Pay Yearly
                        <span style="color: #6DA531">save
                            <span class="percent">5%</span></span>
                    </h3>
                </div>
                <div class="col-md-3 text-center">
                    <img class="step-img img-fluid"
                         src="assets/plan1.png">
                    <h5>BASIC PLAN</h5>
                    <div class="ipadOnly-hidden">
                        <p><strong><sup><span class="big"><span><b>{{'&#8358;'}}</b></span></span></sup><span
                                      class="bigger">1,499.99</span></strong><sup>/mo</sup></p>
                    </div>
                    <div class="ipadOnly-view">
                        <p><strong><span class="big"><span><b>{{'&#8358;'}}</b></span></span><span
                                      class="bigger">1,499.99</span></strong>/mo</p>
                    </div>
                    <button mat-stroked-button
                            (click)="goto('/signup')"
                            color="primary"
                            class="se-button se-button--stroke">
                        START FREE TRIAL
                    </button>
                </div>
                <div class="price-top col-md-3 text-center">
                    <img class="step-img step-img-ipad img-fluid"
                         src="assets/plan2.png">
                    <h5>PREMIUM PLAN</h5>
                    <div class="price-figures">
                        <ng-container *ngIf="discountedPrice !== '2,999.99' else discounted">
                            <div class="striked-price strike">
                                <sup><span class="big">
                                        <span><b>{{'&#8358;'}}</b></span></span></sup><span>2,999.99</span>
                                <sup>/mo</sup>
                            </div>
                            <div class="discounted-price">
                                <strong>
                                    <sup>
                                        <span class="big">
                                            <span><b>{{'&#8358;'}}</b></span>
                                        </span>
                                    </sup>
                                    <span class="price">{{discountedPrice}}</span>
                                </strong>
                                <sup>/mo</sup>
                            </div>
                        </ng-container>
                        <ng-template #discounted>
                            <div class="discounted-price">
                                <strong>
                                    <sup>
                                        <span class="big">
                                            <span><b>{{'&#8358;'}}</b></span>
                                        </span>
                                    </sup>
                                    <span class="price">{{discountedPrice}}</span>
                                </strong>
                                <sup>/mo</sup>
                            </div>
                        </ng-template>
                    </div>
                    <button mat-flat-button
                            (click)="goto('/signup')"
                            color="primary"
                            class="se-button mt-3 mb-4">
                        GO PREMIUM
                    </button>
                </div>
            </div>
            <!-- Store Front Pricing -->
            <div class="row container-fluid">
                <div class="price-line col-md-2">
                    <p class="text-secondary">STORE FRONT</p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Online Store</h5>
                    <p>Fully customisable secure e-commerce website</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3">
                    <i class="fa fa-check"></i>
                </div>
            </div>

            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Sales Channels</h5>
                    <p>Instagram, WhatsApp, EasyDeals</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    All channels on basic plan plus <br> Google shopping
                </div>
            </div>

            <!-- Store Front Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Free SellEasy Subdomain</h5>
                    <p>All created stores have SellEasy subdomains that are accessible immediately E.g;
                        www.mystore.selleasy.ng</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Front Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Free Custom Domain</h5>
                    <p>Get a free ".com.ng" domain or connect a domain you already own to your SellEasy hosted ecommerce
                        website. E.g
                        www.mystore.com.ng</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Front Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Mobile ready Responsive Website</h5>
                    <p>Deliver user-friendly shopping experience across devices</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Front Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>1-page Check Out</h5>
                    <p>Simple single-page checkout process. Avoid abandoned checkouts when your customers checkout with
                        a
                        few clicks.</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Front Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Secure Website, free SSL</h5>
                    <p>Your security is a top priority, SellEasy ensure your website is secure with industry standard
                        SSL
                        certificate out-of-the-box</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Front Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Customer Account Management</h5>
                    <p>Your Customers can create accounts to manage orders, shopping cart, wishlist, compare items
                        across
                        devices and experience faster checkout experience</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Front Pricing -->
            <div class="row container-fluid">
                <div class="price-line col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Customer Order Tracking</h5>
                    <p>Your Customers can track orders right from pick-up / drop-off to delivery</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary">STORE MANAGEMENT</p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Website & Sales Metrics</h5>
                    <p>Simple dashboard to track website visits, orders and payments</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Product Listings</h5>
                </div>
                <div class="price-line col-md-3 text-center">
                    <p>50</p>
                </div>
                <div class="price-body col-md-3 text-center">
                    <p>Unlimited</p>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Inventory Management - Product metrics and reporting</h5>
                    <p> From product listings to product management and reporting, SellEasy's intelligent Inventory
                        Management System manages and monitors your inventory, whilst improving sales</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Order Management - Order metrics, Intelligent reporting</h5>
                    <p> Simplified order fulfilment system tailored to suit your store</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Integrated Payment</h5>
                    <p>Accept payments via bank transfer, USSD, credit / debit cards, pay on delivery, etc.
                        Customer payments are processed and deposited directly to your account</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Integrated Delivery</h5>
                    <p>No more delivery hassle! Drop off your products with our delivery partner (in your
                        LGA) or use the pickup option (orders can be picked up from your store) and we will deliver to
                        your
                        Customers</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Automatic Scaling</h5>
                    <p>Never worry about big sales event crashing your website. Your website would automatically scale
                        to
                        support your website traffic and sales volume</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Integrated messaging (Email and Chat / WhatsApp)</h5>
                    <p>Chat and respond to email inquiries from anywhere via your phone or laptop</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Sales Channel</h5>
                    <p>Social Commerce - Share products directly on WhatsApp</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Discounts & Coupons</h5>
                    <p>Offer store wide, product or order specific discounts to your Customers</p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="price-body col-md-3 text-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Store Management Pricing -->
            <div class="row container-fluid">
                <div class="price-line col-md-2 text-left">
                    <p class="text-secondary"></p>
                </div>
                <div class="price-line col-md-4 text-left">
                    <h5>Transaction Charge</h5>
                    <p>
                        This helps us provide secure payments, process your orders and keep the platform running. It can
                        be charged to your Customers
                    </p>
                </div>
                <div class="col-md-3 price-line div-center">
                    <div class="price-line-font">
                        <p style="text-align: center;"><span>Local Transactions:</span> <span> 2.5%</span> <br>Capped at {{'&#8358;'}}2,500</p>
                        <p style="text-align: center;"><span>International Transactions:</span> <span> 4.5%</span></p>
                    </div>
                </div>
                <div class="price-body col-md-3 text-center">
                    <div class="price-line-font">
                        <p style="text-align: center;"><span>Local Transactions:</span> <span> 2.0%</span> <br>Capped at {{'&#8358;'}}2,500</p>
                        <p style="text-align: center;"><span>International Transactions:</span> <span> 4.0%</span></p>
                    </div>
                </div>
            </div>
            <!-- Support Pricing -->
            <div class="row container-fluid">
                <div class="col-md-2 text-left">
                    <p class="text-secondary">SUPPORT</p>
                </div>
                <div class="col-md-4 text-left">
                    <h5>Customer Support</h5>
                    <p>SellEasy customer service team is available to support and answer your enquiries</p>
                </div>
                <div class="col-md-3 price-mid-bottom div-center">
                    <i class="fa fa-check"></i>
                </div>
                <div class="col-md-3 price-bottom div-center">
                    <i class="fa fa-check"></i>
                </div>
            </div>
            <!-- Pricing Bottom -->
            <div class="row pricing-close container-fluid">
                <div class="col-md-6 text-left">
                </div>
                <div class="col-md-3 text-center">
                    <button mat-stroked-button
                            (click)="goto('/signup')"
                            color="primary"
                            class="se-button se-button--stroke">
                        START FREE TRIAL
                    </button>
                </div>
                <div class="col-md-3 text-center">
                    <button mat-flat-button
                            (click)="goto('/signup')"
                            color="primary"
                            class="se-button">
                        GO PREMIUM
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!---Mobile Pricing-->
<div sec
     id="pricingSection"
     *ngIf="isMobile"
     pricing-mobile
     class="container-fluid mobileOnly-view"
     style="overflow-x: hidden;"><a name="pricing-mobile"></a>
    <div class="row jumbotron-pricing text-center">
        <div class="col-12">
            <h2>Pricing</h2>
        </div>
        <div>
            <p class="title-support-text">Transparent and straightforward pricing. You can choose a basic plan
                or a
                premium plan, You get the best value for the plan you choose.</p>
        </div>
        <mat-tab-group (selectedTabChange)="getSelectedTab($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="pricing-tab-label">
                        <span>BASIC PLAN</span>
                        <div>
                            <strong><sup><span class="big"><span><b>{{'&#8358;'}}</b></span></span></sup><span
                                      class="bigger">1,499.99</span></strong><sup>/mo</sup>
                        </div>
                    </div>
                </ng-template>

                <div id="left-tab"
                     class="tabcontent">
                    <!-- Pricing Top -->
                    <div class="row price-div-spacing">
                        <div class="col-5">
                            <img class="price-img"
                                 src="assets/plan1.png">
                        </div>
                        <div class="col-7"
                             style="padding-top: .5rem;">
                            <button mat-stroked-button
                                    (click)="goto('/signup')"
                                    color="primary"
                                    class="se-button se-button--stroke">
                                START FREE TRIAL
                            </button>
                        </div>
                    </div>
                    <hr>
                    <!--Store Front Pricing-->
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <p class="text-secondary">STORE FRONT</p>
                            <h5>Online Store</h5>
                            <p>Fully customisable secure e-commerce website</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 8rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Sales Channels</h5>
                            <p>Instagram, WhatsApp, EasyDeals</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Free SellEasy Subdomain</h5>
                            <p>All created stores have SellEasy subdomains that are accessible immediately E.g;
                                www.mystore.selleasy.ng</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Free Custom Domain</h5>
                            <p>Get a free ".com.ng" domain or connect a domain you already own to your SellEasy hosted
                                ecommerce
                                website. E.g
                                www.mystore.com.ng</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 3rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Mobile ready Responsive Website</h5>
                            <p>Deliver user-friendly shopping experience across devices</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>1-page Check Out</h5>
                            <p>Simple single-page checkout process. Avoid abandoned checkouts when your customers
                                checkout with
                                a few clicks.</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Secure Website, free SSL</h5>
                            <p>Your security is a top priority, SellEasy ensure your website is secure with industry
                                standard
                                SSL certificate out-of-the-box</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Customer Account Management</h5>
                            <p>Your Customers can create accounts to manage orders, shopping cart, wishlist, compare
                                items
                                across devices and experience faster checkout experience</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 6rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Customer Order Tracking</h5>
                            <p>Your Customers can track orders right from drop-off to delivery</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 3rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <hr>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <p class="text-secondary">STORE MANAGEMENT</p>
                            <h5>Website & Sales Metrics</h5>
                            <p>Simple dashboard to track website visits, orders and payments</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 8rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Product Listings</h5>
                        </div>
                        <div class="col-2 text-right"
                             style="font-size: 15px; padding-left: 3.2rem;">
                            <p>50</p>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Inventory Management - Product metric and reporting</h5>
                            <p> From product listings to product management and reporting, SellEasy's intelligent
                                Inventory
                                Management System manages and monitors your inventory, whilst improving sales</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 8rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Order Management - Order metrics, Intelligent reporting</h5>
                            <p> Simplified order fulfilment system tailored to suit your store</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 4rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Integrated Payment</h5>
                            <p>Accept payments via bank transfer, USSD, credit / debit cards, pay on delivery,
                                etc. Customer payments are
                                processed and deposited directly to your account</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 7rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Integrated Delivery</h5>
                            <p>No more delivery hassle! Drop off your products with our delivery partner (in your
                                LGA) or use the pickup option (orders can be picked up from your store) and we will
                                deliver to your
                                Customers</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 6rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Automatic Scaling</h5>
                            <p>Never worry about big sales event crashing your website. Your website would automatically
                                scale
                                to support your website traffic and sales volume</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 6rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Integrated messaging (Email and Chat)</h5>
                            <p>Chat and respond to email inquiries from anywhere via your phone or laptop</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Sales Channel</h5>
                            <p>Social Commerce - Share products directly on WhatsApp</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Discounts & Coupons</h5>
                            <p>Offer store wide, product or order specific discounts to your Customers</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Transaction Charge</h5>
                            <p>This helps us provide secure payments, process your orders and keep the platform running.
                            </p>
                            <div class="price-line-font">
                                <p><span>Local Transactions:</span> <span> 2.5%</span> <br>Capped at {{'&#8358;'}}2,500</p>
                                <p><span>International Transactions:</span> <span> 4.5%</span></p>
                            </div>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <hr>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <p class="text-secondary">SUPPORT</p>
                            <h5>Customer Support</h5>
                            <p>SellEasy customer service team is available to support and answer your enquiries</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 7rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="pricing-tab-label">
                        <span>PREMIUM PLAN</span>
                        <ng-container *ngIf="discountedPrice !== '2,999.99' else discounted">
                            <div class="striked-price strike mt-2">
                                <span>2,999.99</span>
                            </div>
                            <div class="discounted-price">
                                <strong>
                                    <sup>
                                        <span class="big">
                                            <span><b>{{'&#8358;'}}</b></span>
                                        </span>
                                    </sup>
                                    <span class="price">{{discountedPrice}}</span>
                                </strong>
                                <sup>/mo</sup>
                            </div>
                        </ng-container>
                        <ng-template #discounted>
                            <div class="discounted-price">
                                <strong>
                                    <sup>
                                        <span class="big">
                                            <span><b>{{'&#8358;'}}</b></span>
                                        </span>
                                    </sup>
                                    <span class="price">{{discountedPrice}}</span>
                                </strong>
                                <sup>/mo</sup>
                            </div>
                        </ng-template>
                    </div>
                </ng-template>
                <div id="right-tab"
                     class="tabcontent">
                    <!-- Pricing Top -->
                    <div class="price-div-spacing text-left">
                        <div class="price-radio-buttons">
                            <mat-radio-group color="primary"
                                             aria-label="Select an option"
                                             [(ngModel)]="discountedPrice">
                                <mat-radio-button value="2,999.99">Pay Monthly</mat-radio-button>
                                <mat-radio-button value="2,849.99">Pay Yearly <span>save <b>5%</b></span>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <hr>
                    <div class="row price-div-spacing">
                        <div class="col-5">
                            <img class="price-img"
                                 src="assets/plan2.png">
                        </div>
                        <div class="col-7"
                             style="padding-top: .5rem;">
                            <button mat-stroked-button
                                    (click)="goto('/signup')"
                                    color="primary"
                                    class="se-button se-button--stroke">
                                GO PREMIUM
                            </button>
                        </div>
                    </div>
                    <hr>
                    <!--Store Front Pricing-->
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <p class="text-secondary">STORE FRONT</p>
                            <h5>Online Store</h5>
                            <p>Fully customisable secure e-commerce website</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 8rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Sales Channels</h5>
                            <p>All channels on basic plan plus <br> Google shopping</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Free SellEasy Subdomain</h5>
                            <p>All created stores have SellEasy subdomains that are accessible immediately E.g;
                                www.mystore.selleasy.ng</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Free Custom Domain</h5>
                            <p>Get a free ".com.ng" domain or connect a domain you already own to your SellEasy hosted
                                ecommerce
                                website. E.g
                                www.mystore.com.ng</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Mobile ready Responsive Website</h5>
                            <p>Deliver user-friendly shopping experience across devices</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>1-page Check Out</h5>
                            <p>Simple single-page checkout process. Avoid abandoned checkouts when your customers
                                checkout with
                                a few clicks.</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Secure Website, free SSL</h5>
                            <p>Your security is a top priority, SellEasy ensure your website is secure with industry
                                standard
                                SSL certificate out-of-the-box</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Customer Account Management</h5>
                            <p>Your Customers can create accounts to manage orders, shopping cart, wishlist, compare
                                items
                                across devices and experience faster checkout experience</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 6rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Customer Order Tracking</h5>
                            <p>Your Customers can track orders right from drop-off to delivery</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 3rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <hr>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <p class="text-secondary">STORE MANAGEMENT</p>
                            <h5>Website & Sales Metrics</h5>
                            <p>Simple dashboard to track website visits, orders and payments</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top:8rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Product Listings</h5>
                        </div>
                        <div class="col-2 text-right"
                             style="font-size: 15px; margin-left: -1.5rem;">
                            <p>Unlimited</p>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Inventory Management - Product metric and reporting</h5>
                            <p> From product listings to product management and reporting, SellEasy's intelligent
                                Inventory
                                Management System manages and monitors your inventory, whilst improving sales</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 8rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Order Management - Order metrics, Intelligent reporting</h5>
                            <p> Simplified order fulfilment system tailored to suit your store</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 4rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Integrated Payment</h5>
                            <p>Accept payments via bank transfer, USSD, credit / debit cards, pay on delivery,
                                etc. Customer payments are processed and deposited directly to your account</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 7rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Integrated Delivery</h5>
                            <p>No more delivery hassle! Drop off your products with our delivery partner (in your
                                LGA) or use the pickup option (orders can be picked up from your store) and we will
                                deliver to your Customers</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 6rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Automatic Scaling</h5>
                            <p>Never worry about big sales event crashing your website. Your website would automatically
                                scale
                                to support your website traffic and sales volume</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 6rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Integrated messaging (Email and Chat)</h5>
                            <p>Chat and respond to email inquiries from anywhere via your phone or laptop</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Sales Channel</h5>
                            <p>Social Commerce - Share products directly on WhatsApp</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Discounts & Coupons</h5>
                            <p>Offer store wide, product or order specific discounts to your Customers</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <h5>Transaction Charge</h5>
                            <p>This helps us provide secure payments, process your orders and keep the platform running
                            </p>
                            <div class="price-line-font">
                                <p><span>Local Transactions:</span> <span> 2.0%</span> <br>Capped at {{'&#8358;'}}2,500</p>
                                <p><span>International Transactions:</span> <span> 4.0%</span></p>
                            </div>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 5rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                    <hr>
                    <div class="row price-div">
                        <div class="col-10 text-left">
                            <p class="text-secondary">SUPPORT</p>
                            <h5>Customer Support</h5>
                            <p>SellEasy customer service team is available to support and answer your enquiries</p>
                        </div>
                        <div class="col-2 text-right"
                             style="padding-top: 7rem;">
                            <i class="fa fa-check"></i>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>


<!-- Support -->
<div sec
     support>
    <div class="container"
         id="technicalSupportSection"
         style="overflow-x: hidden;">
        <div class="row support align-items-center">
            <div class="col-lg-6">
                <h2>All the Support you need</h2>
                <p class="title-support-text text-center-mobile-only">For more questions please click the <b>chat with
                        us</b> button on the
                    bottom right of this page to start a conversation with us. If you prefer to reach out to us
                    directly, you can call
                    our technical support team on <b>+234 913 455 5555</b>
                </p>
                <button [hidden]=true
                        mat-stroked-button
                        (click)="goto('/help-center#faq')"
                        color="primary"
                        class="se-button se-button--stroke mt-3 mb-4">
                    FREQUENTLY ASKED QUESTIONS
                </button>
                <mat-form-field [hidden]="true"
                                appearance="outline"
                                class="se-input-field">
                    <input type="text"
                           style="border: none;"
                           matInput>
                    <div matSuffix>
                        <!-- Put the search button here -->
                    </div>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="container-fluid"></div>
</div>
