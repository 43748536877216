import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-product-modal',
  templateUrl: './select-product-modal.component.html',
  styleUrls: ['./select-product-modal.component.scss'],
})
export class SelectProductModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe((_) => {
      this.data.cta();
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
