import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { map, filter, compose } from 'lodash/fp';
import { defer, isEqual } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

/**
 * Data with nested structure.
 * Each node has a name and an optional list of children.
 */
export class ItemNode {
  children?: ItemNode[];
  name: string;
  id?: string;
  variationId?: string;
  image?: string;
  price?: number;
}

/** Flat to-do item node with expandable and level information */
export class ItemFlatNode {
  name: string;
  level: number;
  expandable: boolean;
  id?: string;
  variationId?: string;
  image?: string;
  hasChildren?: boolean;
  price?: number;
}

const TREE_DATA: ItemNode[] = [
  {
    name: 'Parent 1',
    children: [{ name: 'Child 2' }, { name: 'Child 3' }, { name: 'Child 4' }],
  },
  {
    name: 'Parent 2',
    children: [
      {
        name: 'Child 1',
      },
      {
        name: 'Child 2',
      },
    ],
  },
];

const prods = map(({ id, variationId, name, image, price, hasChildren }) => ({
  id,
  variationId,
  name,
  image,
  price,
  hasChildren,
}));

const noNullIds = filter((v: any) => !!v.id);

const filterInSelectedProds = (selection: any[], products: any[]) =>
  products.filter((p) =>
    selection.find(
      (s) => s.id === p.id && isEqual(s.variationId, p.variationId),
    ),
  );

@Component({
  selector: 'app-select-products',
  templateUrl: './select-products.component.html',
  styleUrls: ['./select-products.component.scss'],
})
export class SelectProductsComponent implements AfterViewInit, OnDestroy {
  @Input() set products(val) {
    this._products = val;
    this.data.next(this._products);
  }
  @Input() deselectAll: Observable<boolean>;
  @Input() discountApplied;
  @Input() selectedProductsCtrl = new FormControl();
  @Input() set preSelectedProds(prods) {
    if (this.products?.length && prods?.length) {
      defer(() => {
        const sel = filterInSelectedProds(prods, this.treeControl.dataNodes);
        this.preSelectNodes(sel);
      });
    }
  }
  data = new BehaviorSubject([...TREE_DATA]);
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();
  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();
  selectedParent: ItemFlatNode | null = null;
  treeControl: FlatTreeControl<ItemFlatNode>;
  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;
  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;
  checklistSelection = new SelectionModel<ItemFlatNode>(true);
  _products;
  _preSelectedProds;
  isComponentActive = true;

  get products() {
    return this._products || [...TREE_DATA];
  }

  get preSelectedProds() {
    return this._preSelectedProds;
  }

  constructor() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(
      this.getLevel,
      this.isExpandable,
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener,
    );
    this.data.pipe(takeWhile(() => this.isComponentActive)).subscribe((v) => {
      this.dataSource.data = v;
    });
  }

  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  getChildren = (node: ItemNode): ItemNode[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: ItemFlatNode) => _nodeData.name === '';

  calcPercentage(price: number, discount: string | null): number {
    if (!discount) {
      return;
    }

    return discount.includes('%')
      ? Number(
          (price - price * (Number(discount.split('%')[0]) / 100)).toFixed(0),
        )
      : Number((price - Number(discount.split('₦')[1])).toFixed(0));
  }

  ngAfterViewInit() {
    this.treeControl.expandAll();
    this.checklistSelection.changed
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe(({ source }) => {
        const selected = compose(
          noNullIds,
          prods,
        )(source.selected).filter(
          (v) => this.calcPercentage(v.price, this.discountApplied) > 0,
        );
        this.selectedProductsCtrl.setValue(
          this.discountApplied ? selected : source.selected,
        );
      });

    this.deselectAll
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe((_) => {
        this.checklistSelection.deselect(...this.treeControl.dataNodes);
      });
  }

  preSelectNodes(sel) {
    // Cater for an edgecase where childless nodes aren't preselected
    sel.forEach((v) => {
      if (!v.variationId && !v.hasChildren) {
        this.todoItemSelectionToggle(v);
      } else if (v.variationId && !v.hasChildren) {
        this.todoLeafItemSelectionToggle(v);
      }
    });
  }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.name === node.name
        ? existingNode
        : new ItemFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.id = node.id;
    flatNode.image = node.image;
    flatNode.price = node.price;
    flatNode.variationId = node.variationId;
    flatNode.expandable = !!node.children?.length;
    flatNode.hasChildren = !!(node?.children?.length && node?.children[0]?.id);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child),
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ItemFlatNode): void {
    let parent: ItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  ngOnDestroy() {
    this.isComponentActive = false;
  }
}
