import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormGroup } from '@angular/forms';
import {
  TERMS_OF_USE,
  PRIVACY_POLICY,
  REFUND_POLICY,
} from '../shared/models/website';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { StateFacade } from '../shared/services/state.facade';
import {
  BusinessConfiguration,
  Business,
} from '../shared/models/template-config';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import { SharedDataService } from '../shared/services/shared-data-service';
import { environment } from 'src/environments/environment';
import { defer } from 'lodash';
import { filter, compose, uniq } from 'lodash/fp';
import {
  addErrorClass,
  removeErrorClass,
  toLower,
  truthyNavs,
} from '../shared/utils/validations';
import { FormErrorService } from '../shared/services/form-error.service';
import { ProductService } from '../shared/services/product-service';

@Component({
  selector: 'app-website-customize',
  templateUrl: './website-customize.component.html',
  styleUrls: ['./website-customize.component.scss'],
})
export class WebsiteCustomizeComponent implements OnInit, OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  showTermsOfUse = true;
  showPrivacyPolicy = true;
  showRefundPolicy = true;
  readonly TERMS_OF_USE = TERMS_OF_USE;
  readonly PRIVACY_POLICY = PRIVACY_POLICY;
  readonly REFUND_POLICY = REFUND_POLICY;
  templates: BusinessConfiguration[];
  storeConfig: Business;
  selectedTemplate: string;
  eventSubscription: any;
  url: string;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() websiteForm: FormGroup;
  @Input() selectedTab;

  isMobile: boolean;
  navErrors = {};

  get hasNavError() {
    return !!filter((v) => !!v)(Object.values(this.navErrors)).length;
  }

  hasDuplicates = (index, ref) => {
    const clean = compose(uniq, toLower, truthyNavs)(this.navs);
    clean.length && clean.length !== truthyNavs(this.navs).length
      ? ((this.navErrors[index] = true), addErrorClass(ref))
      : ((this.navErrors[index] = false), removeErrorClass(ref));
  };

  constructor(
    private dialog: MatDialog,
    private stateFacade: StateFacade,
    private businessProfileService: ProfileBusinessService,
    private snackbar: MatSnackBar,
    private sharedDataService: SharedDataService,
    private formErr: FormErrorService,
    private productsService: ProductService,
  ) {}

  ngOnInit(): void {
    this.storeConfig = {} as Business;
    this.termsOfUse.setValue(this.TERMS_OF_USE);
    this.privacyPolicy.setValue(this.PRIVACY_POLICY);
    this.refundPolicy.setValue(this.REFUND_POLICY);
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
    });
    this.eventSubscription = this.sharedDataService.template.subscribe(
      (data: any) => {
        this.getTemplateConfig(data?.name);
        this.selectedTemplate = data?.name;
      },
    );
    this.productsService.concatProducts().subscribe((prods) => {
      this.websiteForm
        .get('websiteDescription')
        .setValue(`We sell ${prods}` ?? null);
    });
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  getTemplateConfig(tm?: string) {
    this.businessProfileService
      .getBusinessProfile()
      .subscribe((result: any) => {
        this.storeConfig = result;
        this.templates = result?.configuration;
        const tmp = this.templates.find((t) => t.isSelected);
        this.getSelectedTemplate(tm ?? tmp.template);
        this.websiteForm
          .get('websiteTitle')
          .setValue(
            typeof result.websiteName === 'string'
              ? `${result.websiteName[0].toUpperCase()}${result.websiteName.slice(
                  1,
                )}`
              : null,
          );
      });
  }

  getSelectedTemplate(tm?: string) {
    let templateConfig = {} as BusinessConfiguration;
    if (!tm) {
      templateConfig = this.templates.find((t) => t.isSelected);
    } else {
      templateConfig = this.templates.find(
        (t) => t.template.toLowerCase() === tm.toLowerCase(),
      );
    }

    this.businessEmail.setValue(templateConfig?.storeMail ?? '');
    this.businessPhoneNumber.setValue(
      this.storeConfig?.businessPhoneNumber ?? '',
    );
    this.businessAddress.setValue(this.storeConfig?.businessAddress ?? '');

    const fbookPage =
      templateConfig?.facebookPage ??
      this.templates?.find(
        (t) => t.facebookPage !== null || t.facebookPage !== '',
      ).facebookPage;

    this.facebookPage.setValue(
      fbookPage ? fbookPage.substring(fbookPage.lastIndexOf('/') + 1) : '',
    );

    const instagramPage =
      templateConfig?.instagramPage ??
      this.templates?.find(
        (t) => t.instagramPage !== null || t.instagramPage !== '',
      ).instagramPage;

    this.instagramPage.setValue(
      instagramPage
        ? instagramPage.substring(instagramPage.lastIndexOf('/') + 1)
        : '',
    );

    const twitterPage =
      templateConfig?.twitterPage ??
      templateConfig?.twitterPage ??
      this.templates?.find(
        (t) => t.twitterPage !== null || t.twitterPage !== '',
      ).twitterPage;

    this.twitterPage.setValue(
      twitterPage
        ? twitterPage.substring(twitterPage.lastIndexOf('/') + 1)
        : '',
    );

    this.whatsappNum.setValue(
      templateConfig?.whatsappNum ??
        templateConfig?.whatsappNum ??
        this.templates?.find(
          (t) => t.whatsappNum !== null || t.whatsappNum !== '',
        ).whatsappNum,
    );

    const navItems = [...(templateConfig?.navItems ?? [])];
    if (navItems.length === 0) {
      // Copy from template with nav
      const navs = this.templates.find((t) => t.navItems?.length > 0)?.navItems;
      // Copy contact details from populated
      this.populateNav(navs);
    } else {
      this.navs.splice(0, this.navs.length);
      this.populateNav(navItems);
    }
  }

  populateNav(navItems: any[]) {
    navItems?.forEach((item, index) => {
      if (typeof item !== 'string') {
        let subNavs = Object.values(navItems[index]).map((v) => v);
        subNavs = subNavs.concat.apply([], subNavs);

        this.navs.push({
          name: Object.keys(navItems[index])[0],
          subNavs,
        });
      } else {
        this.navs.push({
          name: item,
          subNavs: [],
        });
      }
    });
  }

  openModal(content) {
    this.dialog.open(ConfirmationModalComponent, {
      width: this.isMobile ? '100vw' : '40%',
      data: content,
    });
  }

  updateShowTermsOfUse(e) {
    if (!e.checked) {
      this.openModal({
        header: 'Are you sure you want to remove terms of use?',
        body: `By default, this terms of use applies to your website. 
    Removing it means you will have <b>no terms of use</b> applied to your website.`,
        ctas: {
          back: () => {
            this.showTermsOfUse = true;
            e.source.toggle();
          },
          act: () => {
            this.showTermsOfUse = e.checked;
            this.termsOfUse.setValue(null);
          },
          textLeft: 'NO',
          textRight: 'YES',
        },
      });
    } else {
      this.showTermsOfUse = e.checked;
      this.termsOfUse.setValue(TERMS_OF_USE);
    }
  }

  updateShowPrivacyPolicy(e) {
    if (!e.checked) {
      this.openModal({
        header: 'Are you sure you want to remove privacy policy?',
        body: `By default, this privacy policy applies to your website. Removing it means you will have <b>no privacy policy</b> applied to your website.`,
        ctas: {
          back: () => {
            this.showPrivacyPolicy = true;
            e.source.toggle();
          },
          act: () => {
            this.showPrivacyPolicy = e.checked;
            this.privacyPolicy.setValue(null);
          },
          textLeft: 'NO',
          textRight: 'YES',
        },
      });
    } else {
      this.showPrivacyPolicy = e.checked;
      this.privacyPolicy.setValue(TERMS_OF_USE);
    }
  }

  updateShowRefundPolicy(e) {
    if (!e.checked) {
      this.openModal({
        header: 'Are you sure you want to remove refund policy?',
        body: `By default, this refund policy applies to your website. Removing it means you will have <b>no refund policy</b> applied to your website.`,
        ctas: {
          back: () => {
            this.showRefundPolicy = true;
            e.source.toggle();
          },
          act: () => {
            this.showRefundPolicy = e.checked;
            this.refundPolicy.setValue(null);
          },
          textLeft: 'NO',
          textRight: 'YES',
        },
      });
    } else {
      this.showRefundPolicy = e.checked;
      this.refundPolicy.setValue(TERMS_OF_USE);
    }
  }

  get navs(): { name: string; subNavs: any[] }[] {
    return this.websiteForm && this.websiteForm.get('navItems').value;
  }

  get businessEmail() {
    return this.websiteForm && this.websiteForm.get('businessEmail');
  }

  get businessPhoneNumber() {
    return this.websiteForm && this.websiteForm.get('businessPhoneNumber');
  }

  get businessAddress() {
    return this.websiteForm && this.websiteForm.get('businessAddress');
  }

  get businessAddress2() {
    return this.websiteForm && this.websiteForm.get('businessAddress2');
  }

  get facebookPage() {
    return this.websiteForm.get('facebookPage');
  }

  get instagramPage() {
    return this.websiteForm.get('instagramPage');
  }

  get whatsappNum() {
    return this.websiteForm.get('whatsappNum');
  }

  get twitterPage() {
    return this.websiteForm.get('twitterPage');
  }

  get termsOfUse() {
    return this.websiteForm.get('termsOfUse');
  }

  get privacyPolicy() {
    return this.websiteForm.get('privacyPolicy');
  }

  get refundPolicy() {
    return this.websiteForm.get('refundPolicy');
  }

  removeValue(subNav, nav) {
    const index = this.navs.indexOf(nav);
    this.navs[index].subNavs = this.navs[index].subNavs.filter(
      (v) => v !== subNav,
    );
  }

  addValue(e, nav?) {
    const input = e.input;
    const value = (e.value && e.value.trim()) || '';

    if (value) {
      nav.subNavs.push(value);
    }

    if (input) {
      input.value = '';
    }
  }

  addNav() {
    this.navs.push({
      name: null,
      subNavs: [],
    });
  }

  deleteNav(nav) {
    const index = this.navs.indexOf(nav);
    this.navs.splice(index, 1);
  }

  submitForm(selected?: boolean) {
    this.formErr.validate(this.websiteForm);
    if (this.hasNavError) {
      const el: any = document.querySelector('#navItemsId');
      el.scrollIntoView({
        behavior: 'smooth',
      });
    }

    if (this.websiteForm.invalid || this.hasNavError) {
      return;
    }

    this.getTemplateConfig();
    const value = {
      ...this.websiteForm.value,
      termsOfUse: this.termsOfUse.value,
      privacyPolicy: this.privacyPolicy.value,
      refundPolicy: this.refundPolicy.value,
    };
    this.selectedTab.value = 1;

    const templateConfig = {} as BusinessConfiguration;
    templateConfig.storeMail = value.businessEmail;
    templateConfig.facebookPage = !value.facebookPage
      ? ''
      : `https://facebook.com/${value.facebookPage.replace(/[@]/, '')}`;
    templateConfig.instagramPage = !value.instagramPage
      ? ''
      : `https://instagram.com/${value.instagramPage.replace(/[@]/, '')}`;
    templateConfig.twitterPage = !value.twitterPage
      ? ''
      : `https://twitter.com/${value.twitterPage.replace(/[@]/, '')}`;

    templateConfig.whatsappNum = value.whatsappNum;
    if (value.brandColor !== null) {
      templateConfig.brandColour = value.brandColor?.code;
    }
    this.storeConfig.businessPhoneNumber = value.businessPhoneNumber;
    this.storeConfig.businessAddress = value.businessAddress;
    templateConfig.template = value.template.name;
    this.storeConfig.selectedTemplate = value.template.name;
    this.storeConfig.seo = {
      websiteTitle: value.websiteTitle,
      websiteDescription: value.websiteDescription,
    };

    // format navItems
    const navs = [];
    value.navItems
      .filter((nav) => !!nav?.name?.trim())
      .forEach((item) => {
        if (item.subNavs.length === 0) {
          navs.push(item.name);
        } else {
          const navSubnav = {
            [item.name]: item.subNavs,
          };
          navs.push(navSubnav);
        }
      });

    templateConfig.navItems = navs;
    templateConfig.businessId = this.storeConfig.id;
    templateConfig.brandColour = value.brandColor;
    const tmp = this.templates.find((t) => t.isSelected);
    templateConfig.isSelected =
      selected ?? tmp.template === this.selectedTemplate;

    //get index of selected template
    const index = this.templates.findIndex(
      (i) => i.template === templateConfig.template,
    );

    if (templateConfig.isSelected) {
      this.storeConfig.configuration.map((item) => (item.isSelected = false));
      this.storeConfig.configuration.map(
        (item) => (item.storeMail = templateConfig?.storeMail),
      );
      this.storeConfig.configuration.map(
        (item) => (item.facebookPage = templateConfig?.facebookPage),
      );
      this.storeConfig.configuration.map(
        (item) => (item.instagramPage = templateConfig?.instagramPage),
      );
      this.storeConfig.configuration.map(
        (item) => (item.twitterPage = templateConfig?.twitterPage),
      );
      this.storeConfig.configuration.map(
        (item) => (item.whatsappNum = templateConfig?.whatsappNum),
      );
      this.storeConfig.configuration.map(
        (item) => (item.navItems = templateConfig?.navItems),
      );
    }

    if (index !== -1) {
      // update configuration index
      this.storeConfig.configuration[index].storeMail =
        templateConfig?.storeMail;
      this.storeConfig.configuration[index].facebookPage =
        templateConfig?.facebookPage;
      this.storeConfig.configuration[index].instagramPage =
        templateConfig?.instagramPage;
      this.storeConfig.configuration[index].twitterPage =
        templateConfig?.twitterPage;
      this.storeConfig.configuration[index].whatsappNum =
        templateConfig?.whatsappNum;
      this.storeConfig.configuration[index].brandColour =
        templateConfig?.brandColour;
      this.storeConfig.configuration[index].navItems = templateConfig?.navItems;
      this.storeConfig.configuration[index].template = templateConfig?.template;
      this.storeConfig.configuration[index].businessId = this.storeConfig.id;
      this.storeConfig.configuration[index].isSelected =
        templateConfig?.isSelected;
    } else {
      // add to configuration
      this.storeConfig.configuration.push(templateConfig);
    }

    delete this.storeConfig.id;
    delete this.storeConfig.bankDetails;

    // patch
    this.businessProfileService
      .patchBusinessProfile(this.storeConfig)
      .subscribe(
        (_) => {
          this.snackbar.open(
            `Your update has been saved successfully`,
            'Close',
            {
              panelClass: 'success',
            },
          );
          this.getTemplateConfig(templateConfig.template);
          this.openSiteInNewTab(selected);
        },
        (err) => {
          this.snackbar.open(err.message, 'Close', {
            panelClass: 'error',
          });
        },
      );
  }

  openSiteInNewTab(selected) {
    if (!selected) return;

    this.url = this.storeConfig.customDomainName
      ? `https://${this.storeConfig.customDomainName}`
      : environment.path.replace('$websiteName', this.storeConfig.websiteName);

    defer(() => {
      document.getElementById('__view_site2').click();
    });
  }

  // activate config
  publishSettings() {
    this.submitForm(true);
  }
}
