import { Component, OnInit } from '@angular/core';
import { AccountService } from '../shared/services/account-service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-account-tax',
  templateUrl: './account-tax.component.html',
  styleUrls: ['./account-tax.component.scss'],
})
export class AccountTaxComponent implements OnInit {
  sliderValue: number;
  payVatRate: boolean;
  constructor(
    private accountService: AccountService,
    private snackbar: MatSnackBar,
  ) {}
  options: Array<object>;

  ngOnInit(): void {
    this.sliderValue = 20;
    this.payVatRate = true;
    this.getTaxSettings();
    this.options = [
      {
        description: 'Items are tax inclusive',
        value: true,
        checked: true,
      },
      {
        description: 'Customer pays tax on items sold',
        value: false,
        checked: false,
      },
    ];
  }

  /**
   * @description get checked radio button
   * @param value selected value
   */
  selectedValue(value: any) {
    this.payVatRate = value;
    this.patchDetails();
  }

  /**
   * @description update tax percentage
   * @param value selected value for slider
   */
  selectedSliderValue(value: any) {
    this.sliderValue = value;
    this.patchDetails();
  }

  /**
   * @description get the service charge description
   */
  getTaxSettings() {
    this.accountService.getTaxSettings().subscribe((result) => {
      this.payVatRate =
        result[0].payVatRate === null ? this.payVatRate : result[0].payVatRate;
      this.sliderValue =
        result[1].vatRate === null ? this.sliderValue : result[1].vatRate;
    });
  }

  /**
   * @description update settings - Patch
   */
  patchDetails() {
    const data = {
      vatRate: this.sliderValue,
      payVatRate: this.payVatRate,
    };

    this.accountService.patchDetails(data).subscribe(
      () => {
        this.snackbar.open(
          `Your settings has been successfully updated`,
          'Close',
          {
            panelClass: 'success',
          },
        );
      },
      (err) => {
        this.snackbar.open(err.message, 'Close', {
          panelClass: 'error',
        });
      },
    );
  }
}
