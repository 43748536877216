import { Component, OnInit } from '@angular/core';
import { AccountService } from '../shared/services/account-service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-account-service-charge',
  templateUrl: './account-service-charge.component.html',
  styleUrls: ['./account-service-charge.component.scss'],
})
export class AccountServiceChargeComponent implements OnInit {
  payServiceCharge: boolean;
  constructor(
    private accountService: AccountService,
    private snackbar: MatSnackBar,
  ) {}
  options: Array<object>;

  ngOnInit(): void {
    this.getServiceCharge();
    this.options = [
      {
        description: 'Seller pays the service charge',
        value: true,
        checked: false,
      },
      {
        description: 'Customer pays service charge',
        value: false,
        checked: true,
      },
    ];
  }

  /**
   * @description get checked radio button
   * @param event selected value event
   */
  selectedValue(value: any) {
    this.payServiceCharge = value;
    this.patchDetails();
  }

  /**
   * @description get the service charge description
   */
  getServiceCharge() {
    this.accountService.getServiceChargeSettings().subscribe((result) => {
      if (result === null) {
        this.payServiceCharge = true;
      } else {
        this.payServiceCharge = result;
      }
    });
  }

  /**
   * @description update settings - Patch
   */
  patchDetails() {
    const data = {
      payServiceCharge: this.payServiceCharge,
    };

    this.accountService.patchDetails(data).subscribe(
      () => {
        this.snackbar.open(
          `Your settings has been successfully updated`,
          'Close',
          {
            panelClass: 'success',
          },
        );
      },
      (err) => {
        this.snackbar.open(err.message, 'Close', {
          panelClass: 'error',
        });
      },
    );
  }
}
