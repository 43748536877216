import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AnalyticService {
  /**
   * init mixpanel
   * @param id
   */
  initMixpanel(id): void {
    mixpanel.init(environment.mixpanelToken, { batch_requests: true });
    if (localStorage.getItem('AUTH_TOKEN')) {
      mixpanel.identify(id);
    }
  }

  /**
   * track events
   * @param id
   * @param action
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}
