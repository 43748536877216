import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import imageCompression from 'browser-image-compression';
import { StateFacade } from '../shared/services/state.facade';
import { forEach, get as _get } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Blob as _Blob } from 'blob-polyfill';

@Component({
  selector: 'app-product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss'],
})
export class ProductSliderComponent implements OnInit, AfterViewInit {
  @Input() sliderImages;
  @ViewChild('productSliderImages') sliderImagesContRef: ElementRef;
  sliderImageContRef: HTMLElement;
  @Output() imagePosition = new EventEmitter();
  @Output() deleteList = new EventEmitter();
  isDraggedOver = false;
  readonly maxWidthOrHeight = 800;

  imageSrc: SafeUrl;
  selectedImage: File;
  leftPos = {};
  _position = 0;
  isMobile: boolean;

  get position() {
    return this._position;
  }

  set position(val) {
    this._position = val;
    this.imagePosition.emit(val);
  }

  constructor(
    private sanitize: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private stateFacade: StateFacade,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
    });
  }

  ngAfterViewInit() {
    this.getSliderBox();
    this.buildPositions();
  }

  getSliderBox() {
    this.sliderImageContRef = document.querySelector('#productSliderImage1');
  }

  scrollImagesLeft() {
    this.sliderImagesContRef.nativeElement.style.left = '0px';
  }

  scrollImagesRight() {
    this.getSliderBox();
    const boxWidth = (this.sliderImageContRef.offsetWidth + 20) * 2;
    this.sliderImagesContRef.nativeElement.style.left = `-${boxWidth + 10}px`;
  }

  photoEdit(img) {
    // this.imageEditorService.editImage(img.file);
  }

  displayNone(toolBar) {
    if (!this.isMobile) {
      toolBar.style.display = 'none';
    }
  }

  displayFlex(toolBar) {
    if (!this.isMobile) {
      toolBar.style.display = 'flex';
    }
  }

  sanitizeUrl(url) {
    return this.sanitize.bypassSecurityTrustUrl(url);
  }

  getImagesDragNDrop(file, img) {
    if (!file || !file.type.startsWith('image/')) {
      this.snackbar.open('You should upload only images', 'Close', {
        panelClass: 'error',
      });
      return;
    }

    imageCompression(file, {
      maxSizeMB: 1,
      maxWidthOrHeight: this.maxWidthOrHeight,
      useWebWorker: true,
    }).then(async (image: any) => {
      this.polfillArrayBuffer(image);

      img.file = image;
      img.imageSrc = this.sanitizeUrl(URL.createObjectURL(image));
      const buffer = await image.arrayBuffer();
      img['buffer'] = buffer;
      this.cdRef.detectChanges();
    });
  }

  clearFileInput(e: any) {
    e.target.value = '';
  }

  polfillArrayBuffer(image) {
    const fn = new _Blob().arrayBuffer;

    // Safari and Opera are missing this method on the blob prototype
    if (!image.arrayBuffer) {
      image.arrayBuffer = fn;
    }
  }

  getImage(event, img, replace = false) {
    const files: File[] = event?.target?.files;

    if (replace && img.file === null) {
      this.deleteList.emit(img.imageSrc);
    }

    if (!files || !_get(files, 'length')) {
      return;
    }

    if (files.length === 1) {
      if (!files[0].type.startsWith('image/')) {
        this.snackbar.open('You should upload only images', 'Close', {
          panelClass: 'error',
        });
        return;
      }

      imageCompression(files[0], {
        maxSizeMB: 1,
        maxWidthOrHeight: this.maxWidthOrHeight,
        useWebWorker: true,
      }).then(async (image: any) => {
        this.polfillArrayBuffer(image);

        img.file = image;
        img.imageSrc = this.sanitizeUrl(URL.createObjectURL(image));
        const buffer = await image.arrayBuffer();
        img['buffer'] = buffer;

        this.clearFileInput(event);
        this.cdRef.detectChanges();
      });
    } else if (files.length > 1) {
      forEach(files, (file, index) => {
        if (!file.type.startsWith('image/')) {
          this.snackbar.open('You should upload only images', 'Close', {
            panelClass: 'error',
          });
          return;
        }

        imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: this.maxWidthOrHeight,
          useWebWorker: true,
        }).then(async (image: any) => {
          this.sliderImages[index].file = image;
          this.sliderImages[index].imageSrc = this.sanitizeUrl(
            URL.createObjectURL(image),
          );

          this.polfillArrayBuffer(image);
          const buffer = await image.arrayBuffer();
          this.sliderImages[index]['buffer'] = buffer;

          this.clearFileInput(event);
          this.cdRef.detectChanges();
        });
      });
    }
  }

  dropHandler(ev, img?) {
    ev.preventDefault();
    this.isDraggedOver = false;

    const files = ev.dataTransfer.files;
    const noOfFiles = _get(files, 'length');

    if (noOfFiles === 1) {
      this.getImagesDragNDrop(files[0], img);
    } else if (noOfFiles > 1 && noOfFiles <= 5) {
      forEach(files, (file, index) => {
        this.getImagesDragNDrop(file, this.sliderImages[index]);
      });
    } else if (noOfFiles > 5) {
      this.snackbar.open(`You can't upload more than 5 images`, 'Close', {
        panelClass: 'error',
      });
    }
  }

  dragOverHandler(e) {
    e.preventDefault();
    this.isDraggedOver = true;
  }

  dragLeave(e) {
    e.preventDefault();
    this.isDraggedOver = false;
  }

  coverImage(img) {
    this.sliderImages.forEach((i) => (i.isCoverImage = false));
    img.isCoverImage = true;
  }

  deleteImage(img) {
    const src = img.imageSrc;
    const index = this.sliderImages.indexOf(img);
    this.sliderImages[index].imageSrc = null;
    if (img.file === null) {
      this.deleteList.emit(src);
    }
  }

  onSwipeRight(img) {
    const index = this.sliderImages.indexOf(img);
    this.position = this.leftPos[index - 1] ? index - 1 : index;

    if (index === 0) {
      return;
    }

    this.sliderImagesContRef.nativeElement.style.left =
      this.leftPos[index - 1] || this.leftPos[index];
  }

  onSwipeLeft(img) {
    const index = this.sliderImages.indexOf(img);
    this.position = this.leftPos[index + 1] ? index + 1 : index;

    if (index === this.sliderImages.length) {
      return;
    }

    this.sliderImagesContRef.nativeElement.style.left =
      this.leftPos[index + 1] || this.leftPos[index];
  }

  buildPositions() {
    const imgEl: HTMLElement = this.sliderImageContRef;
    const marginRight = 20;
    const sum = imgEl.offsetWidth + marginRight;

    this.leftPos = {
      '0': '0px',
      '1': `${sum * -1}px`,
      '2': `${sum * -2}px`,
      '3': `${sum * -3}px`,
      '4': `${sum * -4}px`,
    };
  }

  slideImage(pos) {
    this.position = pos;
    const el: HTMLElement = this.sliderImagesContRef.nativeElement;
    el.style.left = this.leftPos[pos];
  }

  freeMemory(event) {
    URL.revokeObjectURL(event.target.src);
  }
}
