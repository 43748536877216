import { Component, OnInit, ViewChild } from '@angular/core';
import { IProduct } from '../shared/models/product';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { StateFacade } from '../shared/services/state.facade';
import { ProductService } from '../shared/services/product-service';
import { DownloadReportService } from '../shared/services/download-report.service';
import { DownloadReportModalComponent } from '../download-report-modal/download-report-modal.component';
import { Router } from '@angular/router';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import { environment } from 'src/environments/environment';
import { capitalizeString, TruncateText } from '../shared/utils/format-text';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { noop } from 'lodash';
import { forkJoin, throwError } from 'rxjs';

@Component({
  selector: 'app-manage-product',
  templateUrl: './manage-product.component.html',
  styleUrls: ['./manage-product.component.scss'],
})
export class ManageProductComponent implements OnInit {
  isMobile: boolean;
  showButton: boolean;
  dataSource = new MatTableDataSource<any>([]);
  selectionModel;
  selectedRows: IProduct[] = [];
  isAllSelected = false;
  displayedColumns: string[] = [
    'select',
    'name',
    'inStock',
    'numberOfPurchase',
    'openOrder',
    'numberOfViews',
  ];
  columnsSelect = [
    { label: 'No. of Views', value: 'numberOfViews' },
    { label: 'No. of Purchases', value: 'numberOfPurchase' },
    { label: 'Open Orders', value: 'openOrder' },
    { label: 'In Stock', value: 'inStock' },
  ];
  columnsToDisplay = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoading = false;
  websiteUrl = '';
  websiteName = '';

  constructor(
    private stateFacade: StateFacade,
    private productService: ProductService,
    private downloadService: DownloadReportService,
    private dialog: MatDialog,
    public route: Router,
    private snackbar: MatSnackBar,
    private profileService: ProfileBusinessService,
  ) {}

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
      this.columnsToDisplay = this.displayedColumns.slice(
        0,
        this.isMobile ? 3 : 6,
      );
    });

    this.getProducts();
    this.getWebsiteUrl();
  }

  get isLoggedIn() {
    return !!localStorage.getItem('AUTH_TOKEN');
  }

  //TODO: create shareable service
  getWebsiteUrl() {
    if (!this.websiteUrl && this.isLoggedIn) {
      this.profileService
        .getBusinessProfile()
        .subscribe(({ customDomainName, websiteName }) => {
          this.websiteName = capitalizeString(websiteName);
          if (customDomainName) {
            this.websiteUrl = `https://${customDomainName}`;
            return;
          }
          this.websiteUrl = environment.path.replace(
            '$websiteName',
            websiteName,
          );
        });
    }
  }

  getProducts() {
    this.isLoading = true;
    this.productService
      .getProducts()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (result: any[]) => {
          this.dataSource.data = result;
          this.isLoading = false;
        },
      });
  }

  onSelectChange({ value }) {
    this.columnsToDisplay.pop();
    this.columnsToDisplay.push(value);
  }

  get hideDownloadButton() {
    return !(
      this.dataSource.data.length &&
      this.selectedRows.length === this.dataSource.data.length
    );
  }

  onSelectionModelInit(model) {
    this.selectionModel = model;
    this.selectionModel.changed.subscribe((e) => {
      this.isAllSelected =
        e.source.selected.length === this.dataSource.data.length;
      this.selectedRows = this.isAllSelected ? e.source.selected : [];
    });
  }

  onRowSelected(rows) {
    this.selectedRows = rows;
  }

  openReportsModal() {
    this.dialog.open(DownloadReportModalComponent, {
      width: this.isMobile ? '100vw' : '30%',
      data: { products: [...this.selectionModel.selected] },
    });
  }

  editSelectedProduct() {
    this.route.navigate(['add-product', this.selectedRows[0].id]);
  }

  shareOnSocial(medium: string) {
    switch (medium) {
      case 'whatsapp': {
        //TODO: add image to share
        this.shareOnWhatsapp();
      }
      case 'instagram': {
        //TODO: implement share
      }
      case 'facebook': {
        //TODO: implement share
      }
      case 'twitter': {
        //TODO: implement share
      }
      case 'email': {
        //TODO: implement share
      }
      default:
        return;
    }
  }

  shareOnWhatsapp() {
    const productUrl = `${this.websiteUrl}/%23/product/${this.selectedRows[0].id}`;
    const body = `Checkout this product from ${this.websiteName}
    %0a%0a${this.selectedRows[0].name}
    ${productUrl}
    %0a%0aBuilt using SellEasy
    %0ahttps://selleasy.co`;

    const url = `https://wa.me/?text=${body}`;
    window.open(url, '_blank');
  }

  deleteSelectedProduct() {
    this.openModal({
      header: 'Delete Product',
      body: `Are you sure you want to delete selected product(s)?`,
      ctas: {
        back: noop,
        act: this.doDelete.bind(this),
        textLeft: 'NO',
        textRight: 'YES',
      },
    });
  }

  duplicateProduct() {
    const dupeProd = this.selectedRows.find((prod) => !!prod);
    this.productService.addSharedProdId(JSON.stringify(dupeProd));
    this.route.navigate(['add-product/duplicate']);
  }

  gotoAddProduct() {
    this.route.navigateByUrl('/add-product/:id');
  }

  doDelete() {
    // Remove from backend
    const ids = this.selectedRows.map((v) => v.id);
    forkJoin(ids.map((id) => this.productService.deleteProduct(id))).subscribe(
      (_) => {
        // Remove from product result set
        this.selectionModel.deselect(...this.selectedRows);
        this.dataSource.data = this.dataSource.data.filter(
          (v) => !ids.includes(v.id),
        );
        this.selectedRows = [];

        this.snackbar.open(`Product(s) deleted`, 'Close', {
          panelClass: 'success',
        });
      },
      (err) => {
        this.snackbar.open(`An error occurred deleting product(s)`, 'Close', {
          panelClass: 'error',
        });
      },
    );
  }

  openModal(content) {
    this.dialog.open(ConfirmationModalComponent, {
      width: this.isMobile ? '100vw' : '40%',
      data: content,
    });
  }

  markAsOutOfStockMayBe() {
    this.openModal({
      header: 'Mark as Out of Stock',
      body: `Are you sure you want to set the quantity of this product and all its variants to 0?`,
      ctas: {
        back: noop,
        act: this.markAsOutOfStock.bind(this),
        textLeft: 'NO',
        textRight: 'YES',
      },
    });
  }

  markAsOutOfStock() {
    const prods = this.selectedRows
      .map((p) => ({
        ...p,
        quantity: 0,
        variations: p.variations.map((v) => ({ ...v, quantity: 0 })),

        // A Hack for BE validations
        size: p.size || '',
        stockHistory: p.stockHistory || [],
        discountDetails: (p as any).discountDetails || {},
      }))
      .map((p) => ({ id: p.id, product: p }));
    const streams = prods.map(({ id, product }) =>
      this.productService.patchProduct(product, id),
    );

    this.processOutOfStock(streams, prods);
  }

  processOutOfStock(streams, prods) {
    const ids = prods.map((v) => v.id);
    const products = prods.map((p) => p.product);

    return forkJoin(streams)
      .pipe(
        tap((_) => {
          this.selectionModel.deselect(...this.selectedRows);
          this.selectedRows = [];

          this.dataSource.data = [
            ...products,
            ...this.dataSource.data.filter((v) => !ids.includes(v.id)),
          ];

          this.snackbar.open(
            `Product(s) and variants(s) marked as out of stock`,
            'Close',
            {
              panelClass: 'success',
            },
          );
        }),
        catchError((_) => {
          this.snackbar.open(
            `An error occurred marking product(s) as out of stock`,
            'Close',
            {
              panelClass: 'error',
            },
          );
          return throwError(_);
        }),
      )
      .subscribe();
  }

  download() {
    this.downloadService.download(this.selectionModel.selected);
  }
}
