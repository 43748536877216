import { Directive, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[seInputTrim]',
})
export class InputTrimDirective {
  @Input() formCtrl = new FormControl();

  @HostListener('input', ['$event.target'])
  trimValue(el) {
    if (el.value.startsWith(' ')) {
      el.value = el.value.trim();
    }
    this.formCtrl.setValue(el.value);
  }
}
