import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrdersShippingComponent } from '../orders-shipping/orders-shipping.component';
import { OrderService } from '../shared/services/order-service';
import { Router } from '@angular/router';
import { StateFacade } from '../shared/services/state.facade';
import { ProfileBusinessService } from '../shared/services/profile-business-service';
import { CalculatedateRange } from '../shared/utils/dates';
import { combineLatest } from 'rxjs';
import { AccountService } from '../shared/services/account-service';
import { concatMap, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent extends OrdersShippingComponent
  implements OnInit {
  title = 'Account';
  amountDeposited: number;
  pendingAmount: number;
  duration = ['Week', 'Month', 'Today'];
  amountDepositedSelected = this.duration[0];
  pendingAmountSelected = this.duration[0];
  displayedColumns: string[] = [
    'select',
    'orderDate',
    'total',
    'orderNumber',
    'customer',
    'status',
  ];
  isLoadingPending = false;
  isLoadingDeposit = false;
  columnsSelect = [
    { label: 'Order Number', value: 'orderNumber' },
    { label: 'Customer', value: 'customer' },
    { label: 'Total', value: 'total' },
    { label: 'Status', value: 'status' },
  ];

  constructor(
    public orderService: OrderService,
    public route: Router,
    public stateFacade: StateFacade,
    public snackbar: MatSnackBar,
    public businessProfile: ProfileBusinessService,
    public accountService: AccountService,
  ) {
    super(orderService, route, stateFacade, snackbar, businessProfile);
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.getOrders();
    this.stateFacade.getViewPortSize().subscribe((v) => {
      this.isMobile = v.isMobile;
    });
    this.amountDeposited = 0;
    this.pendingAmount = 0;
    this.getDeliveryMode();
    this.getAmountDeposited(this.duration[0]);
    this.getPendingAmount(this.duration[0]);
    this.columnsToDisplay = this.displayedColumns.slice(
      0,
      this.isMobile ? 3 : 7,
    );
  }

  getAccountMetrics(from = '', to = '', single = true) {
    return this.businessProfile.getBusinessProfile().pipe(
      concatMap((profile: any) => {
        return combineLatest(
          [profile?.mfySubaccountId],
          this.accountService.getAccountMetrics(
            from,
            to,
            profile?.websiteName,
            'fw',
            single,
          ),
          this.accountService.getAccountMetrics(
            from,
            to,
            profile?.websiteName,
            'mfy',
            single,
          ),
        );
      }),
    );
  }

  getBusinessProfile() {
    return this.businessProfile.getBusinessProfile();
  }

  getSettlements(merged, subaccountId, status, provider) {
    if (provider === 'mfy') {
      return (
        merged
          .filter(
            (i) =>
              i.beneficiaryCode === subaccountId &&
              i.settlementStatus.toLowerCase().includes(status),
          )
          ?.map((item) => item?.beneficiaryIncome)
          ?.reduce((prev, next) => prev + next, 0) ?? 0
      );
    } else {
      return [];
    }
  }

  getAmountDeposited(duration: string) {
    this.isLoadingDeposit = true;
    this.amountDeposited = 0;
    switch (duration) {
      // Week
      case this.duration[0]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          7,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        this.getAccountMetrics(from, to, false)
          .pipe(finalize(() => (this.isLoadingDeposit = false)))
          .subscribe(([mfySubaccountId, fw, mfy]: [any, any, any]) => {
            const fwAmount = fw
              .filter((t) => t.status === 'completed')
              .map((t) => t?.settlement_amount)
              ?.reduce((prev, next) => prev + next, 0);
            this.amountDeposited += fwAmount;
            const merged: any[] =
              [].concat.apply(
                [],
                mfy?.map((b) => b?.beneficiaries),
              ) ?? [];
            this.amountDeposited += this.getSettlements(
              merged,
              mfySubaccountId,
              'success',
              'mfy',
            );
          });
        break;
      }
      // Month
      case this.duration[1]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          true,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];

        this.getAccountMetrics(from, to, false)
          .pipe(finalize(() => (this.isLoadingDeposit = false)))
          .subscribe(([mfySubaccountId, fw, mfy]: [any, any, any]) => {
            const fwAmount = fw
              .filter((t) => t.status === 'completed')
              .map((t) => t?.settlement_amount)
              ?.reduce((prev, next) => prev + next, 0);
            this.amountDeposited += fwAmount;
            const merged: any[] =
              [].concat.apply(
                [],
                mfy?.map((b) => b?.beneficiaries),
              ) ?? [];
            this.amountDeposited += this.getSettlements(
              merged,
              mfySubaccountId,
              'success',
              'mfy',
            );
          });
        break;
      }
      // Today
      case this.duration[2]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        this.getAccountMetrics(from, to, false)
          .pipe(finalize(() => (this.isLoadingDeposit = false)))
          .subscribe(([mfySubaccountId, fw, mfy]: [any, any, any]) => {
            const fwAmount = fw
              .filter((t) => t.status === 'completed')
              .map((t) => t?.settlement_amount)
              ?.reduce((prev, next) => prev + next, 0);
            this.amountDeposited += fwAmount;
            const merged: any[] =
              [].concat.apply(
                [],
                mfy?.map((b) => b?.beneficiaries),
              ) ?? [];
            this.amountDeposited += this.getSettlements(
              merged,
              mfySubaccountId,
              'success',
              'mfy',
            );
          });
        break;
      }
      default: {
        break;
      }
    }
  }

  getPendingAmount(duration: string) {
    this.isLoadingPending = true;
    this.pendingAmount = 0;
    switch (duration) {
      // Week
      case this.duration[0]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          7,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        this.getAccountMetrics(from, to, false)
          .pipe(finalize(() => (this.isLoadingPending = false)))
          .subscribe(([mfySubaccountId, fw, mfy]: [any, any, any]) => {
            const fwAmount = fw
              .filter((t) => t.status !== 'completed')
              .map((t) => t?.settlement_amount)
              ?.reduce((prev, next) => prev + next, 0);
            this.pendingAmount += fwAmount;
            const merged: any[] =
              [].concat.apply(
                [],
                mfy?.map((b) => b?.beneficiaries),
              ) ?? [];
            this.pendingAmount += this.getSettlements(
              merged,
              mfySubaccountId,
              'pending',
              'mfy',
            );

            // compute pending amount that hasn't gone through settlement
            const merged2: any[] =
              [].concat.apply(
                [],
                mfy
                  ?.filter((b) => b?.beneficiaries?.length === 0)
                  .map(
                    (a) =>
                      a?.amountPaid -
                      (a?.deliveryCharge + a?.serviceCharge + a?.tax),
                  ),
              ) ?? [];

            const pendingAmount = Number(
              merged2?.reduce((prev, next) => prev + next, 0),
            );
            if (pendingAmount > 0) this.pendingAmount += pendingAmount;
          });
        break;
      }
      // Month
      case this.duration[1]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          true,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        this.getAccountMetrics(from, to, false)
          .pipe(finalize(() => (this.isLoadingPending = false)))
          .subscribe(([mfySubaccountId, fw, mfy]: [any, any, any]) => {
            const fwAmount = fw
              .filter((t) => t.status !== 'completed')
              .map((t) => t?.settlement_amount)
              ?.reduce((prev, next) => prev + next, 0);
            this.pendingAmount += fwAmount;
            const merged: any[] =
              [].concat.apply(
                [],
                mfy?.map((b) => b?.beneficiaries),
              ) ?? [];
            this.pendingAmount += this.getSettlements(
              merged,
              mfySubaccountId,
              'pending',
              'mfy',
            );

            // compute pending amount that hasn't gone through settlement
            const merged2: any[] =
              [].concat.apply(
                [],
                mfy
                  ?.filter((b) => b?.beneficiaries?.length === 0)
                  .map(
                    (a) =>
                      a?.amountPaid -
                      (a?.deliveryCharge + a?.serviceCharge + a?.tax),
                  ),
              ) ?? [];

            const pendingAmount = Number(
              merged2?.reduce((prev, next) => prev + next, 0),
            );
            if (pendingAmount > 0) this.pendingAmount += pendingAmount;
          });
        break;
      }
      // Today
      case this.duration[2]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        this.getAccountMetrics(from, to, false)
          .pipe(finalize(() => (this.isLoadingPending = false)))
          .subscribe(([mfySubaccountId, fw, mfy]: [any, any, any]) => {
            const fwAmount = fw
              .filter((t) => t.status !== 'completed')
              .map((t) => t?.settlement_amount)
              ?.reduce((prev, next) => prev + next, 0);
            this.pendingAmount += fwAmount;
            const merged: any[] =
              [].concat.apply(
                [],
                mfy?.map((b) => b?.beneficiaries),
              ) ?? [];
            this.pendingAmount += this.getSettlements(
              merged,
              mfySubaccountId,
              'pending',
              'mfy',
            );

            // compute pending amount that hasn't gone through settlement
            const merged2: any[] =
              [].concat.apply(
                [],
                mfy
                  ?.filter((b) => b?.beneficiaries?.length === 0)
                  .map(
                    (a) =>
                      a?.amountPaid -
                      (a?.deliveryCharge + a?.serviceCharge + a?.tax),
                  ),
              ) ?? [];

            const pendingAmount = Number(
              merged2?.reduce((prev, next) => prev + next, 0),
            );
            if (pendingAmount > 0) this.pendingAmount += pendingAmount;
          });
        break;
      }
      default: {
        break;
      }
    }
  }
}
