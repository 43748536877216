<app-modal class="selleasy-delivery-dialog">
    <app-modal-header>
        <h1>UnSupported States</h1>
    </app-modal-header>
    <app-modal-content>
        <p>
            For the states you do not support, should SellEasy handle the delivery? <br /><br /> Click <b>NO</b>, if you
            do not want any orders from unsupported states.<br /><br />
            <span *ngIf="isLagosResident">Please enter a pickup address <i>(where we can come to pick up your
                    products)</i></span> <span *ngIf="!isLagosResident">Please select a drop off location <i>(where you
                    can
                    drop off your products for us)</i></span> and click <b>YES</b>
            if you want sellEasy to handle delivery for unsupported states.
        </p>
        <div class="form-fields text-normal mt-4 mb-4 max-height-5">
            <mat-form-field #matField
                            *ngIf="isLagosResident"
                            appearance="outline"
                            class="se-input-field">
                <mat-label>Pick up location</mat-label>
                <input type="text"
                       [tabindex]="-1"
                       autocomplete="off"
                       #pickUp
                       [formControl]="pickUpLocation"
                       (input)="checkEmptyField(pickUp, matField)"
                       (blur)="checkEmptyField(pickUp, matField)"
                       matInput>
                <mat-error>Pickup location seems to be incorrect</mat-error>
                <mat-hint class="error-text"
                          *ngIf="pickUp.__hasReqErr else noErrTmpl">Please enter a pick up location</mat-hint>
                <ng-template #noErrTmpl>
                    <mat-hint>Our delivery service will pick up your products at this location</mat-hint>
                </ng-template>
            </mat-form-field>

            <mat-form-field *ngIf="!isLagosResident"
                            appearance="outline">
                <mat-label>Drop off location</mat-label>
                <mat-select id="dropOffLocationId"
                            class="mat-elevation-z0"
                            [formControl]="dropOffLocation">
                    <mat-option class="dropoff-option"
                                *ngFor="let location of filteredDropOffs"
                                [value]="location.id">
                        {{location?.address}}, {{location?.name}}, {{location?.state?.name}}
                    </mat-option>
                </mat-select>
                <mat-hint>You can drop off your goods for delivery at our drop off location closest to you
                </mat-hint>
                <mat-error>Pickup select a dropoff location</mat-error>
            </mat-form-field>
        </div>
    </app-modal-content>
    <app-modal-footer>
        <button mat-stroked-button
                (click)="doNotHandle()"
                color="primary"
                class="se-button se-button--stroke mr-3">
            NO
        </button>
        <button mat-flat-button
                (click)="handle()"
                color="primary"
                class="se-button">
            YES
        </button>
    </app-modal-footer>
</app-modal>
