<mat-toolbar class="footer"
             [hidden]="!isMainNav">
    <div class="container footer__wrapper">
        <div class="footer__copyright">
            @SellEasy, inc. {{currentYear}}. All rights reserved.
        </div>
    </div>
</mat-toolbar>

<footer *ngIf="isNotMobileOS$ | async"
        [hidden]="isMainNav">
    <div class="container">
        <div class="row text-left">
            <div class="col-md-4 logo">
                <a href="javascript:void(0)"
                   (click)="goTo('/')"><img src="assets/selleasy-logo.svg"></a>
                <p class="footer-text">We provide free ecommerce website, delivery, payment, inventory management,
                    product performance & insights all on one platform.
                </p>

                <div class="get-app">
                    <div class="title">
                        Download App
                    </div>
                    <div class="buttons">
                        <a mat-button
                           class="android"
                           href="https://play.google.com/store/apps/details?id=com.selleasy.app"
                           target="_blank"></a>
                        <a class="ios"
                           href="https://apps.apple.com/app/id1556168902"
                           target="_blank"
                           mat-button
                           class="ios"></a>
                    </div>
                </div>
            </div>
            <div class="col-md-2 text-left">
                <h5 class="footer-title">Company</h5>
                <ul class="list-unstyled">
                    <li class="footer-list"><a href="javascript:void(0)"
                           (click)="goTo('/about-us')">About us</a></li>
                    <li class="footer-list"><a href="javascript:void(0)"
                           (click)="scrollTo('#contactUs','/about-us')">Contact us</a></li>
                </ul>
            </div>
            <div class="col-md-2 text-left">
                <h5 class="footer-title">Product</h5>
                <ul class="list-unstyled">
                    <li class="footer-list"><a href="javascript:void(0)"
                           (click)="goTo('/platform')">SellEasy platform</a></li>
                    <li class="footer-list"><a href="javascript:void(0)"
                           (click)="scrollTo('#platformPayment', '/platform')">Payment</a></li>
                    <li class="footer-list"><a href="javascript:void(0)"
                           (click)="scrollTo('#platformDelivery', '/platform')">Delivery</a></li>
                </ul>
            </div>
            <div class="col-md-2 text-left">
                <h5 class="footer-title">Support</h5>
                <ul class="list-unstyled">
                    <li class="footer-list"><a href="javascript:void(0)"
                           (click)="scrollTo('#technicalSupportSection')">Technical support</a></li>
                    <!-- <li class="footer-list"><a href="/help-center">Help center</a></li> -->
                </ul>
            </div>
            <div class="col-md-2 text-left">
                <h5 class="footer-title">Legal</h5>
                <ul class="list-unstyled">
                    <li class="footer-list"><a href="javascript:void(0)"
                           (click)="goTo('/terms-and-conditions')">Terms & conditions</a></li>
                    <li class="footer-list"><a href="javascript:void(0)"
                           (click)="goTo('/privacy-policy')">Privacy policy</a></li>
                    <li class="footer-list"><a href="javascript:void(0)"
                           (click)="goTo('/refund-policy')">Refund policy</a></li>
                </ul>
            </div>
            <div class="col-12">
                <hr class="line">
                <div class="row padding"
                     [class.footer__is-main-nav]="isMainNav">
                    <div class="social-icons col-lg-6 text-left d-flex">
                        <a href="https://facebook.com/selleasyHQ"
                           target="_blank">
                            <se-icon iconName="facebook-white"
                                     size="md">
                            </se-icon>
                        </a>
                        <a href="https://twitter.com/selleasyHQ"
                           target="_blank">
                            <se-icon iconName="twitter-white"
                                     size="md">
                            </se-icon>
                        </a>
                        <a href="https://linkedin.com/company/selleasyHQ"
                           target="_blank">
                            <se-icon iconName="linkedln-white"
                                     size="md">
                            </se-icon>
                        </a>
                        <a href="https://instagram.com/selleasyHQ"
                           target="_blank">
                            <se-icon iconName="instagram-white"
                                     size="md">
                            </se-icon>
                        </a>
                    </div>
                    <div class="col-lg-6 text-right">
                        <h6 class="copyright">&copy;SellEasy. 2020-2021. All rights reserved.</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
