import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageModule } from './landing-page/landing-page.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './shared/services/auth.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { ProductLibraryComponent } from './product-library/product-library.component';
import { AddProductComponent } from './add-product/add-product.component';
import { ManageProductComponent } from './manage-product/manage-product.component';
import { OrdersShippingComponent } from './orders-shipping/orders-shipping.component';
import { WebsiteComponent } from './website/website.component';
import { AccountComponent } from './account/account.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { ProductService } from './shared/services/product-service';
import { CreateDiscountComponent } from './create-discount/create-discount.component';
import { DiscountsListComponent } from './discounts-list/discounts-list.component';
import { LearnComponent } from './learn/learn.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => LandingPageModule,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'add-product/:id',
    component: AddProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-product',
    component: ManageProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'orders-and-shipping',
    component: OrdersShippingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-discount',
    component: CreateDiscountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'discounts-list',
    component: DiscountsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'view-order',
    component: ViewOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'website',
    component: WebsiteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account-details',
    component: AccountDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'product-library',
  //   component: ProductLibraryComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'learn',
    component: LearnComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
