import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { StateFacade } from '../shared/services/state.facade';
import { ImageService } from '../shared/services/image-service';
import { FormControl } from '@angular/forms';
import { defer } from 'lodash';

@Component({
  selector: 'app-upload-website-logo',
  templateUrl: './upload-website-logo.component.html',
  styleUrls: ['./upload-website-logo.component.scss'],
})
export class UploadWebsiteLogoComponent implements OnInit, OnChanges {
  @Input() logoControl: FormControl;
  @Input() logoUrl = '';
  @ViewChild('fileInput') fileInput: ElementRef;

  showCropper = false;
  showCroppedImage = false;
  isMobile = false;
  buttonText = 'UPLOAD IMAGE';
  hasImage = false;
  imageChangedEvent;
  scale = 1;
  transform = {};
  croppedImage;
  logoShape = 'rectangle';

  constructor(
    private stateFacade: StateFacade,
    private imageService: ImageService,
  ) {}

  ngOnChanges() {
    if (this.logoUrl) {
      this.displayCroppedImage();
    }
  }

  onUpload(e) {
    this.displayCropper();
    this.hideCroppedImage();
    this.imageChangedEvent = e;
    defer(() => {
      this.clearFileInput(e);
    });
  }

  imageCropped(e) {
    this.croppedImage = e;
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  ngOnInit() {
    this.stateFacade.getViewPortSize().subscribe(({ isMobile }) => {
      this.isMobile = isMobile;
    });
  }

  clearFileInput(e: any) {
    e.target.value = '';
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  displayCroppedImage() {
    this.showCroppedImage = true;
  }

  hideCroppedImage() {
    this.showCroppedImage = false;
  }

  displayCropper() {
    this.showCropper = true;
  }

  hideCropper() {
    this.showCropper = false;
  }

  clearImage() {
    this.logoControl.setValue(null);
    this.hideCroppedImage();
  }

  cropImage() {
    const img = this.imageService.convertDataUrlToBlob(
      this.croppedImage.base64,
    );
    this.imageService.saveImage(img).subscribe((url: string) => {
      this.logoControl.setValue(url);
      this.logoUrl = url;

      this.displayCroppedImage();
      this.hideCropper();
    });
  }
}
