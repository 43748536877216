import { Status } from './status';
import { ProductVariant } from './variation';

export interface IProduct {
  id?: string;
  sku: string;
  name: string;
  price: number;
  sale?: boolean;
  salePrice?: number;
  discount?: number;
  description: string;
  shortDetails?: string;
  brand?: string;
  category?: string;
  tags?: string[];
  size?: string;
  weight?: number;
  weightType?: string;
  quantity: number;
  status?: string;
  timestampCreated?: Date;
  timestampModified?: Date;
  imageUri?: string[];
  defaultImageIndex?: number;
  views?: number;
  showInNotification?: boolean;
  openOrder?: number;
  purchased?: number;
  stockHistory?: object[];
  variations?: ProductVariant[];
  businessId: string;
}

export interface Category {
  id?: string;
  name: string;
  businessId: string;
}

export interface IDiscount {
  id: string;
  code: string;
  start: string;
  end: string;
  name: string;
  scope: string;
  products: any[];
  type: string;
  value: number;
  rules: any[];
  status: string;
  businessId: string;
  timestampCreated: string;
  timestampModified: string;
}

export enum variantErrors {
  EMPTY_OPTION = 'EMPTY_OPTION',
  ZERO_PRICE = 'ZERO_PRICE',
  ZERO_TOTAL_QUANTITY = 'ZERO_TOTAL_QUANTITY',
}
