<div class="policies">
    <div class="policies__banner-img">
        <div class="policy-banner-text text-center">
            <h2 class="h2-bold">SellEasy Privacy Policy</h2>
            <div class="policy-text">
                <div class="text-muted">Last updated: August 1, 2020</div>
                <div class="title-support-text">
                    <div>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure
                        of
                        Your information when You use the Service and tells You about Your privacy rights and how the
                        law
                        protects You.</div>
                    <div>We use Your Personal data to provide and improve the Service. By using the Service, You agree
                        to
                        the
                        collection and use of information in accordance with this Privacy Policy.</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container policies__text-wrapper text-normal">
        <section id="Interpretation-and-Definitions">
            <h3 class="policy-area-h">1. Interpretation and Definitions</h3>
            <h6 class="policy-area-h6">Interpretation</h6>
            <p>The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear in
                singular or in plural.</p>
            <h6 class="policy-area-h6">Definitions</h6>
            <p>For the purposes of this Return and Refund Policy:</p>
            <ul>
                <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other
                    legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                    Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as
                    the User as you are the individual using the Service.</li>
                <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                    Agreement) refers to SellEasy Online Limited, Lekki, Lagos. For the purpose of the GDPR, the Company
                    is the Data Controller.</li>
                <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
                    control with a party, where "control" means ownership of 50% or more of the shares, equity interest
                    or other securities entitled to vote for election of directors or other managing authority.</li>
                <li><strong>Account</strong> means a unique account created for You to access our Service or parts of
                    our Service.</li>
                <li><strong>Website</strong> refers to SellEasy, accessible from selleasy.ng</li>
                <li><strong>Service</strong> refers to the Website.</li>
                <li><strong>Country</strong> refers to: Nigeria</li>
                <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf
                    of the Company. It refers to third-party companies or individuals employed by the Company to
                    facilitate the Service, to provide the Service on behalf of the Company, to perform services related
                    to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the
                    GDPR, Service Providers are considered Data Processors.</li>
                <li><strong>Third-party Social Media Service</strong> refers to any website or any social network
                    website through which a User can log in or create an account to use the Service.</li>
                <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                    individual. For the purposes for GDPR, Personal Data means any information relating to You such as a
                    name, an identification number, location data, online identifier or to one or more factors specific
                    to the physical, physiological, genetic, mental, economic, cultural or social identity.</li>
                <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any
                    other device by a website, containing the details of Your browsing history on that website among its
                    many uses.</li>
                <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of
                    the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                </li>
                <li><strong>Data Controller</strong>, for the purposes of the GDPR (General Data Protection Regulation),
                    refers to the Company as the legal person which alone or jointly with others determines the purposes
                    and means of the processing of Personal Data.</li>
            </ul>
        </section>
        <section id="Collecting-Personal-Data">
            <h3 class="policy-area-h">2. Collecting and Using Your Personal Data</h3>
            <h6 class="policy-area-h6">Types of Data Collected</h6>
            <h5>Personal Data</h5>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information
                that can be used to contact or identify You. Personally identifiable information may include, but is not
                limited to:</p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Bank account information in order to pay for products and/or services within the Service</li>
                <li>Usage Data</li>
            </ul>
            <p>When You pay for a product and/or a service via bank transfer, We may ask You to provide information to
                facilitate this transaction and to verify Your identity. Such information may include, without
                limitation:</p>
            <ul>
                <li>Passport or National ID card</li>
                <li>Bank card statement</li>
                <li>Other information linking You to an address</li>
            </ul>
            <h5>Usage Data</h5>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
                the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p>When You access the Service by or through a mobile device, We may collect certain information
                automatically, including, but not limited to, the type of mobile device You use, Your mobile device
                unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                Internet browser You use, unique device identifiers and other diagnostic data.</p>
            <p>We may also collect information that Your browser sends whenever You visit our Service or when You access
                the Service by or through a mobile device.</p>

            <h5>Information from Third-Party Social Media Services</h5>
            <p>The Company allows You to create an account and log in to use the Service through the following
                Third-party Social Media Services:</p>
            <ul>
                <li>Google</li>
                <li>Facebook</li>
                <li>Twitter</li>
            </ul>
            <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We
                may collect Personal data that is already associated with Your Third-Party Social Media Service's
                account, such as Your name, Your email address, Your activities or Your contact list associated with
                that account.</p>
            <p>You may also have the option of sharing additional information with the Company through Your Third-Party
                Social Media Service's account. If You choose to provide such information and Personal Data, during
                registration or otherwise, You are giving the Company permission to use, share, and store it in a manner
                consistent with this Privacy Policy.</p>

            <h5>Tracking Technologies and Cookies</h5>
            <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
                information. Tracking technologies used are beacons, tags, and scripts to collect and track information
                and to improve and analyze Our Service.</p>
            <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However,
                if You do not accept Cookies, You may not be able to use some parts of our Service.</p>
            <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or
                mobile device when You go offline, while Session Cookies are deleted as soon as You close your web
                browser.</p>
            <p>We use both session and persistent Cookies for the purposes set out below:</p>
            <ul>
                <li>
                    <h5>Necessary / Essential Cookies</h5>
                    <p>Type: Session Cookies</p>
                    <p>Administered by: Us</p>
                    <p>Purpose: These Cookies are essential to provide You with services available through the Website
                        and to enable You to use some of its features. They help to authenticate users and prevent
                        fraudulent use of user accounts. Without these Cookies, the services that You have asked for
                        cannot be provided, and We only use these Cookies to provide You with those services.</p>
                </li>

            </ul>
            <ul>
                <li>
                    <h5>Cookies Policy / Notice Acceptance Cookies</h5>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Us</p>
                    <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                </li>

            </ul>
            <ul>
                <li>
                    <h5>Functionality Cookies</h5>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Us</p>
                    <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                        remembering your login details or language preference. The purpose of these Cookies is to
                        provide You with a more personal experience and to avoid You having to re-enter your preferences
                        every time You use the Website.</p>
                </li>

            </ul>
            <ul>
                <li>
                    <h5>Tracking and Performance Cookies</h5>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Third-Parties</p>
                    <p>Purpose: These Cookies are used to track information about traffic to the Website and how users
                        use the Website. The information gathered via these Cookies may directly or indirectly identify
                        you as an individual visitor. This is because the information collected is typically linked to a
                        pseudonymous identifier associated with the device you use to access the Website. We may also
                        use these Cookies to test new advertisements, pages, features or new functionality of the
                        Website to see how our users react to them.</p>
                </li>

            </ul>
            <ul>
                <li>
                    <h5>Targeting and Advertising Cookies</h5>
                    <p>Type: Persistent Cookies</p>
                    <p>Administered by: Third-Parties</p>
                    <p>Purpose: These Cookies track your browsing habits to enable Us to show advertising which is more
                        likely to be of interest to You. These Cookies use information about your browsing history to
                        group You with other users who have similar interests. Based on that information, and with Our
                        permission, third party advertisers can place Cookies to enable them to show adverts which We
                        think will be relevant to your interests while You are on third party websites.</p>
                </li>

            </ul>

            <h6 class="policy-area-h6">Use of Your Personal Data</h6>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
                <li><strong>To provide and maintain our Service,</strong> including to monitor the usage of our Service.
                </li>
                <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The
                    Personal Data You provide can give You access to different functionalities of the Service that are
                    available to You as a registered user.</li>
                <li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of
                    the purchase contract for the products, items or services You have purchased or of any other
                    contract with Us through the Service.</li>
                <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent
                    forms of electronic communication, such as a mobile application's push notifications regarding
                    updates or informative communications related to the functionalities, products or contracted
                    services, including the security updates, when necessary or reasonable for their implementation.
                </li>
                <li><strong>To provide You</strong> with news, special offers and general information about other goods,
                    services and events which we offer that are similar to those that you have already purchased or
                    enquired about unless You have opted not to receive such information.</li>
                <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
            </ul>
            <p>We may share your personal information in the following situations:</p>
            <ul>
                <li><strong>With Service Providers:</strong> We may share Your personal information with Service
                    Providers to monitor and analyze the use of our Service, to show advertisements to You to help
                    support and maintain Our Service, to contact You, to advertise on third party websites to You after
                    You visited our Service or for payment processing.</li>
                <li><strong>For Business transfers:</strong> We may share or transfer Your personal information in
                    connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                    acquisition of all or a portion of our business to another company.</li>
                <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case
                    we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company
                    and any other subsidiaries, joint venture partners or other companies that We control or that are
                    under common control with Us.</li>
                <li><strong>With Business partners:</strong> We may share Your information with Our business partners to
                    offer You certain products, services or promotions.</li>
                <li><strong>With other users:</strong> when You share personal information or otherwise interact in the
                    public areas with other users, such information may be viewed by all users and may be publicly
                    distributed outside. If You interact with other users or register through a Third-Party Social Media
                    Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures
                    and description of Your activity. Similarly, other users will be able to view descriptions of Your
                    activity, communicate with You and view Your profile.</li>
            </ul>

            <h6 class="policy-area-h6">Retention of Your Personal Data</h6>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with
                our legal obligations (for example, if we are required to retain your data to comply with applicable
                laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
                for a shorter period of time, except when this data is used to strengthen the security or to improve the
                functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            </p>

            <h6 class="policy-area-h6">Transfer of Your Personal Data</h6>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with
                our legal obligations (for example, if we are required to retain your data to comply with applicable
                laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
                for a shorter period of time, except when this data is used to strengthen the security or to improve the
                functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
            </p>

            <h6 class="policy-area-h6">Disclosure of Your Personal Data</h6>
            <h5>Business Transactions</h5>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
                We will provide notice before Your Personal Data is transferred and becomes subject to a different
                Privacy Policy.</p>
            <h5>Law enforcement</h5>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do
                so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
            </p>
            <h5>Other legal requirements</h5>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            </p>
            <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Company</li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect against legal liability</li>
            </ul>

            <h6 class="policy-area-h6">Security of Your Personal Data</h6>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over
                the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
                acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
        </section>
        <section id="Detailed-Information-oProcessing-Personal-Data">
            <h3 class="policy-area-h">3. Detailed Information on the Processing of Your Personal Data</h3>
            <p>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are
                obligated not to disclose or use it for any other purpose.</p>

            <h6 class="policy-area-h6">Analytics</h6>
            <p>We may use third-party Service providers to monitor and analyze the use of our Service.</p>

            <h5>Google Analytics</h5>
            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
                Google uses the data collected to track and monitor the use of our Service. This data is shared with
                other Google services. Google may use the collected data to contextualise and personalise the ads of its
                own advertising network.</p>
            <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing
                the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
                analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</p>
            <p>For more information on the privacy practices of Google, please visit the Google Privacy &amp; Terms web
                page: <a href="https://policies.google.com/privacy?hl=en"
                   target="_blank"><u>on their website</u></a></p>

            <h5>Yandex Metrica</h5>
            <p>Yandex Metrica is a web analytics service offered by Yandex that tracks and reports website traffic.
                Yandex uses the data collected to track and monitor the use of our Service. Yandex may use the collected
                data to improve their services.</p>
            <p>For more information on the privacy practices of Yandex, please visit the Yandex Privacy &amp; Terms web
                page: <a href="https://metrica.yandex.com/about/info/privacy-policy"
                   target="_blank"><u>on their website</u></a></p>

            <h6 class="policy-area-h6">Advertising</h6>
            <p>We may use Service providers to show advertisements to You to help support and maintain Our Service.</p>

            <h5>Google AdSense &amp; DoubleClick Cookie</h5>
            <p>Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the
                DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our
                Service or other websites on the Internet.</p>
            <p>You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the
                Google Ads Settings web page: <a href="http://www.google.com/ads/preferences/"
                   target="_blank"><u>on their website</u></a></p>

            <h6 class="policy-area-h6">Email Marketing</h6>
            <p>We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
                other information that may be of interest to You. You may opt-out of receiving any, or all, of these
                communications from Us by following the unsubscribe link or instructions provided in any email We send
                or by contacting Us.</p>
            <p>We may use Email Marketing Service Providers to manage and send emails to You.</p>

            <h6 class="policy-area-h6">Behavioral Remarketing</h6>
            <p>The Company uses remarketing services to advertise on third party websites to You after You visited our
                Service. We and Our third-party vendors use cookies to inform, optimize and serve ads based on Your past
                visits to our Service.</p>

            <h5>Google Ads Remarketing</h5>
            <p>Google Ads remarketing service is provided by Google</p>
            <p>You can opt-out of Google Ads interest-based ads by following their instructions: <a
                   href="https://www.google.com/settings/ads/anonymous"
                   target="_blank"><u>on their website</u></a></p>
            <p>You can learn more about the privacy practices and policies of Google by visiting their Privacy Policy
                page: <a href="https://policies.google.com/privacy?hl=en-US"
                   target="_blank"><u>on their website</u></a></p>

            <h5>Facebook Ads Remarketing</h5>
            <p>Facebook Ads remarketing service is provided by Facebook</p>
            <p>You can opt-out of Bing Ads interest-based ads by following their instructions: <a
                   href="https://www.facebook.com/settings/?tab=ads"
                   target="_blank"><u>on their website</u></a></p>
            <p>You can learn more about the privacy practices and policies of Microsoft by visiting their Privacy Policy
                page: <a href="https://www.facebook.com/policies/ads/"
                   target="_blank"><u>on their website</u></a></p>

            <h5>Bing Ads Remarketing</h5>
            <p>Bing Ads remarketing service is provided by Microsoft Inc.</p>
            <p>You can opt-out of Bing Ads interest-based ads by following their instructions: <a
                   href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
                   target="_blank"><u>on their website</u></a></p>
            <p>You can learn more about the privacy practices and policies of Microsoft by visiting their Privacy Policy
                page: <a href="https://privacy.microsoft.com/en-us/PrivacyStatement"
                   target="_blank"><u>on their website</u></a></p>

            <h6 class="policy-area-h6">Payments</h6>
            <p>We may provide paid products and/or services within the Service. In that case, we may use third-party
                services for payment processing (e.g. payment processors).</p>
            <p>We will not store or collect Your payment card details. That information is provided directly to Our
                third-party payment processors whose use of Your personal information is governed by their Privacy
                Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and
                Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
            <ul>
                <li>
                    <h5>Flutterwave</h5>
                    <p>Their Privacy Policy can be viewed on <a href="https://flutterwave.com/us/privacy-policy"
                           target="_blank"><u>on their website</u></a></p>
                </li>
                <li>
                    <h5>Monnify</h5>
                    <p>Their Privacy Policy can be viewed on <a href="https://monnify.com/privacy-policy.html"
                           target="_blank"><u>on their website</u></a></p>
                </li>
            </ul>
            <p>When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide
                information to facilitate this transaction and to verify Your identity.</p>
        </section>
        <section id="GDPR-Privacy">
            <h3 class="policy-area-h">4. GDPR Privacy</h3>
            <h6 class="policy-area-h6">Legal Basis for Processing Personal Data under GDPR</h6>
            <p>We may process Personal Data under the following conditions:</p>
            <ul>
                <li><strong>Consent:</strong> You have given Your consent for processing Personal Data for one or more
                    specific purposes.</li>
                <li><strong>Performance of a contract:</strong> Provision of Personal Data is necessary for the
                    performance of an agreement with You and/or for any pre-contractual obligations thereof.</li>
                <li><strong>Legal obligations:</strong> Processing Personal Data is necessary for compliance with a
                    legal obligation to which the Company is subject.</li>
                <li><strong>Vital interests:</strong> Processing Personal Data is necessary in order to protect Your
                    vital interests or of another natural person.</li>
                <li><strong>Public interests:</strong> Processing Personal Data is related to a task that is carried out
                    in the public interest or in the exercise of official authority vested in the Company.</li>
                <li><strong>Legitimate interests:</strong> Processing Personal Data is necessary for the purposes of the
                    legitimate interests pursued by the Company.</li>
            </ul>
            <p>In any case, the Company will gladly help to clarify the specific legal basis that applies to the
                processing, and in particular whether the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a contract.</p>

            <h6 class="policy-area-h6">Your Rights under the GDPR</h6>
            <p>The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can
                exercise Your rights.</p>
            <ul>
                <li><strong>Request access to Your Personal Data.</strong> The right to access, update or delete the
                    information We have on You. Whenever made possible, you can access, update or request deletion of
                    Your Personal Data directly within Your account settings section. If you are unable to perform these
                    actions yourself, please contact Us to assist You. This also enables You to receive a copy of the
                    Personal Data We hold about You.</li>
                <li><strong>Request correction of the Personal Data that We hold about You.</strong> You have the right
                    to to have any incomplete or inaccurate information We hold about You corrected.</li>
                <li><strong>Object to processing of Your Personal Data.</strong> This right exists where We are relying
                    on a legitimate interest as the legal basis for Our processing and there is something about Your
                    particular situation, which makes You want to object to our processing of Your Personal Data on this
                    ground. You also have the right to object where We are processing Your Personal Data for direct
                    marketing purposes.</li>
                <li><strong>Request erasure of Your Personal Data.</strong> You have the right to ask Us to delete or
                    remove Personal Data when there is no good reason for Us to continue processing it.</li>
                <li><strong>Request the transfer of Your Personal Data.</strong> We will provide to You, or to a
                    third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable
                    format. Please note that this right only applies to automated information which You initially
                    provided consent for Us to use or where We used the information to perform a contract with You.</li>
                <li><strong>Withdraw Your consent.</strong> You have the right to withdraw Your consent on using your
                    Personal Data. If You withdraw Your consent, We may not be able to provide You with access to
                    certain specific functionalities of the Service.</li>
            </ul>

            <h6 class="policy-area-h6">Exercising of Your GDPR Data Protection Rights</h6>
            <p>You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us.
                Please note that we may ask You to verify Your identity before responding to such requests. If You make
                a request, We will try our best to respond to You as soon as possible.</p>
            <p>You have the right to complain to a Data Protection Authority about Our collection and use of Your
                Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your
                local data protection authority in the EEA.</p>
        </section>
        <section id="Children-Privacy">
            <h3 class="policy-area-h">5. Children's Privacy</h3>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
                identifiable information from anyone under the age of 13. If You are a parent or guardian and You are
                aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We
                have collected Personal Data from anyone under the age of 13 without verification of parental consent,
                We take steps to remove that information from Our servers.</p>
            <p>We also may limit how We collect, use, and store some of the information of Users between 13 and 18 years
                old. In some cases, this means We will be unable to provide certain functionality of the Service to
                these users.</p>
            <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
                consent from a parent, We may require Your parent's consent before We collect and use that information.
            </p>
        </section>
        <section id="Links-to-Other-Websites">
            <h3 class="policy-area-h">6. Links to Other Websites</h3>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
                party link, You will be directed to that third party's site. We strongly advise You to review the
                Privacy Policy of every site You visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of
                any third party sites or services.</p>
        </section>
        <section id="Changes">
            <h3 class="policy-area-h">7. Changes to this Privacy Policy</h3>
            <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy Policy on this page.</p>
            <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective and update the "Last updated" date at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.</p>
        </section>
        <section id="Contact-Us">
            <h3 class="policy-area-h">20. Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
                <li>By email: info@selleasy.co</li>
                <li>By phone: +234 913 455 5555</li>
            </ul>
        </section>
    </div>
</div>
