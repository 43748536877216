<div class="paginated-list">
    <div class="paginated-list__header">
        <div class="paginated-list__title">
            <ng-content></ng-content>
        </div>
        <div class="paginated-list__controls"
             *ngIf="isSelected">
            <span class="paginated-list__spaced-right pointer"
                  (click)="removeNotification()">mark as read</span>
            <se-icon [md]="true"
                     iconName="delete"
                     (click)="removeNotification()">
            </se-icon>
        </div>
        <mat-paginator [hidden]="isSelected"
                       color="primary"
                       hidePageSize
                       [pageSizeOptions]="[10, 15, 20]">
        </mat-paginator>
    </div>
    <div class="paginated-list__table-wrapper">
        <table mat-table
               *ngIf="data?.length else empty"
               [dataSource]="dataSource">
            <ng-container matColumnDef="select">
                <th mat-header-cell
                    *matHeaderCellDef>
                    <mat-checkbox color="primary"
                                  (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                                  [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell
                    *matCellDef="let row"
                    class="paginated-list__select">
                    <mat-checkbox color="primary"
                                  (click)="$event.stopPropagation()"
                                  (change)="deleteRow(row); $event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)"
                                  [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell
                    *matHeaderCellDef>Type</th>
                <td mat-cell
                    *matCellDef="let notifications"
                    class="paginated-list__icon">
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell
                    *matHeaderCellDef>Name</th>
                <td mat-cell
                    *matCellDef="let notifications">
                    <span #noticeName
                          class="d-none"
                          [innerHTML]="notifications.name"></span>
                    <span [innerHTML]="noticeName.textContent"></span>
                </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"
                (click)="deleteRow(row); selection.toggle(row)"></tr>
        </table>
        <ng-template #empty>
            <div class="d-flex justify-content-center p-3">
                You have no transactions
            </div>
        </ng-template>
    </div>
</div>
