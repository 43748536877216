<div class="pt-3 plan-button-grp">
    <mat-button-toggle-group (change)="onPlanSelected($event)">
        <mat-button-toggle value="monthly"
                           [checked]="true">PAY MONTHLY</mat-button-toggle>
        <mat-button-toggle value="annually">PAY ANNUALLY <span>(Save 5%)</span></mat-button-toggle>
    </mat-button-toggle-group>
</div>
<div class="plan row">
    <div class="plan__free col-md-6">
        <div class="plan__paid-heading">
            <div>
                <h1>Basic Plan</h1>
                <div class="d-flex">
                    <h2><span>&#8358;</span>{{1499.99 | number : '1.2-2'}}</h2><span>/month</span>
                </div>
            </div>
            <div class="plan__free-icon"></div>
        </div>
        <div class="plan__free-body">
            <div class="row m-0">
                <div class="col-md-6 pl-0">
                    <div *ngFor="let plan of plan.free.slice(0, 10)">
                        <se-icon *ngIf="paidOnly[plan] else notAvailable"
                                 iconName="red-cancel-icon"
                                 [sm]="true">
                        </se-icon>
                        <ng-template #notAvailable>
                            <se-icon iconName="check-mark"
                                     [md]="true">
                            </se-icon>
                        </ng-template>
                        <span>{{plan}}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div *ngFor="let plan of plan.free.slice(10)">
                        <se-icon *ngIf="paidOnly[plan] else notAvailable"
                                 iconName="red-cancel-icon"
                                 [sm]="true">
                        </se-icon>
                        <ng-template #notAvailable>
                            <se-icon iconName="check-mark"
                                     [md]="true">
                            </se-icon>
                        </ng-template>
                        <span>{{plan}}</span>
                    </div>
                </div>
            </div>
            <br />
            <div id="selectFreePlanButton">
                <button mat-flat-button
                        (click)="selectPlan(basicPlan)"
                        color="primary"
                        class="se-button"
                        *ngIf="showSelectFreeBtn() else showSubscribed ">
                    SELECT PLAN
                </button>
            </div>
            <ng-template #showSubscribed>
                <span class="plan__free-subscribed"
                      *ngIf="subscribedPlan === freePlan">You are on a 30-day free trial
                </span>

                <div class="mt-3 mb-3 pt-2"
                     *ngIf="subscribedPlan === basicPlan">
                    <button mat-stroked-button
                            color="primary"
                            class="se-button__accent"
                            disabled>
                        ACTIVE
                    </button>
                    &nbsp;
                    <button *ngIf="isSubscriptionVerified; else notVerified"
                            mat-stroked-button
                            color="primary"
                            class="se-button__accent"
                            disabled>
                        VERIFIED
                    </button>
                    <ng-template #notVerified>
                        <button mat-stroked-button
                                color="primary"
                                class="se-button__accent"
                                disabled>
                            NOT VERIFIED
                        </button>
                    </ng-template>
                    <p *ngIf="isSubscriptionVerified">Next payment due: {{nextDue | date}}</p>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="plan__paid col-md-6">
        <div class="plan__paid-heading">
            <div>
                <h1>Premium Plan</h1>
                <div class="d-flex"
                     *ngIf="!showAnnualFee else annual">
                    <h2><span>&#8358;</span>{{(monthlyFee || 2999.99) | number : '1.2-2'}}</h2><span>/month</span>
                </div>
                <ng-template #annual>
                    <div class="plan__annual-fee">
                        <div class="strike"><span>&#8358;</span>{{(monthlyFee || 2999.99) | number : '1.2-2'}}</div>
                        <div class="d-flex">
                            <h2><span>&#8358;</span>{{(monthlyFeeDiscounted || 2849.99) | number : '1.2-2'}}</h2>
                            <span>/month</span>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="plan__paid-icon"></div>
        </div>
        <div class="plan__paid-body">
            <div class="row m-0">
                <div class="col-md-6 pl-0">
                    <div *ngFor="let plan of plan.paid.slice(0, 10)">
                        <se-icon iconName="check-mark"
                                 [md]="true">
                        </se-icon>
                        <span>{{plan}}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div *ngFor="let plan of plan.paid.slice(10)">
                        <se-icon iconName="check-mark"
                                 [md]="true">
                        </se-icon>
                        <span>{{plan}}</span>
                    </div>
                </div>
            </div>
            <div class="mt-3 mb-3 pt-2"
                 [class]="showSelectPremiumBtn() ? 'd-block' : 'd-none'">
                <button mat-flat-button
                        (click)="pay()"
                        color="primary"
                        class="se-button">
                    SELECT PLAN
                </button>
                <button hidden
                        #payBtn
                        angular-rave
                        (callback)="verifyPayment($event)"
                        [raveOptions]="paymentService.paymentOptions">
                </button>
            </div>
            <div class="mt-3 mb-3 pt-2"
                 *ngIf="isSubscribed && subscribedPlan === monthlyPlan && !showAnnualFee">
                <button mat-stroked-button
                        color="primary"
                        class="se-button__accent"
                        disabled>
                    ACTIVE
                </button>
                &nbsp;
                <button *ngIf="isSubscriptionVerified; else notVerified"
                        mat-stroked-button
                        color="primary"
                        class="se-button__accent"
                        disabled>
                    VERIFIED
                </button>
                <ng-template #notVerified>
                    <button mat-stroked-button
                            color="primary"
                            class="se-button__accent"
                            disabled>
                        NOT VERIFIED
                    </button>
                </ng-template>
                <p *ngIf="isSubscriptionVerified"
                   class="spaced-top-mini">Next payment due: {{nextDue | date}}</p>
            </div>
            <div class="mt-3 mb-3 pt-2"
                 *ngIf="isSubscribed && subscribedPlan === yearlyPlan && showAnnualFee">
                <button mat-stroked-button
                        color="primary"
                        class="se-button__accent"
                        disabled>
                    ACTIVE
                </button>
                &nbsp;
                <button *ngIf="isSubscriptionVerified; else notVerified"
                        mat-stroked-button
                        color="primary"
                        class="se-button__accent"
                        disabled>
                    VERIFIED
                </button>
                <ng-template #notVerified>
                    <button mat-stroked-button
                            color="primary"
                            class="se-button__accent"
                            disabled>
                        NOT VERIFIED
                    </button>
                </ng-template>
                <p *ngIf="isSubscriptionVerified">Next payment due: {{nextDue | date}}</p>
            </div>

            <button *ngIf="isSubscribed && subscribedPlan === monthlyPlan && showAnnualFee"
                    mat-flat-button
                    (click)="pay()"
                    color="primary"
                    class="se-button mt-3 mb-5 pt-2">
                SELECT PLAN
            </button>


        </div>
    </div>
