import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { ProfileBusinessService } from 'src/app/shared/services/profile-business-service';
import { StateFacade } from 'src/app/shared/services/state.facade';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  constructor(
    private router: Router,
    private stateFacade: StateFacade,
    private profileService: ProfileBusinessService,
  ) {}

  isComponentActive = true;

  ngOnInit(): void {
    localStorage.setItem('splashScreen', 'true');
    this.getWebsiteUrl();
  }

  gotoWebsiteDesign() {
    this.router.navigateByUrl('/add-product/:id');
  }

  getWebsiteUrl() {
    this.profileService
      .getBusinessProfile()
      .pipe(takeWhile(() => this.isComponentActive))
      .subscribe(({ customDomainName, websiteName }) => {
        if (customDomainName) {
          this.stateFacade.setWebsiteUrl(`https://${customDomainName}`);
          return;
        }
        this.stateFacade.setWebsiteUrl(
          environment.path.replace('$websiteName', websiteName),
        );
      });
  }
}
