import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IS_MOBILE_APP } from '../factories/user-agent-factory';

@Injectable()
export class UserAgentGuard implements CanActivate {
  constructor(
    @Inject(IS_MOBILE_APP) private isMobileApp: Observable<boolean>,
  ) {}

  canActivate() {
    return this.isMobileApp.pipe(map((v) => !v));
  }
}
