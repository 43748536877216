<div class="container main-page">
    <div class="row">
        <app-header-title [title]="pageTitle"
                          subTitle="{{discount.editMode ? 'Update discount on your products' : 'Add discount to your products'}}">
        </app-header-title>
    </div>

    <app-spinner *ngIf="isSubmitting"></app-spinner>

    <form [formGroup]="discountsForm"
          autocomplete="off">
        <div class="row discount-content">
            <mat-form-field appearance="outline"
                            class="d-none"
                            [ngClass]="type.errors ? 'mb-4' : ''">
                <mat-label>Discount type</mat-label>
                <mat-select class="mat-elevation-z0"
                            id="typeId"
                            [formControl]="type"
                            (selectionChange)="onTypeChange($event)">
                    <mat-option *ngFor="let type of discountTypes"
                                [value]="type.value">
                        {{type.label}}
                    </mat-option>
                </mat-select>
                <mat-error>You can use amount for only selected products
                </mat-error>
            </mat-form-field>
            <div class="col-md-6 pl-0"
                 [ngClass]="isMobile ? 'pr-0' : ''">
                <mat-form-field appearance="outline"
                                class="se-input-field mb-4">
                    <mat-label>Discount name</mat-label>
                    <input type="text"
                           id="nameId"
                           formControlName="name"
                           matInput>
                    <mat-error>
                        <div *ngIf="name.hasError('required')">
                            Please enter a discount name
                        </div>
                        <div *ngIf="name.hasError('pattern')">
                            Discount name cannot contain special characters like .,* etc
                        </div>
                    </mat-error>
                    <mat-hint>Ex. New Year Promo</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-md-6 pr-0"
                 [ngClass]="isMobile ? 'pl-0' : ''">
                <mat-form-field appearance="outline"
                                class="se-input-field">
                    <mat-label>Discount value in percent</mat-label>
                    <input type="number"
                           min="0"
                           max="90"
                           id="valueId"
                           formControlName="value"
                           matInput>
                    <mat-error>
                        <div *ngIf="value.hasError('required')">
                            Please enter a discount value
                        </div>
                        <div *ngIf="value.hasError('invalidNumber')">
                            Please enter a valid discount value
                        </div>
                        <div *ngIf="value.hasError('min')">
                            Minimum value of 1%
                        </div>
                        <div *ngIf="value.hasError('max')">
                            Maximum value of 90%
                        </div>
                    </mat-error>
                    <mat-hint>Ex. 30</mat-hint>
                    <div *ngIf="type.value === 'percentage' else amountTpl"
                         class="body-text-2 se-suffix"
                         matSuffix>%</div>
                    <ng-template #amountTpl>
                        <div class="body-text-2 se-suffix"
                             matSuffix>₦</div>
                    </ng-template>
                </mat-form-field>
            </div>
            <div class="col-12 pl-0 mt-4">
                <div class="discount-radios">
                    <label>Apply this discount to</label>
                    <mat-radio-group class="discount-radio-group"
                                     color="primary"
                                     formControlName="scope"
                                     (change)="onScopeChange($event)">
                        <mat-radio-button class="discount-radio-button"
                                          *ngFor="let _scope of discountScopes"
                                          [disabled]="_scope.value === 'product' && products && products.length === 0"
                                          [value]="_scope.value">
                            {{_scope.label}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <app-select-products *ngIf="products?.length"
                                 #productsList
                                 [discountApplied]="appliedDiscount"
                                 [products]="products"
                                 [selectedProductsCtrl]="selectedProductsCtrl"
                                 [preSelectedProds]="discount?.discount?.products"
                                 [deselectAll]="deselectAllProducts"
                                 class="w-100"
                                 [ngClass]="{'error-border': false}"
                                 [hidden]="scopeCtrl.value !== 'product'">
            </app-select-products>

            <div class="col-12 pl-0 pr-0"
                 [ngClass]="scopeCtrl.value === 'product' ? 'mt-5': 'mt-4'">
                <div>
                    <h4 class="field-title">Discount Duration</h4>
                    <p>This discount will begin on the start date and expire after the end date {{ isMobile ? 'Click the
                        calendar icon
                        to enter date' : ''}}</p>
                </div>
                <mat-form-field class="col-md-6 pl-0"
                                [ngClass]="isMobile ? 'pr-0 mb-4' : ''"
                                appearance="outline">
                    <mat-label>DD/MM/YYYY
                        {{ isMobile ? '(Start Date)' : '(Click on the calendar icon to enter start date)' }}</mat-label>
                    <input matInput
                           id="startDateId"
                           [matDatepicker]="picker1"
                           [min]="minDate"
                           [max]="maxDate"
                           [formControl]="startDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="picker1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <mat-hint>Click on the calendar icon on the right to enter start date</mat-hint>
                    <mat-error>
                        <div
                             *ngIf="startDate.hasError('required') && !startDate.hasError('matDatepickerParse') else startDateErr">
                            Please click on the calendar icon on the right to enter start date
                        </div>
                        <ng-template #startDateErr>
                            <div>
                                Please click on the calendar icon on the right to enter a valid date
                            </div>
                        </ng-template>

                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-md-6 pr-0"
                                [ngClass]="isMobile ? 'pl-0' : ''"
                                appearance="outline">
                    <mat-label>DD/MM/YYYY
                        {{ isMobile ? '(End Date)' : '(Click on the calendar icon to enter end date)' }}</mat-label>
                    <input matInput
                           id="endDateId"
                           [min]="expMinDate"
                           [max]="maxDate"
                           [matDatepicker]="picker2"
                           [formControl]="endDate">
                    <mat-datepicker-toggle matSuffix
                                           [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-hint>Click on the calendar icon on the right to enter end date</mat-hint>
                    <mat-error>
                        <div *ngIf="endDate.hasError('required') && !endDate.hasError('matDatepickerParse')">
                            Please click on the calendar icon on the right to enter end date
                        </div>
                        <div *ngIf="endDate.hasError('matDatepickerParse')">
                            Please click on the calendar icon on the right to enter a valid date
                        </div>
                        <div *ngIf="endDate.hasError('invalidEndDate')">
                            Please select an end date on or after selected start date
                        </div>
                    </mat-error>
                </mat-form-field>
            </div>
            <div *ngIf="scopeCtrl.value === 'order'"
                 class="col-12 mt-5 pl-0 pr-0">
                <div>
                    <h4 class="field-title">Discount Code (Coupon)</h4>
                    <p>Enter a discount code you will like to share with your customers e.g.
                        NEWYEAR{{ nextYear }}
                        or click the <b>Generate</b> button to generate one</p>
                </div>
                <mat-form-field appearance="outline"
                                class="se-input-field">
                    <mat-label>Discount code {{ isOrderSelected ? '' : '(optional)'}}</mat-label>
                    <input type="text"
                           id="discountCodeId"
                           formControlName="discountCode"
                           matInput>
                    <mat-error>
                        <div *ngIf="value.hasError('required')">
                            Please enter a discount code or click the button generate one
                        </div>
                        <div *ngIf="false">
                            Sorry, this discount code is not available
                        </div>
                    </mat-error>
                    <mat-hint>Enter a discount code or click button on the right to generate one</mat-hint>
                    <div class="se-suffix"
                         matSuffix>
                        <button type="button"
                                (click)="generateCode()"
                                mat-stroked-button
                                color="primary"
                                class="se-button--stroke discount-generate">
                            Generate
                        </button>
                    </div>
                </mat-form-field>
            </div>

            <app-error-alert class="m-auto"
                             *ngIf="selectedProductsCtrl?.hasError('noSelectedProducts') && isSaveClicked"
                             [text]="value.value && value.value.toString().trim() ? 'You have not selected any products that this discount can be applied to. Please select the products from the product list' : 'Please enter a discount value and select products to apply discount'">
            </app-error-alert>

            <div class="mt-5 mb-5 d-flex justify-content-between w-100">
                <button type="button"
                        mat-stroked-button
                        (click)="save()"
                        color="primary"
                        class="se-button se-button--stroke">
                    SAVE
                </button>
                <button type="button"
                        mat-flat-button
                        (click)="save(true)"
                        color="primary"
                        class="se-button">
                    SAVE & VIEW {{ isMobile ? '' : 'DISCOUNTS'}}
                </button>
            </div>
        </div>
    </form>
</div>
