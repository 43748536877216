<div class="container manage-product main-page">
    <div class="row">
        <app-header-title title="Manage Products"
                          subTitle="Manage your product inventory and view product stats">
        </app-header-title>
    </div>
    <div class="row">
        <app-spinner *ngIf="isLoading">
        </app-spinner>

        <ng-template #emptyState>
            <div class="p-5 text-center flex-column">
                <div>You have not added any products</div>
                <button (click)="gotoAddProduct()"
                        mat-flat-button
                        color="primary"
                        class="se-button mt-3">
                    Add Product
                </button>
            </div>
        </ng-template>

        <app-data-table class="w-100"
                        [isLoading]="isLoading"
                        [dataSource]="dataSource"
                        [displayedColumns]="columnsToDisplay"
                        [showHeaderActions]="true"
                        [showSelectBoxes]="true"
                        [emptyStateTemplate]="emptyState"
                        [stickyHeader]="true"
                        (onSelectionInit)="onSelectionModelInit($event)"
                        (onRowSelected)="onRowSelected($event)"
                        matSort
                        #sort="matSort">

            <!-- For extra header actions if needed -->
            <app-data-table-header-actions class="d-flex">
                <div *ngIf="isMobile">
                    <button mat-icon-button
                            class="manage-product__more"
                            [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <div class="manage-product__menu">
                            <button class="d-flex"
                                    (click)="route.navigate(['add-product/:id'])"
                                    mat-menu-item>
                                <se-icon iconName="add-orange"
                                         [lg]="true">
                                </se-icon>
                                <span>Add Product</span>
                            </button>
                            <button mat-menu-item
                                    (click)="editSelectedProduct()"
                                    [disabled]="!selectedRows.length || selectedRows.length > 1">
                                <se-icon iconName="edit-green">
                                </se-icon>
                                <span>Edit Product</span>
                            </button>
                            <button class="d-flex"
                                    (click)="duplicateProduct()"
                                    [disabled]="!selectedRows.length || selectedRows.length > 1"
                                    mat-menu-item>
                                <se-icon iconName="duplicate">
                                </se-icon>
                                <span>Create Duplicate</span>
                            </button>
                            <button class="d-flex"
                                    (click)="deleteSelectedProduct()"
                                    [disabled]="!selectedRows.length"
                                    mat-menu-item>
                                <se-icon iconName="delete">
                                </se-icon>
                                <span>Delete Product</span>
                            </button>
                            <button mat-menu-item
                                    class="whatsapp"
                                    (click)="shareOnSocial('whatsapp')"
                                    [disabled]="!selectedRows.length || selectedRows.length > 1">
                                <div class="d-flex">
                                    <se-icon iconName="whatsapp"
                                             [xl]="true"></se-icon>
                                    <span>Share on WhatsApp</span>
                                </div>
                            </button>
                            <button class="d-flex pl-3 pr-2"
                                    matTooltip="Mark product(s) and its variants out of stock"
                                    matTooltipPosition="above"
                                    (click)="markAsOutOfStockMayBe()"
                                    [disabled]="!selectedRows.length"
                                    mat-menu-item>
                                <se-icon iconName="disable"
                                         [lg]="true">
                                </se-icon>
                                <span>Mark as Out of Stock</span>
                            </button>
                            <button mat-menu-item
                                    [disabled]="hideDownloadButton"
                                    (click)="openReportsModal()">
                                <se-icon iconName="download">
                                </se-icon>
                                <span>Download Report</span>
                            </button>
                        </div>
                    </mat-menu>
                </div>

                <div class="d-flex"
                     *ngIf="!isMobile">
                    <button class="d-flex"
                            matTooltip="Add product"
                            matTooltipPosition="above"
                            [ngClass]="{'animated heartBeat': selectedRows.length === 1 || !hideDownloadButton}"
                            (click)="route.navigate(['add-product/:id'])"
                            mat-menu-item>
                        <se-icon iconName="add-orange"
                                 [lg]="true">
                        </se-icon>
                    </button>
                    <button class="d-flex"
                            matTooltip="Edit product"
                            matTooltipPosition="above"
                            (click)="editSelectedProduct()"
                            [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                            [disabled]="!selectedRows.length || selectedRows.length > 1"
                            mat-menu-item>
                        <se-icon iconName="edit-green"
                                 [lg]="true">
                        </se-icon>
                    </button>
                    <button class="d-flex pr-1"
                            matTooltip="Create duplicate"
                            matTooltipPosition="above"
                            [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                            (click)="duplicateProduct()"
                            [disabled]="!selectedRows.length || selectedRows.length > 1"
                            mat-menu-item>
                        <se-icon iconName="duplicate"
                                 [lg]="true">
                        </se-icon>
                    </button>
                    <button class="d-flex pr-1"
                            matTooltip="Delete product"
                            matTooltipPosition="above"
                            [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                            (click)="deleteSelectedProduct()"
                            [disabled]="!selectedRows.length"
                            mat-menu-item>
                        <se-icon iconName="delete"
                                 [lg]="true">
                        </se-icon>
                    </button>
                    <button class="d-flex pl-3 pr-2"
                            matTooltip="Share on WhatsApp"
                            matTooltipPosition="above"
                            [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                            (click)="shareOnSocial('whatsapp')"
                            [disabled]="!selectedRows.length || selectedRows.length > 1"
                            mat-menu-item>
                        <se-icon iconName="whatsapp-lg"
                                 [lg]="true">
                        </se-icon>
                    </button>
                    <button class="d-flex pl-3 pr-2"
                            matTooltip="Mark product(s) and its variants out of stock"
                            matTooltipPosition="above"
                            [ngClass]="{'animated heartBeat': selectedRows.length === 1}"
                            (click)="markAsOutOfStockMayBe()"
                            [disabled]="!selectedRows.length"
                            mat-menu-item>
                        <se-icon iconName="disable"
                                 [xl]="true">
                        </se-icon>
                    </button>

                    <!-- Hide useless feature temporarily on desktop -->
                    <!-- <button class="d-flex"
                            matTooltip="Download product reports"
                            matTooltipPosition="above"
                            [ngClass]="{'animated heartBeat': !hideDownloadButton}"
                            [disabled]="hideDownloadButton"
                            (click)="openReportsModal()"
                            mat-menu-item>
                        <se-icon class="mt-2"
                                 iconName="download"
                                 [lg]="true">
                        </se-icon>
                    </button> -->
                </div>

            </app-data-table-header-actions>


            <!-- Setup table content (rows and columns) -->

            <!-- name Column -->
            <ng-container matColumnDef="name"
                          sticky>
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <div class="ml-3">
                        Product
                    </div>
                </th>
                <td mat-cell
                    *matCellDef="let el">
                    <div class="data-table__img">
                        <img [src]="el.imageUri[0]"
                             alt="selleasy image">
                        <span>{{el.name}}</span>
                    </div>
                </td>
            </ng-container>

            <!-- numberOfViews Column -->
            <ng-container matColumnDef="inStock">
                <th class="p-0"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else inStockRef">
                        In Stock
                    </span>
                    <ng-template #inStockRef>
                        <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                             class="manage-product__last-col ml-3">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                In Stock
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    *matCellDef="let el"> {{el.quantity}} </td>
            </ng-container>

            <!-- numberOfPurchase Column -->
            <ng-container matColumnDef="numberOfPurchase">
                <th class="p-0"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else purchasedRef">No. of Purchases</span>
                    <ng-template #purchasedRef>
                        <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                             class="manage-product__last-col ml-3">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Purchases
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    *matCellDef="let el"> {{el.purchased}} </td>
            </ng-container>

            <!-- openOrder Column -->
            <ng-container matColumnDef="openOrder">
                <th class="p-0"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else openOrderRef">Open Order</span>
                    <ng-template #openOrderRef>
                        <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                             class="manage-product__last-col ml-3">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                Orders
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>
                </th>
                <td mat-cell
                    *matCellDef="let el"> {{el.openOrder}} </td>
            </ng-container>

            <!-- inStock Column -->
            <ng-container matColumnDef="numberOfViews">
                <th class="p-0"
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    <span *ngIf="!isMobile else numberOfViewsRef">No. of Views</span>
                    <ng-template #numberOfViewsRef>
                        <div [ngClass]="{'ml-5 pl-5': !dataSource.data?.length}"
                             class="manage-product__last-col ml-3">
                            <button mat-stroked-button
                                    color="primary"
                                    class="se-button se-button--stroke">
                                {{isMobile ? 'Views' : 'No. of Views'}}
                            </button>
                            <mat-form-field appearance="outline">
                                <mat-label></mat-label>
                                <mat-select class="mat-elevation-z0"
                                            (selectionChange)="onSelectChange($event)">
                                    <mat-option *ngFor="let col of columnsSelect"
                                                [value]="col.value">
                                        {{col.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-template>

                </th>
                <td mat-cell
                    *matCellDef="let el"> {{el.views}} </td>
            </ng-container>

        </app-data-table>
    </div>
</div>
