import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth-service';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProfileBusinessService {
  private readonly BASEURL = (window as any).API_BASE_URL;
  businessConfigSub$ = new BehaviorSubject<any>({ subscriptionExpired: false });

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {}

  getCustomer(customerId: string) {
    // TODO: only return necessary fields
    const id = this.authService.getBusinessId();
    const token = this.authService.getAuthtoken();
    const path = `${this.BASEURL}/storefront/user/${customerId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .get(path, httpOptions)
      .pipe(map((result: any) => result?.firstName + ' ' + result?.lastName));
  }

  getLgas(stateId: string) {
    const id = this.authService.getBusinessId();
    const path = `${this.BASEURL}/businesses/shipping/lgas/${stateId}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(path, httpOptions).pipe(map((result) => result));
  }

  getStates() {
    const id = this.authService.getBusinessId();
    const path = `${this.BASEURL}/businesses/shipping/states`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(path, httpOptions).pipe(map((result) => result));
  }

  getDropOffLocations() {
    const id = this.authService.getBusinessId();
    const path = `${this.BASEURL}/businesses/shipping/locations`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(path, httpOptions).pipe(map((result) => result));
  }

  getBusinessProfile() {
    const id = this.authService.getBusinessId();
    const path = `${this.BASEURL}/businesses/${id}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .get<any>(path, httpOptions)
      .pipe(tap((config) => this.businessConfigSub$.next(config)));
  }

  patchBusinessProfile(data) {
    const id = this.authService.getUserId();
    const businessId = this.authService.getBusinessId();
    const path = `${this.BASEURL}/users/${id}/business/${businessId}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .patch(path, data, httpOptions)
      .pipe(map((result) => result));
  }

  patchProfile(data) {
    const id = this.authService.getUserId();
    const path = `${this.BASEURL}/users/${id}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient
      .patch(path, data, httpOptions)
      .pipe(map((result) => result));
  }

  getProfile() {
    const id = this.authService.getUserId();
    const path = `${this.BASEURL}/users/${id}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(path, httpOptions).pipe(map((result) => result));
  }

  getPaymentPlans() {
    const path = `${this.BASEURL}/businesses/paymentplans`;
    return this.httpClient.get(path).pipe(map((result) => result));
  }

  verifySubscription(ref: string) {
    const id = this.authService.getUserId();
    const path = `${this.BASEURL}/businesses/subscription/${ref}`;
    const token = this.authService.getAuthtoken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.httpClient.get(path, httpOptions).pipe(map((result) => result));
  }
}
