import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-table-header-actions',
  templateUrl: './data-table-header-actions.component.html',
  styleUrls: ['./data-table-header-actions.component.scss'],
})
export class DataTableHeaderActionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
