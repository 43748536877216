import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { StateFacade } from 'src/app/shared/services/state.facade';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  demoStoreUrl = ['https://demo.selleasy.ng'];
  isSmall;

  constructor(private router: Router, private stateFacade: StateFacade) {
    this.isSmall = stateFacade
      .getViewPortSize()
      .pipe(map(({ isMobile }) => isMobile));
  }

  get isLoggedIn() {
    return localStorage.getItem('AUTH_TOKEN');
  }

  get demoUrl() {
    return this.demoStoreUrl[
      Math.floor(Math.random() * this.demoStoreUrl.length)
    ];
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }

  scrollToHowItWorks() {
    document.querySelector('#howItWorksSection').scrollIntoView({
      behavior: 'smooth',
    });
  }
}
