export function TruncateText(text: string, length = 100) {
  const ending = '...';
  if (text.length > length) {
    return text.substring(0, length - ending.length) + ending;
  } else {
    return text;
  }
}

export function capitalizeString(s: string) {
  return s.length > 0 ? s.charAt(0).toUpperCase() + s.slice(1) : '';
}
