<div class="container main-page view-order">
    <div class="row">
        <app-header-title title="Order Details"
                          [showBackButton]="true">
        </app-header-title>
    </div>
    <div id="scrollPos"></div>
    <button mat-flat-button
            *ngIf="isMobile"
            (click)="navBack()"
            class="se-button view-order__back-btn">
        <div class="d-flex align-items-center view-order__back-btn-inner">
            <se-icon iconName="back-icon"></se-icon>
            <span>BACK</span>
        </div>
    </button>
    <section *ngFor="let product of orderDetails?.products; let last = last;"
             class="row view-order__content">
        <div class="col-md-6 pl-2 pr-2">
            <img [src]="product.imageUri"
                 alt="selleasy-order-image">
        </div>
        <div class="col-md-6 pl-2 pr-2">
            <h1>₦{{product.price}}</h1>
            <h4>{{product.name}}</h4>
            <ul>
                <li>
                    <h6>Order Number:</h6>
                    <span>{{product.orderId}}</span>
                </li>
                <li>
                    <h6>Status:</h6>
                    <span>{{formatStr(status[product.status])}}</span>
                </li>
                <li>
                    <h6>Quantity Bought</h6>
                    <span>{{product.quantityBought}}</span>
                </li>
                <li>
                    <h6>Mode of delivery:</h6>
                    <span>{{deliveryTypes[deliveryType] ? 'Selleasy Delivery' : 'Self Delivery'}}</span>
                </li>
                <li>
                    <h6>Delivery Charge:</h6>
                    <span>{{product.deliveryCharge | currency:'₦':'symbol':'4.2-2'}}</span>
                </li>
                <li>
                    <h6>Service Charge</h6>
                    <span>{{product.serviceCharge | currency:'₦':'symbol':'4.2-2'}}</span>
                </li>
            </ul>
            <div class="d-flex justify-content-between view-order__ctas">
                <button mat-stroked-button
                        *ngIf="isMobile && last"
                        (click)="navBack()"
                        color="primary"
                        class="se-button se-button--stroke mt-3">
                    BACK
                </button>
                <button [hidden]="true"
                        mat-flat-button
                        [disabled]="!isSelfDelivery"
                        (click)="confirmDelivery()"
                        color="primary"
                        class="se-button">
                    CONFIRM DELIVERY
                </button>
            </div>
        </div>
    </section>
</div>
