import { Component, OnInit } from '@angular/core';
import {
  DashboardService,
  INotifications,
} from '../shared/services/dashboard.service';
import { StateFacade } from '../shared/services/state.facade';
import { AuthService } from '../shared/services/auth-service';
import { Notification } from '../shared/models/notification';
import { CalculatedateRange } from '../shared/utils/dates';
import { forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { defer } from 'lodash';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  title = 'Dashboard';
  siteVisits = 0;
  ordersPlaced = 0;
  grossAmount = 0;
  notifications: INotifications[];
  info: INotifications[];
  alerts: INotifications[];
  warnings: INotifications[];
  duration = ['Week', 'Month', 'Today'];
  visitSelected = this.duration[1];
  orderSelected = this.duration[1];
  amountSelected = this.duration[1];
  isMobile: boolean;
  orderPlacedComparisonText = '';
  siteVisitsComparisonText = '';
  grossAmountComparisonText = '';
  orderPlacedTimeLapseText = '';
  siteVisitsTimeLapseText = '';
  grossAmountTimeLapseText = '';

  constructor(
    private dashboardService: DashboardService,
    private authService: AuthService,
    private stateFacade: StateFacade,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getNotifications();
    this.getVisits(this.visitSelected);
    this.getOrdersPlaced(this.orderSelected);
    this.getGrossAmount(this.amountSelected);
    this.stateFacade.getViewPortSize().subscribe((m) => {
      this.isMobile = m.isMobile;
    });
    this.activatedRoute.params.subscribe((p) => {
      if (p.fromNotifications) {
        defer(() => document.querySelector('#notifications').scrollIntoView());
      }
    });
  }

  getNotifications() {
    this.dashboardService
      .getNotifications(this.authService.getBusinessId())
      .subscribe((data) => {
        const notifications = [];
        data.forEach(
          (item: { id: string; details: any; description: any; type: any }) => {
            const details = {} as INotifications;
            details.id = item.id;
            details.name = item.details ?? item.description;
            details.type =
              typeof item.type === 'undefined'
                ? Notification.OutOfStock
                : Notification[Notification[item.type]];
            if (
              details.type.toString() === Notification.OutOfStock.toString() &&
              !details.name.includes('out of stock')
            ) {
              details.name += ' is out of stock';
            }
            notifications.push(details);
          },
        );
        this.setAll(notifications);
      });
  }

  setAll(value: INotifications[] = []) {
    this.notifications =
      this.dashboardService.getSharedNotifications() ?? value;
  }

  getall() {
    return this.dashboardService.getSharedNotifications();
  }

  getinfo() {
    return this.dashboardService.getSharedInfo();
  }

  getalerts() {
    return this.dashboardService.getSharedAlerts();
  }

  getwarnings() {
    return this.dashboardService.getSharedWarnings();
  }

  compareOrderPlace(result: any, resultPrevious: any) {
    if (resultPrevious.orders !== 0) {
      const percentageIncDec =
        ((result.orders - resultPrevious.orders) / resultPrevious.orders) * 100;
      if (percentageIncDec > 0) {
        this.orderPlacedComparisonText = `Up ${Math.round(
          Math.abs(percentageIncDec),
        )}% compared to ${this.orderPlacedTimeLapseText}`;
      } else if (percentageIncDec < 0) {
        this.orderPlacedComparisonText = `Down ${Math.round(
          Math.abs(percentageIncDec),
        )}% compared to ${this.orderPlacedTimeLapseText}`;
      } else {
        this.orderPlacedComparisonText = `No changes since ${this.orderPlacedTimeLapseText}`;
      }
    } else {
      this.orderPlacedComparisonText = '';
    }
  }

  compareSiteVisits(result: any, resultPrevious: any) {
    if (result && resultPrevious !== 0) {
      const percentageIncDec =
        ((result - resultPrevious) / resultPrevious) * 100;
      if (percentageIncDec > 0) {
        this.siteVisitsComparisonText = `Up ${Math.round(
          Math.abs(percentageIncDec),
        )}% compared to ${this.siteVisitsTimeLapseText}`;
      } else if (percentageIncDec < 0) {
        this.siteVisitsComparisonText = `Down ${Math.round(
          Math.abs(percentageIncDec),
        )}% compared to ${this.siteVisitsTimeLapseText}`;
      } else {
        this.siteVisitsComparisonText = `No changes since ${this.siteVisitsTimeLapseText}`;
      }
    } else {
      this.siteVisitsComparisonText = '';
    }
  }

  compareGrossAmount(result: any, resultPrevious: any) {
    if (result && resultPrevious !== 0) {
      const percentageIncDec =
        ((result - resultPrevious) / resultPrevious) * 100;
      if (percentageIncDec > 0) {
        this.grossAmountComparisonText = `Up ${Math.round(
          Math.abs(percentageIncDec),
        )}% compared to ${this.grossAmountTimeLapseText}`;
      } else if (percentageIncDec < 0) {
        this.grossAmountComparisonText = `Down ${Math.round(
          Math.abs(percentageIncDec),
        )}% compared to ${this.grossAmountTimeLapseText}`;
      } else {
        this.grossAmountComparisonText = `No changes since ${this.grossAmountTimeLapseText}`;
      }
    } else {
      this.grossAmountComparisonText = '';
    }
  }

  getPercIncrOrDecr(result: any, resultPrevious: any, metricsTypeText: string) {
    if (metricsTypeText === 'orderPlacedComparisonText') {
      this.ordersPlaced = result?.orders ?? 0;
      this.compareOrderPlace(result, resultPrevious);
    } else if (metricsTypeText === 'siteVisitsComparisonText') {
      this.siteVisits = Number(result);
      this.compareSiteVisits(result, resultPrevious);
    } else if (metricsTypeText === 'grossAmountComparisonText') {
      this.grossAmount = Number(result);
      this.compareGrossAmount(result, resultPrevious);
    }
  }

  /**
   * @description get gross amount
   * @param duration day, week, month
   */
  getGrossAmount(duration: string) {
    switch (duration) {
      // Week
      case this.duration[0]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          7,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        const previousFrom = calculatedDates[2];
        const previousTo = calculatedDates[3];
        forkJoin(
          this.dashboardService.getGrossAmount(
            this.authService.getBusinessId(),
            from,
            to,
          ),
          this.dashboardService.getGrossAmount(
            this.authService.getBusinessId(),
            previousFrom,
            previousTo,
          ),
        ).subscribe(([result, resultPrevious]: [any, any]) => {
          this.getPercIncrOrDecr(
            result,
            resultPrevious,
            'grossAmountComparisonText',
          );
        });
        this.grossAmountTimeLapseText = '7 days ago';
        break;
      }
      // Month
      case this.duration[1]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          true,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        const previousFrom = calculatedDates[2];
        const previousTo = calculatedDates[3];
        forkJoin(
          this.dashboardService.getGrossAmount(
            this.authService.getBusinessId(),
            from,
            to,
          ),
          this.dashboardService.getGrossAmount(
            this.authService.getBusinessId(),
            previousFrom,
            previousTo,
          ),
        ).subscribe(([result, resultPrevious]: [any, any]) => {
          this.getPercIncrOrDecr(
            result,
            resultPrevious,
            'grossAmountComparisonText',
          );
        });
        this.grossAmountTimeLapseText = '30 days ago';
        break;
      }
      // Today
      case this.duration[2]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        const previousFrom = calculatedDates[2];
        const previousTo = calculatedDates[3];
        forkJoin(
          this.dashboardService.getGrossAmount(
            this.authService.getBusinessId(),
            from,
            to,
          ),
          this.dashboardService.getGrossAmount(
            this.authService.getBusinessId(),
            previousFrom,
            previousTo,
          ),
        ).subscribe(([result, resultPrevious]: [any, any]) => {
          this.getPercIncrOrDecr(
            result,
            resultPrevious,
            'grossAmountComparisonText',
          );
        });
        this.grossAmountTimeLapseText = 'yesterday';
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * @description get orders placed
   * @param duration day, week, month
   */
  getOrdersPlaced(duration: string) {
    switch (duration) {
      // Week
      case this.duration[0]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          7,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        const previousFrom = calculatedDates[2];
        const previousTo = calculatedDates[3];
        forkJoin(
          this.dashboardService.getOrdersPlaced(
            this.authService.getBusinessId(),
            from,
            to,
          ),
          this.dashboardService.getOrdersPlaced(
            this.authService.getBusinessId(),
            previousFrom,
            previousTo,
          ),
        ).subscribe(([result, resultPrevious]: [any, any]) => {
          this.getPercIncrOrDecr(
            result,
            resultPrevious,
            'orderPlacedComparisonText',
          );
        });
        this.orderPlacedTimeLapseText = '7 days ago';
        break;
      }
      // Month
      case this.duration[1]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          true,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        const previousFrom = calculatedDates[2];
        const previousTo = calculatedDates[3];
        forkJoin(
          this.dashboardService.getOrdersPlaced(
            this.authService.getBusinessId(),
            from,
            to,
          ),
          this.dashboardService.getOrdersPlaced(
            this.authService.getBusinessId(),
            previousFrom,
            previousTo,
          ),
        ).subscribe(([result, resultPrevious]: [any, any]) => {
          this.getPercIncrOrDecr(
            result,
            resultPrevious,
            'orderPlacedComparisonText',
          );
        });
        this.orderPlacedTimeLapseText = '30 days ago';
        break;
      }
      // Today
      case this.duration[2]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        const previousFrom = calculatedDates[2];
        const previousTo = calculatedDates[3];
        forkJoin(
          this.dashboardService.getOrdersPlaced(
            this.authService.getBusinessId(),
            from,
            to,
          ),
          this.dashboardService.getOrdersPlaced(
            this.authService.getBusinessId(),
            previousFrom,
            previousTo,
          ),
        ).subscribe(([result, resultPrevious]: [any, any]) => {
          this.getPercIncrOrDecr(
            result,
            resultPrevious,
            'orderPlacedComparisonText',
          );
        });
        this.orderPlacedTimeLapseText = 'yesterday';
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * @description get site visits
   * @param duration day, week, month
   */
  getVisits(duration: string) {
    switch (duration) {
      // Week
      case this.duration[0]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          7,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        const previousFrom = calculatedDates[2];
        const previousTo = calculatedDates[3];
        forkJoin(
          this.dashboardService.getVisits(
            this.authService.getBusinessId(),
            from,
            to,
          ),
          this.dashboardService.getVisits(
            this.authService.getBusinessId(),
            previousFrom,
            previousTo,
          ),
        ).subscribe(([result, resultPrevious]: [any, any]) => {
          this.getPercIncrOrDecr(
            result,
            resultPrevious,
            'siteVisitsComparisonText',
          );
        });
        this.siteVisitsTimeLapseText = '7 days ago';
        break;
      }
      // Month
      case this.duration[1]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          true,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        const previousFrom = calculatedDates[2];
        const previousTo = calculatedDates[3];
        forkJoin(
          this.dashboardService.getVisits(
            this.authService.getBusinessId(),
            from,
            to,
          ),
          this.dashboardService.getVisits(
            this.authService.getBusinessId(),
            previousFrom,
            previousTo,
          ),
        ).subscribe(([result, resultPrevious]: [any, any]) => {
          this.getPercIncrOrDecr(
            result,
            resultPrevious,
            'siteVisitsComparisonText',
          );
        });
        this.siteVisitsTimeLapseText = '30 days ago';
        break;
      }
      // Today
      case this.duration[2]: {
        const calculatedDates = CalculatedateRange(
          new Date(Date.now()),
          1,
          false,
        );
        const from = calculatedDates[0];
        const to = calculatedDates[1];
        const previousFrom = calculatedDates[2];
        const previousTo = calculatedDates[3];
        forkJoin(
          this.dashboardService.getVisits(
            this.authService.getBusinessId(),
            from,
            to,
          ),
          this.dashboardService.getVisits(
            this.authService.getBusinessId(),
            previousFrom,
            previousTo,
          ),
        ).subscribe(([result, resultPrevious]: [any, any]) => {
          this.getPercIncrOrDecr(
            result,
            resultPrevious,
            'siteVisitsComparisonText',
          );
        });
        this.siteVisitsTimeLapseText = 'yesterday';
        break;
      }
      default: {
        break;
      }
    }
  }
}
