import {
  ComponentFactoryResolver,
  Directive,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { catchError, tap } from 'rxjs/operators';
import { ErrorBannerComponent } from '../error-banner/error-banner.component';
import { ProfileBusinessService } from '../services/profile-business-service';
import { websiteRoutes } from '../nav/nav.config';
import { combineLatest, Observable, of } from 'rxjs';
import { takeWhile, filter } from 'rxjs/operators';

@Directive({
  selector: '[appBanner]',
})
export class BannerDirective implements OnInit, OnDestroy {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private cmpFactoryResolver: ComponentFactoryResolver,
    private businessProfile: ProfileBusinessService,
    private location: Location,
  ) {}

  isComponentActive = true;

  onPathChange$: Observable<any> = new Observable((subscriber) => {
    this.location.onUrlChange((path) => {
      subscriber.next(path);
    });
  }).pipe(takeWhile(() => this.isComponentActive));

  subscriptionError$ = combineLatest([
    this.businessProfile.businessConfigSub$,
    this.onPathChange$,
  ]).pipe(
    tap(() => this.viewContainerRef.clear()),
    filter(
      ([config, path]) =>
        !websiteRoutes[path.slice(1)] && config.subscriptionExpired,
    ),
    tap(() => {
      const cmpFactory = this.cmpFactoryResolver.resolveComponentFactory(
        ErrorBannerComponent,
      );
      this.viewContainerRef.createComponent(cmpFactory);
    }),
  );

  ngOnInit() {
    this.subscriptionError$.subscribe();
  }

  ngOnDestroy() {
    this.isComponentActive = false;
  }
}
