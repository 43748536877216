export function CalculatedateRange(
  date: Date,
  duration: number,
  isMonth = false,
): [string, string, string, string] {
  const dateCalc = new Date(date);
  let d = null;
  d = calculateDate(isMonth, dateCalc, duration);
  const from = new Date(d).toJSON().split('T')[0];
  const to = new Date(setDate(new Date(), -1, false)).toJSON().split('T')[0];
  const previousFrom = isMonth
    ? new Date(calculateDate(isMonth, dateCalc, duration))
        .toJSON()
        .split('T')[0]
    : new Date(dateCalc.setDate(dateCalc.getDate() - duration))
        .toJSON()
        .split('T')[0];
  const previousTo = from;
  return [from, to, previousFrom, previousTo];
}

function calculateDate(isMonth: any, dateCalc: Date, duration: number) {
  let d = null;
  if (isMonth) {
    d = dateCalc.setMonth(dateCalc.getMonth() - duration);
    d = setDate(dateCalc, duration, isMonth);
  } else {
    d = setDate(dateCalc, duration, isMonth);
  }
  return d;
}

function setDate(dateCalc: Date, duration, isMonth: boolean) {
  if (isMonth) {
    return dateCalc.setMonth(dateCalc.getMonth());
  } else {
    return dateCalc.setDate(dateCalc.getDate() - duration);
  }
}

export function startOfToday() {
  return new Date().setHours(0, 0, 0, 0);
}

export function endOfToday() {
  return new Date().setHours(23, 59, 59, 999);
}

export function today() {
  return new Date();
}

export function isBeforeToday(date) {
  return date.getTime() < startOfToday();
}
