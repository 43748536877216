<div class="product-variant__variant">
    <div>
        <div class="d-flex align-items-center"
             id="productVariantsContainer">
            <h4 class="d-inline">Product Variants</h4>
            <ng-template #variantInfo>
                <div class="full-info-box-content">
                    <h4 class="heading-text-3--lg">Product Variants</h4>
                    <div class="no-overflow">
                        <img class="w-100"
                             src="../../../assets/variation-info.png"
                             alt="selleasy - variation description">
                    </div>
                    <div class="content-text">
                        <h5 class="mt-5">OPTIONS</h5>
                        <p>
                            Basketball as a product can come in 2 colors or more. You can specify a color option and
                            enter the colors. e.g red and black.
                            <span class="no-overflow mt-2 d-block">
                                <img class="w-100"
                                     src="../../../assets/options-info.png"
                                     alt="selleasy - options description">
                            </span>
                        </p>

                        <h5 class="mt-4">VARIANTS</h5>
                        <p>
                            The variants list will be generated after entering your options. You can enter quantity or
                            update
                            the price
                            for each variant.
                            <span class="no-overflow mt-2 d-block">
                                <img class="w-100"
                                     src="../../../assets/variants-info.png"
                                     alt="selleasy - variants description">
                            </span>
                        </p>
                        <h5 class="mt-4">WATCH A VIDEO</h5>
                        <p>
                            To learn more about variants you can also watch <a
                               href="https://www.youtube.com/watch?v=QsW6vkUhZN0&list=UUFE_BUP7y78JtMXhhyYhm5Q&index=1"
                               target="_blank">this
                                video</a>
                        </p>
                    </div>
                </div>
            </ng-template>
            <app-full-info-box #fIBox
                               class="d-inline-block ml-3"
                               [ngClass]="{'animated heartBeat infinite': !hasViewedVariantsLearnMore}"
                               (click)="removeAnimation(fIBox)"
                               [template]="variantInfo">
            </app-full-info-box>
        </div>
        <p class="d-block variant-desc">What types, shapes, colours, sizes, brands etc. can your product appear? If you
            are not sure you can click on the question mark, <a class="a-tag"
               href="https://www.youtube.com/watch?v=QsW6vkUhZN0&list=UUFE_BUP7y78JtMXhhyYhm5Q&index=1"
               target="_blank">watch a video</a> to learn more about product variants or skip
            this section.
        </p>
    </div>
    <div [class.closed]="!showProductVariant.show">
        <div class="row product-variant__list-heading product-variant__list-heading--option">
            <div class="col-3 pr-0 pl-0">Options</div>
            <div class="col-8">Values</div>
            <div class="col-1 pl-0"></div>
        </div>
        <div *ngFor="let opt of variants?.options; index as i"
             class="row mt-3"
             [ngClass]="{'mb-5': i === 0}">
            <div class="col-3 pr-0">
                <mat-form-field #matFieldRef
                                appearance="outline"
                                class="se-input-field product-variant__options-mat-field">
                    <mat-label>{{ i === 0 ? 'E.g. Colour' : i === 1 ? 'E.g. Size' : 'Option' }}</mat-label>
                    <input type="text"
                           class="product-variant__options-input"
                           #optInput
                           inputFilter
                           [attr.data-index]="i"
                           [regex]="optionRegex"
                           [value]="opt.option"
                           (input)="(opt.option = optInput.value); hasDuplicates(i, matFieldRef)"
                           (change)="checkEmptyField($event, matFieldRef)"
                           matInput>
                    <mat-hint *ngIf="optErrors[i]"
                              class="error-text">You should not have 2 options with the same
                        name
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="col-8">
                <mat-form-field appearance="outline"
                                class="se-input-field">
                    <mat-label>{{ i === 0 ? 'E.g. Red, Blue, Yellow' : i === 1 ? 'E.g. XL, L, S, XS' : 'Values' }}
                    </mat-label>
                    <mat-chip-list #chipList>
                        <mat-chip *ngFor="let val of opt?.values"
                                  [selectable]="selectable"
                                  [removable]="removable"
                                  (removed)="removeValue(val, opt)">
                            {{val}}
                            <mat-icon matChipRemove
                                      *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input inputFilter
                               [regex]="valueRegex"
                               chipsMobileHack
                               [matChipInputFor]="chipList"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="addOnBlur"
                               (matChipInputTokenEnd)="addValue($event, opt)">
                    </mat-chip-list>
                    <mat-hint *ngIf="i === 0">
                        Separate variants by a comma or by clicking out of this box
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="col-1 pl-0">
                <button mat-icon-button
                        (click)="deleteVariant(opt); hasDuplicates(i, matFieldRef)">
                    <div>
                        <se-icon iconName="delete"
                                 size="md">
                        </se-icon>
                    </div>
                </button>
            </div>
        </div>
        <div class="product-variant__add-icon">
            <button mat-mini-fab
                    class="mat-elevation-z0 se-fab-icon"
                    type="button"
                    color="primary"
                    [disabled]="hasOptError"
                    (click)="addVariant()">
                <mat-icon>add</mat-icon>
            </button>
            <div class="mt-2 mb-3">Add Variant</div>
        </div>
    </div>
</div>
<div class="product-variant__variant-list slide mb-5"
     [class.closed]="!showProductVariant.show">
    <div [ngClass]="{'error-border': hasVariantError || qtySumIsZero}">
        <h4 id="productVariantsList">VARIANTS</h4>
        <div class="row product-variant__list-heading justify-content-between">
            <div class="col-3 col-md-2 p-0">Variants</div>
            <div class="col-5 col-md-5">Qty</div>
            <div class="col-4 col-md-5 pl-0">Price</div>
        </div>
        <div class="product-variant__list-wrapper">
            <div class="row mb-2"
                 *ngFor="let variation of variants?.variations; index as i">
                <div class="col-3 col-md-2 pr-0">
                    <div *ngFor="let v of variation?.variations"
                         class="font-500">{{v}}</div>
                </div>
                <div class="col-5 col-md-5">
                    <mat-form-field appearance="outline"
                                    class="se-input-field product-variant__quantity-field"
                                    #field>
                        <mat-label>Quantity</mat-label>
                        <input type="text"
                               inputFilter
                               [regex]="numRegex"
                               #input
                               class="product-variant__variant-input"
                               [value]="variants?.variations[i].quantity"
                               (input)="variants?.variations[i].quantity = input.value; showErrorMessage(i, field)"
                               matInput>
                        <mat-hint *ngIf="errors[i]"
                                  class="product-variant__error">
                            {{ isMobile ? 'Should not exceed total quantity' : 'Quantity of variant should not exceed
                            total quantity'}}
                            <span *ngIf="totalQuantity">of {{totalQuantity}}</span>
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-4 col-md-5 pl-0">
                    <mat-form-field #matFieldRef
                                    appearance="outline"
                                    class="se-input-field product-variant__price-mat-field">
                        <mat-label>Price</mat-label>
                        <input type="number"
                               class="product-variant__price-field"
                               [ngModel]="variants?.variations[i].price === undefined ? price : variants?.variations[i].price"
                               #inputPrice="ngModel"
                               [attr.data-index]="i"
                               (change)="variants?.variations[i].price = inputPrice.value"
                               (input)="priceCannotBeZero($event, matFieldRef)"
                               matInput>
                        <div class="body-text-2 se-suffix"
                             matSuffix>₦</div>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="hasVariantError && !errorObjectList"
         class="error-text mt-2">
        <b>The sum of variants quantity is more than the total quantity</b>
    </div>
    <div *ngIf="qtySumIsZero"
         class="error-text mt-2">
        <b>All variants quantity are zero. At least one should be more than zero</b>
    </div>
</div>
