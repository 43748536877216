import { filter, map, reduce, compose } from 'lodash/fp';

export const TABLETPLUS = '(max-width: 1023px)';
export interface INavConfig {
  position: string;
  opened: boolean;
  mode: string;
}

export interface INavItem {
  name: string;
  show: boolean;
  active: boolean;
  url: string;
  icon?: string;
  showChildren?: boolean;
  children?: INavItem[];
  first?: boolean;
  childUrls?: string[];
}

export const navConfig: INavConfig = {
  position: 'start',
  opened: true,
  mode: 'over',
};

export const navItems: INavItem[] = [
  {
    name: 'Dashboard',
    show: true,
    active: false,
    url: '/dashboard',
    first: true,
    children: [],
  },
  {
    name: 'Products',
    show: true,
    active: false,
    url: '/add-product/:id',
    icon: 'book',
    showChildren: false,
    childUrls: ['manage-product', 'product-library'],
    children: [
      {
        name: 'Add Product',
        show: true,
        active: false,
        url: '/add-product/:id',
      },
      {
        name: 'Manage Products',
        show: true,
        active: false,
        url: '/manage-product',
      },
      // {
      //   name: 'Product Images',
      //   show: true,
      //   active: false,
      //   url: '/product-library',
      // },
    ],
  },
  {
    name: 'Discounts',
    show: true,
    active: false,
    url: 'discount',
    icon: 'discount',
    showChildren: false,
    childUrls: ['create-discount', 'discounts-list'],
    children: [
      {
        name: 'Add Discount',
        show: true,
        active: false,
        url: '/create-discount',
      },
      {
        name: 'View Discounts',
        show: true,
        active: false,
        url: '/discounts-list',
      },
    ],
  },
  {
    name: 'Orders & Shipping',
    show: true,
    active: false,
    url: '/orders-and-shipping',
    icon: 'truck',
    showChildren: false,
    children: [],
  },
  {
    name: 'Account',
    show: true,
    active: false,
    url: '/account',
    icon: 'account',
    childUrls: ['account-details', 'account-settings'],
    showChildren: false,
    children: [
      {
        name: 'Account',
        show: true,
        active: false,
        url: '/account',
      },
      {
        name: 'Account details',
        show: true,
        active: false,
        url: '/account-details',
      },
      {
        name: 'Account settings',
        show: true,
        active: false,
        url: '/account-settings',
      },
    ],
  },
  {
    name: 'Website',
    show: true,
    active: false,
    showChildren: false,
    url: '/website',
    icon: 'website',
    children: [],
  },
  {
    name: 'Learn',
    show: true,
    active: false,
    showChildren: false,
    url: '/learn',
    icon: 'help-icon',
    children: [],
  },
];

export const websiteRoutes = {
  home: 'home',
  signin: 'signin',
  signup: 'signup',
  'website-ready': 'website-ready',
  'forgot-password': 'forgot-password',
  'reset-password': 'reset-password',
  platform: 'platform',
  'terms-and-conditions': 'terms-and-conditions',
  'privacy-policy': 'privacy-policy',
  'refund-policy': 'refund-policy',
  'about-us': 'about-us',
  'splash-screen': 'splash-screen',
};

const filterWebsiteRoutes = filter(
  (v: any) => !v.canActivate && v.path !== '**',
);
const buildRoutesObject = (acc, curr) => {
  acc[curr] = curr;
  return acc;
};
const mapRoutePaths = map((v: any) => v.path.split('/')[0]);
const buildRoutesFn = reduce(buildRoutesObject)({});

export const buildWebsiteRoutes = compose(
  buildRoutesFn,
  mapRoutePaths,
  filterWebsiteRoutes,
);
