import { noop } from 'rxjs';

export function scrollToView(router) {
  return (id, url = '/') => {
    if (router.url !== url) {
      router.navigate([url]);
    }

    setTimeout(() => {
      document.querySelector(id).scrollIntoView({
        behavior: 'smooth',
      });
    }, 100);
  };
}

export const append234 = (val) =>
  val.toString().startsWith('+234') ? val : `+234${val.slice(1)}`;

export function adjustModalWidth(isMobile: boolean) {
  setTimeout(() => {
    isMobile
      ? ((document.querySelector('.cdk-overlay-pane') as any).style.maxWidth =
          '90vw')
      : noop();
  }, 10);
}
