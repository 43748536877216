<div class="container main-page learn">
    <app-header-title title="Learn"
                      subTitle="See videos to learn more about selleasy">
    </app-header-title>
    <div class="learn__contents row pl-3">
        <div *ngFor="let config of configList"
             class="col-12 col-md-6 col-lg-4 mb-4 pl-0">
            <mat-card class="learn__video-card mat-elevation-z">
                <mat-card-header>
                    <mat-card-title>{{ config.title }}</mat-card-title>
                    <mat-card-subtitle>{{ config.subTitle }}</mat-card-subtitle>
                </mat-card-header>
                <iframe width="100%"
                        height="315"
                        [src]="config.embeddedLink"
                        title="SellEasy YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                </iframe>
                <mat-card-actions>
                    <a class="ml-auto"
                       [href]="config.link"
                       target="_"
                       mat-button>VIEW
                    </a>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
