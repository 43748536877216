<div class="mt-3">
    <h4 class="field-title">Products</h4>
    <p>Please select the products you want to apply this discount to</p>
</div>

<mat-form-field [hidden]="true"
                appearance="outline">
    <mat-label>Search</mat-label>
    <input #seachInput
           matInput
           type="text">
    <button mat-button
            *ngIf="seachInput.value.trim()"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="seachInput.value=''">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<div class="product-tree">
    <mat-tree [dataSource]="dataSource"
              [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node"
                       matTreeNodeToggle
                       child-node
                       [ngClass]="{'d-none': !node.name}">
            <button mat-icon-button
                    disabled></button>
            <div class="child-node">
                <mat-checkbox #box
                              color="primary"
                              [checked]="checklistSelection.isSelected(node)"
                              (change)="todoLeafItemSelectionToggle(node)">
                    <div class="text">
                        <div class="node-desc">
                            <span>{{node.name}}</span>
                            <span>
                                <span class="body-text-1">
                                    <b class="title">PRICE</b>&nbsp;
                                    <span
                                          [class.strike-through]="box.checked && discountApplied && calcPercentage(node.price, discountApplied) > 0">₦{{node.price}}</span>
                                </span>
                                <span *ngIf="box.checked && discountApplied && calcPercentage(node.price, discountApplied) > 0"
                                      class="body-text-1">
                                    &nbsp;-({{discountApplied}})</span>
                            </span>
                            <span *ngIf="box.checked && discountApplied && calcPercentage(node.price, discountApplied) > 0"
                                  class="body-text-1 mt-neg"><b
                                   class="title invisible">PRICE..</b>&nbsp;₦{{calcPercentage(node.price,
                                discountApplied)}}
                            </span>
                            <span class="error-text"
                                  *ngIf="box.checked && discountApplied && calcPercentage(node.price, discountApplied) <= 0">
                                Cannot add discount to this product because discount is too high
                            </span>
                        </div>
                    </div>
                </mat-checkbox>
            </div>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent">
            <button mat-icon-button
                    disabled></button>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                       class="pl-3 parent-node">
            <mat-checkbox #pBox
                          [checked]="descendantsAllSelected(node)"
                          color="primary"
                          [indeterminate]="descendantsPartiallySelected(node)"
                          (change)="todoItemSelectionToggle(node)">
                <div class="text d-flex">
                    <span class="image-circle"
                          [style.background-image]="'url(' + node.image + ')'">
                    </span>
                    <div class="node-desc">
                        <span>{{node.name}}</span>
                        <span>
                            <span class="body-text-1">
                                <b class="title">PRICE</b>&nbsp;
                                <span
                                      [class.strike-through]="pBox.checked && discountApplied && calcPercentage(node.price, discountApplied) > 0">
                                    ₦{{node.price}}
                                </span>
                            </span>
                            <span *ngIf="pBox.checked && discountApplied && calcPercentage(node.price, discountApplied) > 0"
                                  class="body-text-1">
                                &nbsp;-({{discountApplied}})</span>
                        </span>
                        <span *ngIf="pBox.checked && discountApplied && calcPercentage(node.price, discountApplied) > 0"
                              class="body-text-1 mt-neg"><b
                               class="title invisible">PRICE,,</b>&nbsp;₦{{calcPercentage(node.price,
                            discountApplied)}}</span>
                        <span class="error-text"
                              *ngIf="pBox.checked && discountApplied && calcPercentage(node.price, discountApplied) <= 0">
                            Cannot add discount to product(s) because discount is too high
                        </span>
                    </div>
                </div>
            </mat-checkbox>
        </mat-tree-node>
    </mat-tree>
</div>
<div id="productTree"></div>
