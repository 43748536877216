import { HostListener, OnInit } from '@angular/core';
import { Directive } from '@angular/core';
import { noop } from 'lodash';
import { StateFacade } from '../services/state.facade';

@Directive({
  selector: '[chipsMobileHack]',
})
export class ChipsMobileHackDirective implements OnInit {
  private isMobile;
  constructor(private stateFacade: StateFacade) {}

  ngOnInit() {
    this.stateFacade.getViewPortSize().subscribe(({ isMobile }) => {
      this.isMobile = isMobile;
    });
  }

  @HostListener('input', ['$event.target'])
  fakeCommaEffect(el) {
    this.isMobile && el.value.endsWith(',')
      ? ((el.value = el.value.replace(/[,]$/, '')), el.blur(), el.focus())
      : noop();
  }
}
