<div class="slider bg-darkgrey">
    <div class="container p-0">
        <div class="row m-0">
            <section class="col-12 col-md-6 pl-0 pr-0">
                <div *ngIf="isSmall | async else lgScreen"
                     class="main-text">
                    MAKE <br>
                    MORE <span>SALES</span> <br>
                    ONLINE
                </div>
                <ng-template #lgScreen>
                    <div class="main-text">
                        MAKE MORE <br>
                        <span>SALES</span> ONLINE
                    </div>
                </ng-template>

                <div class="supporting-text">
                    Sell on your ecommerce website, Google Shopping,
                    Instagram, WhatsApp, EasyDeals all from your SellEasy
                    App. <span (click)="scrollToHowItWorks()">Learn more</span>
                </div>
                <div class="ctas">
                    <button *ngIf="!isLoggedIn else dashboard"
                            mat-flat-button
                            class="se-button start-free"
                            routerLink="/signup">START FREE
                    </button>
                    <ng-template #dashboard>
                        <button mat-flat-button
                                class="se-button dashboard"
                                (click)="goTo('/dashboard')">DASHBOARD
                        </button>
                    </ng-template>
                    <a mat-stroked-button
                       [href]="demoUrl"
                       target="_blank"
                       class="se-button se-button--stroke">SEE DEMO STORE
                    </a>
                </div>
                <div class="get-app">
                    <div class="title">
                        Download App
                    </div>
                    <div class="buttons">
                        <a mat-button
                           class="android"
                           href="https://play.google.com/store/apps/details?id=com.selleasy.app"
                           target="_blank"></a>
                        <a class="ios"
                           href="https://apps.apple.com/app/id1556168902"
                           target="_blank"
                           mat-button
                           class="ios"></a>
                    </div>
                </div>
            </section>
            <section class="selleasy-phone-illustration col-12 col-md-6 pr-0">
            </section>
        </div>
    </div>
</div>
