import { Component, OnInit } from '@angular/core';
import { StateFacade } from '../shared/services/state.facade';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  title = 'Account Details';
  subTitle = 'Enter your account details. Click on the icon for more info';
  showInfoBox = true;
  isMobile = false;

  constructor(private stateFacade: StateFacade) {}

  ngOnInit(): void {
    this.stateFacade.getViewPortSize().subscribe(({ isMobile }) => {
      this.isMobile = isMobile;
    });
  }

  onUpdateSubtitle(text) {
    this.subTitle = text;
    this.showInfoBox = false;
  }
}
