<div class="container account-details main-page">
    <div class="row">
        <app-header-title [title]="title"
                          subTitle="Set up your store account settings. Click on the icon for more info"
                          [showInfoBox]="true"
                          [infoBoxContent]="{title: 'Transaction charge & Tax', content: '<p><strong>transaction charge: </strong>There are 2 options for transaction charge settings</p>
                          <p><u>Option 1</u>: Customers pay transaction charge - Customers are prompted to pay transaction charge at checkout<br>
                          <u>Option 2</u>: Seller pays transaction charge (Default option) - When selected, Customers are NOT prompted to pay transaction charge at checkout, the transaction charge will be deducted from your payment this case</p><br>
                          <p><strong>Tax: </strong>There are 2 options for tax settings</p>
                          <p><u>Option 1</u>: Items are tax inclusive (Default option) - This option assumes taxes are inclusive in your product prices, as such Customers are NOT prompted to pay tax at checkout<br>
                          <u>Option 2</u>: Customer pays tax on items sold - When selected, Customers are prompted to pay additional percentage (set below) as tax charges. The taxes are paid to your account along with the product prices.</p>'}">
        </app-header-title>
    </div>
    <div class="row">
        <div class="col-lg split-row account-settings__props p-0">
            <div class="half-row spaced-row">
                <mat-tab-group (selectedTabChange)="getSelectedTab($event)">
                    <mat-tab label="TRANSACTION CHARGE">
                        <div class="account-settings__tab-content">
                            <app-account-service-charge></app-account-service-charge>
                        </div>
                    </mat-tab>
                    <mat-tab label="TAX">
                        <div class="account-settings__tab-content">
                            <app-account-tax></app-account-tax>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="half-row spaced-top">
                <div>
                    <p class="account-settings__charge-text ">
                        <!--span class="account-settings__charge-text-inner">
                            Understand your transaction charge when you choose to pay the transaction charge
                        </span>
                    </p>
                    <br />
                    <app-account-calculate-charge></app-account-calculate-charge-->
                </div>
            </div>
        </div>
    </div>
</div>
