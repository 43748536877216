import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from '../shared/services/account-service';
import { BankCode, SavedBankCodes } from '../shared/models/bank-code';
import { BankDetails } from '../shared/models/bank-details';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import { isEqual } from 'lodash';
import { noWhiteSpace } from '../shared/utils/validations';

@Component({
  selector: 'app-account-details-form',
  templateUrl: './account-details-form.component.html',
  styleUrls: ['./account-details-form.component.scss'],
})
export class AccountDetailsFormComponent implements OnInit {
  @Output() updateSubtitle = new EventEmitter();
  bankForm: FormGroup;
  banks: BankCode[] = [];
  bankDetails: BankDetails;
  selectedBank: any;
  formHasChanges = false;
  formInitialValues = null;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    // setup bank details form
    this.bankForm = this.fb.group({
      bankCode: [
        null,
        [Validators.required, Validators.minLength(3), noWhiteSpace],
      ],
      accountNumber: [
        null,
        [Validators.required, Validators.minLength(10), noWhiteSpace],
      ],
      accountName: [
        null,
        [Validators.required, Validators.minLength(3), noWhiteSpace],
      ],
    });

    this.getBanks();
    this.getAccountDetails();
    this.bankDetails = {} as BankDetails;
  }

  watchFormChanges() {
    this.formInitialValues = this.bankForm.value;
    this.bankForm.valueChanges.subscribe((changes) => {
      this.formHasChanges = !isEqual(changes, this.formInitialValues);
    });
  }

  // TODO: check if form values are different
  /**
   * @description init/ patch bank form
   * @param data bank details
   */
  patchFormValue(data: BankDetails) {
    if (!data) {
      return;
    }

    this.selectedBank = data?.bankCode;
    this.bankForm.patchValue({
      accountNumber: data?.accountNumber ?? '',
      accountName: data?.fullName ?? '',
    });
    this.bankForm.get('accountNumber').disable();
    this.bankForm.get('accountName').disable();
    this.bankForm.get('bankCode').disable();
    this.updateSubtitle.emit(
      'For security reasons, we require users to contact us for changes to account details',
    );
  }

  /**
   * set bank name from the selected dropdown
   * @param event change event
   */
  selectedBankName(event: MatSelectChange) {
    const bankName = (event.source.selected as MatOption).viewValue;
    this.bankDetails.bankName = bankName;
  }

  /**
   * @description get account details
   */
  getAccountDetails() {
    this.accountService.getAccountDetails().subscribe((result) => {
      this.patchFormValue(result);
      this.watchFormChanges();
    });
  }

  /**
   * @description get bank codes
   */
  getBanks() {
    // TODO: sort banks by name
    this.accountService.getBanks().subscribe(
      (result) => {
        this.banks = result['data']['Banks'];
        this.formInitialValues = this.bankForm.value;
        this.formHasChanges = false;
      },
      () => {
        // use locally stored bank codes
        this.banks = this.getSavedBankCodes();
        this.formInitialValues = this.bankForm.value;
        this.formHasChanges = false;
      },
    );
  }

  getSavedBankCodes() {
    return SavedBankCodes.data.Banks as any;
  }

  /**
   * @description send account details to endpoint and update
   */
  submitAccountDetails() {
    if (this.bankForm.invalid) {
      return;
    }
    this.bankDetails.accountNumber = this.bankForm.value.accountNumber;
    this.bankDetails.bankCode = this.bankForm.value.bankCode;
    this.bankDetails.fullName = this.bankForm.value.accountName;

    const data = {
      bankDetails: {
        ...this.bankDetails,
      },
    };

    this.accountService.patchDetails(data).subscribe(
      () => {
        this.formInitialValues = this.bankForm.value;
        this.formHasChanges = false;

        this.snackbar.open(
          `Your account details has been successfully updated`,
          'Close',
          {
            panelClass: 'success',
          },
        );
      },
      (err) => {
        this.snackbar.open(
          'Your account details might be incorrect. If error persists please contact SellEasy',
          'Close',
          {
            panelClass: 'error',
          },
        );
      },
    );
  }
}
