import { INav, IUser } from './selectors';
import { AppActionTypes } from './app.actions';

const initNavState = {
  isMainNav: false,
  isMobile: false,
  pageTitle: null,
};

const initUserState = {
  email: null,
  isLoggedIn: false,
  websiteUrl: null,
};

export const userReducer = (state, action): IUser => {
  switch (action.type) {
    case AppActionTypes.IsLoggedIn:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case AppActionTypes.SetWebsiteUrl:
      return {
        ...state,
        websiteUrl: action.payload,
      };
    default:
      return state;
  }
};

export const navReducer = (state, action): INav => {
  switch (action.type) {
    case AppActionTypes.InitNav:
      return initNavState;

    case AppActionTypes.ToggleNav:
      return {
        ...state,
        isMainNav: action.payload,
      };
    case AppActionTypes.IsMobile:
      return {
        ...state,
        isMobile: action.payload,
      };
    case AppActionTypes.SetPageTitle:
      return {
        ...state,
        pageTitle: action.payload,
      };
    default:
      return state;
  }
};

export const selectedOrderReducer = (state, action) => {
  switch (action.type) {
    case AppActionTypes.SelectOrder:
      return {
        ...state,
        order: action.payload,
      };
    case AppActionTypes.DeliveryType:
      return {
        ...state,
        deliveryType: action.payload,
      };
    default:
      return state;
  }
};
