import { Component, OnInit, ViewChild } from '@angular/core';
import { StateFacade } from '../shared/services/state.facade';
import { STORE_PLANS } from '../shared/models/user-profile';
import { ActivatedRoute } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { defer } from 'lodash';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  title = 'Profile Details';
  showLong = false;
  hidePassword = true;
  tabIndex = 0;
  storePlans = { ...STORE_PLANS };
  userEmail = '';
  @ViewChild('profileTab') profileTab: MatTabGroup;

  constructor(
    private stateFacade: StateFacade,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.stateFacade.getViewPortSize().subscribe((viewPort) => {
      this.showLong = !viewPort.isMobile;
    });
    this.activatedRoute.params.subscribe((p) => {
      if (p.showPlan) {
        defer(() => (this.profileTab.selectedIndex = 2));
      }
    });
  }

  onUserProfile({ email }) {
    this.userEmail = email;
  }

  getSelectedTab(e) {
    this.tabIndex = e.index;
  }
}
