import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { noop } from 'lodash';
import { StateFacade } from '../shared/services/state.facade';

@Component({
  selector: 'app-selleasy-delivery-modal',
  templateUrl: './selleasy-delivery-modal.component.html',
  styleUrls: ['./selleasy-delivery-modal.component.scss'],
})
export class SelleasyDeliveryModalComponent implements OnInit {
  action = noop;
  isMobile = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<any>,
    private statefacade: StateFacade,
  ) {
    this.action = data.action;
  }

  ngOnInit(): void {
    this.statefacade.getViewPortSize().subscribe(({ isMobile }) => {
      this.isMobile = isMobile;
    });
  }

  closeModal() {
    this.dialogRef.close({ continue: false });
  }

  continue() {
    this.dialogRef.close({ continue: true });
  }
}
