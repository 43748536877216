<div class="website-ready container">
    <div class="website-ready__left-col">
        <h1>Hurray! You have successfully created your website
            <a href="{{ 'https://' + websiteName }}"
               target="_">{{ 'www.' + websiteName }}
            </a>
        </h1>
        <p>
            Continue to <span (click)="goTo('/splash-screen')">set up store</span>
            for your custom domain e.g. www.storename.com
        </p>
        <div class="website-ready__button-wrapper">
            <button mat-flat-button
                    color="primary"
                    class="se-button"
                    (click)="goTo('/splash-screen')">
                SET UP YOUR STORE
            </button>
            <button mat-stroked-button
                    (click)="openWebsite()"
                    color="outline"
                    class="se-stroke se-button">
                CONTINUE TO WEBSITE
            </button>
        </div>
    </div>
    <div class="website-ready__right-col"></div>
</div>
