import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import {
  tap,
  mapTo,
  catchError,
  switchMap,
  debounceTime,
  map,
} from 'rxjs/operators';
import { get as _get } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as decode from 'jwt-decode';
import { Router } from '@angular/router';
import { StateFacade } from './state.facade';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  constructor(
    private httpClient: HttpClient,
    private snackbar: MatSnackBar,
    private router: Router,
    private stateFacade: StateFacade,
  ) {}

  private readonly BASEURL = (window as any).API_BASE_URL;
  protected tenantName: string;
  protected businessId: string;
  private timeOut;

  /**
   * get tenant's name from jwt
   */
  getTenantName() {
    const authToken = localStorage.getItem('AUTH_TOKEN');
    if (authToken) {
      const decoded: TenantDetails = decode(authToken);
      return (this.tenantName = `${decoded.firstName} ${decoded.lastName}`);
    }
  }

  /**
   * @description get tenant's businessId from jwt
   * @returns string
   */
  getBusinessId() {
    const authToken = localStorage.getItem('AUTH_TOKEN');
    if (authToken) {
      const decoded: TenantDetails = decode(authToken);
      return (this.businessId = decoded.businessId);
    }
  }

  getAuthtoken() {
    return localStorage.getItem('AUTH_TOKEN') || null;
  }

  /**
   * @description get tenant's id from jwt
   * @returns string
   */
  getUserId() {
    const authToken = localStorage.getItem('AUTH_TOKEN');
    if (authToken) {
      const decoded: TenantDetails = decode(authToken);
      return (this.businessId = decoded.id);
    }
  }

  login(formData) {
    return this.httpClient
      .post<{ token }>(`${this.BASEURL}/users/login`, formData)
      .pipe(
        tap(({ token }) => {
          this.setAutoLogOut(token);
        }),
        mapTo({ isLogin: true, msg: null }),
        catchError(({ error }) =>
          of({
            isLogin: false,
            msg: _get(error, 'error.message'),
          }),
        ),
      );
  }

  sendResetLink(email) {
    return this.httpClient
      .post(`${this.BASEURL}/users/reset/${email}`, null)
      .pipe(catchError((err) => of(err.message)));
  }

  resetPassword(token) {
    return (password) =>
      this.httpClient
        .patch(`${this.BASEURL}/users/reset/${token}`, password)
        .pipe(catchError((err) => of(err.error?.message)));
  }

  setAutoLogOut(token) {
    localStorage.setItem('AUTH_TOKEN', token);
    const { exp } = decode(token);
    this.timeOut = setTimeout(() => {
      this.logout(true);
    }, exp);
    this.stateFacade.setLoginStatus(true);
  }

  signUp(formData) {
    const { email, password } = formData;

    return this.httpClient.post(`${this.BASEURL}/users`, formData).pipe(
      switchMap((_) => this.login({ email, password })),
      mapTo({ isSignup: true, msg: null }),
      catchError(({ error }) =>
        of({
          isSignup: false,
          msg: _get(error, 'error.message'),
        }),
      ),
    );
  }

  checkSiteAvailability = ({ value }) => {
    return this.httpClient.get(`${this.BASEURL}/businessess/${value}`).pipe(
      debounceTime(5000),
      map((canUse: boolean) => (!canUse ? { nameExists: true } : null)),
    );
  };

  checkEmailAvailability = ({ value }) => {
    return this.httpClient.get(`${this.BASEURL}/users/email/${value}`).pipe(
      debounceTime(5000),
      map((canUse: boolean) => (canUse ? { emailExists: true } : null)),
    );
  };

  logout(shouldReroute = false) {
    localStorage.removeItem('AUTH_TOKEN');
    this.stateFacade.setLoginStatus(false);
    this.snackbar.open('You are now logged out.', 'Close', {
      panelClass: 'success',
    });

    if (shouldReroute) {
      this.router.navigate(['signin']);
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeOut);
  }
}

export interface TenantDetails {
  firstName: string;
  lastName: string;
  businessId: string;
  id: string;
}
