<div class="container user-profile main-page">
    <div class="row">
        <app-header-title [title]="title"
                          subTitle="Update your profile and your store details">
        </app-header-title>
    </div>
    <div class="row">
        <div class="col-lg user-profile__props p-0">
            <mat-tab-group #profileTab
                           (selectedTabChange)="getSelectedTab($event)">
                <mat-tab [label]="showLong ? 'STORE PROFILE' : 'STORE'">
                    <div class="user-profile__tab-content">
                        <app-store-profile-edit-form [userEmail]="userEmail"></app-store-profile-edit-form>
                    </div>
                </mat-tab>
                <mat-tab [label]="showLong ? 'YOUR PROFILE' : 'PROFILE'">
                    <div class="user-profile__tab-content">
                        <app-user-profile-edit-form (userProfile)="onUserProfile($event)"></app-user-profile-edit-form>
                    </div>
                </mat-tab>
                <mat-tab [label]="'PLAN'">
                    <div class="user-profile__plan-tab">
                        <app-plan></app-plan>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div *ngIf="tabIndex === 1"
             class="col-lg p-0 d-sm-none d-md-block user-profile__store-bag">
        </div>
        <div *ngIf="tabIndex === 2">
        </div>
    </div>
</div>
