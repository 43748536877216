<div class="container add-product main-page">
    <div class="row"
         id="addProductHeader">
        <app-header-title [title]="productTitle"
                          [subTitle]="productSubtitle">
        </app-header-title>
    </div>
    <app-spinner *ngIf="isSubmitting"></app-spinner>

    <div class="row add-product__content">
        <form [formGroup]="addProductFormGrp"
              enctype="multipart/form-data"
              autocomplete="off">
            <div class="row">
                <mat-form-field appearance="outline"
                                class="col-md-6 se-input-field">
                    <mat-label>Product name</mat-label>
                    <input type="text"
                           id="nameId"
                           formControlName="name"
                           matInput>
                    <mat-error>Please enter a valid product name</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline"
                                class="se-input-field col-md-6">
                    <mat-label>Price</mat-label>
                    <input type="text"
                           id="priceId"
                           [ctrl]="price"
                           numbersOnly
                           formControlName="price"
                           matInput>
                    <mat-error>Please enter product price</mat-error>
                    <div class="body-text-2 add-product__suffix"
                         matSuffix>
                        ₦
                    </div>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="outline"
                                class="se-input-field col-5 col-md-6">
                    <mat-label>Total quantity</mat-label>
                    <input type="text"
                           id="quantityId"
                           [ctrl]="quantity"
                           numbersOnly
                           formControlName="quantity"
                           matInput>
                    <mat-error>Total quantity should be more than zero</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline"
                                class="se-input-field col-7 col-md-6 mb-4"
                                [ngClass]="{'pl-0': isMobile}">
                    <mat-label>Weight</mat-label>
                    <input type="text"
                           id="weightId"
                           [ctrl]="weight"
                           numbersOnly
                           formControlName="weight"
                           placeholder="Weight per product"
                           matInput>
                    <mat-hint>
                        <div class="d-flex add-product__weight-hint">
                            <app-info-box title="Weight"
                                          content="The product weight is used to calculate shipping cost. Please provide accurate value in <b>kilograms</b> to ensure the shipping rate is accurate">
                            </app-info-box>
                            <div class="ml-2">Click icon for more information</div>
                        </div>
                    </mat-hint>
                    <mat-error>
                        Please enter product weight
                    </mat-error>
                    <div class="body-text-2 add-product__suffix"
                         matSuffix>
                        Kg
                    </div>
                </mat-form-field>
                <mat-form-field [hidden]="true"
                                appearance="outline"
                                class="se-input-field mb-4 col-md-12">
                    <mat-label>Processing time</mat-label>
                    <input type="text"
                           matInput>
                    <mat-error>This field seems invalid</mat-error>
                    <div class="body-text-2 add-product__suffix"
                         matSuffix>
                        days
                    </div>
                    <mat-hint>Number of days to make product available</mat-hint>
                </mat-form-field>
            </div>

            <div class="editor mt-2"
                 id="editorWrapper"
                 [ngClass]="{'error-outline': description.touched && description.errors}">
                <ngx-editor-menu [editor]="editor"
                                 [toolbar]="toolbar">
                </ngx-editor-menu>
                <ngx-editor [editor]="editor"
                            placeholder=""
                            formControlName="description">
                </ngx-editor>
            </div>
            <div *ngIf="description.touched && description.errors else customHint"
                 class="mat-error mt-2 fs-10">Please enter a product description</div>
            <ng-template #customHint>
                <div class="mt-2 fs-10">
                    Product description. Say more about this product
                </div>
            </ng-template>


            <!-- Category -->
            <div class="add-product__nav-tab mt-5">
                <h4 class="heading-text-3 d-flex">
                    <div class="d-flex align-items-center">
                        <div>Product category</div>
                        <app-info-box class="ml-3 add-product__category-info"
                                      title="Product category"
                                      content="<p>Categorise related products. 
                                          Assuming you sell clothes, you could categorise Gucci brands as GUCCI.
                                          </p>">
                        </app-info-box>
                    </div>
                </h4>
                <div class="row"
                     class="slide"
                     [class.closed]="!showCategory">
                    <mat-form-field appearance="outline">
                        <mat-label>Please select a product category</mat-label>
                        <mat-select class="mat-elevation-z0"
                                    [formControl]="category">
                            <mat-option *ngFor="let categ of CATEGORIES"
                                        [value]="categ">
                                {{categ}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            Please select a category
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>


            <div class="add-product__images">
                <div class="d-flex justify-content-between">
                    <h4 class="heading-text-3">Product images</h4>
                    <span *ngIf="isMobile"
                          class="align-self-center">{{imagePosition + 1}}/5</span>
                </div>
                <app-product-slider [sliderImages]="images.value"
                                    (imagePosition)="getSliderImagePosition($event)"
                                    (deleteList)="setDeleteList($event)">
                </app-product-slider>
            </div>

            <ng-template #navigationInfo>
                <div class="full-info-box-content">
                    <h4 class="heading-text-3--lg">Navigation and Sub-Navigation</h4>
                    <div class="no-overflow">
                        <img class="w-100"
                             src="../../../assets/navigation-info.png"
                             alt="selleasy - navigation description">
                    </div>
                    <div class="content-text">
                        <h5 class="mt-4">GROUP (navigation)</h5>
                        <p>
                            Assuming you sell clothes you could have a group called <i>MEN</i>.
                            It appears as a group button on your website. When your customer clicks, it displays all
                            men's clothes.
                        </p>

                        <h5 class="mt-4">SUB-GROUP (sub-navigation)</h5>
                        <p>
                            Within a group you can have groups. For example within the <i>MEN</i> group, you
                            could have <i>SHIRTS, TROUSERS</i> etc.
                        </p>

                        <h5 class="mt-4">MANAGE GROUPS AND SUB-GROUPS</h5>
                        <p>
                            To delete, add and edit your groups/sub-groups, go to <a href="javascript:void()"
                               (click)="gotoWebsiteCustomize()">Customize Your Website</a>
                        </p>
                    </div>
                </div>
            </ng-template>

            <!-- Product variants -->
            <mat-accordion displayMode="flat"
                           class="mt-4">
                <mat-expansion-panel togglePosition="before"
                                     (opened)="prepSubNavs(navigation.value)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Product variants <small class="mt-1">&nbsp;(Optional)</small>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <app-product-variant #productVariants
                                         [showProductVariant]="showProductVariant"
                                         [variants]="variants.value"
                                         [generateVariant]="generateVariant"
                                         [editMode]="editMode"
                                         [productVariation]="productVariation"
                                         [businessVariationList]="businessVariationList"
                                         [totalQuantity]="quantity.value"
                                         [price]="price.value"
                                         (variantErrors)="initVariantErrors($event)"
                                         (emptyOptFieldRef)="emptyVariantOptionField($event)"
                                         (emptyPriceFieldRef)="emptyVariantPriceField($event)">
                    </app-product-variant>
                </mat-expansion-panel>
            </mat-accordion>


            <!-- Navigation -->
            <mat-accordion displayMode="flat"
                           class="mt-4">
                <mat-expansion-panel togglePosition="before"
                                     (opened)="prepSubNavs(navigation.value)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Website navigation <small class="mt-1">&nbsp;(Optional)</small>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="add-product__nav-tab">
                        <h4 class="heading-text-3 d-flex">
                            <div>
                                <span>Add or choose a navigation item for your product</span>
                                <app-full-info-box class="d-inline-block ml-3"
                                                   [template]="navigationInfo">
                                </app-full-info-box>
                            </div>
                        </h4>
                        <p class="f-normal pt-1 pb-3"><span class="f-600">What is website navigation?</span> Please
                            click on the question icon or
                            watch <a class="a-tag"
                               href="https://www.youtube.com/watch?v=1XcgjoBGVsw&feature=youtu.be"
                               target="_blank">this
                                short video</a> to learn more</p>
                        <div class="row"
                             class="slide"
                             [class.closed]="!showCategory">
                            <mat-form-field appearance="outline"
                                            class="se-input-field col-md-6 add-product__nav-tab-field1 mb-4">
                                <mat-label>Add or choose a navigation item</mat-label>
                                <input type="text"
                                       seInputTrim
                                       [regex]="forwardSlashRegex"
                                       inputFilter
                                       [formCtrl]="navigation"
                                       formControlName="navigation"
                                       #autoInputNav="matAutocompleteTrigger"
                                       [matAutocomplete]="auto2"
                                       matInput>
                                <div *ngIf="navigationTypeAhead.showAddButton"
                                     class="se-suffix-2"
                                     (click)="navigationTypeAhead.addOption('nav', storeConfig)"
                                     matSuffix>
                                    <se-icon iconName="add"
                                             [lg]="true">
                                    </se-icon>
                                </div>
                                <div class="body-text-2 se-suffix pl-2"
                                     (click)="navigationTypeAhead.openPanel()"
                                     matSuffix>
                                    <se-icon iconName="arrow-down"
                                             size="sm"></se-icon>
                                </div>
                                <mat-autocomplete #auto2="matAutocomplete">
                                    <mat-option *ngFor="let option of navigationTypeAhead.filteredOptions | async"
                                                [value]="option"
                                                (click)="selectOption(option, true)">
                                        <div class="w-100 d-flex justify-content-between align-items-center">
                                            <span>{{option}}</span>
                                            <div *ngIf="option !== 'Home' && option !== 'Products'"
                                                 class="p-2"
                                                 (click)="navigationTypeAhead.removeOption(option)">
                                                <se-icon iconName="cancel"
                                                         size="sm">
                                                </se-icon>
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-hint>
                                    <div class="d-flex add-product__weight-hint">
                                        <se-icon iconName="info-icon-circle"
                                                 size="md"></se-icon>
                                        <div class="ml-2">You cannot add more than 5 navigation items</div>
                                    </div>
                                </mat-hint>
                                <mat-error>Please add navigation item to your list using the add button
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline"
                                            class="se-input-field col-md-6 add-product__nav-tab-field2">
                                <mat-label>Add or choose a sub-navigation item (optional)</mat-label>
                                <input type="text"
                                       seInputTrim
                                       [regex]="forwardSlashRegex"
                                       inputFilter
                                       [formCtrl]="subNavigation"
                                       formControlName="subNavigation"
                                       #autoInputSubNav="matAutocompleteTrigger"
                                       [matAutocomplete]="auto3"
                                       matInput>
                                <div *ngIf="subNavigationTypeAhead.showAddButton"
                                     class="se-suffix-2"
                                     (click)="subNavigationTypeAhead.addOption('subNav', storeConfig, businessId, nav)"
                                     matSuffix>
                                    <se-icon iconName="add"
                                             [lg]="true">
                                    </se-icon>
                                </div>
                                <div class="body-text-2 se-suffix pl-2"
                                     (click)="subNavigationTypeAhead.openPanel()"
                                     matSuffix>
                                    <se-icon iconName="arrow-down"
                                             size="sm"></se-icon>
                                </div>
                                <mat-autocomplete #auto3="matAutocomplete">
                                    <mat-option *ngFor="let option of subNavigationTypeAhead.filteredOptions | async"
                                                [value]="option">
                                        <div class="w-100 d-flex justify-content-between align-items-center">
                                            <span>{{option}}</span>
                                            <div *ngIf="option !== 'Home' && option !== 'Products'"
                                                 class="p-2"
                                                 (click)="subNavigationTypeAhead.removeOption(option)">
                                                <se-icon iconName="cancel"
                                                         size="sm">
                                                </se-icon>
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-hint>
                                    Add a sub-navigation to your selected navigation item
                                </mat-hint>
                                <mat-error>Please add sub-navigation to your list using the add button
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>

            <app-error-alert *ngIf="hasVariantErrors"
                             text="The sum of variants quantity is more than the total quantity.">
            </app-error-alert>

            <div class="add-product__form-ctas d-flex justify-content-between mt-5 mb-5 pt-5">
                <button mat-stroked-button
                        type="button"
                        (click)="submit()"
                        color="primary"
                        class="se-button se-button--stroke">
                    SAVE
                </button>
                <button mat-flat-button
                        type="button"
                        (click)="submit(true)"
                        color="primary"
                        class="se-button">
                    SAVE & MANAGE {{isMobile ? '' : 'PRODUCTS'}}
                </button>
            </div>
        </form>
    </div>
</div>
